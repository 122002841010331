import { Link } from '@mui/material';
import { ReactNode, useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { AppListItem, AppListSectionData } from 'src/types/common/AppListSectionData';
import { getSoshikiLeaders } from 'src/utilities/restApi/common/soshikiLeaders';

const suspendDataList = [
  {
    header: '所属先（代表者）情報',
    items: [
      { title: '地域', value: <AppSkeleton /> },
      { title: '＜代表者＞', value: <AppSkeleton /> },
      { title: '氏名', value: <AppSkeleton /> },
      { title: '氏名カナ', value: <AppSkeleton /> },
      { title: '連絡先', value: <AppSkeleton /> },
    ],
  },
] as AppListSectionData[];

export const useSoshikiLeadersSectionDataList = (
  clickPhoneNumberCallback: (phoneNumber: string, message: ReactNode) => void
) => {
  const [sectionDataList, setSectionDataList] = useState<AppListSectionData[]>(suspendDataList);

  const getSectionDataList = (soshikiidList?: string[]) => {
    return new Promise<AppListSectionData[]>((resolve, reject) => {
      if (soshikiidList && soshikiidList.length > 0) {
        getSoshikiLeaders({ soshikiidList })
          .then((response) => {
            if (response.data?.soshiki_leaders) {
              const sectionDataList = response.data.soshiki_leaders.map((x, x_index, x_list) => {
                const leaders =
                  x.leaders?.map(
                    (y, y_index, y_list) =>
                      [
                        { title: `＜代表者${y_list.length > 1 ? y_index + 1 : ''}＞` },
                        { title: '氏名', value: `${y.fname} ${y.sname}` },
                        { title: '氏名カナ', value: `${y.kfname} ${y.ksname}` },
                        {
                          title: '連絡先',
                          value: y.telno && (
                            <Link
                              onClick={() => {
                                clickPhoneNumberCallback(y.telno!, '代表者に電話をかけますか？');
                              }}
                            >
                              {y.telno}
                            </Link>
                          ),
                        },
                      ] as AppListItem[]
                  ) ?? [];

                return {
                  header: `所属先（代表者）情報${x_list.length > 1 ? `（${x_index + 1}）` : ''}`,
                  items: [{ title: '地域', value: x.soshikiname }, ...leaders.flat()],
                };
              });

              setSectionDataList(sectionDataList);
              resolve(sectionDataList);
            } else {
              setSectionDataList([]);
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        setSectionDataList([]);
        resolve([]);
      }
    });
  };

  return { sectionDataList, getSectionDataList };
};
