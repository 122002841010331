import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import {
  RequestGetSupporter,
  RequestPatchSupporter,
  ResponseGetSupporter,
} from 'src/utilities/restApi/shien/types/supporters';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/supporters';

export const getSupporter = async (params: RequestGetSupporter) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetSupporter>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetSupporter>>(`${url}/${params.spid}`, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError) => reject(error.response?.status));
  });
};

export const patchSupporter = async (params: RequestPatchSupporter) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<string>>((resolve, reject) => {
    restApi()
      .patch<SuccessResponse<string>>(`${url}/${params.spid}`, params.data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
