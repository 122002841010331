import { CircularProgress, Container, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { ListButton } from 'src/components/shien/atoms/buttons/ListButton';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { HeaderWithBackButtonTemplate } from 'src/components/shien/templates/HeaderWithBackButtonTemplate';
import { appConst } from 'src/constants/common';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { isStatusColor } from 'src/types/common/statusColor';
import { PrsSafetyRegistrationFlowType } from 'src/types/shien/PrsSafetyRegistrationFlowType';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';
import { getPrsStatus } from 'src/utilities/restApi/common/prsStatus';

type PrsStatus = {
  prsstatusid: string;
  jichitaiid: string;
  status: string;
  color: string;
  sortorder: number;
};

export const PrsRegisterSafetyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const theme = useTheme();
  const { prs, refreshData, initialized } = useContext(PrsContext);

  // states
  const { userData } = useUserData();
  const { setShowBackdrop } = useBackdrop();
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [prsStatusList, setPrsStatusList] = useState<PrsStatus[]>([]);

  type FromState = { prsSafetyRegistrationFlowType: PrsSafetyRegistrationFlowType };
  const fromState = location.state as FromState;
  const getPageTitle = (type: PrsSafetyRegistrationFlowType) => {
    if (type === 'register-from-visit' || type === 'register-from-evacuate') {
      return '安否を登録';
    } else if (type === 'change') {
      return '安否を変更';
    }
    throw new Error('invalid types : PrsSafetyRegistrationFlowType');
  };

  const pageTitle = getPageTitle(fromState.prsSafetyRegistrationFlowType);

  useEffect(() => {
    if (userData) {
      const fetchPrsStatusist = async () => {
        try {
          // 自治体IDに紐づく安否ステータスを取得
          const res = await getPrsStatus({ jichitaiid: userData.jichitaiId });
          let resPrsStatusList = res.data!.prsstatus_list as PrsStatus[];

          if (
            fromState.prsSafetyRegistrationFlowType === 'register-from-visit' ||
            fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate'
          ) {
            // 遷移元が自宅訪問画面または避難先同行画面の場合、「未確認」は選択肢から除く
            resPrsStatusList = resPrsStatusList.filter(
              (item) => item.prsstatusid !== appConst.ID_PRS_STATUTS_UNCHECKED
            );
          }

          // ステータス並び優先度の昇順でソート
          const sortedList = resPrsStatusList.sort((n1, n2) => n1.sortorder - n2.sortorder);

          setPrsStatusList(sortedList);
        } catch (e) {
          setError(true);
        }
      };

      setLoading(true);

      fetchPrsStatusist()
        .then(() => setLoading(false))
        .catch(() => {});
    }
  }, [userData]);

  // apiでerror時にエラー画面に遷移
  useEffect(() => {
    if (error) {
      navigateSystemError();
    }
  }, [error, navigate]);

  // 安否ステータス選択
  const handleSelect = async (prsStatusId: string) => {
    setShowBackdrop(true);

    try {
      // 要支援者情報の更新
      if (prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED) {
        // 安否状況で「未確認」が選択された場合、併せて避難状況をクリアする
        await patchPrsDetail({
          prsid: prs!.prsId,
          prsstatusid: prsStatusId,
          shelter_type_code: null,
          shelterid: null,
          custom_shelter_name: null,
          lastprsstatusupdatedat: true,
        });
      } else {
        await patchPrsDetail({ prsid: prs!.prsId, prsstatusid: prsStatusId, lastprsstatusupdatedat: true });
      }

      // 要支援者の情報を最新化
      await refreshData();

      if (fromState.prsSafetyRegistrationFlowType === 'change') {
        // 安否登録フローが「変更」の場合、安否サマリ画面に戻る
        navigate('/app/shien/prs/safety-summary');
      } else if (
        fromState.prsSafetyRegistrationFlowType === 'register-from-visit' ||
        fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate'
      ) {
        // 安否登録フローが「登録」の場合、避難先登録画面に遷移する
        navigate('/app/shien/prs/register-shelter-type', {
          state: { prsSafetyRegistrationFlowType: fromState.prsSafetyRegistrationFlowType },
        });
      }
    } catch {
      navigateSystemError();
    } finally {
      setShowBackdrop(false);
    }
  };

  return (
    <PageContainer title={`${pageTitle}| 避難行動支援`}>
      <HeaderWithBackButtonTemplate
        headlineText={`${pageTitle}`}
        upperText={(initialized && `${prs?.fName} ${prs?.sName}`) || <AppSkeleton width="100px" />}
        onBackClick={() => {
          if (fromState.prsSafetyRegistrationFlowType === 'change') {
            // 安否登録フローが「変更」の場合、安否サマリ画面に戻る
            navigate('/app/shien/prs/safety-summary');
          } else if (fromState.prsSafetyRegistrationFlowType === 'register-from-visit') {
            // 安否登録フローが「登録（自宅訪問から）」の場合、自宅訪問画面に戻る
            navigate('/app/shien/prs/action/visit');
          } else if (fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate') {
            // 安否登録フローが「登録（避難先同行から）」の場合、避難先同行画面に戻る
            navigate('/app/shien/prs/action/evacuate');
          }
        }}
      >
        {isLoading ? (
          <Container sx={{ py: 1, height: '100%' }}>
            <Stack alignItems="center" justifyContent="center" height="100%">
              <CircularProgress />
            </Stack>
          </Container>
        ) : (
          <Container sx={{ pt: 1, pb: 2 }}>
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} mb={1} px={1}>
                <Typography sx={{ p: 1 }}>対象者の安否を教えてください。</Typography>
                {prsStatusList.length === 0 ? (
                  <Typography
                    textAlign="center"
                    sx={{ pt: 6, fontSize: '24px', color: theme.palette.uiStandard.gray40 }}
                  >
                    ステータスが未定義です。
                  </Typography>
                ) : (
                  prsStatusList.map((prsstatus) => (
                    <React.Fragment key={prsstatus.prsstatusid}>
                      <ListButton
                        statusText={prsstatus.status ?? ''}
                        barColor={isStatusColor(prsstatus.color) ? prsstatus.color : 'none'}
                        displayChecked={initialized && prs?.prsStatusId === prsstatus.prsstatusid}
                        displayArrow={false}
                        onClick={() => void handleSelect(prsstatus.prsstatusid)}
                      />
                    </React.Fragment>
                  ))
                )}
              </Stack>
            </Stack>
          </Container>
        )}
      </HeaderWithBackButtonTemplate>
    </PageContainer>
  );
};
