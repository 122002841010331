import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { PrsStatusRow } from 'src/components/kanri/pages/jichitai/settings/StatusSettingPage/logics/statusSettingPage';
import { TransitionUp } from 'src/components/shien/atoms/TransitionUp';
import * as yup from 'yup';

const colors = [
  { color: 'red', label: '赤' },
  { color: 'yellow', label: '黄' },
  { color: 'green', label: '緑' },
];

const INPUT_WIDTH = '280px';
const LABEL_WIDTH = '90px';

// ダイアログの型定義
type EditDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: PrsStatusRow) => void;
  initialData: PrsStatusRow; // 新規の場合はundefined, 編集の場合は既存データが入る
};

type FormValues = {
  status?: string;
  color?: string;
  sortorder?: string;
};

export const EditDialog = ({ isOpen, onClose, onSave, initialData }: EditDialogProps) => {
  // validation
  const schema: yup.ObjectSchema<FormValues> = yup.object({
    status: yup
      .string()
      .required()
      .matches(/^\S(.*\S)?$/, '前後に空白を含むことはできません')
      .max(7),
    color: yup.string().required(),
    sortorder: yup
      .string()
      .required()
      .matches(/^[1-9]{1}$/, '1～9の整数を半角で入力してください'), // 未確認を除く1～9
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      status: initialData.status ?? undefined,
      color: initialData.color ?? undefined,
      sortorder: String(initialData.sortorder) ?? undefined,
    },
    resolver: yupResolver(schema),
  });

  // ダイアログが開かれたときにフォームをリセット
  useEffect(() => {
    if (isOpen) {
      reset();
      setValue('status', initialData.status ?? undefined);
      setValue('color', initialData.color ?? undefined);
      setValue('sortorder', String(initialData.sortorder) ?? undefined);
    }
  }, [isOpen, reset]);

  if (!isOpen) return null;

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    initialData.status = data.status ?? '';
    initialData.color = data.color ?? '';
    initialData.sortorder = Number(data.sortorder) ?? '';
    onSave(initialData);
    onClose();
  };

  return (
    <Dialog disableRestoreFocus open={isOpen} TransitionComponent={TransitionUp} onClose={onClose}>
      <PageContainer title={`避難行動支援`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h4">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box>{initialData.prsstatusid === '' ? '新規登録' : '編集'}</Box>
              <span style={{ color: 'red', margin: '0 0 0 100px', fontSize: 14 }}>
                *は必須項目です。
                <br />
                名称は7文字まで入力できます。
                <br />
                並び順は1～9まで入力できます。
              </span>
            </Stack>
          </DialogTitle>
          <Stack sx={{ height: '100%' }}>
            <Paper variant="outlined" sx={{ px: 2, pt: 4 }}>
              <Stack>
                {/* 名称 */}
                <Stack direction="column">
                  <Stack direction="row" alignItems="center" spacing={1} mb={1} px={1}>
                    <InputLabel sx={{ width: LABEL_WIDTH }}>
                      名称 <span style={{ color: 'red', fontSize: 12 }}>※</span>
                    </InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          error={'status' in errors}
                          sx={{ width: INPUT_WIDTH }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1} mb={1} px={1}>
                    <Box sx={{ width: LABEL_WIDTH, height: '24px' }} />
                    {errors.status && <Typography sx={{ color: 'red' }}>{errors.status.message}</Typography>}
                  </Stack>
                </Stack>
                {/* 背景色 */}
                <Stack direction="column">
                  <Stack direction="row" alignItems="center" spacing={1} mb={1} px={1}>
                    <InputLabel sx={{ width: LABEL_WIDTH }}>
                      背景色 <span style={{ color: 'red', fontSize: 12 }}>※</span>
                    </InputLabel>
                    <Controller
                      name="color"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} autoComplete="off" error={'color' in errors} sx={{ width: INPUT_WIDTH }}>
                          {colors.map((color) => (
                            <MenuItem key={color.color} value={color.color}>
                              {color.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1} mb={1} px={1}>
                    <Box sx={{ width: LABEL_WIDTH, height: '24px' }} />
                    {errors.color && <span style={{ color: 'red' }}>{errors.color.message}</span>}
                  </Stack>
                </Stack>
                {/* 並び順 */}
                <Stack direction="column">
                  <Stack direction="row" alignItems="center" spacing={1} mb={1} px={1}>
                    <InputLabel id="demo-simple-select-label" sx={{ width: LABEL_WIDTH }}>
                      並び順 <span style={{ color: 'red', fontSize: 12 }}>※</span>
                    </InputLabel>
                    <Controller
                      name="sortorder"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          error={'sortorder' in errors}
                          sx={{ width: INPUT_WIDTH }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1} mb={1} px={1}>
                    <Box sx={{ width: LABEL_WIDTH, height: '24px' }} />
                    {errors.sortorder && <span style={{ color: 'red' }}>{errors.sortorder.message}</span>}
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
            <Stack direction="row" spacing={1.5} sx={{ display: 'flex', justifyContent: 'flex-end', m: '10px' }}>
              <Box>
                <Button onClick={() => onClose()}>やめる</Button>
              </Box>
              <Box>
                <Button variant="contained" type="submit">
                  保存する
                </Button>
              </Box>
            </Stack>
          </Stack>
        </form>
      </PageContainer>
    </Dialog>
  );
};
