import { Box } from '@mui/material';
import { FC } from 'react';

interface ComponentProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: FC<ComponentProps> = ({ children, value, index }) => {
  return (
    <Box hidden={value !== index} sx={{ px: 0, py: 3, flexGrow: 1, overflow: 'auto' }}>
      {value === index && children}
    </Box>
  );
};
