import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { IconBack } from 'src/components/shien/atoms/images/IconBack';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '12px 16px 12px 8px',
  borderRadius: '0px 10px 10px 0px',
  border: `1px solid ${theme.palette.uiStandard.gray25}`,
  borderLeft: 'none',
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.uiStandard.black,
  textAlign: 'center',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
}));

type ComponentProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const BackButton = ({ onClick, disabled = false }: ComponentProps) => {
  return (
    <Box>
      <StyledButton variant="secondary" onClick={onClick} disabled={disabled}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <Box>
            <IconBack />
          </Box>
          <ButtonText>戻る</ButtonText>
        </Stack>
      </StyledButton>
    </Box>
  );
};
