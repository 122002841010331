import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useEffect, useMemo } from 'react';
import {
  SearchType,
  UserGridRow,
  UserGridRowSoshiki,
} from 'src/components/kanri/pages/jichitai/CreateAccountPage/components/UsersTable/types';
import { appConst, RoleId } from 'src/constants/common';

type ComponentProps = {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  roleid: RoleId;
  searchType: SearchType;
  rows: readonly UserGridRow[];
  rowCount: number;
  isLoading: boolean;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel, details: GridCallbackDetails) => void;
};

export const UsersTable = ({
  apiRef,
  roleid,
  searchType,
  rows,
  rowCount,
  isLoading,
  paginationModel,
  setPaginationModel,
}: ComponentProps) => {
  // ページ切り替え時にチェックボックスの選択を解除
  useEffect(() => {
    apiRef.current.setRowSelectionModel([]);
  }, [paginationModel]);

  const columns: GridColDef<UserGridRow>[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'name', headerName: '氏名', width: 150, disableColumnMenu: true, sortable: false },
      {
        field: 'mailAddress',
        headerName: 'メールアドレス',
        width: 160,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: 'soshikiList',
        headerName: '所属地域',
        width: 200,
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<UserGridRow, UserGridRowSoshiki[]>) => {
          return params.row.soshikiList?.map((x) => x.soshikiName).join(', ');
        },
      },
      {
        field: 'evacuationPlanAccountStatus',
        renderHeader: () => (roleid === RoleId.Supporter ? '個別データ' : '個別アカウント'),
        width: 140,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<UserGridRow, boolean>) => {
          return params.row.delflg ? '削除' : '登録済み';
        },
      },
      {
        field: 'mutualAssistanceAccountStatus',
        headerName: '共助アカウント',
        width: 140,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<UserGridRow, string>) => {
          return params.row.linkedAadb2cTypeCode === appConst.LINKED_AADB2C_TYPE_CODE.LINKED
            ? '発行済み'
            : params.row.linkedAadb2cTypeCode === appConst.LINKED_AADB2C_TYPE_CODE.NOT_LINKED
            ? '未発行'
            : params.row.linkedAadb2cTypeCode === appConst.LINKED_AADB2C_TYPE_CODE.LINK_FAILURE
            ? '失敗'
            : '(不明)';
        },
      },
    ],
    []
  );

  return (
    <DataGrid
      checkboxSelection
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      rowCount={rowCount}
      loading={isLoading} // 初回orページ切替時のみローディング表示
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      isRowSelectable={(params: GridRowParams<UserGridRow>) => {
        return params.row.mailAddress !== null && params.row.mailAddress.length > 0;
      }} // メールアドレスがnullまたは空の行は選択不可
      pageSizeOptions={[]}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      slotProps={{
        loadingOverlay: {
          variant: searchType === 'search' ? 'circular-progress' : 'linear-progress',
          noRowsVariant: 'skeleton',
          sx: searchType === 'search' ? {} : { backgroundColor: 'transparent' },
        },
      }}
    />
  );
};
