export const IconCheck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" strokeWidth="0px">
      <circle cx="16" cy="16" r="12" fill="#147942" strokeWidth="0px" />
      <path
        d="M15,21c-.26,0-.52-.1-.71-.29l-4.35-4.35c-.2-.2-.2-.51,0-.71l.71-.71c.2-.2.51-.2.71,0l3.59,3.59,5.62-6.56c.18-.21.5-.23.71-.05l.76.65c.21.18.23.5.05.71l-6.33,7.38c-.18.21-.44.34-.72.35-.01,0-.03,0-.04,0Z"
        fill="#fff"
        strokeWidth="0px"
      />
    </svg>
  );
};
