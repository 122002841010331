import { Box, CircularProgress, Container, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'react-use';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { Footer } from 'src/components/shien/atoms/Footer';
import { CardButton } from 'src/components/shien/atoms/buttons/CardButton';
import { SortButton } from 'src/components/shien/atoms/buttons/SortButton';
import { HeaderWithUncheckedCount } from 'src/components/shien/pages/PrsListPage/components/HeaderWithUncheckedCount';
import { PrsOrderSelectionModal } from 'src/components/shien/pages/PrsListPage/components/PrsOrderSelectionModal';
import { usePrsList } from 'src/components/shien/pages/PrsListPage/hooks/usePrsList';
import { PrsOrderKind, prsOrders } from 'src/components/shien/pages/PrsListPage/logics/prsOrders';
import { PrsForPrsList } from 'src/components/shien/pages/PrsListPage/types';
import { dataStore, selectedPrsIdAtom } from 'src/components/shien/pages/prs/dataStore';
import { appConst } from 'src/constants/common';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { determineShelterName } from 'src/utilities/appUtil/commonUtil';

export const PrsListPage = () => {
  // state
  const { prsList, isError, initialized, refreshData } = usePrsList({ initSearch: true });
  const [sortedList, setSortedList] = useState<PrsForPrsList[]>([]);
  const [PrsOrder, setPrsOrder] = useState<PrsOrderKind>('status');
  const [modalOpen, setModalOpen] = useState(false);

  // hook
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const theme = useTheme();

  // 10秒周期の画面更新
  // 初回の処理完了後(initialized = true)、10秒周期の更新タイマーを開始する
  const POLLING_INTERVAL = 10000; // 画面更新周期(ms)
  useInterval(() => refreshData(), initialized ? POLLING_INTERVAL : null);

  // 要支援者カードクリック
  const handleSelect = (prsId: string) => {
    dataStore.set(selectedPrsIdAtom, prsId);
    navigate('/app/shien/prs/summary');
  };

  // apiでerror時にエラー画面に遷移
  useEffect(() => {
    if (isError) {
      navigateSystemError();
    }
  }, [isError, navigate]);

  // ソート
  useEffect(() => {
    const sorted = prsOrders[PrsOrder].sort(prsList);
    setSortedList(sorted);
  }, [prsList, PrsOrder]);

  return (
    <PageContainer title="要支援者一覧">
      <Stack height="100%">
        {/* ヘッダー */}
        <Box sx={{ position: 'static', top: 0, left: 0, right: 0, pt: theme.spacing(4) }}>
          <HeaderWithUncheckedCount
            top={prsList.filter((x) => x.prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED).length}
            bottom={prsList.length}
          />
        </Box>

        {/* 並び順 */}
        <Container sx={{ py: theme.spacing(2) }}>
          <SortButton
            buttonText={prsOrders[PrsOrder].label}
            onClick={() => setModalOpen(true)}
            disabled={prsList.length === 0}
          />
        </Container>

        {/* 一覧 */}
        <Container
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            pb: 2,
          }}
        >
          {!initialized ? (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <CircularProgress />
            </Stack>
          ) : (
            <Stack spacing={2}>
              {sortedList.length === 0 ? (
                <Typography textAlign="center" sx={{ pt: 6, fontSize: '24px', color: theme.palette.uiStandard.gray40 }}>
                  担当する要支援者はいません。
                </Typography>
              ) : (
                sortedList.map((prs) => (
                  <CardButton
                    key={prs.prsId}
                    status={prs.prsStatusName}
                    statusColor={prs.prsStatusColor}
                    shelterName={determineShelterName(
                      prs.prsStatusId,
                      prs.shelterTypeCode,
                      prs.shelterName,
                      prs.customShelterName,
                      { unregistered: '' }
                    )}
                    memo={prs.prsMemo}
                    name={prs.prsName}
                    share={prs.share}
                    consent={prs.agreement}
                    address={prs.address}
                    prsActionType={prs.prsActionType}
                    onClick={() => handleSelect(prs.prsId)}
                  />
                ))
              )}
            </Stack>
          )}
        </Container>

        {/* フッター */}
        <Box>
          <Footer mode="supporter" selected="対象者一覧" />
        </Box>
      </Stack>

      {/* 並び順選択モーダル */}
      <PrsOrderSelectionModal
        open={modalOpen}
        current={PrsOrder}
        onClose={() => setModalOpen(false)}
        onSelect={setPrsOrder}
      />
    </PageContainer>
  );
};
