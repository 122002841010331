import { useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { Illust01 } from 'src/components/shien/atoms/images/Illust01';
import { ButtonPageTemplate } from 'src/components/shien/templates/ButtonPageTemplate';

export const EnsuringSafetyPage = () => {
  const navigate = useNavigate();
  return (
    <PageContainer title="安全確保確認">
      <ButtonPageTemplate
        headline="安全を確保してください"
        description="災害が発生しました。ご自身やご家族の安全を確保してください。確保ができたら、「安全を確保した」を押してください。"
        primary={{
          text: '安全を確保した',
          onClick: () => {
            navigate('/app/shien/possibility/question', {
              state: { from: 'EnsuringSafetyPage' },
            });
          },
        }}
      >
        <Illust01 />
      </ButtonPageTemplate>
    </PageContainer>
  );
};
