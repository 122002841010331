import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ResponseGetJianinfo } from 'src/utilities/restApi/common/types/jianinfo';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

export const getJianinfo = async () => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetJianinfo>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetJianinfo>>('jianinfo', config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
