import { Button, Stack, Typography } from '@mui/material';
import { IconCheck } from 'src/components/shien/atoms/images/IconCheck';

type PrsOrderItemButtonProps = {
  label: string;
  checked: boolean;
  onClick: () => void;
};

export const PrsOrderItemButton = ({ label, checked, onClick }: PrsOrderItemButtonProps) => {
  return (
    <Button onClick={onClick} fullWidth sx={{ p: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="center" width="100%" sx={{ minHeight: '32px' }}>
        <Typography
          textAlign="left"
          flexGrow={1}
          sx={{ fontSize: '20px', fontStyle: 'normal', fontWeight: 'normal', lineHeight: '20px' }}
        >
          {label}
        </Typography>
        {checked && (
          <Stack alignItems="center" justifyContent="center">
            <IconCheck />
          </Stack>
        )}
      </Stack>
    </Button>
  );
};
