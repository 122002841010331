type ComponentProps = {
  fill?: string;
};

export const IconNo = ({ fill = '#b33d35' }: ComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.98 5L2 50H53.96L27.98 5ZM27.98 17L12.39 44H43.57L27.98 17Z"
        fill={fill}
      />
    </svg>
  );
};
