export const IconMemo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
      <path
        d="M17 23.9998L18.2 22.7998V18.5998C18.2 18.2638 18.464 17.9998 18.8 17.9998H23L24.2 16.7998V17.0038C24.2 17.3278 24.08 17.6278 23.852 17.8558L18.056 23.6518C17.828 23.8798 17.528 23.9998 17.204 23.9998H17ZM5.6 23.9998C5.264 23.9998 5 23.7358 5 23.3998V5.3998C5 5.0638 5.264 4.7998 5.6 4.7998H23.6C23.936 4.7998 24.2 5.0638 24.2 5.3998V5.9998L23 4.7998H6.2L5 5.9998V22.7998L6.2 23.9998H5.6Z"
        fill="#999999"
      />
      <path
        d="M20.8009 19.1996L19.3969 20.6036V19.1996H20.8009ZM23.5969 3.59961H5.59688C4.60088 3.59961 3.79688 4.40361 3.79688 5.39961V23.3996C3.79688 24.3956 4.60088 25.1996 5.59688 25.1996H17.2009C17.8369 25.1996 18.4489 24.9476 18.8929 24.4916L24.6889 18.6956C25.1449 18.2396 25.3969 17.6396 25.3969 17.0036V5.39961C25.3969 4.40361 24.5929 3.59961 23.5969 3.59961ZM6.19688 22.7996V5.99961H22.9969V16.7996H18.7969C17.8009 16.7996 16.9969 17.6036 16.9969 18.5996V22.7996H6.19688Z"
        fill="#999999"
      />
      <path
        d="M19.9977 9.59961H9.19766C8.86629 9.59961 8.59766 9.86824 8.59766 10.1996V11.3996C8.59766 11.731 8.86629 11.9996 9.19766 11.9996H19.9977C20.329 11.9996 20.5977 11.731 20.5977 11.3996V10.1996C20.5977 9.86824 20.329 9.59961 19.9977 9.59961Z"
        fill="#999999"
      />
      <path
        d="M13.9977 14.4004H9.19766C8.86629 14.4004 8.59766 14.669 8.59766 15.0004V16.2004C8.59766 16.5318 8.86629 16.8004 9.19766 16.8004H13.9977C14.329 16.8004 14.5977 16.5318 14.5977 16.2004V15.0004C14.5977 14.669 14.329 14.4004 13.9977 14.4004Z"
        fill="#999999"
      />
    </svg>
  );
};
