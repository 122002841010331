/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export const sessionStorageSetItem = (key: string, value: any) => {
  const valueStr = JSON.stringify(value);
  sessionStorage.setItem(key, valueStr);
};

export const sessionStorageGetItem = <T>(key: string): T => {
  const valueStr = sessionStorage.getItem(key);
  let value;
  if (valueStr) {
    value = JSON.parse(valueStr);
  }
  return value;
};

export const sessionStorageRemoveItem = (key: string) => {
  sessionStorage.removeItem(key);
};

export const isFindSessionStorageKey = (key: string): boolean => {
  let findKey = false;
  for (let i = 0; i < sessionStorage.length; i++) {
    if (sessionStorage.key(i) === key) {
      findKey = true;
      break;
    }
  }
  return findKey;
};

export const localStorageSetItem = (key: string, value: any) => {
  const valueStr = JSON.stringify(value);
  localStorage.setItem(key, valueStr);
};

export const localStorageGetItem = <T>(key: string): T => {
  const valueStr = localStorage.getItem(key);
  let value;
  if (valueStr) {
    value = JSON.parse(valueStr);
  }
  return value;
};

export const localStorageRemoveItem = (key: string) => {
  localStorage.removeItem(key);
};

export const isFindLocalStorageKey = (key: string): boolean => {
  let findKey = false;
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i) === key) {
      findKey = true;
      break;
    }
  }
  return findKey;
};
