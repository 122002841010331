import { AccountInfo } from '@azure/msal-browser';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { userIdAtom } from 'src/jotai/atom';
import { acquireTokenSilent, initialize, setActiveAccount } from 'src/utilities/azure/publicClientApplicationUtil';
import { ResponseGetUser, ResponseGetUserid } from 'src/utilities/restApi/common/types/users';
import { getUser } from 'src/utilities/restApi/common/users';
import { getUserid } from 'src/utilities/restApi/common/users/me/id';

export const useUserDetails = (accountInfo: undefined | AccountInfo) => {
  const navigateSystemError = useNavigateSystemError();
  // States
  const setGlobalUserId = useSetAtom(userIdAtom);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [id, setId] = useState<ResponseGetUserid['userid']>();
  const [details, setDetails] = useState<ResponseGetUser['user']>();

  // Effects

  // 最新のIDトークンの取得とユーザーIDの取得
  useEffect(() => {
    if (accountInfo != null) {
      setError(false);
      setLoading(true);

      // 最新のトークンを取得する
      initialize().then(() => {
        setActiveAccount(accountInfo);
        acquireTokenSilent()
          .then((response) => {
            if (!response) {
              return;
            }

            const idToken = response.idToken;
            getUserid({ idToken })
              .then((response) => {
                const userId = response.data!.userid;
                if (userId !== null) {
                  setGlobalUserId(userId);
                }
                setId(userId);
              })
              .catch(() => {
                setError(true);
                setLoading(false);
              });
          })
          .catch(() => navigateSystemError());
      });
    }
  }, [accountInfo]);

  useEffect(() => {
    // 初期値
    if (id === undefined) {
      return;
    }

    // IDトークンに合致するユーザーなし
    if (id === null) {
      setError(true);
      setLoading(false);
      return;
    }

    getUser({ userid: id })
      .then((response) => setDetails(response.data!.user))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [id]);

  return { details, error, loading };
};
