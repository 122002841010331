import { Box, Button, ButtonProps, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { IconNext } from 'src/components/shien/atoms/images/IconNext';
import { StatusColor } from 'src/types/common/statusColor';

export const upperColors = {
  red: { text: 'red50', baseBg: 'red20', clickedBg: 'red25' },
  green: { text: 'green50', baseBg: 'green20', clickedBg: 'green25' },
  yellow: { text: 'yellow50', baseBg: 'yellow20', clickedBg: 'yellow25' },
  none: { text: 'black', baseBg: 'white', clickedBg: 'gray25' },
} as const;

export type TemplateProps = {
  children?: ReactNode;
  upperColor?: StatusColor | 'none';
  buttonText: string;
  onClick: () => void;
  disabled?: boolean;
  fullWidth?: ButtonProps['fullWidth'];
};

export const Template = ({
  children,
  upperColor = 'none',
  buttonText,
  onClick,
  disabled = false,
  fullWidth,
}: TemplateProps) => {
  const theme = useTheme();
  const transition = '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';
  const containerStyles = {
    overflow: 'hidden',
    padding: 0,
    '.upperContent, .lowerContent': {
      transition: `background-color ${transition}, border-color ${transition}`,
    },
    '.upperContent': {
      borderBottom: `solid 1px ${upperColor === 'none' ? theme.palette.uiStandard.gray25 : 'transparent'}`,
    },
    '&:active': {
      '.upperContent': {
        backgroundColor: theme.palette.uiStandard[upperColors[upperColor].clickedBg],
        borderBottomColor: upperColor === 'none' ? theme.palette.uiStandard.white : 'transparent',
      },
      '.lowerContent': { backgroundColor: theme.palette.uiStandard.gray25 },
    },
  };

  return (
    <Button variant="secondary" onClick={onClick} disabled={disabled} fullWidth={fullWidth} sx={containerStyles}>
      <Stack width="100%">
        {children && (
          <Box
            className="upperContent"
            sx={{
              backgroundColor: theme.palette.uiStandard[upperColors[upperColor].baseBg],
              position: 'relative',
              p: '16px 24px',
            }}
          >
            {children}
          </Box>
        )}
        <Stack className="lowerContent" direction="row" justifyContent="center" alignItems="center" sx={{ py: '16px' }}>
          <Typography
            sx={{
              color: theme.palette.uiStandard.black,
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: '20px',
            }}
          >
            {buttonText}
          </Typography>
          <Box>
            <IconNext />
          </Box>
        </Stack>
      </Stack>
    </Button>
  );
};
