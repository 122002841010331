import { Stack } from '@mui/material';
import { useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PrsActionChip } from 'src/components/common/atoms/PrsActionChip';
import { StatusChip } from 'src/components/kanri/atoms/StatusChip';
import { appConst } from 'src/constants/common';
import { AppListSectionData } from 'src/types/common/AppListSectionData';
import { Prs2, ShelterTypeCode } from 'src/types/common/Prs2';
import { getShelter } from 'src/utilities/restApi/common/shelters';

const suspendDataList = [
  {
    header: '安否情報',
    items: [
      { title: '安否状況', value: <AppSkeleton /> },
      { title: '避難先', value: <AppSkeleton /> },
      { title: 'メモ', value: <AppSkeleton /> },
    ],
  },
] as AppListSectionData[];

export const usePrsStatusInfoSectionDataList = () => {
  const [sectionDataList, setSectionDataList] = useState<AppListSectionData[]>(suspendDataList);

  const getSectionDataList = (prs?: Prs2) => {
    return new Promise<AppListSectionData[]>((resolve, reject) => {
      const prsActionType =
        prs?.lastVisitedAt && prs?.lastVisitedAt === prs?.lastPrsStatusUpdatedAt
          ? 'visit'
          : prs?.lastEvacuatedAt && prs?.lastEvacuatedAt === prs?.lastPrsStatusUpdatedAt
          ? 'evacuate'
          : undefined;

      const prsActionChip = prsActionType && <PrsActionChip prsActionType={prsActionType} />;
      const statusChip = prs ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StatusChip prsStatusId={prs.prsStatusId} label={prs.prsStatusName} color={prs.prsStatusColor} />
          {prsActionChip}
        </Stack>
      ) : (
        <></>
      );
      if (prs?.shelterTypeCode) {
        switch (prs.shelterTypeCode) {
          case ShelterTypeCode.Home: {
            const sectionDataList = [
              {
                header: '安否情報',
                items: [
                  {
                    title: '安否状況',
                    value: statusChip,
                  },
                  { title: '避難先', value: appConst.SHELTER_TYPE.SUMMARY_NAME.HOME },
                  { title: 'メモ', value: prs.prsMemo },
                ],
              },
            ];
            // switchの他のcaseで非同期処理があるため、ここでresolve
            setSectionDataList(sectionDataList);
            resolve(sectionDataList);
            break;
          }
          case ShelterTypeCode.Shelter:
            if (prs.shelterId) {
              getShelter({ shelterid: prs.shelterId })
                .then((response) => {
                  if (response.data?.shelter) {
                    const sectionDataList = [
                      {
                        header: '安否情報',
                        items: [
                          {
                            title: '安否状況',
                            value: statusChip,
                          },
                          {
                            title: '避難先',
                            value: response.data.shelter.name,
                            subValue: response.data.shelter.address ?? undefined,
                          },
                          { title: 'メモ', value: prs.prsMemo },
                        ],
                      },
                    ];

                    setSectionDataList(sectionDataList);
                    resolve(sectionDataList);
                  } else {
                    setSectionDataList([]);
                    resolve([]);
                  }
                })
                .catch((error) => {
                  reject(error);
                })
                .finally(() => {});
            } else {
              // 非同期処理があるため、ここでresolve
              const sectionDataList = [
                {
                  header: '安否情報',
                  items: [
                    {
                      title: '安否状況',
                      value: statusChip,
                    },
                    { title: '避難先', value: '未設定' },
                    { title: 'メモ', value: prs.prsMemo },
                  ],
                },
              ];
              setSectionDataList(sectionDataList);
              resolve(sectionDataList);
            }

            break;

          case ShelterTypeCode.Other: {
            const sectionDataList = [
              {
                header: '安否情報',
                items: [
                  {
                    title: '安否状況',
                    value: statusChip,
                  },
                  { title: '避難先', value: prs.customShelterName ?? '未設定' },
                  { title: 'メモ', value: prs.prsMemo },
                ],
              },
            ];
            setSectionDataList(sectionDataList);
            resolve(sectionDataList);
            break;
          }
          case ShelterTypeCode.Unknown: {
            const sectionDataList = [
              {
                header: '安否情報',
                items: [
                  {
                    title: '安否状況',
                    value: statusChip,
                  },
                  { title: '避難先', value: appConst.SHELTER_TYPE.SUMMARY_NAME.UNKNOWN },
                  { title: 'メモ', value: prs.prsMemo },
                ],
              },
            ];
            setSectionDataList(sectionDataList);
            resolve(sectionDataList);
            break;
          }
        }
      } else {
        if (prs) {
          const sectionDataList = [
            {
              header: '安否情報',
              items: [
                {
                  title: '安否状況',
                  value: statusChip,
                },
                { title: '避難先', value: appConst.SHELTER_TYPE.SUMMARY_NAME.UNREGISTERED },
                { title: 'メモ', value: prs.prsMemo },
              ],
            },
          ];
          setSectionDataList(sectionDataList);
          resolve(sectionDataList);
        }
      }
    });
  };

  const clearSectionDataList = () => {
    setSectionDataList(suspendDataList);
  };

  return { sectionDataList, getSectionDataList, clearSectionDataList };
};
