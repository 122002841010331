import { CircularProgress, Container, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { ListButton } from 'src/components/shien/atoms/buttons/ListButton';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { HeaderWithBackButtonTemplate } from 'src/components/shien/templates/HeaderWithBackButtonTemplate';
import { appConst } from 'src/constants/common';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { PrsSafetyRegistrationFlowType } from 'src/types/shien/PrsSafetyRegistrationFlowType';
import { Shelter } from 'src/types/shien/Shelter';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';
import { getShelters } from 'src/utilities/restApi/common/shelters';

export const PrsSelectShelterPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const { prs, refreshData, initialized } = useContext(PrsContext);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [shelters, setShelters] = useState<Shelter[]>([]);
  const theme = useTheme();
  type FromState = { prsSafetyRegistrationFlowType: PrsSafetyRegistrationFlowType };
  const fromState = location.state as FromState;
  const { setShowBackdrop } = useBackdrop();
  const { userData } = useUserData();

  useEffect(() => {
    if (userData) {
      const fetchShelters = async () => {
        try {
          // 自治体IDに紐づく避難所を取得
          const res = await getShelters();
          const resShelters: Shelter[] = res.data!.shelters.map((item) => ({
            evaShelterId: item.evashelterid,
            jichitaiId: item.jichitaiid,
            name: item.name,
            shelterId: item.shelterid,
            address: item.address ?? undefined,
          }));

          // 名前の昇順でソート
          const sortedList = resShelters.sort((n1, n2) => {
            if (n1.name > n2.name) {
              return 1;
            } else {
              return -1;
            }
          });

          setShelters(sortedList);
        } catch (e) {
          setError(true);
        }
      };

      setLoading(true);
      fetchShelters()
        .then(() => setLoading(false))
        .catch(() => {});
    }
  }, [userData]);

  // apiでerror時にエラー画面に遷移
  useEffect(() => {
    if (error) {
      navigateSystemError();
    }
  }, [error, navigate]);

  // 避難所選択
  const handleSelect = async (shelterid: string) => {
    setShowBackdrop(true);

    try {
      // 要支援者情報の更新
      await patchPrsDetail({
        prsid: prs!.prsId,
        shelter_type_code: appConst.SHELTER_TYPE.CODE.SHELTER,
        shelterid: shelterid,
        custom_shelter_name: null,
        lastprsstatusupdatedat: true,
      });

      // 要支援者情報を再読み込み
      await refreshData();

      if (fromState.prsSafetyRegistrationFlowType === 'change') {
        // 安否登録フローが「変更」の場合、安否サマリ画面に戻る
        navigate('/app/shien/prs/safety-summary');
      } else if (
        fromState.prsSafetyRegistrationFlowType === 'register-from-visit' ||
        fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate'
      ) {
        // 安否登録フローが「登録」の場合は登録完了画面に遷移する
        navigate('/app/shien/prs/complete-registration');
      }
    } catch {
      navigateSystemError();
    } finally {
      setShowBackdrop(false);
    }
  };

  return (
    <PageContainer title={`避難所を選択| 避難行動支援`}>
      <HeaderWithBackButtonTemplate
        headlineText={'避難所を選択'}
        upperText={(initialized && `${prs?.fName} ${prs?.sName}`) || <AppSkeleton width="100px" />}
        onBackClick={() => {
          // 避難先登録画面に戻る
          navigate('/app/shien/prs/register-shelter-type', {
            state: {
              prsSafetyRegistrationFlowType: fromState.prsSafetyRegistrationFlowType,
            },
          });
        }}
      >
        {isLoading ? (
          <Container sx={{ py: 1, height: '100%' }}>
            <Stack alignItems="center" justifyContent="center" height="100%">
              <CircularProgress />
            </Stack>
          </Container>
        ) : (
          <Container sx={{ pt: 1, pb: 2 }}>
            <Stack spacing={2}>
              <Stack direction={'column'} spacing={1} mb={1} px={1}>
                <Typography sx={{ p: 1 }}>対象者がどの避難所に避難したのか、教えてください。</Typography>
                {shelters.length === 0 ? (
                  <Typography
                    textAlign="center"
                    sx={{ pt: 6, fontSize: '24px', color: theme.palette.uiStandard.gray40 }}
                  >
                    避難所が未定義です。
                  </Typography>
                ) : (
                  shelters.map((shelter) => (
                    <React.Fragment key={shelter.shelterId}>
                      <ListButton
                        statusText={shelter.name ?? ''}
                        displayChecked={initialized && prs?.shelterId === shelter.shelterId}
                        displayArrow={false}
                        onClick={() => void handleSelect(shelter.shelterId)}
                      />
                    </React.Fragment>
                  ))
                )}
              </Stack>
            </Stack>
          </Container>
        )}
      </HeaderWithBackButtonTemplate>
    </PageContainer>
  );
};
