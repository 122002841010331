import { Box, Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { PrsActionChip } from 'src/components/common/atoms/PrsActionChip';
import { StatusLabel, StatusLabelProps } from 'src/components/shien/atoms/buttons/CardButton/StatusLabel';
import { UncheckedLabel } from 'src/components/shien/atoms/buttons/CardButton/UncheckedLabel';
import { IconGroup } from 'src/components/shien/atoms/images/IconGroup';
import { IconMemo } from 'src/components/shien/atoms/images/IconMemo';
import { IconNext } from 'src/components/shien/atoms/images/IconNext';
import { appConst } from 'src/constants/common';
import { PrsActionType } from 'src/types/common/PrsActionType';

const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'unconfirmed' })<{
  unconfirmed: boolean;
}>(({ theme, unconfirmed }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  padding: '15px',
  lineHeight: 1,
  borderRadius: '10px',
  whiteSpace: 'nowrap',
  backgroundColor: theme.palette.uiStandard[unconfirmed ? 'red20' : 'white'],
  boxShadow: `0px 4px 0px 0px ${theme.palette.uiStandard[unconfirmed ? 'red25' : 'gray25']}`,
  marginBottom: '4px',
  border: `1px solid ${unconfirmed ? 'transparent' : theme.palette.uiStandard.gray25}`,
  '&:hover': {
    backgroundColor: theme.palette.uiStandard[unconfirmed ? 'red20' : 'white'],
    boxShadow: `0px 4px 0px 0px ${theme.palette.uiStandard[unconfirmed ? 'red25' : 'gray25']}`,
    border: `1px solid ${unconfirmed ? 'transparent' : theme.palette.uiStandard.gray25}`,
  },
  '&:active': {
    backgroundColor: theme.palette.uiStandard[unconfirmed ? 'red25' : 'gray25'],
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: 0.3,
    boxShadow: `0px 4px 0px 0px ${theme.palette.uiStandard[unconfirmed ? 'red25' : 'gray25']}`,
  },
}));

type ComponentProps = StatusLabelProps & {
  shelterName: string;
  memo: string;
  name: string;
  share: boolean;
  consent?: boolean;
  address: string;
  prsActionType?: PrsActionType;
  onClick: () => void;
  disabled?: boolean;
};
export const CardButton = ({
  shelterName,
  memo,
  name,
  share,
  consent,
  address,
  prsActionType,
  onClick,
  disabled = false,
  status,
  ...statusLabelProps
}: ComponentProps) => {
  const theme = useTheme();
  const unconfirmed = status === appConst.LABEL_UNCHECKED;

  return (
    <StyledButton unconfirmed={unconfirmed} onClick={onClick} disabled={disabled} fullWidth>
      <Stack spacing={1} width="100%" minWidth={0}>
        <Stack direction="row" alignItems="center" justifyContent="start" spacing={1} sx={{ minHeight: '30px' }}>
          {unconfirmed ? <UncheckedLabel /> : <StatusLabel status={status} {...statusLabelProps} />}
          <PrsActionChip prsActionType={prsActionType} />
          <Typography
            sx={{
              color: theme.palette.uiStandard.gray50,
              fontSize: '16px',
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {shelterName}
          </Typography>
          <Box>{memo && <IconMemo />}</Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              color: theme.palette.uiStandard.black,
              fontSize: '24px',
              fontWeight: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Typography>
          <Box>{share && <IconGroup />}</Box>
          {!consent && (
            <Box sx={{ color: theme.palette.uiStandard.gray50, fontSize: '16px', fontWeight: 'normal' }}>(未同意)</Box>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              color: theme.palette.uiStandard.gray50,
              fontSize: '16px',
              fontWeight: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              direction: 'rtl',
            }}
          >
            {address}
          </Typography>
        </Stack>
      </Stack>
      <Box>
        <IconNext />
      </Box>
    </StyledButton>
  );
};
