import { Provider } from 'jotai';
import { FC, ReactNode } from 'react';
import { dataStore } from 'src/components/shien/pages/prs/dataStore';

type ComponentProps = {
  children: ReactNode;
};

export const PrsDataStore: FC<ComponentProps> = ({ children }) => {
  return <Provider store={dataStore}>{children}</Provider>;
};
