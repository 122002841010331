import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import {
  RequestGetPrsStatus,
  RequestPrsStatusBody,
  RequestPrsStatusQuery,
  ResponseGetPrsStatus,
} from 'src/utilities/restApi/common/types/prsStatus';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/prsstatus';

/**
 * 要支援者状態マスタ取得
 */
export const getPrsStatus = async (params: RequestGetPrsStatus) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseGetPrsStatus>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetPrsStatus>>(url, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};

/**
 * 要支援者状態マスタ追加
 */
export const postPrsStatus = async (data: RequestPrsStatusBody) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetPrsStatus>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponseGetPrsStatus>>(url, data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};

/**
 * 要支援者状態マスタ更新
 */
export const patchPrsStatus = async (params: RequestPrsStatusQuery, data: RequestPrsStatusBody) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<string>>((resolve, reject) => {
    restApi()
      .patch<SuccessResponse<string>>(url, data, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};

export const PRSSTATUSID_IS_USED = 'PRSSTATUSID_IS_USED';
/**
 * 要支援者状態マスタ削除
 */
export const deletePrsStatus = async (params: RequestPrsStatusQuery) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<string>>((resolve, reject) => {
    restApi()
      .delete<SuccessResponse<string>>(url, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};
