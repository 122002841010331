import { Container, Stack, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { ListButton } from 'src/components/shien/atoms/buttons/ListButton';
import { CustomShelterNameEditDialog } from 'src/components/shien/pages/prs/components/CustomShelterNameEditDialog';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { HeaderWithBackButtonTemplate } from 'src/components/shien/templates/HeaderWithBackButtonTemplate';
import { appConst } from 'src/constants/common';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { PrsSafetyRegistrationFlowType } from 'src/types/shien/PrsSafetyRegistrationFlowType';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';

type ShelterType = {
  shelter_type_code: string;
  shelter_type_name: string;
};

export const PrsRegisterShelterTypePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const { prs, refreshData, initialized } = useContext(PrsContext);
  type FromState = { prsSafetyRegistrationFlowType: PrsSafetyRegistrationFlowType };
  const fromState = location.state as FromState;
  const { setShowBackdrop } = useBackdrop();

  const [customShelterNameDialogOpen, setCustomShelterNameDialogOpen] = useState(false);

  // definitions
  const shelterTypeList = [
    {
      shelter_type_code: appConst.SHELTER_TYPE.CODE.HOME,
      shelter_type_name: appConst.SHELTER_TYPE.NAME.HOME,
    },
    {
      shelter_type_code: appConst.SHELTER_TYPE.CODE.SHELTER,
      shelter_type_name: appConst.SHELTER_TYPE.NAME.SHELTER,
    },
    {
      shelter_type_code: appConst.SHELTER_TYPE.CODE.CUSTOM,
      shelter_type_name: appConst.SHELTER_TYPE.NAME.CUSTOM,
    },
    {
      shelter_type_code: appConst.SHELTER_TYPE.CODE.UNKNOWN,
      shelter_type_name: appConst.SHELTER_TYPE.NAME.UNKNOWN,
    },
  ] as ShelterType[];

  const getPageTitle = (type: PrsSafetyRegistrationFlowType) => {
    if (type === 'register-from-visit' || type === 'register-from-evacuate') {
      return '避難先を登録';
    } else if (type === 'change') {
      return '避難先を変更';
    }
    throw new Error('invalid types : PrsSafetyRegistrationFlowType');
  };

  const pageTitle = getPageTitle(fromState.prsSafetyRegistrationFlowType);

  // 避難先種別選択
  const handleSelect = async (shelterTypeCode: string) => {
    setShowBackdrop(true);

    try {
      // 選択された避難先種別で分岐
      if (
        shelterTypeCode === appConst.SHELTER_TYPE.CODE.HOME ||
        shelterTypeCode === appConst.SHELTER_TYPE.CODE.UNKNOWN
      ) {
        // 「自宅」または「わからない」の場合、この画面で要支援者情報を更新する
        await patchPrsDetail({
          prsid: prs!.prsId,
          shelter_type_code: shelterTypeCode,
          shelterid: null,
          custom_shelter_name: null,
          lastprsstatusupdatedat: true,
        });

        // 要支援者の情報を最新化
        await refreshData();

        // 要支援者情報更新後の画面遷移
        if (fromState.prsSafetyRegistrationFlowType === 'change') {
          // 安否登録フローが「変更」の場合は安否サマリ画面に戻る
          navigate('/app/shien/prs/safety-summary');
        } else if (
          fromState.prsSafetyRegistrationFlowType === 'register-from-visit' ||
          fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate'
        ) {
          // 安否登録フローが「登録」の場合は登録完了画面に遷移する
          navigate('/app/shien/prs/complete-registration');
        }
      } else if (shelterTypeCode === appConst.SHELTER_TYPE.CODE.SHELTER) {
        // 「避難所」の場合、避難所選択画面に遷移する
        navigate('/app/shien/prs/select-shelter', {
          state: { prsSafetyRegistrationFlowType: fromState.prsSafetyRegistrationFlowType },
        });
      } else if (shelterTypeCode === appConst.SHELTER_TYPE.CODE.CUSTOM) {
        // 「その他」の場合、その他避難先入力ダイアログを表示する
        setCustomShelterNameDialogOpen(true);
      }

      // 画面遷移
    } catch {
      navigateSystemError();
    } finally {
      setShowBackdrop(false);
    }
  };

  return (
    <PageContainer title={`${pageTitle}| 避難行動支援`}>
      <HeaderWithBackButtonTemplate
        headlineText={`${pageTitle}`}
        upperText={(initialized && `${prs?.fName} ${prs?.sName}`) || <AppSkeleton width="100px" />}
        onBackClick={() => {
          if (fromState.prsSafetyRegistrationFlowType === 'change') {
            // 安否登録フローが「変更」の場合、安否サマリ画面に戻る
            navigate('/app/shien/prs/safety-summary');
          } else if (
            fromState.prsSafetyRegistrationFlowType === 'register-from-visit' ||
            fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate'
          ) {
            // 安否登録フローが「登録」の場合、安否登録画面に戻る
            navigate('/app/shien/prs/register-safety', {
              state: { prsSafetyRegistrationFlowType: fromState.prsSafetyRegistrationFlowType },
            });
          }
        }}
      >
        <Container sx={{ pt: 1, pb: 2 }}>
          <Stack spacing={2}>
            <Stack direction={'column'} spacing={1} mb={1} px={1}>
              <Typography sx={{ p: 1 }}>対象者の避難先を教えてください。</Typography>
              {shelterTypeList.map((sheltertype) => (
                <React.Fragment key={sheltertype.shelter_type_code}>
                  <ListButton
                    statusText={sheltertype.shelter_type_name ?? ''}
                    displayChecked={initialized && prs?.shelterTypeCode === sheltertype.shelter_type_code}
                    displayArrow={false}
                    onClick={() => void handleSelect(sheltertype.shelter_type_code)}
                  />
                </React.Fragment>
              ))}
            </Stack>
          </Stack>
        </Container>
      </HeaderWithBackButtonTemplate>
      <CustomShelterNameEditDialog
        prs={prs}
        dialogOpen={customShelterNameDialogOpen}
        onClose={({ reason, text }) => {
          const textChanged = text !== prs?.customShelterName;
          const isRegister =
            fromState.prsSafetyRegistrationFlowType === 'register-from-visit' ||
            fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate';

          // 入力値に変更がある場合、要支援者情報を更新する。
          // ただし安否登録フローが「登録」の場合は入力値に変更がなくても要支援者情報を更新する（後続の登録完了画面に遷移したいため）
          if (reason === 'registerButtonClick' && (textChanged || isRegister)) {
            setShowBackdrop(true);

            (async () => {
              try {
                await patchPrsDetail({
                  prsid: prs!.prsId,
                  custom_shelter_name: text === '' ? null : text ?? null,
                  shelter_type_code: appConst.SHELTER_TYPE.CODE.CUSTOM,
                  shelterid: null,
                  lastprsstatusupdatedat: true,
                });
                await refreshData();
                setCustomShelterNameDialogOpen(false);

                // 要支援者情報更新後の画面遷移
                if (fromState.prsSafetyRegistrationFlowType === 'change') {
                  // 安否登録フローが「変更」の場合は安否サマリ画面に戻る
                  navigate('/app/shien/prs/safety-summary');
                } else if (
                  fromState.prsSafetyRegistrationFlowType === 'register-from-visit' ||
                  fromState.prsSafetyRegistrationFlowType === 'register-from-evacuate'
                ) {
                  // 安否登録フローが「登録」の場合は登録完了画面に遷移する
                  navigate('/app/shien/prs/complete-registration');
                }
              } catch {
                navigateSystemError();
              } finally {
                setShowBackdrop(false);
              }
            })();
          } else {
            setCustomShelterNameDialogOpen(false);
          }
        }}
      ></CustomShelterNameEditDialog>
    </PageContainer>
  );
};
