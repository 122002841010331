import { PaletteMode, ThemeOptions } from '@mui/material';
import { standardColors } from 'src/themes/standardColors';

// typgraphy
declare module '@mui/material/styles' {
  interface TypographyVariants {
    headline: React.CSSProperties;
    subtitle: React.CSSProperties;
    title1: React.CSSProperties;
    title2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headline?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    title1?: React.CSSProperties;
    title2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;
    button: true;
    subtitle1: false;
    subtitle2: false;
    body1: true;
    body2: true;
    caption: true;
    overline: false;
    headline: true;
    subtitle: true;
    title1: true;
    title2: true;
  }
}

// Custom Button Variants
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    uiStandard: Palette['primary'];
  }

  interface PaletteOptions {
    uiStandard?: PaletteOptions['primary'];
  }
}

// Custom PaletteColors
declare module '@mui/material/styles' {
  interface PaletteColor {
    black: string;
    white: string;
    gray50: string;
    gray40: string;
    gray30: string;
    gray25: string;
    gray10: string;
    green60: string;
    green50: string;
    green40: string;
    green25: string;
    green20: string;
    yellow60: string;
    yellow50: string;
    yellow25: string;
    yellow20: string;
    red50: string;
    red25: string;
    red20: string;
    blue50: string;
    lineGreen: string;
  }

  interface SimplePaletteColorOptions {
    black?: string;
    white?: string;
    gray50?: string;
    gray40?: string;
    gray30?: string;
    gray25?: string;
    gray10?: string;
    green60?: string;
    green50?: string;
    green40?: string;
    green25?: string;
    green20?: string;
    yellow60?: string;
    yellow50?: string;
    yellow25?: string;
    yellow20?: string;
    red50?: string;
    red25?: string;
    red20?: string;
    blue50?: string;
    lineGreen?: string;
  }
}

const fontFamily =
  "'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif";

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  ...(mode === 'light'
    ? {
        palette: {
          mode,
          primary: {
            main: '#4081e0',
          },
          uiStandard: {
            main: '',
            ...standardColors,
          },
          background: {
            default: standardColors.white,
          },
        },
        typography: {
          fontFamily,
          headline: {
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 'bold',
            color: standardColors.black,
            fontFamily,
          },
          subtitle: {
            fontSize: '16px',
            fontWeight: 'normal',
            color: standardColors.gray50,
            fontFamily,
          },
          title1: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: standardColors.gray50,
            fontFamily,
          },
          title2: {
            fontSize: '16px',
            lineHeight: '26px',
            fontWeight: 'normal',
            color: standardColors.gray50,
            fontFamily,
          },
          body1: {
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 'normal',
            color: standardColors.gray50,
            fontFamily,
          },
          body2: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 'normal',
            color: standardColors.black,
            fontFamily,
          },
          caption: {
            fontSize: '16px',
            lineHeight: '26px',
            fontWeight: 'normal',
            color: standardColors.gray50,
            fontFamily,
          },
          button: {
            fontFamily,
          },
        },
        components: {
          MuiButton: {
            defaultProps: {
              disableElevation: true,
              disableRipple: true,
            },
            variants: [
              {
                props: { variant: 'primary' },
                style: {
                  paddingTop: '22px',
                  paddingBottom: '22px',
                  lineHeight: 1,
                  borderRadius: '10px',
                  backgroundColor: standardColors.green40,
                  boxShadow: `0px 4px 0px 0px ${standardColors.green60}`,
                  marginBottom: '4px',
                  color: standardColors.white,
                  fontSize: '20px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: standardColors.green40,
                    boxShadow: `0px 4px 0px 0px ${standardColors.green60}`,
                  },
                  '&:active': {
                    backgroundColor: standardColors.green60,
                    boxShadow: 'none',
                  },
                  '&:disabled': {
                    opacity: 0.3,
                    boxShadow: `0px 4px 0px 0px ${standardColors.green60}`,
                  },
                },
              },
              {
                props: { variant: 'secondary' },
                style: {
                  paddingTop: '22px',
                  paddingBottom: '22px',
                  lineHeight: 1,
                  borderRadius: '10px',
                  backgroundColor: standardColors.white,
                  border: `1px solid ${standardColors.gray25}`,
                  boxShadow: `0px 4px 0px 0px ${standardColors.gray25}`,
                  marginBottom: '4px',
                  color: standardColors.black,
                  fontSize: '20px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: standardColors.white,
                    boxShadow: `0px 4px 0px 0px ${standardColors.gray25}`,
                  },
                  '&:active': {
                    backgroundColor: standardColors.gray25,
                    boxShadow: 'none',
                  },
                  '&:disabled': {
                    opacity: 0.3,
                    boxShadow: `0px 4px 0px 0px ${standardColors.gray25}`,
                  },
                },
              },
            ],
            styleOverrides: {
              root: {
                textTransform: 'none',
              },
            },
          },
          MuiBottomNavigationAction: {
            defaultProps: {
              disableRipple: true,
            },
            styleOverrides: {
              root: {
                fontSize: 14,
                color: standardColors.gray50,
                fontWeight: 'normal',
                '.Mui-selected': {
                  color: standardColors.black,
                  fontWeight: 'bold',
                },
              },
            },
          },
          // ローディング
          MuiCircularProgress: {
            styleOverrides: {
              root: {
                '.MuiCircularProgress-circle': {
                  color: standardColors.gray30,
                },
              },
            },
          },
        },
      }
    : {}),
});
