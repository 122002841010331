import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { ResponseGetDisasterTypes } from 'src/utilities/restApi/kanri/types/disasterTypes';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/disaster-types';

export const getDisasterTypes = async () => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetDisasterTypes>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetDisasterTypes>>(url, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};
