export const IconPersonFull = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M16 16C19.13 16 21 13.94 21 10.5C21 7.06 19.13 5 16 5C12.87 5 11 7.06 11 10.5C11 13.94 12.87 16 16 16Z"
        fill="black"
      />
      <path
        d="M25.94 25.29C25.71 23.58 24.3 18 16.01 18C7.71999 18 6.28999 23.58 6.05999 25.29C5.99999 25.72 6.12999 26.16 6.41999 26.48C6.69999 26.81 7.11999 26.99 7.54999 26.99H24.46C24.89 26.99 25.31 26.8 25.59 26.47C25.88 26.14 26 25.71 25.95 25.28L25.94 25.29Z"
        fill="black"
      />
    </svg>
  );
};
