import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, colors, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInterval } from 'react-use';
import { SearchType, SpGridRow, SpGridRowSoshiki } from 'src/components/common/types/SupportersGridTypes';
import { SearchConditions } from 'src/components/common/types/SupportersSearchConditions';
import { useQuery } from 'src/components/common/useQuery/SupportersTableQuery';
import { PreFetchedData } from 'src/components/kanri/pages/jichitai/SearchSupportersPage/components/SupporterDetailsDialog';
import { appConst } from 'src/constants/common';
import { useJianInfo } from 'src/hooks/common/useJianInfo';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';

type ComponentProps = {
  onDetailsClick: (spId: string, preFetchedDataForDetailsDialog: PreFetchedData) => void;
  onChangeAssignClick: (spId: string, soshikiIds: string[]) => void;
  searchType: SearchType;
  setSearchType: (searchType: SearchType) => void;
  searchConditions: SearchConditions;
  onSearchError: (error: SearchError) => void;
  onChangeLoadingStatus: (isLoading: SearchCallerType | undefined) => void;
};

const iconColorGrey = colors.grey[700];
const POLLING_INTERVAL = 10000; // 画面更新周期(ms)

export const SupportersTable = ({
  onDetailsClick,
  onChangeAssignClick,
  searchType,
  setSearchType,
  searchConditions,
  onSearchError,
  onChangeLoadingStatus,
}: ComponentProps) => {
  const { jianOngoing } = useJianInfo();
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>([{ field: 'name', sort: 'asc' }]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const {
    isLoading,
    rows,
    pageInfo,
    error: searchError,
    search,
  } = useQuery({ paginationModel, searchConditions, sortModel });

  // 10秒周期の画面更新
  // 初回orページ切替時の処理完了後(initialized = true)、10秒周期の更新タイマーを開始する
  useInterval(
    () => {
      setSearchType('refresh');
      search('system');
    },
    isLoading ? null : POLLING_INTERVAL
  );

  const rowCountRef = useRef(pageInfo?.totalRowCount || 0);

  const rowCount = useMemo(() => {
    if (pageInfo?.totalRowCount !== undefined) {
      rowCountRef.current = pageInfo.totalRowCount;
    }
    return rowCountRef.current;
  }, [pageInfo?.totalRowCount]);

  const onSortModelChange = (model: GridSortModel) => {
    if (isLoading !== 'user') {
      setSortModel(model);
    }
  };

  // エラー通知
  useEffect(() => {
    if (searchError) {
      onSearchError(searchError);
    }
  }, [searchError]);

  useEffect(() => {
    onChangeLoadingStatus(isLoading);
  }, [isLoading]);

  const columns: GridColDef<SpGridRow>[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'name', headerName: '氏名', width: 150, disableColumnMenu: true, sortable: true },
      {
        field: 'spcheck',
        headerName: '支援可否',
        width: 120,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<SpGridRow, string>) => {
          // 支援可否IDに対応する文言とアイコンを表示
          const availability =
            params.row.spCheckId === appConst.SPCHECKID.NOT_ANSWERD
              ? {
                  // 未回答
                  spCheckName: params.row.spCheckStatus,
                  icon: <QuestionMarkIcon fontSize="small" htmlColor={iconColorGrey} />,
                }
              : params.row.spCheckId === appConst.SPCHECKID.AVAILABLE
              ? {
                  // 支援可
                  spCheckName: params.row.spCheckStatus,
                  icon: <CircleOutlinedIcon fontSize="small" color="success" />,
                }
              : params.row.spCheckId === appConst.SPCHECKID.UNABLE
              ? {
                  // 支援不可
                  spCheckName: params.row.spCheckStatus,
                  icon: <ChangeHistoryIcon fontSize="small" color="error" />,
                }
              : {
                  // 未知の支援可否IDが来た場合、ハイフンを表示（本来は到達しないコード）
                  spCheckName: undefined,
                  icon: <RemoveIcon htmlColor={iconColorGrey} />,
                };

          return (
            <Stack height="100%" direction="row" alignItems="center">
              {availability.icon}
              {availability.spCheckName}
            </Stack>
          );
        },
      },
      {
        field: 'supportStatus',
        renderHeader: () => (
          <Stack direction="column" alignItems="center">
            <Box>支援状況</Box>
            <Box>(未確認/予定)</Box>
          </Stack>
        ),
        width: 200,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<SpGridRow, string>) => {
          const prsList = params.row.supportRecipients.map((x) => ({
            prsId: x.prsId,
            prsStatusId: x.prsStatusId,
          }));

          const totalCount = prsList.length ?? 0;
          const uncheckedCount = prsList.filter((x) => x.prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED).length ?? 0;

          return (
            <Stack height="100%" direction="row" alignItems="center" justifyContent="space-between">
              <Box sx={{ m: 2 }}>
                {uncheckedCount}/{totalCount}人
              </Box>
              {jianOngoing() && (
                <Button
                  onClick={() =>
                    onChangeAssignClick(
                      params.row.id,
                      params.row.soshikiList.map((x) => x.soshikiId)
                    )
                  }
                >
                  割当変更
                </Button>
              )}
            </Stack>
          );
        },
      },
      {
        field: 'soshikiList',
        headerName: '所属',
        width: 200,
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<SpGridRow, SpGridRowSoshiki[]>) =>
          params.value?.map((x) => x.soshikiName).join(', '),
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        width: 90,
        minWidth: 90,
        maxWidth: 90,
        renderCell: (params) => (
          <Button
            onClick={() => {
              onDetailsClick(params.row.id, {
                soshikiNames: params.row.soshikiList.map((x) => x.soshikiName),
              });
            }}
          >
            詳細
          </Button>
        ),
      },
    ],
    [jianOngoing()]
  );

  return (
    <Stack flexGrow={1} sx={{ overflow: 'auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        loading={!!isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(sortModel) => {
          onSortModelChange(sortModel);
        }}
        slotProps={{
          loadingOverlay: {
            variant: searchType === 'search' ? 'circular-progress' : 'linear-progress',
            noRowsVariant: 'skeleton',
            sx: searchType === 'search' ? {} : { backgroundColor: 'transparent' },
          },
          ...(isLoading === 'user'
            ? {
                pagination: {
                  backIconButtonProps: {
                    disabled: true,
                  },
                  nextIconButtonProps: {
                    disabled: true,
                  },
                },
              }
            : {}),
        }}
      />
    </Stack>
  );
};
