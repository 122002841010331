import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { ConfirmDialog } from 'src/components/kanri/atoms/ConfirmDialog';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import { FailedUserGridRow, ProcessResultDialog } from 'src/components/kanri/atoms/ProcessResultDialog';
import { SearchConditions } from 'src/components/kanri/pages/jichitai/CreateAccountPage/components/SearchConditionField/types';
import { UsersTable } from 'src/components/kanri/pages/jichitai/CreateAccountPage/components/UsersTable';
import { UserGridRow } from 'src/components/kanri/pages/jichitai/CreateAccountPage/components/UsersTable/types';
import { TabPanel } from 'src/components/kanri/pages/jichitai/CreateAccountPage/TabPanel';
import { useQuery } from 'src/components/kanri/pages/jichitai/CreateAccountPage/useQuery';
import { appConst, RoleId } from 'src/constants/common';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { createAccount, deleteAccount, resetAccount } from 'src/utilities/restApi/kanri/account';

export const CreateAccountPage = () => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigateSystemError = useNavigateSystemError();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [searchConditions, setSearchConditions] = useState<SearchConditions>({
    delflg: null,
    roleid: RoleId.JichitaiStaff,
  });

  const { isLoading, rows, pageInfo, isError: searchError } = useQuery({ paginationModel, searchConditions });

  const rowCountRef = useRef(pageInfo?.totalRowCount || 0);

  const rowCount = useMemo(() => {
    if (pageInfo?.totalRowCount !== undefined) {
      rowCountRef.current = pageInfo.totalRowCount;
    }
    return rowCountRef.current;
  }, [pageInfo?.totalRowCount]);

  // タブに値をアサイン
  const [tabValue, setTabValue] = useState(0);

  // 選択中のタブに対応する検索の実行
  const refresh = () => {
    if (tabValue === 0) {
      setSearchConditions({ delflg: null, roleid: RoleId.JichitaiStaff });
    } else if (tabValue === 1) {
      setSearchConditions({ delflg: null, roleid: RoleId.SoshikiLeader });
    } else if (tabValue === 2) {
      setSearchConditions({ delflg: null, roleid: RoleId.Supporter });
    }
  };

  // タブ変更ハンドラ
  const tabChange = (_event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  // タブ変更を検出したら検索実行
  useEffect(() => {
    refresh();
  }, [tabValue]);

  // エラーページ遷移
  useEffect(() => {
    if (searchError) {
      navigateSystemError();
    }
  }, [searchError]);

  // 処理可能なユーザーの最大数
  const MAX_HANDLING_USER_ID_COUNT = 100;

  // 1リクエスト当たりのユーザー数の上限
  const MAX_USER_ID_COUNT_PER_REQUEST = 20;
  const MSG_TARGET_SELECTION_REQUIED = 'チェックボックスで対象ユーザーを選択してください';
  const MSG_PROCESSING_LIMIT_EXCEEDED = '処理可能なユーザー数の上限を超えています';
  const MSG_NO_RESET_PASSWORD_TARGET_IN_SELECTION =
    '選択されたユーザーの中にパスワードリセットを実行可能なユーザーがいません';
  const MSG_NO_CREATE_ACCOUNT_TARGET_IN_SELECTION =
    '選択されたユーザーの中に共助アカウントを発行可能なユーザーがいません';
  const MSG_NO_DELETE_ACCOUNT_TARGET_IN_SELECTION =
    '選択されたユーザーの中に共助アカウントを削除可能なユーザーがいません';

  const [createAccountConfirmDialogOpen, setCreateAccountConfirmDialogOpen] = useState(false);
  const [createAccountResultDialogOpen, setCreateAccountResultDialogOpen] = useState(false);
  const [createAccountProcessing, setCreateAccountProcessing] = useState(false);
  const [createAccountSuccessCount, setCreateAccountSuccessCount] = useState(0);
  const [createAccountFailedCount, setCreateAccountFailedCount] = useState(0);
  const [createAccountExcludedCount, setCreateAccountExcludedCount] = useState(0);

  const [deleteAccountConfirmDialogOpen, setDeleteAccountConfirmDialogOpen] = useState(false);
  const [deleteAccountResultDialogOpen, setDeleteAccountResultDialogOpen] = useState(false);
  const [deleteAccountProcessing, setDeleteAccountProcessing] = useState(false);
  const [deleteAccountSuccessCount, setDeleteAccountSuccessCount] = useState(0);
  const [deleteAccountFailedCount, setDeleteAccountFailedCount] = useState(0);
  const [deleteAccountExcludedCount, setDeleteAccountExcludedCount] = useState(0);

  const [passwordResetConfirmDialogOpen, setPasswordResetConfirmDialogOpen] = useState(false);
  const [passwordResetResultDialogOpen, setPasswordResetResultDialogOpen] = useState(false);
  const [passwordResetProcessing, setPasswordResetProcessing] = useState(false);
  const [passwordResetSuccessCount, setPasswordResetSuccessCount] = useState(0);
  const [passwordResetFailedCount, setPasswordResetFailedCount] = useState(0);
  const [passwordResetExcludedCount, setPasswordResetExcludedCount] = useState(0);
  const [passwordResetFailedUserRows, setPasswordResetFailedUserRows] = useState<GridRowsProp<FailedUserGridRow>>([]);

  // ユーザーIDの振り分け
  const splitUserIdsPerRequest = (sourceRows: UserGridRow[]) => {
    const userIdsArray: string[][] = [];
    let userIds: string[] = [];
    sourceRows.forEach((x) => {
      userIds.push(x.id);
      if (userIds.length === MAX_USER_ID_COUNT_PER_REQUEST) {
        userIdsArray.push(userIds);
        userIds = [];
      }
    });
    if (userIds.length > 0) {
      userIdsArray.push(userIds);
    }
    return userIdsArray;
  };

  // アカウント発行ボタン押下
  const createAccountButtonClick = () => {
    // チェックされた行の収集
    const selectedRows = apiRef.current.getSelectedRows();

    // 1つもチェックされていない
    if (selectedRows.size === 0) {
      enqueueSnackbar(MSG_TARGET_SELECTION_REQUIED, { variant: 'warning', preventDuplicate: true });
      return;
    }

    // 確認ダイアログを開く
    setCreateAccountConfirmDialogOpen(true);
  };

  // アカウント発行処理
  const createAccountConfirmHandler = async () => {
    // 確認ダイアログを閉じる
    setCreateAccountConfirmDialogOpen(false);

    // チェックされた行の収集
    const selectedRows = apiRef.current.getSelectedRows();

    // アカウント発行は個別アカウントが「発行済み」のユーザーを対象とする
    const excludedUserIds: string[] = [];
    const targetRows: UserGridRow[] = [];
    selectedRows.forEach((value) => {
      const row = value as UserGridRow;
      if (row.delflg === false) {
        targetRows.push(row);
      } else {
        excludedUserIds.push(row.id);
      }
    });

    // 対象ユーザーがいない
    if (targetRows.length === 0) {
      enqueueSnackbar(MSG_NO_CREATE_ACCOUNT_TARGET_IN_SELECTION, { variant: 'warning' });
      return;
    }
    // 対象ユーザー数が上限を超えている（異常系。通常は表示されないエラー）
    if (targetRows.length > MAX_HANDLING_USER_ID_COUNT) {
      enqueueSnackbar(MSG_PROCESSING_LIMIT_EXCEEDED, { variant: 'error' });
      return;
    }

    // 対象ユーザーを1リクエストあたりの人数で振り分ける
    const userIdsArray = splitUserIdsPerRequest(targetRows);
    const successUserIds: string[] = [];
    const failedUserIds: string[] = [];

    try {
      // 処理結果ダイアログを処理中状態にする
      setCreateAccountProcessing(true);

      // 処理結果ダイアログを表示する
      setCreateAccountResultDialogOpen(true);

      // アカウント発行処理の呼び出し（並列実行）
      await Promise.all(
        userIdsArray.map(async (userIds) => {
          await createAccount({ userIdList: userIds }).then((response) => {
            if (response.data) {
              // 成功数、失敗数の集計
              response.data.results.forEach((x) => {
                if (x.status === appConst.LINKED_AADB2C_TYPE_CODE.LINKED) {
                  successUserIds.push(x.userid);
                } else {
                  failedUserIds.push(x.userid);
                }
              });
            }
          });
        })
      );
    } catch {
      navigateSystemError();
    } finally {
      // 処理結果ダイアログを処理完了状態にする
      setCreateAccountProcessing(false);
    }

    // 成功数、失敗数、対象外数のセット
    setCreateAccountSuccessCount(successUserIds.length);
    setCreateAccountFailedCount(failedUserIds.length);
    setCreateAccountExcludedCount(excludedUserIds.length);
  };

  // アカウント発行終了処理
  const createAccountCloseHandler = () => {
    // 処理結果ダイアログを閉じる
    setCreateAccountResultDialogOpen(false);
    // チェックボックスの選択解除
    apiRef.current.setRowSelectionModel([]);
    // 再検索実行
    refresh();
  };

  // アカウント削除ボタン押下
  const deleteAccountButtonClick = () => {
    // チェックされた行の収集
    const selectedRows = apiRef.current.getSelectedRows();

    // 1つもチェックされていない
    if (selectedRows.size === 0) {
      enqueueSnackbar(MSG_TARGET_SELECTION_REQUIED, { variant: 'warning', preventDuplicate: true });
      return;
    }

    // 確認ダイアログを開く
    setDeleteAccountConfirmDialogOpen(true);
  };

  // アカウント削除処理
  const deleteAccountConfirmHandler = async () => {
    // 確認ダイアログを閉じる
    setDeleteAccountConfirmDialogOpen(false);

    // チェックされた行の収集
    const selectedRows = apiRef.current.getSelectedRows();

    // アカウント削除は個別アカウントが「削除」のユーザーを対象とする
    const excludedUserIds: string[] = [];
    const targetRows: UserGridRow[] = [];
    selectedRows.forEach((value) => {
      const row = value as UserGridRow;
      if (row.delflg === true) {
        targetRows.push(row);
      } else {
        excludedUserIds.push(row.id);
      }
    });

    // 対象ユーザーがいない
    if (targetRows.length === 0) {
      enqueueSnackbar(MSG_NO_DELETE_ACCOUNT_TARGET_IN_SELECTION, { variant: 'warning' });
      return;
    }
    // 対象ユーザー数が上限を超えている（異常系。通常は表示されないエラー）
    if (targetRows.length > MAX_HANDLING_USER_ID_COUNT) {
      enqueueSnackbar(MSG_PROCESSING_LIMIT_EXCEEDED, { variant: 'error' });
      return;
    }

    // 対象ユーザーを1リクエストあたりの人数で振り分ける
    const userIdsArray = splitUserIdsPerRequest(targetRows);
    const successUserIds: string[] = [];
    const failedUserIds: string[] = [];

    try {
      // 処理結果ダイアログを処理中状態にする
      setDeleteAccountProcessing(true);

      // 処理結果ダイアログを表示する
      setDeleteAccountResultDialogOpen(true);

      // アカウント削除処理の呼び出し（並列実行）
      await Promise.all(
        userIdsArray.map(async (userIds) => {
          await deleteAccount({ userIdList: userIds }).then((response) => {
            if (response.data) {
              // 成功数、失敗数の集計
              response.data.results.forEach((x) => {
                if (x.status === appConst.AADB2C_API_STATUS.SUCCESS) {
                  successUserIds.push(x.userid);
                } else {
                  failedUserIds.push(x.userid);
                }
              });
            }
          });
        })
      );
    } catch {
      navigateSystemError();
    } finally {
      // 処理結果ダイアログを処理完了状態にする
      setDeleteAccountProcessing(false);
    }

    // 成功数、失敗数、対象外数のセット
    setDeleteAccountSuccessCount(successUserIds.length);
    setDeleteAccountFailedCount(failedUserIds.length);
    setDeleteAccountExcludedCount(excludedUserIds.length);
  };

  // アカウント削除終了処理
  const deleteAccountCloseHandler = () => {
    // 処理結果ダイアログを閉じる
    setDeleteAccountResultDialogOpen(false);
    // チェックボックスの選択解除
    apiRef.current.setRowSelectionModel([]);
    // 再検索実行
    refresh();
  };

  // パスワードリセットボタン押下
  const passwordResetButtonClick = () => {
    // チェックされた行の収集
    const selectedRows = apiRef.current.getSelectedRows();

    // 1つもチェックされていない
    if (selectedRows.size === 0) {
      enqueueSnackbar(MSG_TARGET_SELECTION_REQUIED, { variant: 'warning', preventDuplicate: true });
      return;
    }

    // 確認ダイアログを開く
    setPasswordResetConfirmDialogOpen(true);
  };

  // パスワードリセット処理
  const passwordResetConfirmHandler = async () => {
    // 確認ダイアログを閉じる
    setPasswordResetConfirmDialogOpen(false);

    // チェックされた行の収集
    const selectedRows = apiRef.current.getSelectedRows();

    // パスワードリセットは個別アカウントが「登録済み」かつ共助アカウントが「発行済み」のユーザーを対象とする
    const excludedUserIds: string[] = [];
    const targetRows: UserGridRow[] = [];
    selectedRows.forEach((value) => {
      const row = value as UserGridRow;

      if (row.linkedAadb2cTypeCode === appConst.LINKED_AADB2C_TYPE_CODE.LINKED && row.delflg === false) {
        targetRows.push(row);
      } else {
        excludedUserIds.push(row.id);
      }
    });

    // 対象ユーザーがいない
    if (targetRows.length === 0) {
      enqueueSnackbar(MSG_NO_RESET_PASSWORD_TARGET_IN_SELECTION, { variant: 'warning' });
      return;
    }
    // 対象ユーザー数が上限を超えている（異常系。通常は表示されないエラー）
    if (targetRows.length > MAX_HANDLING_USER_ID_COUNT) {
      enqueueSnackbar(MSG_PROCESSING_LIMIT_EXCEEDED, { variant: 'error' });
      return;
    }

    // 対象ユーザーを1リクエストあたりの人数で振り分ける
    const userIdsArray = splitUserIdsPerRequest(targetRows);
    const successUserIds: string[] = [];
    const failedUserIds: string[] = [];

    try {
      // 処理結果ダイアログを処理中状態にする
      setPasswordResetProcessing(true);

      // 処理結果ダイアログを表示する
      setPasswordResetResultDialogOpen(true);

      // パスワードリセット処理の呼び出し（並列実行）
      await Promise.all(
        userIdsArray.map(async (userIds) => {
          await resetAccount({ userIdList: userIds }).then((response) => {
            if (response.data) {
              // 成功数、失敗数の集計
              response.data.results.forEach((x) => {
                if (x.status === appConst.AADB2C_API_STATUS.SUCCESS) {
                  successUserIds.push(x.userid);
                } else {
                  failedUserIds.push(x.userid);
                }
              });
            }
          });
        })
      );
    } catch {
      navigateSystemError();
    } finally {
      // 処理結果ダイアログを処理完了状態にする
      setPasswordResetProcessing(false);
    }

    // 成功数、失敗数、対象外数のセット
    setPasswordResetSuccessCount(successUserIds.length);
    setPasswordResetFailedCount(failedUserIds.length);
    setPasswordResetExcludedCount(excludedUserIds.length);

    // 失敗が1件以上の場合、エラーになったユーザー情報を収集する
    if (failedUserIds.length > 0) {
      const failedRows = targetRows.filter((x) => failedUserIds.includes(x.id));
      setPasswordResetFailedUserRows(failedRows);
    } else {
      setPasswordResetFailedUserRows([]);
    }
  };

  // パスワードリセット終了処理
  const passwordResetCloseHandler = () => {
    // 処理結果ダイアログを閉じる
    setPasswordResetResultDialogOpen(false);
    // チェックボックスの選択解除
    apiRef.current.setRowSelectionModel([]);
    // 再検索実行
    refresh();
  };

  return (
    <PageContainer title="アカウント発行">
      <KanriPageContainer title="アカウント発行">
        <Stack spacing={2} sx={{ height: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={tabChange}>
              <Tab label="自治体職員" />
              <Tab label="地域代表者" />
              <Tab label="支援者" />
              <Stack sx={{ ml: 5 }} direction="column" alignItems="left" justifyContent="center">
                <Typography color="red" variant="caption" style={{ display: 'inline-block', whiteSpace: 'pre-line' }}>
                  ページ、タブを切り替えると選択が解除されます。ページ単位での操作をお願いします。
                </Typography>
              </Stack>
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <UsersTable
              apiRef={apiRef}
              roleid={RoleId.JichitaiStaff}
              searchType={'search'}
              rows={rows}
              rowCount={rowCount}
              isLoading={isLoading}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <UsersTable
              apiRef={apiRef}
              roleid={RoleId.SoshikiLeader}
              searchType={'search'}
              rows={rows}
              rowCount={rowCount}
              isLoading={isLoading}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <UsersTable
              apiRef={apiRef}
              roleid={RoleId.Supporter}
              searchType={'search'}
              rows={rows}
              rowCount={rowCount}
              isLoading={isLoading}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
            />
          </TabPanel>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
            <Button variant="outlined" onClick={() => passwordResetButtonClick()}>
              パスワードリセット
            </Button>
            <Button variant="outlined" onClick={() => deleteAccountButtonClick()}>
              共助アカウントを削除
            </Button>
            <Button variant="contained" onClick={() => createAccountButtonClick()}>
              共助アカウントを発行
            </Button>
          </Stack>
        </Stack>
      </KanriPageContainer>
      <ConfirmDialog
        message={
          <>
            共助アカウントを発行します。
            <br />
            よろしいですか？
          </>
        }
        open={createAccountConfirmDialogOpen}
        onClose={() => setCreateAccountConfirmDialogOpen(false)}
        onConfirm={createAccountConfirmHandler}
      />
      <ProcessResultDialog
        open={createAccountResultDialogOpen}
        processing={createAccountProcessing}
        processingMessage="共助アカウントを発行中"
        completeMessage="共助アカウントを発行しました。"
        onClose={createAccountCloseHandler}
        successCount={createAccountSuccessCount}
        failedCount={createAccountFailedCount}
        failedUserRows={[]}
        excludedCount={createAccountExcludedCount}
      />
      <ConfirmDialog
        message={
          <>
            共助アカウントを削除します。
            <br />
            よろしいですか？
          </>
        }
        open={deleteAccountConfirmDialogOpen}
        onClose={() => setDeleteAccountConfirmDialogOpen(false)}
        onConfirm={deleteAccountConfirmHandler}
      />
      <ProcessResultDialog
        open={deleteAccountResultDialogOpen}
        processing={deleteAccountProcessing}
        processingMessage="共助アカウントを削除中"
        completeMessage="共助アカウントを削除しました。"
        onClose={deleteAccountCloseHandler}
        successCount={deleteAccountSuccessCount}
        failedCount={deleteAccountFailedCount}
        failedUserRows={[]}
        excludedCount={deleteAccountExcludedCount}
      />
      <ConfirmDialog
        message={
          <>
            パスワードリセットを実行します。
            <br />
            よろしいですか？
          </>
        }
        open={passwordResetConfirmDialogOpen}
        onClose={() => setPasswordResetConfirmDialogOpen(false)}
        onConfirm={passwordResetConfirmHandler}
      />
      <ProcessResultDialog
        open={passwordResetResultDialogOpen}
        processing={passwordResetProcessing}
        processingMessage="パスワードリセット中"
        completeMessage="パスワードリセットが完了しました。"
        onClose={passwordResetCloseHandler}
        successCount={passwordResetSuccessCount}
        failedCount={passwordResetFailedCount}
        failedUserRows={passwordResetFailedUserRows}
        excludedCount={passwordResetExcludedCount}
      />
    </PageContainer>
  );
};
