import { BrowserUtils, IPublicClientApplication } from '@azure/msal-browser';

type loginWithRedirectOpts = {
  /**
   * ユーザーに認証画面での資格情報入力を強制する
   * @default false
   */
  requirePrompt?: boolean;
};

/**
 * Azure AD B2Cへリダイレクトによってログインする
 *
 * 認証後のリダイレクト先は`/callback/azure`
 * @param pca {@link IPublicClientApplication}のインスタンス
 */
export const loginWithRedirect = async (
  pca: IPublicClientApplication,
  { requirePrompt = false }: loginWithRedirectOpts = {}
) => {
  // nonce
  const nonce = BrowserUtils.createGuid();

  // Login
  await pca.loginRedirect({
    redirectUri: `${window.location.origin}/callback/azure`,
    nonce: nonce,
    ...(requirePrompt ? { prompt: 'login' } : {}),
    scopes: ['openid'],
  });
};
