import { Box, Button, Stack, lighten, styled, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { SelectedLabel } from 'src/components/shien/atoms/buttons/toggleButtons/SelectedLabel';
import { IconNo } from 'src/components/shien/atoms/images/IconNo';

type ComponentProps = {
  children: ReactNode;
  selected: boolean;
  onClick: () => void;
  showLabel?: boolean;
  disabled?: boolean;
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<ComponentProps>(({ selected, theme }) => ({
  width: '164px',
  height: '164px',
  paddingTop: '22px',
  paddingBottom: '22px',
  lineHeight: 1,
  borderRadius: '10px',
  backgroundColor: '#ffffff',
  border: `1px solid ${selected ? theme.palette.uiStandard.red50 : theme.palette.uiStandard.gray25}`,
  boxShadow: selected ? 'none' : `0px 4px 0px 0px ${theme.palette.uiStandard.gray25}`,
  color: '#000000',
  fontSize: '20px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#ffffff',
    border: `1px solid ${selected ? theme.palette.uiStandard.red50 : theme.palette.uiStandard.gray25}`,
    boxShadow: selected ? 'none' : `0px 4px 0px 0px ${theme.palette.uiStandard.gray25}`,
    color: '#000000',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  '&:active': {
    // 選択中の場合クリックできないように
    backgroundColor: selected ? theme.palette.uiStandard.white : theme.palette.uiStandard.gray25,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: '#ffffff',
    border: selected
      ? `1px solid ${lighten(theme.palette.uiStandard.red50, 0.7)}`
      : `1px solid ${lighten(theme.palette.uiStandard.gray25, 0.7)}`,
    boxShadow: selected ? 'none' : `0px 4px 0px 0px ${lighten(theme.palette.uiStandard.gray25, 0.7)}`,
    color: lighten('#000000', 0.7),
  },
}));

export const ToggleButtonRed = ({
  children,
  selected,
  onClick,
  showLabel = true,
  disabled = false,
}: ComponentProps) => {
  const theme = useTheme();

  return (
    <Stack justifyContent="center" alignItems="center" position="relative">
      <StyledButton selected={selected} onClick={() => onClick()} disabled={disabled}>
        <Stack alignItems="center" spacing={2}>
          <IconNo fill={disabled ? lighten(theme.palette.uiStandard.red50, 0.7) : theme.palette.uiStandard.red50} />
          <Box sx={{ fontSize: '20px' }}>{children}</Box>
        </Stack>
      </StyledButton>
      {showLabel && selected && (
        <Box sx={{ position: 'absolute', bottom: -35 }}>
          <SelectedLabel color={theme.palette.uiStandard.red50} />
        </Box>
      )}
    </Stack>
  );
};
