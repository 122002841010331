import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AzureSigninPage } from 'src/components/common/pages/AzureSigninPage';
import { AzureCallbackPage } from 'src/components/common/pages/callback/azure';
import { NotFoundPage } from 'src/components/common/pages/errors/NotFoundPage';
import { IndexPage } from 'src/components/common/pages/IndexPage';
import { CreateAccountPage } from 'src/components/kanri/pages/jichitai/CreateAccountPage';
import { SafetyConfirmationPage } from 'src/components/kanri/pages/jichitai/SafetyConfirmation';
import { SupportersPage } from 'src/components/kanri/pages/jichitai/SearchSupportersPage';
import { SearchSupportRecipientsPage } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage';
import { SelectSupportRecipientsPage } from 'src/components/kanri/pages/jichitai/SelectSupportRecipientsPage';
import { DisplayNonConsentingUsersPage } from 'src/components/kanri/pages/jichitai/settings/DisplayNonConsentingUsers';
import { LineIntegrationPage } from 'src/components/kanri/pages/jichitai/settings/lineIntegrationPage';
import { StatusSettingPage } from 'src/components/kanri/pages/jichitai/settings/StatusSettingPage';
import { SystemErrorPage as KanriSystemErrorPage } from 'src/components/kanri/pages/SystemErrorPage';
import { SystemStopInformationPage as KanriSystemStopInformationPage } from 'src/components/kanri/pages/SystemStopInformationPage';
import { EnsuringSafetyPage } from 'src/components/shien/pages/EnsuringSafetyPage';
import { AnswerNotPossiblePage } from 'src/components/shien/pages/possibility/AnswerNotPossiblePage';
import { AnswerPossiblePage } from 'src/components/shien/pages/possibility/AnswerPossiblePage';
import { SupportPossibleSubmissionPage } from 'src/components/shien/pages/possibility/SupportPossibleSubmissionPage';
import { PrsActionPage } from 'src/components/shien/pages/prs/PrsActionPage';
import { PrsCompleteRegistrationPage } from 'src/components/shien/pages/prs/PrsCompleteRegistrationPage';
import { PrsContextTemplate } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { PrsDataStore } from 'src/components/shien/pages/prs/PrsDataStore';
import { PrsDetailPage } from 'src/components/shien/pages/prs/PrsDetailPage';
import { PrsRegisterSafetyPage } from 'src/components/shien/pages/prs/PrsRegisterSafetyPage';
import { PrsRegisterShelterTypePage } from 'src/components/shien/pages/prs/PrsRegisterShelterTypePage';
import { PrsSafetySummary } from 'src/components/shien/pages/prs/PrsSafetySummaryPage';
import { PrsSelectShelterPage } from 'src/components/shien/pages/prs/PrsSelectShelterPage';
import { PrsSummaryPage } from 'src/components/shien/pages/prs/PrsSummaryPage';
import { PrsListPage } from 'src/components/shien/pages/PrsListPage';
import { SupporterProfilePage } from 'src/components/shien/pages/SupporterProfilePage';
import { SystemErrorPage } from 'src/components/shien/pages/SystemErrorPage';
import { SystemStopInformationPage } from 'src/components/shien/pages/SystemStopInformationPage';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { AuthAreaDataStore } from 'src/routes/AuthAreaDataStore';
import { AuthAreaTemplate } from 'src/routes/AuthAreaTemplate';
import { JichitaiTemplate } from 'src/routes/kanri/jichitai/JichitaiTemplate';
import { KanriTemplate } from 'src/routes/kanri/KanriTemplate';
import { NoAuthAreaTemplate } from 'src/routes/NoAuthAreaTemplate';
import { RequirePermission } from 'src/routes/RequirePermission';
import { ShienTemplate } from 'src/routes/shien/ShienTemplate';
import VConsole from 'vconsole';

export const AppRouters = () => {
  const { showBackdrop, setShowBackdrop } = useBackdrop();
  const location = useLocation();

  useEffect(() => {
    if (showBackdrop) {
      setShowBackdrop(false);
    }

    if (location.search.includes('vconsole')) {
      new VConsole();
    }
  }, [location]);

  return (
    <Routes>
      {/* no auth area */}
      <Route index element={<IndexPage />} />
      <Route path="*" element={<Navigate to="/error/404" />} />
      <Route element={<NoAuthAreaTemplate />}>
        <Route path="azure-signin" element={<AzureSigninPage />} />
        <Route path="callback/azure" element={<AzureCallbackPage />} />
        <Route path="error">
          <Route path="SystemError" element={<KanriSystemErrorPage />} />
          <Route path="404" element={<NotFoundPage />} />
        </Route>
      </Route>
      {/* auth area */}
      <Route
        path="app"
        element={
          <AuthAreaDataStore>
            <AuthAreaTemplate />
          </AuthAreaDataStore>
        }
      >
        <Route element={<RequirePermission notPermissionPath="/error/404" />}>
          <Route path="kanri" element={<KanriTemplate />}>
            <Route element={<JichitaiTemplate />}>
              <Route path="search-support-recipients" element={<SearchSupportRecipientsPage />} />
              <Route path="supporters" element={<SupportersPage />} />
              <Route path="select-support-recipients" element={<SelectSupportRecipientsPage />} />
              <Route path="safety-confirmation" element={<SafetyConfirmationPage />} />
              <Route path="settings">
                <Route path="display-non-consenting-users" element={<DisplayNonConsentingUsersPage />} />
                <Route path="statusSetting" element={<StatusSettingPage />} />
                <Route path="lineIntegration" element={<LineIntegrationPage />} />
                <Route path="create-account" element={<CreateAccountPage />} />
              </Route>
            </Route>
            <Route path="error">
              <Route path="SystemError" element={<KanriSystemErrorPage />} />
            </Route>
            <Route path="system-stop-information" element={<KanriSystemStopInformationPage />} />
          </Route>
          <Route path="shien" element={<ShienTemplate />}>
            <Route
              path="prs"
              element={
                <PrsDataStore>
                  <PrsContextTemplate />
                </PrsDataStore>
              }
            >
              <Route path="summary" element={<PrsSummaryPage />} />
              <Route path="detail" element={<PrsDetailPage />} />
              <Route path="action/:prsActionType" element={<PrsActionPage />} />
              <Route path="safety-summary" element={<PrsSafetySummary />} />
              <Route path="register-safety" element={<PrsRegisterSafetyPage />} />
              <Route path="register-shelter-type" element={<PrsRegisterShelterTypePage />} />
              <Route path="select-shelter" element={<PrsSelectShelterPage />} />
              <Route path="complete-registration" element={<PrsCompleteRegistrationPage />} />
            </Route>
            {/* 要支援者一覧 */}
            <Route path="prs-list" element={<PrsListPage />} />
            <Route path="sp-ensuring-safety" element={<EnsuringSafetyPage />} />
            {/* 支援可否 */}
            <Route path="possibility">
              <Route path="question" element={<SupportPossibleSubmissionPage />} />
              <Route path="answer">
                <Route path="possible" element={<AnswerPossiblePage />} />
                <Route path="not-possible" element={<AnswerNotPossiblePage />} />
              </Route>
            </Route>
            {/* あなたについて */}
            <Route path="profile" element={<SupporterProfilePage />}></Route>
            <Route path="error">
              <Route path="SystemError" element={<SystemErrorPage />} />
            </Route>
            <Route path="system-stop-information" element={<SystemStopInformationPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
