import { useEffect, useState } from 'react';
import { useUserData } from 'src/hooks/common/useUserData';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';
import { ErrorResponse } from 'src/utilities/restApi/common/types/responses';
import { getSoshikiList } from 'src/utilities/restApi/kanri/soshiki';
import { SoshikiGridRow } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/types';
import { Soshiki } from 'src/types/common/Soshiki';

type HookParams = {
  searchBtnClickFlg: boolean;
  selectedSoshikiList?: readonly Soshiki[];
};

export const useQuery = ({ searchBtnClickFlg, selectedSoshikiList }: HookParams) => {
  const [controller, setController] = useState<AbortController>();
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState<SearchCallerType | undefined>('system');
  const [error, setError] = useState<SearchError>({ isError: false, reason: undefined });
  const [rows, setRows] = useState<SoshikiGridRow[] | undefined>(undefined);

  useEffect(() => {
    search('user');
  }, [userData, searchBtnClickFlg, selectedSoshikiList]);

  // functions
  const search = (callerType: SearchCallerType) => {
    if (userData && (!searchBtnClickFlg || (searchBtnClickFlg && selectedSoshikiList?.length == 0))) {
      controller?.abort();
      setIsLoading(callerType);
      const newController = new AbortController();
      setController(newController);
      getSoshikiList()
        .then((response) => {
          if (response.data) {
            const newData = response.data.soshiki_list.map((x) => {
              return {
                id: x.soshikiid,
                name: x.soshikiname,
              } as SoshikiGridRow;
            });
            setRows(newData);
          }
        })
        .catch((response: ErrorResponse) => {
          setError({ isError: true, reason: response.error.reason });
        })
        .finally(() => {
          // abort時は次のリクエストのローディングが走るので、ローディングを解除しない
          if (!newController.signal.aborted) {
            setIsLoading(undefined);
          }
        });
    } else {
      const newData = selectedSoshikiList?.map((x) => {
        return {
          id: x.soshikiId,
          name: x.soshikiName,
        } as SoshikiGridRow;
      });
      setRows(newData);
      setIsLoading(undefined);
    }
  };

  return { isLoading, rows, error, search };
};
