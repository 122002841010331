export const Illust01 = () => {
  return (
    <svg width="175" height="221" viewBox="0 0 175 221" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.2678 103.316C49.2678 103.316 43.9378 97.9059 43.3578 96.1559C42.7778 94.4059 43.5678 88.8359 47.0378 87.9459C50.5078 87.0559 68.6978 86.6559 78.0778 83.1959C87.4578 79.7459 110.328 72.4459 115.198 74.0359C120.068 75.6159 125.468 79.4059 127.708 85.7359C129.948 92.0659 133.508 99.6659 120.938 101.726C120.938 101.726 126.588 101.966 129.908 107.146C133.228 112.316 159.608 161.716 161.258 174.946C162.908 188.186 154.128 194.626 146.248 193.036C138.368 191.446 139.138 189.866 139.138 189.866L154.208 419.896C154.208 419.896 72.9378 427.786 22.9178 419.806L35.4978 184.366C35.4978 184.366 19.0678 196.646 7.99779 192.006C-3.49221 187.196 4.42779 172.096 7.96779 165.026C11.4978 157.956 21.8278 142.826 21.8278 142.826C21.8278 142.826 21.8278 140.806 24.5778 140.806C24.5778 140.806 37.2078 110.896 41.8278 108.036C43.8378 106.796 46.8778 104.646 49.2778 103.316H49.2678Z"
        fill="#86A4C4"
      />
      <path
        d="M49.6078 133.456H91.1678L91.1978 137.056L88.7478 138.426L88.7178 141.236L51.3478 140.646L49.6078 133.456Z"
        fill="white"
      />
      <path
        d="M115.328 147.336L103.748 130.766L88.7478 138.426L88.5578 154.166L94.8178 157.146L95.5578 153.446L102.348 151.196L110.408 157.146L115.328 147.336Z"
        fill="white"
      />
      <path
        d="M129.458 89.876C129.458 89.876 131.308 92.726 129.758 95.536C128.208 98.346 119.718 101.856 119.718 101.856C119.718 101.856 126.998 103.576 129.258 106.506C131.518 109.436 155.998 158.256 157.688 163.356C159.378 168.456 162.508 177.566 161.448 180.296C160.388 183.026 157.398 193.526 147.368 193.726L140.688 191.516L128.478 183.896L106.608 166.676L110.398 157.146L101.668 151.196L93.8778 153.526C93.8778 153.526 93.9178 155.816 97.8378 164.836C101.758 173.856 106.178 176.956 106.818 177.086C107.458 177.216 110.828 177.086 110.828 177.086C110.828 177.086 125.418 207.706 139.658 208.336C158.158 209.156 169.028 191.696 170.228 188.896C171.428 186.096 174.128 173.296 173.208 166.236C172.368 159.776 169.998 147.636 166.298 135.836C162.598 124.036 158.268 124.666 158.268 124.666C158.268 124.666 160.898 121.476 153.398 105.346C145.898 89.226 129.468 89.866 129.468 89.866L129.458 89.876Z"
        fill="#6D6D6D"
      />
      <path
        d="M102.348 97.7161L116.088 99.8861C116.088 99.8861 108.258 110.396 106.538 118.346C104.818 126.296 104.128 131.306 104.128 131.306L90.2878 136.526C90.2878 136.526 91.3778 120.936 93.8778 115.026C96.3778 109.116 102.338 97.7061 102.338 97.7061L102.348 97.7161Z"
        fill="#6D6D6D"
      />
      <path
        d="M57.0378 110.836C57.0378 110.836 54.0478 116.446 52.8378 122.496C51.6278 128.546 50.6878 137.836 50.6878 137.836L40.0778 132.466C40.0778 132.466 42.6078 116.796 45.3178 111.876C48.0278 106.956 50.6778 105.746 50.6778 105.746L57.0278 110.836H57.0378Z"
        fill="#6D6D6D"
      />
      <path
        d="M71.6478 92.2861V98.6061C71.6478 98.6061 95.3178 95.9661 96.5278 92.2861L95.5578 78.4561C95.5578 78.4561 91.8578 81.3261 90.2978 82.6561C88.7278 83.9861 83.7878 91.7361 71.6478 92.2861Z"
        fill="white"
      />
      <path
        d="M50.7178 133.806C51.4578 133.986 91.1678 133.456 91.1678 133.456L91.1378 137.836L88.7478 139.116L88.1678 140.646L51.9378 140.276L50.7178 133.806Z"
        fill="#6D6D6D"
      />
      <path d="M71.6478 92.7759V98.1759" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M96.1678 90.8959L95.5578 74.5659"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.8878 154.056C97.2478 165.136 101.188 172.906 106.828 177.086H110.838C110.848 175.506 122.828 208.076 139.668 208.336C189.088 209.106 173.678 137.126 158.398 125.596C158.398 125.596 154.908 94.436 135.068 91.516C133.448 91.276 131.848 91.076 130.248 90.896"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.438 131.746C104.618 113.426 116.088 99.8861 116.088 99.8861L103.208 97.7161C103.208 97.7161 91.3278 111.106 91.1578 133.866C91.1578 134.986 91.1578 136.086 91.1978 137.166"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.428 100.936C149.428 100.936 143.418 95.556 136.718 99.846C130.018 104.136 129.918 107.156 129.918 107.156"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.278 100.916C115.278 100.916 125.408 102.256 129.908 107.146C134.408 112.036 159.848 164.686 161.258 174.946C162.668 185.176 154.768 192.836 147.378 193.716C139.988 194.596 106.568 167.326 106.568 167.326L118.898 138.846L149.418 162.106"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.618 146.076L103.748 130.766L88.7478 138.426V154.186L93.5778 157.146"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.408 157.146L101.678 151.196L93.5778 154.166"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7978 148.166L36.7578 130.766L51.7578 138.426L51.9378 154.166L46.7778 156.626L44.9378 153.446L38.1578 151.196L28.4478 157.866L23.7978 148.166Z"
        fill="white"
      />
      <path
        d="M38.8178 152.166C38.9678 152.246 45.7078 154.776 45.7078 154.776L43.2278 162.566L38.8178 152.166Z"
        fill="#6D6D6D"
      />
      <path
        d="M24.5678 147.926L36.7578 130.766L51.7578 138.426V154.186L46.9178 157.146"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0878 157.146L38.8178 151.196L46.9178 154.166"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5678 140.806C24.5678 140.806 37.0378 110.516 42.9178 107.146C42.9178 107.146 47.5378 104.096 49.2678 103.316"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5578 183.246C33.3678 185.796 24.3878 191.166 17.4678 192.656C17.4678 192.656 0.167801 196.906 2.4078 178.076C3.4278 169.536 22.2078 142.116 21.8178 142.816C21.8178 142.816 29.7078 166.856 38.5578 168.736C47.4078 170.616 37.4378 152.126 37.4378 152.126"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2678 103.316C46.6778 106.956 40.0778 123.626 40.0778 132.466"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6878 137.836C51.1278 126.976 53.9678 116.156 57.2478 110.456"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.668 208.336L154.208 419.896C154.208 419.896 126.768 423.346 73.7978 423.346C45.8878 423.346 22.9178 419.816 22.9178 419.816L35.2278 189.396L35.9078 178.416"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.088 99.896C116.088 99.896 118.688 100.576 119.728 101.866"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M51.4578 133.996H90.7378" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M51.9378 140.646H88.5578" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M67.6778 133.086L68.0178 117.446L62.5378 98.1859C62.5378 98.1859 89.6378 102.996 112.478 81.7859"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6278 421.626L67.5378 141.236"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8678 111.876L101.678 99.696"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.5478 98.1761C62.5478 98.1761 51.6878 97.2461 50.3678 95.5361C47.3578 91.6361 51.2078 87.8761 54.4778 86.7061"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.7178 78.9061C95.7178 78.9061 115.208 69.4261 121.518 76.9261C141.688 100.926 119.728 101.866 119.728 101.866"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.5878 112.426C59.5878 112.426 43.3578 99.0261 43.3678 96.1561C43.4078 87.5961 49.7578 86.7961 54.4778 86.7061L59.8678 86.2161"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8678 151.196L71.2878 421.626"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3478 141.956C28.3478 141.956 24.0778 138.866 21.8178 142.826"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.908 107.146C134.408 112.036 159.848 164.686 161.258 174.946"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.1478 167.336C42.1478 167.336 35.3278 180.966 29.3278 187.416"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.9178 154.166C46.9178 154.166 44.6278 163.316 42.1478 167.326"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1978 46.416C50.0378 51.636 50.1678 56.106 50.5678 60.716C50.9678 65.326 53.2378 87.466 67.3778 91.536C81.5178 95.606 95.0878 85.196 97.4578 81.226C99.8278 77.246 101.418 72.826 101.418 72.826C101.418 72.826 110.108 71.986 110.628 66.336C111.148 60.686 111.638 63.016 110.078 57.526C109.808 56.576 107.078 54.726 102.378 56.006L104.458 42.056C96.3178 39.946 82.6978 35.446 68.0778 35.446C55.3678 35.446 49.0078 45.236 49.1978 46.416Z"
        fill="white"
      />
      <path
        d="M49.1878 50.5359L49.9678 55.5559C49.9678 55.5559 44.1178 54.5859 42.3378 51.5759C40.5578 48.5659 42.1478 46.7059 43.3178 44.9859C44.4878 43.2659 44.9578 42.6459 45.0078 38.7559C45.0578 34.8659 45.8178 14.0659 65.8278 6.85595C65.8278 6.85595 72.9178 0.335946 85.0978 2.40595C97.2778 4.47595 102.558 9.79595 104.408 11.5659C106.268 13.3359 112.168 20.9159 113.548 23.3459C114.928 25.7759 117.438 30.5959 115.688 43.2559C115.688 43.2559 115.418 44.3059 116.488 47.6659C117.548 51.0259 118.108 52.5059 116.788 53.8559C115.468 55.2059 110.468 58.1959 110.468 58.1959L107.538 55.5359L109.148 51.2659C109.148 51.2659 106.888 48.5359 100.108 48.1259C93.3278 47.7159 95.7978 50.0859 91.0478 49.6359C86.2978 49.1859 83.5578 44.8759 78.3578 43.4559C73.1578 42.0359 61.8478 42.4359 58.0378 44.0259C54.2278 45.6159 55.0278 48.2859 49.1778 50.5159L49.1878 50.5359Z"
        fill="#FEDA04"
      />
      <path
        d="M107.548 55.5559L101.888 56.9959L103.348 48.5659C103.348 48.5659 107.318 49.2859 109.158 51.2859C110.998 53.2859 107.548 55.5559 107.548 55.5559Z"
        fill="#6D6D6D"
      />
      <path d="M45.3178 54.156L49.9778 55.556L51.3978 68.966L45.3178 54.156Z" fill="#86A4C4" />
      <path d="M97.5578 46.386L103.658 47.016L98.8778 79.536L94.6978 84.276L97.5578 46.386Z" fill="#6D6D6D" />
      <path
        d="M105.528 55.996C105.528 55.996 108.128 55.046 110.068 57.516C112.008 59.986 112.968 71.866 101.258 73.366"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.7478 64.246C85.5482 64.246 87.0078 62.6656 87.0078 60.716C87.0078 58.7665 85.5482 57.186 83.7478 57.186C81.9473 57.186 80.4878 58.7665 80.4878 60.716C80.4878 62.6656 81.9473 64.246 83.7478 64.246Z"
        fill="black"
      />
      <path
        d="M61.7478 64.246C63.5482 64.246 65.0078 62.6656 65.0078 60.716C65.0078 58.7665 63.5482 57.186 61.7478 57.186C59.9473 57.186 58.4878 58.7665 58.4878 60.716C58.4878 62.6656 59.9473 64.246 61.7478 64.246Z"
        fill="black"
      />
      <path
        d="M71.3678 73.5959C72.4447 73.5959 73.3178 72.8617 73.3178 71.9559C73.3178 71.0502 72.4447 70.3159 71.3678 70.3159C70.2908 70.3159 69.4178 71.0502 69.4178 71.9559C69.4178 72.8617 70.2908 73.5959 71.3678 73.5959Z"
        fill="black"
      />
      <path
        d="M76.3178 80.5259C75.9478 80.5659 74.4178 80.5359 71.7378 80.5359C70.0578 80.5359 68.7478 80.6359 68.2378 80.5859"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.4078 52.1158C81.4078 52.1158 82.3478 51.7458 84.3478 51.7358C86.1078 51.7358 87.4478 52.2158 87.4478 52.2158"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.0878 52.1158C64.0878 52.1158 63.1478 51.7458 61.1478 51.7358C59.3878 51.7358 58.0478 52.2158 58.0478 52.2158"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5278 50.946C49.5278 50.946 50.3478 59.626 51.1778 67.426C52.0078 75.226 59.1178 92.776 73.9278 92.776C84.3978 92.776 97.3978 86.4361 101.108 73.6161"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3178 52.676L51.3978 68.966"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.0178 78.566L103.338 49.156"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.4278 48.146L94.6978 84.276"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9778 55.556C49.9778 55.556 41.1178 52.886 41.2178 49.276C41.2978 46.426 43.6078 45.216 44.4878 43.266C45.8478 40.286 42.2678 14.636 67.7778 6.15601"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.298 24.976C114.298 24.976 105.188 2.41602 80.1578 2.41602C59.4278 2.41602 60.1978 23.896 60.1978 23.896"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.6978 8.33594C94.6978 8.33594 121.388 15.7059 115.708 43.2659C115.118 46.1559 117.848 49.3259 117.618 52.2159C117.458 54.2059 112.738 57.9359 110.078 57.5159"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.118 52.736C108.358 50.936 102.408 47.106 97.4278 48.146C95.8978 48.466 92.1978 49.976 90.4378 49.606C87.6478 49.016 83.7078 46.756 80.1678 44.266C76.3278 41.566 59.9078 42.626 57.4978 44.336C56.0278 45.386 53.2878 48.746 51.4078 49.596C45.6478 52.206 43.0878 52.236 41.2278 49.266"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.9378 23.8959C76.9378 23.8959 75.6178 8.14588 86.5078 7.52588"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.2978 17.366C90.2978 19.706 88.6278 33.046 107.078 37.206"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.3978 40.8859C50.3978 40.8859 54.9878 33.5159 67.9978 33.5159C84.0078 33.5159 94.4478 40.8859 94.4478 40.8859"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.678 51.7458L108.258 55.9958"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
