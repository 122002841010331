export const appConst = {
  STORAGE_KEY_USERID: 'N5xKiW7U',
  STORAGE_KEY_LINE_STATE: 'Ejyn4iGn',
  STORAGE_KEY_LINE_NONCE: 'eKDKNS2Y',
  STORAGE_KEY_LINE_CODE_VERIFIER: 's9EkhMLG',
  LABEL_HALF_COMMA: ',',
  LABEL_HALF_SPACE: ' ',
  LABEL_UNCHECKED: '未確認',
  LABEL_UNAGREED: '未同意',
  LABEL_AGREED: '同意',
  ID_PRS_STATUTS_UNCHECKED: '00000000-0000-0000-0000-000000000000',
  CD_PRS_STATUS_SYSTEM_CODE_VISITED: 1,
  CD_PRS_STATUS_SYSTEM_CODE_ACOMPANY: 2,
  CD_SEX_MALE: '1',
  CD_SEX_FEMALE: '2',
  CD_SOLITUDE: '1',
  CD_COHABITATION: '2',
  CD_GRADE_1: '1',
  CD_GRADE_2: '2',
  CD_GRADE_3: '3',
  CD_GRADE_4: '4',
  CD_GRADE_5: '5',
  CD_GRADE_6: '6',
  CD_GRADE_7: '7',
  CD_GRADE_NOTHING: '8',
  CD_REHABILITATION_NOTEBOOK_A: '1',
  CD_REHABILITATION_NOTEBOOK_B: '2',
  CD_REHABILITATION_NOTEBOOK_NOTHING: '3',
  CD_NURSING_CARE_LEVEL_1: '1',
  CD_NURSING_CARE_LEVEL_2: '2',
  CD_NURSING_CARE_LEVEL_3: '3',
  CD_NURSING_CARE_LEVEL_4: '4',
  CD_NURSING_CARE_LEVEL_5: '5',
  CD_NURSING_CARE_LEVEL_6: '6',
  CD_NURSING_CARE_LEVEL_7: '7',
  CD_NURSING_CARE_LEVEL_NOTHING: '8',
  CD_ABSENCE: '0',
  CD_PRESENCE: '1',
  STR_EMPTY: '',
  STR_FALSE: '0',
  STR_TRUE: '1',
  SPCHECKID: {
    NOT_ANSWERD: '00000000-0000-0000-0000-000000000000',
    AVAILABLE: '00000000-0000-0000-0000-000000000001',
    UNABLE: '00000000-0000-0000-0000-000000000002',
  } as const,
  SHELTER_TYPE: {
    CODE: {
      HOME: '1', // 自宅
      SHELTER: '2', // 避難所
      CUSTOM: '3', // その他
      UNKNOWN: '4', // わからない
      UNREGISTERED: null, // 未登録 (※用途未定)
    } as const,
    NAME: {
      HOME: '自宅', // 自宅
      SHELTER: '避難所', // 避難所
      CUSTOM: 'その他', // その他
      UNKNOWN: 'わからない', // わからない
    } as const,
    SUMMARY_NAME: {
      HOME: '自宅', // trn_prsのshelter_type_code = 1 の場合
      UNKNOWN: '避難先不明', // trn_prsのshelter_type_code = 4 の場合
      UNREGISTERED: '避難先未登録', // trn_prsのshelter_type_code = null の場合(場所によっては空文字使用)
    } as const,
  } as const,
  LINKED_AADB2C_TYPE_CODE: {
    NOT_LINKED: '0', // 未発行
    LINKED: '1', // 発行済み
    LINK_FAILURE: '2', // 発行失敗
  } as const,
  AADB2C_API_STATUS: {
    FAILURE: -1, // 失敗
    PARTIALFAILURE: 0, // 部分的に失敗
    SUCCESS: 1, // 成功
  } as const,
} as const;

/** AADB2CAPIステータス */
export type Aadb2cApiStatus = (typeof appConst.AADB2C_API_STATUS)[keyof typeof appConst.AADB2C_API_STATUS];

/** AADB2C連携状況種別コード */
export type LinkedAadb2cTypeCode =
  (typeof appConst.LINKED_AADB2C_TYPE_CODE)[keyof typeof appConst.LINKED_AADB2C_TYPE_CODE];

/** 性別コード 1:男|2:女 */
export const sexCodes = [
  {
    code: appConst.CD_SEX_MALE,
    Label: '男性',
  },
  {
    code: appConst.CD_SEX_FEMALE,
    Label: '女性',
  },
] as const;

/** 独居・同居コード 1:独居|2:同居 */
export const solitudeCohabitationCodes = [
  {
    code: appConst.CD_SOLITUDE,
    Label: '独居',
  },
  {
    code: appConst.CD_COHABITATION,
    Label: '同居',
  },
] as const;

/** 等級コード 1:1級|2:2級|3:3級|4:4級|5:5級|6:6級|7:7級|8:無 */
export const gradeCodes = [
  {
    code: appConst.CD_GRADE_1,
    Label: '1級',
  },
  {
    code: appConst.CD_GRADE_2,
    Label: '2級',
  },
  {
    code: appConst.CD_GRADE_3,
    Label: '3級',
  },
  {
    code: appConst.CD_GRADE_4,
    Label: '4級',
  },
  {
    code: appConst.CD_GRADE_5,
    Label: '5級',
  },
  {
    code: appConst.CD_GRADE_6,
    Label: '6級',
  },
  {
    code: appConst.CD_GRADE_7,
    Label: '7級',
  },
  {
    code: appConst.CD_GRADE_NOTHING,
    Label: 'なし',
  },
] as const;

/** 療育手帳コード 1:療育手帳A|2:療育手帳B|3:無 */
export const rehabilitationNotebookCodes = [
  {
    code: appConst.CD_REHABILITATION_NOTEBOOK_A,
    Label: '療育手帳A',
  },
  {
    code: appConst.CD_REHABILITATION_NOTEBOOK_B,
    Label: '療育手帳B',
  },
  {
    code: appConst.CD_REHABILITATION_NOTEBOOK_NOTHING,
    Label: 'なし',
  },
] as const;

/** 精神障害者保健福祉手帳コード 1:1級|2:2級|3:3級|4:無 */
export const mentalDisabilityHealthAndWelfareHandbookCodes = [
  {
    code: appConst.CD_GRADE_1,
    Label: '1級',
  },
  {
    code: appConst.CD_GRADE_2,
    Label: '2級',
  },
  {
    code: appConst.CD_GRADE_3,
    Label: '3級',
  },
  {
    code: appConst.CD_GRADE_4,
    Label: 'なし',
  },
] as const;

/** 要介護度コード 1:要支援1|2:要支援2|3:要介護1|4:要介護2|5:要介護3|6:要介護4|7:要介護5|8:無 */
export const nursingCareLevelCodes = [
  {
    code: appConst.CD_NURSING_CARE_LEVEL_1,
    Label: '要支援1',
  },
  {
    code: appConst.CD_NURSING_CARE_LEVEL_2,
    Label: '要支援2',
  },
  {
    code: appConst.CD_NURSING_CARE_LEVEL_3,
    Label: '要介護1',
  },
  {
    code: appConst.CD_NURSING_CARE_LEVEL_4,
    Label: '要介護2',
  },
  {
    code: appConst.CD_NURSING_CARE_LEVEL_5,
    Label: '要介護3',
  },
  {
    code: appConst.CD_NURSING_CARE_LEVEL_6,
    Label: '要介護4',
  },
  {
    code: appConst.CD_NURSING_CARE_LEVEL_7,
    Label: '要介護5',
  },
  {
    code: appConst.CD_NURSING_CARE_LEVEL_NOTHING,
    Label: 'なし',
  },
] as const;

/** 有無コード  */
export const presenceAbsenceCodes = [
  {
    code: appConst.CD_ABSENCE,
    Label: '無',
  },
  {
    code: appConst.CD_PRESENCE,
    Label: '有',
  },
] as const;

export enum JianMode {
  Prod = '1',
  Training = '2',
}

export enum RoleId {
  Admin = '00000000-0000-0000-0000-000000000001',
  JichitaiStaff = '00000000-0000-0000-0000-000000000002',
  SoshikiLeader = '00000000-0000-0000-0000-000000000003',
  Supporter = '00000000-0000-0000-0000-000000000004',
}

/**
 * {@link roleId}引数が既知の{@link RoleId}であるかどうかを検証する型ガード
 */
export const isKnownRoleId = (roleId: string): roleId is RoleId =>
  Object.values(RoleId as Record<string, string>).includes(roleId);
