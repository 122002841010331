import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestGetEvaMap, ResponseGetEvaMap } from 'src/utilities/restApi/shien/types/evamap';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

/**
 * 避難経路図の取得
 */
export const getResponseEvaMap = async (params: RequestGetEvaMap) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };
  return new Promise<SuccessResponse<ResponseGetEvaMap>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetEvaMap>>('evamap', config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError) => reject(error.response?.data));
  });
};
