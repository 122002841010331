export const IconNext = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.0001 19.0001C9.74006 19.0001 9.49006 18.9001 9.29006 18.7101C8.90006 18.3201 8.90006 17.6901 9.29006 17.3001L14.5801 12.0101L9.29006 6.72008C8.90006 6.33008 8.90006 5.70008 9.29006 5.31008C9.68006 4.92008 10.3101 4.92008 10.7001 5.31008L16.7001 11.3101C17.0901 11.7001 17.0901 12.3301 16.7001 12.7201L10.7001 18.7201C10.5001 18.9201 10.2501 19.0101 9.99006 19.0101L10.0001 19.0001Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};
