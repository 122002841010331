export const IconPullDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.35003 10.65L13.14 5.86001C13.45 5.55001 13.23 5.01001 12.79 5.01001H3.21003C2.76003 5.01001 2.54003 5.55001 2.86003 5.86001L7.65003 10.65C7.85003 10.85 8.16003 10.85 8.36003 10.65H8.35003Z"
        fill="black"
      />
    </svg>
  );
};
