import { FC } from 'react';
import { AppChip } from 'src/components/common/atoms/AppChip';
import { PrsActionType } from 'src/types/common/PrsActionType';

type ComponentProps = {
  prsActionType?: PrsActionType;
};

export const PrsActionChip: FC<ComponentProps> = ({ prsActionType }) => {
  const label = prsActionType === 'visit' ? '訪問中' : prsActionType === 'evacuate' ? '避難中' : undefined;
  return label && <AppChip label={label} />;
};
