import { Box, Button, Container, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { HeaderWithBackButtonTemplate } from 'src/components/shien/templates/HeaderWithBackButtonTemplate';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { useJianInfo } from 'src/hooks/common/useJianInfo';
import { useJichitaiData } from 'src/hooks/common/useJichitaiData';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { PlannedShelterTypeCode } from 'src/types/common/Prs2';
import { PrsActionType } from 'src/types/common/PrsActionType';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';
import { RequestPatchPrsDetail } from 'src/utilities/restApi/common/types/prsDetail';
import { getResponseEvaMap } from 'src/utilities/restApi/shien/evamap';

type PrsActionLabels = {
  prsActionType: PrsActionType;
  title: string;
  description: string;
  actionLabel: string;
};

// definitions
const actionDefines = [
  {
    prsActionType: 'visit',
    title: '自宅に訪問',
    description: {
      notStarted: '「訪問する」を押し、他の支援者に自宅に訪問することを共有しましょう。',
      inProgress: '「訪問をやめる」を押すと、他の支援者への共有を取り消すことができます。',
    },
    actionLabel: { notStarted: '訪問する', inProgress: '訪問をやめる' },
  },
  {
    prsActionType: 'evacuate',
    title: '避難先に同行',
    description: {
      notStarted: '「避難する」を押し、他の支援者に避難先まで同行することを共有しましょう。',
      inProgress: '「避難をやめる」を押すと、他の支援者への共有を取り消すことができます。',
    },
    actionLabel: { notStarted: '避難する', inProgress: '避難をやめる' },
  },
];

export const PrsActionPage = () => {
  // context
  const { prs, refreshData, initialized } = useContext(PrsContext);

  // navigation
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const { prsActionType } = useParams();

  // states
  const { setShowBackdrop } = useBackdrop();
  const [prsAction, setPrsAction] = useState<PrsActionLabels>();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [address, setAddress] = useState<string>();
  const [imgUrl, setImageUrl] = useState<string>();
  const [width, setWidth] = useState<number>(0);
  const { jichitaiData } = useJichitaiData();

  // ref
  const ref = useRef<HTMLDivElement>();

  // hooks: other
  const theme = useTheme();
  const { jianInfo, jianOngoing: jianOnGoing } = useJianInfo();

  // hooks: useEffect
  useEffect(() => {
    if (prs) {
      const actionInProgress =
        (prsActionType === 'visit' &&
          !!prs.lastPrsStatusUpdatedAt &&
          !!prs.lastVisitedAt &&
          prs.lastPrsStatusUpdatedAt === prs.lastVisitedAt) ||
        (prsActionType === 'evacuate' &&
          !!prs.lastPrsStatusUpdatedAt &&
          !!prs.lastEvacuatedAt &&
          prs.lastPrsStatusUpdatedAt === prs.lastEvacuatedAt);

      setActionInProgress(actionInProgress);

      const action = actionDefines
        .filter((x) => x.prsActionType === prsActionType)
        .map(
          (x) =>
            ({
              prsActionType: x.prsActionType,
              title: x.title,
              description: actionInProgress ? x.description.inProgress : x.description.notStarted,
              actionLabel: actionInProgress ? x.actionLabel.inProgress : x.actionLabel.notStarted,
            } as PrsActionLabels)
        )[0];
      setPrsAction(action);
    }
  }, [prs, prsActionType]);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setWidth(rect.width);
    }

    if (typeof imgUrl === 'undefined' && prs && jichitaiData && jianInfo !== undefined) {
      // 住所と避難経路図の取得
      prsActionType === 'visit' && setAddress(prs.address);
      (async () => {
        try {
          if (jianOnGoing()) {
            // 事案が発生している場合
            // 住所の設定
            const plannedShelter = prs.plannedShelters.find(
              (shelter) => shelter.disasterTypeNumber === jianInfo!.disasterTypeNumber
            );
            if (prsActionType === 'evacuate') {
              if (plannedShelter) {
                plannedShelter.shelterTypeCode === PlannedShelterTypeCode.Home
                  ? setAddress(prs.address || '')
                  : plannedShelter.shelterTypeCode === PlannedShelterTypeCode.Shelter
                  ? setAddress(plannedShelter.shelterAddress || '')
                  : plannedShelter.shelterTypeCode === PlannedShelterTypeCode.Other
                  ? setAddress(plannedShelter.customShelterAddress || '')
                  : setAddress('');
              } else {
                setAddress('');
              }
            }
            // 避難経路図の設定
            const resEvaMap = await getResponseEvaMap({
              evajichitaiid: jichitaiData.evaJichitaiId,
              evaprsid: prs.evaPrsId,
              disasterTypeNumber: jianInfo!.disasterTypeNumber.toString(),
            });
            resEvaMap.data?.exists ? setImageUrl(`data:image/png;base64,${resEvaMap.data.image}`) : setImageUrl('');
          } else {
            prsActionType === 'evacuate' && setAddress('');
            setImageUrl('');
          }
        } catch {
          navigateSystemError();
        }
      })();
    }
  }, [prs, jichitaiData, jianInfo]);

  // functions
  const handleRegisterSafety = () => {
    if (prsAction!.prsActionType === 'visit') {
      navigate('/app/shien/prs/register-safety', {
        state: { prsSafetyRegistrationFlowType: 'register-from-visit' },
      });
    } else if (prsAction!.prsActionType === 'evacuate') {
      navigate('/app/shien/prs/register-safety', {
        state: { prsSafetyRegistrationFlowType: 'register-from-evacuate' },
      });
    }
  };

  const handleAction = async () => {
    setShowBackdrop(true);

    try {
      const params: RequestPatchPrsDetail = {
        prsid: prs!.prsId,
      };

      if (prsAction!.prsActionType === 'visit') {
        if (actionInProgress) {
          // 訪問をやめる
          params.lastvisitedat = false;
        } else {
          // 訪問する
          params.lastprsstatusupdatedat = true;
          params.lastvisitedat = true;
        }
      } else if (prsAction!.prsActionType === 'evacuate') {
        if (actionInProgress) {
          // 避難をやめる
          params.lastevacuatedat = false;
        } else {
          // 避難する
          params.lastprsstatusupdatedat = true;
          params.lastevacuatedat = true;
        }
      }

      await patchPrsDetail(params);
      await refreshData();
    } catch {
      navigateSystemError();
    } finally {
      setShowBackdrop(false);
    }
  };

  return (
    <PageContainer title={`${prsAction?.title ?? ''}`}>
      <HeaderWithBackButtonTemplate
        headlineText={(initialized && prsAction?.title) || <AppSkeleton width="200px" />}
        prsActionType={initialized && actionInProgress ? prsAction?.prsActionType : undefined}
        upperText={(initialized && `${prs?.fName} ${prs?.sName}`) || <AppSkeleton width="100px" />}
        onBackClick={() => navigate('/app/shien/prs/summary')}
      >
        <Stack sx={{ height: '100%' }}>
          <Divider />
          <Stack flexGrow={1}>
            <Container sx={{ my: 1, minHeight: 26 }}>
              {address != null ? (
                <Typography variant="body1" style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                  {address}
                </Typography>
              ) : (
                <AppSkeleton />
              )}
            </Container>
            <Box
              ref={ref}
              sx={{
                flexGrow: 1,
              }}
            >
              {typeof imgUrl !== 'undefined' ? (
                imgUrl === '' ? (
                  <Stack spacing={2}>
                    <Typography
                      textAlign="center"
                      sx={{ pt: 6, fontSize: '24px', color: theme.palette.uiStandard.gray40 }}
                    >
                      該当する避難経路図はありません。
                    </Typography>
                  </Stack>
                ) : (
                  <img src={imgUrl} style={{ width: '100%' }} />
                )
              ) : (
                <AppSkeleton height={`${width}px`} />
              )}
            </Box>
            <Stack sx={{ mt: 1, mb: 2 }}>
              <Container>
                <Stack spacing={1}>
                  {(initialized && <Typography variant="body1">{prsAction?.description}</Typography>) || (
                    <Stack spacing={1}>
                      <AppSkeleton />
                      <AppSkeleton />
                    </Stack>
                  )}
                  <Stack direction="row" spacing={2}>
                    {(initialized && (
                      <Button
                        variant={actionInProgress ? 'secondary' : 'primary'}
                        sx={Object.assign({ flex: 1 }, actionInProgress && { color: theme.palette.uiStandard.red50 })}
                        onClick={() => void handleAction()}
                      >
                        {prsAction?.actionLabel}
                      </Button>
                    )) || (
                      <Stack sx={{ flex: 1 }}>
                        <AppSkeleton variant="card" sx={{ flex: 1 }} />
                      </Stack>
                    )}
                    <Button variant="secondary" sx={{ flex: 1 }} onClick={() => handleRegisterSafety()}>
                      安否を登録
                    </Button>
                  </Stack>
                </Stack>
              </Container>
            </Stack>
          </Stack>
        </Stack>
      </HeaderWithBackButtonTemplate>
    </PageContainer>
  );
};
