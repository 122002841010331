import { Box, Container, Stack, Typography } from '@mui/material';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { Illust04 } from 'src/components/common/atoms/images/Illust04';

export const SystemErrorPage = () => {
  return (
    <PageContainer title="システムエラー">
      <Container sx={{ py: 5 }}>
        <Stack
          alignItems="center"
          spacing={4}
          sx={{ textShadow: '3px 3px 6px #ffffff,-3px 3px 6px #ffffff,3px -3px 6px #ffffff,-3px -3px 6px #ffffff' }}
        >
          <Typography variant="h3">ただいまご利用できません</Typography>
          <Typography variant="h5">
            現在システムが停止しているため、このサービスは利用できません。
            <br />
            少し時間をあけてから、もう一度お試しください。
          </Typography>
        </Stack>
      </Container>
      <Box sx={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: -1 }}>
        <Illust04 />
      </Box>
    </PageContainer>
  );
};
