export const IconListFull = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M6.75 7.5H5.25C4.83579 7.5 4.5 7.83579 4.5 8.25V9.75C4.5 10.1642 4.83579 10.5 5.25 10.5H6.75C7.16421 10.5 7.5 10.1642 7.5 9.75V8.25C7.5 7.83579 7.16421 7.5 6.75 7.5Z"
        fill="black"
      />
      <path
        d="M6.75 14.5H5.25C4.83579 14.5 4.5 14.8358 4.5 15.25V16.75C4.5 17.1642 4.83579 17.5 5.25 17.5H6.75C7.16421 17.5 7.5 17.1642 7.5 16.75V15.25C7.5 14.8358 7.16421 14.5 6.75 14.5Z"
        fill="black"
      />
      <path
        d="M6.75 21.5H5.25C4.83579 21.5 4.5 21.8358 4.5 22.25V23.75C4.5 24.1642 4.83579 24.5 5.25 24.5H6.75C7.16421 24.5 7.5 24.1642 7.5 23.75V22.25C7.5 21.8358 7.16421 21.5 6.75 21.5Z"
        fill="black"
      />
      <path
        d="M26.75 7.5H10.25C9.83579 7.5 9.5 7.83579 9.5 8.25V9.75C9.5 10.1642 9.83579 10.5 10.25 10.5H26.75C27.1642 10.5 27.5 10.1642 27.5 9.75V8.25C27.5 7.83579 27.1642 7.5 26.75 7.5Z"
        fill="black"
      />
      <path
        d="M26.75 14.5H10.25C9.83579 14.5 9.5 14.8358 9.5 15.25V16.75C9.5 17.1642 9.83579 17.5 10.25 17.5H26.75C27.1642 17.5 27.5 17.1642 27.5 16.75V15.25C27.5 14.8358 27.1642 14.5 26.75 14.5Z"
        fill="black"
      />
      <path
        d="M26.75 21.5H10.25C9.83579 21.5 9.5 21.8358 9.5 22.25V23.75C9.5 24.1642 9.83579 24.5 10.25 24.5H26.75C27.1642 24.5 27.5 24.1642 27.5 23.75V22.25C27.5 21.8358 27.1642 21.5 26.75 21.5Z"
        fill="black"
      />
    </svg>
  );
};
