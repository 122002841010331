import { v4 as uuidv4 } from 'uuid';

const sha256 = (plain: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
};

const generateCodeChallenge = (a: ArrayBuffer) => {
  let str = '';
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const generateCodeVerifier = (): string => {
  const CHARACTER_TYPE = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~';
  const CODE_VERIFIER_LEN = 43; // 43〜128文字の指定有

  return Array.from(Array(CODE_VERIFIER_LEN))
    .map(() => CHARACTER_TYPE[Math.floor(Math.random() * CHARACTER_TYPE.length)])
    .join('');
};

// codeVerifierとchallengeCodeの生成
export const generateParamOfPKCE = async () => {
  const codeVerifier = generateCodeVerifier();
  const hashCodeVerifier = await sha256(codeVerifier);
  const codeChallenge = generateCodeChallenge(hashCodeVerifier);

  return { codeVerifier, codeChallenge };
};

// LINE認証画面へのURLを生成する
export const generateLineAuthUrl = async (client_id: string, redirectUri: string) => {
  // LINE認証URLの作成
  const BASE_URL = 'https://access.line.me/oauth2/v2.1/authorize';
  const RESPONSE_TYPE = 'code';
  const SCOPE = 'openid';
  const state = uuidv4();
  const nonce = uuidv4();
  const { codeVerifier, codeChallenge } = await generateParamOfPKCE();
  const CODE_CHALLENGE_METHOD = 'S256';

  const url = `${BASE_URL}?response_type=${RESPONSE_TYPE}&client_id=${client_id}&redirect_uri=${redirectUri}&state=${state}&scope=${SCOPE}&nonce=${nonce}&code_challenge=${codeChallenge}&code_challenge_method=${CODE_CHALLENGE_METHOD}`;
  return { url, state, nonce, codeVerifier };
};
