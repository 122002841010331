import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routerPaths } from 'src/routes/path';

export const useNavigateSystemError = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateSystemError = useCallback(() => {
    if (location.pathname.includes('/app/kanri')) {
      navigate(routerPaths.app.kanri.error.systemError);
    } else if (location.pathname.includes('/app/shien')) {
      navigate(routerPaths.app.shien.error.systemError);
    } else {
      navigate(routerPaths.error.systemError);
    }
  }, [location]);

  return navigateSystemError;
};
