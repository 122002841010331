import { Provider } from 'jotai';
import { FC, ReactNode } from 'react';
import { globalDataStore } from 'src/jotai/atom';

type ComponentProps = {
  children: ReactNode;
};

export const AuthAreaDataStore: FC<ComponentProps> = ({ children }) => {
  return <Provider store={globalDataStore}>{children}</Provider>;
};
