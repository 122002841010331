import { Skeleton, SkeletonProps, styled } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Override } from 'src/types/common/Override';

type StyledSkeletonVariant = 'card' | 'rounded' | 'chip';
type StyledSkeletonProps = Override<SkeletonProps, { myVariant?: StyledSkeletonVariant }>;
const StyledSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'myVariant',
})<StyledSkeletonProps>(({ myVariant }) => ({
  ...(myVariant === 'card' && {
    borderRadius: '10px',
  }),
  ...(myVariant === 'chip' && {
    borderRadius: '9999px',
  }),
}));

type ComponentProps = Override<
  SkeletonProps,
  { variant?: StyledSkeletonVariant; animation?: never; show?: boolean; children?: ReactNode }
>;

export const AppSkeleton: FC<ComponentProps> = ({ variant = 'rounded', show = true, children, ...rest }) => {
  return show ? (
    <StyledSkeleton variant="rounded" myVariant={variant} animation="wave" {...rest}>
      {children}
    </StyledSkeleton>
  ) : (
    <>{children}</>
  );
};
