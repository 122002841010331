import {
  Box,
  Button,
  CircularProgress,
  colors,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { SearchConditionField } from 'src/components/common/SearchConditionField';
import { SearchConditions } from 'src/components/common/types/SupportersSearchConditions';
import { SearchConditionFieldForSoshikiNm } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/SearchConditionField/SoshikiSearchCondition';
import { SoshikisList } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/SoshikisList';
import { SupportersList } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/SupportersList';
import { AssignPageInfo } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/types';
import { TabPanel } from 'src/components/kanri/pages/jichitai/CreateAccountPage/TabPanel';
import {
  PreFetchedData,
  SupporterDetailsDialog,
} from 'src/components/kanri/pages/jichitai/SearchSupportersPage/components/SupporterDetailsDialog';
import { TransitionUp } from 'src/components/shien/atoms/TransitionUp';
import { SnackbarMessageId, snackbarMessages } from 'src/constants/snackbarMessages';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { standardColors } from 'src/themes/standardColors';
import { Soshiki } from 'src/types/common/Soshiki';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';
import { patchShienkankei } from 'src/utilities/restApi/kanri/assignment/supportAssignment';
import { ShienkankeiList } from 'src/utilities/restApi/kanri/types/searchSupporters';
import { RequestPatchSupportAssignment } from 'src/utilities/restApi/kanri/types/supportAssignment';
import { BackFlgType } from './types';

// ダイアログの型定義
type AssignSupportersSoshikiDialog = {
  pageInfoCallAssign?: AssignPageInfo; // 複数要支援の場合はカンマで区切り
  isOpen: boolean;
  onClose: (backFlg: string) => void;
};

export const AssignSupportersSoshikiDialog = ({
  pageInfoCallAssign,
  isOpen,
  onClose: callOnClose,
}: AssignSupportersSoshikiDialog) => {
  const theme = useTheme();
  const breakPointsSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const navigateSystemError = useNavigateSystemError();

  // States
  const [detailsShownSpId, setDetailsShownSpId] = useState<string>();
  const [detailsShownPreFetchedData, setDetailsShownPreFetchedData] = useState<PreFetchedData>();
  const [supporterModalOpen, setSupporterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<SearchCallerType | undefined>('system');
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel | undefined>();
  const [shienkankeiList, setShienkankeiList] = useState<ShienkankeiList>([])

  // search state
  const [searchConditions, setSearchConditions] = useState<SearchConditions>({
    simple: {
      spCheckId: '',
      soshikiList: [],
    },
    advanced: undefined,
  });
  const [selectedSoshikiList, setSelectedSoshikiList] = useState<Soshiki[]>();
  const [searchBtnClickFlg, setSearchBtnClickFlg] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonContent, setButtonContent] = useState<string | JSX.Element>();
  const StyledCircularProgress = styled(CircularProgress)(() => ({
    '.MuiCircularProgress-circle': {
      color: standardColors.white,
    },
  }));

  const {userData} = useUserData()

  // tab
  const [value, setValue] = useState(0);
  const tabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchBtnClickFlg(false);
  };

  // ダイアログが開かれた時にタブをリセットして0に設定
  useEffect(() => {
    if (isOpen) {
      setValue(0);
    }
  }, [isOpen]);

  // Event handelers
  const supporterDetailsClick = (spId: string, preFetchedData: Readonly<PreFetchedData>) => {
    setDetailsShownSpId(spId);
    setDetailsShownPreFetchedData(preFetchedData);
    setSupporterOpen(true);
  };

  const supportDialogClose = () => {
    setDetailsShownSpId(undefined);
    setSupporterOpen(false);
  };

  const onSearchError = (searchError: SearchError) => {
    if (searchError.isError) {
      if (![axios.AxiosError.ERR_CANCELED, axios.AxiosError.ECONNABORTED].includes(searchError.reason ?? '')) {
        navigateSystemError();
      } else if (searchError.reason === axios.AxiosError.ECONNABORTED) {
        const snackbarMessage = snackbarMessages[SnackbarMessageId.SearchTimeOut];

        if (snackbarMessage) {
          enqueueSnackbar(snackbarMessage.message, {
            variant: snackbarMessage.variant,
          });
        }
      }
    }
  };

  // ボタンの表示設定
  useEffect(() => {
    if (!loading) {
      setButtonContent('割り当てる');
    } else {
      setButtonContent(<StyledCircularProgress size={14.5} />); // ボタンローリング表示
    }
  }, [loading]);

  // 割り当て処理
  const handleAssign = async (backFlg: BackFlgType) => {
    const requestBody: RequestPatchSupportAssignment = { shienkankei_list: [] };
    pageInfoCallAssign?.prsIds.forEach((prsId) => {
      selectedRows?.map((id) => {
        requestBody.shienkankei_list.push({
          prsid: prsId,
          spid: value === 0 ? id.toString() : null,
          soshikiid: value === 1 ? id.toString() : null,
        });
      });
      if(value===0){
        shienkankeiList.forEach(sk => {
          sk.supporters.forEach(sp=>{
            if(userData!.soshikiid && !sp.soshikiid_list.includes(userData!.soshikiid)){
            requestBody.shienkankei_list.push({
              prsid: sk.prsid,
              spid: sp.spid,
              soshikiid:  null,
            });
          }
        })
      })
    }
    });
    try {
      if (selectedRows?.length !== 0) {
        setButtonContent(<StyledCircularProgress size={14.5} />);
        setLoading(true);
        await patchShienkankei(requestBody);
      }
    } catch {
      navigateSystemError();
    } finally {
      onClose(backFlg);
      setLoading(false);
    }
  };

  const onClose = (backFlg: BackFlgType) => {
    callOnClose(backFlg);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose('back')}
      maxWidth={'xl'}
      PaperProps={{
        sx: {
          minHeight: 300, // 最小高さを300pxに設定
          maxHeight: { xs: 'auto', sm: 'calc(100svh - 64px)' }, // 最大高さを画面高さに制限
          height: '100%',
        },
      }}
      TransitionComponent={TransitionUp}
      fullScreen={breakPointsSmDown}
    >
      <PageContainer title="別の支援者の割当て">
        <DialogTitle variant="h2" component="h1">
          別の支援者の割当て
        </DialogTitle>
        <DialogContent>
          <Box>
            <Paper variant="outlined" sx={{ p: 1, backgroundColor: colors.grey[100] }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center">
                <Typography sx={{ whiteSpace: 'nowrap' }}>要支援者（対象者）:</Typography>
                <Typography fontWeight="bold" sx={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{pageInfoCallAssign?.prsNames}</Typography>
              </Stack>
            </Paper>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={tabChange}>
              <Tab label="支援者" />
              <Tab label="支援組織" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Stack spacing={2} sx={{ overflow: 'auto', height: '100%' }}>
              <SearchConditionField
                searchButtonOnClick={(searchConditions) => {
                  setSearchConditions(searchConditions);
                }}
                isLoading={isLoading}
              />
              <SupportersList
                onDetailsClick={(spId, preFetchedData) => {
                  supporterDetailsClick(spId, preFetchedData);
                }}
                searchConditions={searchConditions}
                onSearchError={onSearchError}
                onChangeLoadingStatus={(isLoading) => {
                  setIsLoading(isLoading);
                }}
                onChangeSelectedRows={(selectedRows) => {
                  setSelectedRows(selectedRows);
                }}
                oldAssignInfo={pageInfoCallAssign}
                setShienkankeiList={setShienkankeiList}
              />
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Stack spacing={2} sx={{ overflow: 'auto', height: '100%' }}>
              <SearchConditionFieldForSoshikiNm
                isLoading={isLoading}
                onSearchButtonClick={(searchBtnClickFlg, selectedSoshikiList) => {
                  setSearchBtnClickFlg(searchBtnClickFlg);
                  setSelectedSoshikiList(selectedSoshikiList);
                }}
              />
              <SoshikisList
                searchBtnClickFlg={searchBtnClickFlg}
                selectedSoshikiList={selectedSoshikiList}
                onSearchError={onSearchError}
                onChangeLoadingStatus={(isLoading) => {
                  setIsLoading(isLoading);
                }}
                onChangeSelectedRows={(selectedRows) => {
                  setSelectedRows(selectedRows);
                }}
                oldAssignIds={pageInfoCallAssign?.soshikiIds}
              />
            </Stack>
          </TabPanel>
        </DialogContent>
        <Stack
          direction="row"
          spacing={1.5}
          sx={{ display: 'flex', justifyContent: 'flex-end', m: '5px', pr: 2, pb: 2 }}
        >
          <Box>
            <Button onClick={() => onClose('back')}>戻る</Button>
          </Box>
          <Box>
            <Button
              disabled={selectedRows?.length === 0 || !userData}
              variant="contained"
              sx={{ pointerEvents: loading ? 'none' : 'auto', width: '105px', height: '36px' }}
              onClick={() => handleAssign('finish')}
            >
              {buttonContent}
            </Button>
          </Box>
        </Stack>
        <SupporterDetailsDialog
          open={supporterModalOpen}
          onClose={() => supportDialogClose()}
          spId={detailsShownSpId}
          preFetched={detailsShownPreFetchedData}
        />
      </PageContainer>
    </Dialog>
  );
};
