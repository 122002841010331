import { Chip, Stack, useTheme } from '@mui/material';
import { FC } from 'react';
import { standardColors } from 'src/themes/standardColors';

type ComponentProps = {
  label: string;
};

export const AppChip: FC<ComponentProps> = ({ label }) => {
  const theme = useTheme();

  return (
    <Stack>
      <Chip
        label={label}
        size="small"
        sx={{
          borderRadius: '2px',
          backgroundColor: standardColors.green50,
          fontSize: '14px',
          lineHeight: '14px',
          color: standardColors.white,
          fontFamily: theme.typography.fontFamily,
        }}
      />
    </Stack>
  );
};
