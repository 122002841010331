import { PrsActionType } from 'src/types/common/PrsActionType';

export const routerPaths = {
  index: '/',
  'azure-signin': '/azure-signin',
  error: {
    systemError: '/error/SystemError',
    notFound: '/error/404',
  },
  app: {
    kanri: {
      searchSupportRecipients: '/app/kanri/search-support-recipients',
      supporters: '/app/kanri/supporters',
      selectSupportRecipients: '/app/kanri/select-support-recipients',
      safetyConfirmation: '/app/kanri/safety-confirmation',
      settings: {
        displayNonConsentingUsers: '/app/kanri/settings/display-non-consenting-users',
        statusSetting: '/app/kanri/settings/statusSetting',
        lineIntegration: '/app/kanri/settings/lineIntegration',
        createAccount: '/app/kanri/settings/create-account',
      },
      error: {
        systemError: '/app/kanri/error/SystemError',
      },
      systemStopInformationPage: '/app/kanri/system-stop-information',
    },
    shien: {
      prs: {
        summary: '/app/shien/prs/summary',
        detail: '/app/shien/prs/detail',
        action: (prsActionType: PrsActionType) => `/app/shien/prs/action/${prsActionType}`,
        safetySummary: '/app/shien/prs/safety-summary',
        registerSafety: '/app/shien/prs/register-safety',
        registerShelterType: '/app/shien/prs/register-shelter-type',
        selectShelter: '/app/shien/prs/select-shelter',
        completeRegistration: '/app/shien/prs/complete-registration',
      },
      prsList: '/app/shien/prs-list',
      spEnsuringSafety: '/app/shien/sp-ensuring-safety',
      possibility: {
        question: '/app/shien/possibility/question',
        answer: {
          possible: '/app/shien/possibility/answer/possible',
          notPossible: '/app/shien/possibility/answer/not-possible',
        },
      },
      profile: '/app/shien/profile',
      error: {
        systemError: '/app/shien/error/SystemError',
      },
      systemStopInformationPage: '/app/shien/system-stop-information',
    },
  },
};
