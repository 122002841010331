import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { DisasterType } from 'src/types/common/DisasterType';
import { JianInfo } from 'src/types/common/JianInfo';
import { getDisasterTypes } from 'src/utilities/restApi/kanri/disasterTypes';

type ComponentProps = {
  jianInfo?: JianInfo | null;
  onChange: (disasterTypeNumber: number) => void;
  disabled: boolean;
};
export const DisasterTypesSelect = ({ jianInfo, onChange, disabled }: ComponentProps) => {
  const [disasterTypes, setDisasterTypes] = useState<DisasterType[]>();
  const [selectedDisasterType, setSelectedDisasterType] = useState<string>('');

  const _onChange = (event: SelectChangeEvent<string>) => {
    setSelectedDisasterType(event.target.value);
    onChange(parseInt(event.target.value));
  };

  // useEffect
  useEffect(() => {
    if (jianInfo !== undefined) {
      getDisasterTypes().then((response) => {
        if (response.data) {
          const newData = response.data.disaster_types.map((x) => {
            return {
              disasterTypeNumber: x.disaster_type_number,
              disasterTypeName: x.disaster_type_name,
            } as DisasterType;
          });
          setDisasterTypes(newData);
          if (jianInfo) {
            setSelectedDisasterType(jianInfo.disasterTypeNumber.toString());
            onChange(jianInfo.disasterTypeNumber);
          } else {
            setSelectedDisasterType(newData[0].disasterTypeNumber.toString());
            onChange(newData[0].disasterTypeNumber);
          }
        }
      });
    }
  }, [jianInfo]);

  return (
    <AppSkeleton show={!disasterTypes} sx={{ minWidth: { xs: '100%', lg: 300 } }}>
      <Select
        value={selectedDisasterType}
        size="small"
        sx={{ minWidth: { xs: '100%', lg: 300 } }}
        onChange={_onChange}
        disabled={disabled}
      >
        {disasterTypes?.map((x) => (
          <MenuItem key={`disasterType-${x.disasterTypeNumber}`} value={x.disasterTypeNumber}>
            {x.disasterTypeName}
          </MenuItem>
        ))}
      </Select>
    </AppSkeleton>
  );
};
