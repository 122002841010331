import { AxiosError, AxiosRequestConfig } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestStartJian, ResponseStartJian } from 'src/utilities/restApi/kanri/types/startJian';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

export const startJian = async (data: RequestStartJian) => {
  const headers = { headers: await getCommonHeaders() };
  const config: AxiosRequestConfig = { ...headers };

  return new Promise<SuccessResponse<ResponseStartJian>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponseStartJian>>('/start-jian', data, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        return reject(error.response?.data);
      });
  });
};
