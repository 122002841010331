import { FC } from 'react';
import { Reason, TextEditDialog } from 'src/components/shien/atoms/dialogs/TextEditDialog';
import { Prs2 } from 'src/types/common/Prs2';

const CUSTOM_SHELTER_NAME_MAX_LENGTH = 10;

type ComponentProps = {
  prs: Prs2 | undefined;
  dialogOpen: boolean;
  onClose: ({ reason, text }: { reason: Reason; text?: string }) => void;
};

// その他避難先名登録ダイアログ
export const CustomShelterNameEditDialog: FC<ComponentProps> = ({ prs, dialogOpen, onClose }) => {
  return (
    <TextEditDialog
      pageTitle="その他"
      headlineText="その他"
      upperText={`${prs?.fName} ${prs?.sName}`}
      description={<>{CUSTOM_SHELTER_NAME_MAX_LENGTH}文字以内で入力してください。</>}
      text={prs?.customShelterName}
      multiline={false}
      max={CUSTOM_SHELTER_NAME_MAX_LENGTH}
      isRequired={true}
      open={dialogOpen}
      onClose={onClose}
    />
  );
};
