import { standardColors } from 'src/themes/standardColors';

export const primaryButtonStyle = {
  paddingTop: '22px',
  paddingBottom: '22px',
  lineHeight: 1,
  borderRadius: '10px',
  backgroundColor: standardColors.green40,
  boxShadow: `0px 4px 0px 0px ${standardColors.green60}`,
  marginBottom: '4px',
  color: standardColors.white,
  fontSize: '20px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: standardColors.green40,
    boxShadow: `0px 4px 0px 0px ${standardColors.green60}`,
  },
  '&:active': {
    backgroundColor: standardColors.green60,
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: 0.3,
    boxShadow: `0px 4px 0px 0px ${standardColors.green60}`,
  },
};

export const secondaryButtonStyle = {
  paddingTop: '22px',
  paddingBottom: '22px',
  lineHeight: 1,
  borderRadius: '10px',
  backgroundColor: standardColors.white,
  border: `1px solid ${standardColors.gray25}`,
  boxShadow: `0px 4px 0px 0px ${standardColors.gray25}`,
  marginBottom: '4px',
  color: standardColors.black,
  fontSize: '20px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: standardColors.white,
    boxShadow: `0px 4px 0px 0px ${standardColors.gray25}`,
  },
  '&:active': {
    backgroundColor: standardColors.gray25,
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: 0.3,
    boxShadow: `0px 4px 0px 0px ${standardColors.gray25}`,
  },
};
