import BlindIcon from '@mui/icons-material/Blind';
import PeopleIcon from '@mui/icons-material/People';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { List } from '@mui/material';
import { FC, useMemo } from 'react';
import { useUserData } from 'src/hooks/common/useUserData';
import { JichitaiMenu, NestedMenuItem } from 'src/routes/kanri/jichitai/JichitaiMenu/JichitaiMenu';
import { routerPaths } from 'src/routes/path';
import { hasPermission } from 'src/utilities/appUtil/commonUtil';

type ComponentProps = {
  menuOnClick: () => void;
};

export const JichitaiMenuList: FC<ComponentProps> = ({ menuOnClick }) => {
  const { userData } = useUserData();

  const menuItems = useMemo<NestedMenuItem[]>(() => {
    return userData
      ? [
          ...(hasPermission(routerPaths.app.kanri.searchSupportRecipients, userData.roleId)
            ? [{ label: '要支援者一覧', path: routerPaths.app.kanri.searchSupportRecipients, icon: <BlindIcon /> }]
            : []),
          ...(hasPermission(routerPaths.app.kanri.supporters, userData.roleId)
            ? [{ label: '支援者一覧', path: routerPaths.app.kanri.supporters, icon: <PeopleIcon /> }]
            : []),
          ...(hasPermission(routerPaths.app.kanri.safetyConfirmation, userData.roleId)
            ? [{ label: '安否確認操作', path: routerPaths.app.kanri.safetyConfirmation, icon: <TouchAppIcon /> }]
            : []),
          {
            label: '設定',
            icon: <SettingsOutlinedIcon />,
            subMenuItems: [
              ...(hasPermission(routerPaths.app.kanri.settings.createAccount, userData.roleId)
                ? [{ label: 'アカウント発行', path: routerPaths.app.kanri.settings.createAccount }]
                : []),
              ...(hasPermission(routerPaths.app.kanri.settings.statusSetting, userData.roleId)
                ? [{ label: '安否状況設定', path: routerPaths.app.kanri.settings.statusSetting }]
                : []),
              ...(hasPermission(routerPaths.app.kanri.settings.displayNonConsentingUsers, userData.roleId)
                ? [{ label: '未同意者表示設定', path: routerPaths.app.kanri.settings.displayNonConsentingUsers }]
                : []),
              ...(hasPermission(routerPaths.app.kanri.settings.lineIntegration, userData.roleId)
                ? [{ label: 'LINE連携', path: routerPaths.app.kanri.settings.lineIntegration }]
                : []),
            ],
          },
        ]
      : [];
  }, [userData]);

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
      {menuItems.map((x, index) => (
        <JichitaiMenu key={`JichitaiMenuList-${index}`} nestedMenuItem={x} menuOnClick={menuOnClick} />
      ))}
    </List>
  );
};
