import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { ReactNode } from 'react';

export type FailedUserGridRow = {
  id: string;
  name: string;
  mailAddress: string;
};

type ComponentProps = {
  title?: string;
  closeButtonLabel?: string;
  processingMessage?: ReactNode;
  completeMessage?: ReactNode;
  open: boolean;
  processing: boolean;
  onClose: () => void;
  successCount: number;
  failedCount: number;
  failedUserRows: GridRowsProp<FailedUserGridRow>;
  excludedCount: number;
};

export const ProcessResultDialog = ({
  title,
  closeButtonLabel,
  processingMessage,
  completeMessage,
  open,
  processing,
  successCount,
  failedCount,
  failedUserRows,
  excludedCount,
  onClose,
}: ComponentProps) => {
  const cols: GridColDef<FailedUserGridRow>[] = [
    {
      field: 'name',
      headerName: '氏名',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'mailAddress',
      headerName: 'メールアドレス',
      width: 160,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!processing) {
          onClose();
        }
      }}
    >
      {processing ? (
        <></>
      ) : (
        <DialogTitle variant="h4">
          <Stack direction="row" alignItems="center" spacing={1}>
            <InfoIcon sx={{ color: colors.grey['700'] }} />
            <Box>{title ?? '完了'}</Box>
          </Stack>
        </DialogTitle>
      )}

      {processing ? (
        <>
          {processingMessage && (
            <DialogContent>
              <Box sx={{ pt: 3 }}>
                <DialogContentText variant="h4" sx={{ px: { xs: 'none', sm: 3 } }}>
                  {processingMessage}
                </DialogContentText>
                <Stack alignItems="center" justifyContent="center" sx={{ pt: 3 }}>
                  <CircularProgress />
                </Stack>
              </Box>
            </DialogContent>
          )}
        </>
      ) : (
        <>
          {completeMessage && (
            <DialogContent>
              <DialogContentText variant="h4" sx={{ px: { xs: 'none', sm: 3 } }}>
                <Stack
                  direction="column"
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ mb: 3 }}>{completeMessage}</Box>
                  <Box>成功：{successCount}件</Box>
                  <Box>失敗：{failedCount}件</Box>
                  <Box>対象外：{excludedCount}件</Box>
                </Stack>
              </DialogContentText>
              {failedUserRows.length > 0 && (
                <Stack sx={{ pt: 3, height: '200px', width: '100%' }}>
                  <Typography>失敗したユーザー</Typography>
                  <DataGrid
                    columns={cols}
                    rows={failedUserRows}
                    hideFooterPagination={true}
                    hideFooter={true}
                    density="compact"
                  />
                </Stack>
              )}
            </DialogContent>
          )}
        </>
      )}
      <DialogActions>
        {processing ? (
          <></>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              if (!processing) {
                onClose();
              }
            }}
          >
            {closeButtonLabel ?? '閉じる'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
