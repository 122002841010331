import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  colors,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type NestedMenuItem = {
  label: string;
  path?: string;
  icon?: ReactNode;
  disabled?: boolean;
  subMenuItems?: {
    label: string;
    path: string;
    disabled?: boolean;
  }[];
};

type ComponentProps = {
  nestedMenuItem: NestedMenuItem;
  menuOnClick: () => void;
};

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<ListItemButtonProps & { selected: boolean }>(({ theme, selected }) => ({
  color: colors.grey[700],
  borderRadius: theme.shape.borderRadius,
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  }),

  '&:hover': {
    ...(selected
      ? {
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
        }
      : {
          outline: `1px solid ${theme.palette.primary.main}`,
          color: colors.grey[700],
        }),
  },
}));

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<ListItemButtonProps & { selected: boolean }>(({ theme }) => ({
  color: 'inherit',
  minWidth: '0px',
  marginRight: theme.spacing(1),
}));

export const JichitaiMenu: FC<ComponentProps> = ({ nestedMenuItem, menuOnClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const parentHandleClick = () => {
    if (nestedMenuItem.subMenuItems) {
      setOpen(!open);
    } else {
      navigatePath(nestedMenuItem.path!);
    }
  };

  const subMenuHandleClick = (path: string) => {
    navigatePath(path);
  };

  const navigatePath = (path: string) => {
    menuOnClick();
    navigate(path);
  };

  return (
    <>
      <StyledListItemButton
        onClick={parentHandleClick}
        selected={nestedMenuItem.path === location.pathname}
        disabled={nestedMenuItem.disabled}
      >
        {nestedMenuItem.icon && (
          <StyledListItemIcon selected={nestedMenuItem.path === location.pathname}>
            {nestedMenuItem.icon}
          </StyledListItemIcon>
        )}
        <ListItemText primary={nestedMenuItem.label} />
        {nestedMenuItem.subMenuItems ? open ? <ExpandLess /> : <ExpandMore /> : <></>}
      </StyledListItemButton>
      {nestedMenuItem.subMenuItems?.map((x, index) => (
        <Collapse key={`JichitaiMenu-${nestedMenuItem.label}-${index}`} in={open} timeout="auto" unmountOnExit>
          <List component="div" sx={{ pl: 4 }} disablePadding>
            <StyledListItemButton
              onClick={() => subMenuHandleClick(x.path)}
              selected={x.path === location.pathname}
              disabled={x.disabled}
            >
              <ListItemText primary={x.label} />
            </StyledListItemButton>
          </List>
        </Collapse>
      ))}
    </>
  );
};
