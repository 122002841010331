import { useState } from 'react';
import { sendLineMessage } from 'src/utilities/restApi/kanri/sendLineMessage';

export const useSendLine = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const send = async (templateid: string) => {
    let isSuccess = true;

    setIsLoading(true);
    try {
      await sendLineMessage({
        templateid,
      });
    } catch (e) {
      setIsError(true);
      isSuccess = false;
    }
    setIsLoading(false);
    return isSuccess;
  };

  return { isLoading, isError, sendLine: send };
};
