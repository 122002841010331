import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, IconButton, Link, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { AppList } from 'src/components/kanri/atoms/AppList';
import { ConfirmDialog } from 'src/components/kanri/atoms/ConfirmDialog';
import { usePrsStatusInfoSectionDataList } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/SupportRecipientDialog/usePrsStatusInfoSectionDataList';
import { TransitionUp } from 'src/components/shien/atoms/TransitionUp';
import { useOtherSupportersSectionDataList } from 'src/hooks/common/useOtherSupportersSectionDataList';
import { usePlannedShelterSectionDataList } from 'src/hooks/common/usePlannedShelterSectionDataList';
import { usePrs } from 'src/hooks/common/usePrs';
import { usePrsSectionDataList } from 'src/hooks/common/usePrsSectionDataList';
import { useSoshikiLeadersSectionDataList } from 'src/hooks/common/useSoshikiLeadersSectionDataList';
import { useUserData } from 'src/hooks/common/useUserData';
import { Prs2 } from 'src/types/common/Prs2';

type ComponentProps = {
  prsId?: string;
  open: boolean;
  onClose: () => void;
  onError: () => void;
};

export const SupportRecipientDialog = ({ prsId, open, onClose, onError }: ComponentProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { userData } = useUserData();

  const { data: prs, refreshData } = usePrs({
    initSearch: false,
  });

  const [displayPrsData, setDisplayPrsData] = useState<Prs2>();
  const [confirmCallDialogOpen, setConfirmCallDialogOpen] = useState(false);
  const [confirmCallDialogMessage, setConfirmCallDialogMessage] = useState<ReactNode>('');
  const [callPhoneNumber, setCallPhoneNumber] = useState<string>();

  const { sectionDataList: prsSectionDataList, ...prsSectionDataListFunctions } = usePrsSectionDataList(
    (telNo, message) => {
      setCallPhoneNumber(telNo);
      setConfirmCallDialogMessage(message);
      setConfirmCallDialogOpen(true);
    },
    displayPrsData
  );
  const { sectionDataList: plannedShelterSectionDataList, ...plannedShelterSectionDataListFunstions } =
    usePlannedShelterSectionDataList();
  const { sectionDataList: otherSupportersSectionDataList, ...otherSupportersSectionDataListFunctions } =
    useOtherSupportersSectionDataList();
  const { sectionDataList: shienSoshikiLeadersSectionDataList, ...shienSoshikiLeadersDataListFunctions } =
    useSoshikiLeadersSectionDataList();
  const { sectionDataList: prsStatusInfoSectionDataList, ...prsStatusInfoSectionDataListFunctions } =
    usePrsStatusInfoSectionDataList();

  useEffect(() => {
    if (prsId && open) {
      refreshData({ prsid: prsId }).catch(() => {
        onError();
      });
    }
  }, [prsId, open]);

  useEffect(() => {
    if (userData && prs) {
      setDisplayPrsData(prs);
      plannedShelterSectionDataListFunstions.getSectionDataList(prs.plannedShelters);

      const spIds: string[] = prs.shienKankei
        .filter((x) => x.spId && userData.userId !== x.spId)
        .map((x) => x.spId as string);

      otherSupportersSectionDataListFunctions
        .getSectionDataList(spIds, (telNo, message) => {
          setCallPhoneNumber(telNo);
          setConfirmCallDialogMessage(message);
          setConfirmCallDialogOpen(true);
        })
        .catch(() => onError());

      const soshikiIds: string[] = prs.shienKankei.filter((x) => x.soshikiId).map((x) => x.soshikiId as string);
      shienSoshikiLeadersDataListFunctions
        .getSectionDataList(soshikiIds, (telNo, message) => {
          setCallPhoneNumber(telNo);
          setConfirmCallDialogMessage(message);
          setConfirmCallDialogOpen(true);
        })
        .catch(() => onError());

      prsStatusInfoSectionDataListFunctions.getSectionDataList(prs).catch(() => onError());
    }
  }, [userData, prs]);

  const _onClose = () => {
    onClose();
    setDisplayPrsData(undefined);
    prsSectionDataListFunctions.clearSectionDataList();
    plannedShelterSectionDataListFunstions.clearSectionDataList();
    otherSupportersSectionDataListFunctions.clearSectionDataList();
    shienSoshikiLeadersDataListFunctions.clearSectionDataList();
    prsStatusInfoSectionDataListFunctions.clearSectionDataList();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={_onClose}
        fullWidth
        keepMounted={false}
        TransitionComponent={TransitionUp}
        fullScreen={fullScreen}
      >
        <DialogTitle variant="h4">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box>要支援者詳細</Box>
            <StackSpacer />
            <IconButton onClick={_onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <AppList
          sectionDataList={[
            ...prsSectionDataList,
            ...plannedShelterSectionDataList,
            ...otherSupportersSectionDataList,
            ...shienSoshikiLeadersSectionDataList,
            ...prsStatusInfoSectionDataList,
          ]}
        />
      </Dialog>
      <ConfirmDialog
        title="電話をかける"
        message={
          <>
            {confirmCallDialogMessage}
            <br />
            {callPhoneNumber}
          </>
        }
        positiveButtonProps={Object.assign(
          { children: 'かける' },
          callPhoneNumber && { component: Link, href: `tel:${callPhoneNumber}` }
        )}
        negativeButtonProps={{ children: 'かけない' }}
        open={confirmCallDialogOpen}
        onClose={() => {
          setConfirmCallDialogOpen(false);
          setCallPhoneNumber(undefined);
        }}
        onConfirm={() => {
          setConfirmCallDialogOpen(false);
          setCallPhoneNumber(undefined);
        }}
      />
    </>
  );
};
