import { useAtom } from 'jotai';
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { selectedPrsIdAtom } from 'src/components/shien/pages/prs/dataStore';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { usePrs } from 'src/hooks/common/usePrs';
import { Prs2 } from 'src/types/common/Prs2';

export const PrsContext = createContext<{
  prs: Prs2 | undefined;
  setPrs: Dispatch<SetStateAction<Prs2 | undefined>>;
  refreshData: () => Promise<Prs2>;
  initialized: boolean;
}>({
  prs: undefined,
  setPrs: () => {},
  refreshData: async () => ({} as Prs2),
  initialized: false,
});

export const PrsContextTemplate = () => {
  const [prs, setPrs] = useState<Prs2>();
  const [selectedPrsId] = useAtom(selectedPrsIdAtom);
  const { data, refreshData, initialized } = usePrs({
    initSearch: false,
  });
  const navigateSystemError = useNavigateSystemError();

  useEffect(() => {
    if (data) {
      setPrs(data);
    } else {
      if (selectedPrsId) {
        refreshData({ prsid: selectedPrsId }).catch(() => {
          navigateSystemError();
        });
      }
    }
  }, [data, selectedPrsId]);

  const _refreshData = () => {
    return new Promise<Prs2>((resolve, reject) => {
      if (selectedPrsId) {
        refreshData({ prsid: selectedPrsId })
          .then((res) => {
            setPrs(res);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject();
      }
    });
  };

  return (
    <PrsContext.Provider value={{ prs, setPrs, refreshData: _refreshData, initialized }}>
      <Outlet />
    </PrsContext.Provider>
  );
};
