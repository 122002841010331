import { useEffect, useState } from 'react';
import { PrsForPrsList } from 'src/components/shien/pages/PrsListPage/types';
import { useUserData } from 'src/hooks/common/useUserData';
import { determinePrsActionType } from 'src/utilities/appUtil/shienUtil';
import { getResponsePrsList } from 'src/utilities/restApi/shien/prsList';

type HookParams = {
  initSearch?: boolean;
};

/**
 * 支援者と支援者が属する団体が担当する用支援者の一覧を取得し、一定間隔で更新する
 */
export const usePrsList = ({ initSearch = true }: HookParams) => {
  // state
  const [prsList, setPrsList] = useState<PrsForPrsList[]>([]); // フェッチした要支援者一覧(絞込前)
  const [initialized, setInitialized] = useState(false); // 初回の処理が完了したか
  const [isLoading, setIsLoading] = useState(false); // (初回に限らず)フェッチが完了したか
  const [isError, setIsError] = useState(false); // フェッチでエラーが発生したか

  // global state
  const { userData } = useUserData();

  // fetch
  const refreshData = async (): Promise<void> => {
    if (userData) {
      setIsLoading(true);
      try {
        // 支援者の属する団体、要支援者一覧を取得
        const res = await getResponsePrsList({ spid: userData.userId });

        // 要支援者一覧に必要なプロパティのみ抽出
        const filteredPrsList: PrsForPrsList[] = res.data!.prslist.map((prs) => ({
          prsId: prs.prsId,
          prsName: `${prs.fName} ${prs.sName}`,
          kPrsName: `${prs.kfName} ${prs.ksName}`,
          shelterName: prs.shelterName ?? '',
          shelterTypeCode: prs.shelterTypeCode ?? null,
          customShelterName: prs.customShelterName ?? '',
          address: prs.address ?? '',
          agreement: prs.agreement ?? false,
          prsStatusId: prs.prsStatusId,
          prsStatusName: prs.prsStatusName,
          prsStatusColor: prs.prsStatusColor,
          sortOrder: prs.prsStatusSortOrder,
          prsMemo: prs.prsMemo ?? '',
          share: prs.share,
          prsActionType: determinePrsActionType(
            prs.lastPrsStatusUpdatedAt ?? undefined,
            prs.lastVisitedAt ?? undefined,
            prs.lastEvacuatedAt ?? undefined
          ),
        }));

        setPrsList(filteredPrsList);
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    } else {
      // userDataが存在しない場合何もしない
    }
    return;
  };

  // first fetch
  useEffect(() => {
    if (userData && initSearch && !initialized) {
      setInitialized(false);
      refreshData().then(() => setInitialized(true));
    }
  }, [userData]);

  return { prsList, isLoading, isError, initialized, refreshData };
};
