import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type ComponentProps = CircularProgressProps & {
  children: ReactNode;
  loading: boolean;
  current?: number;
  all?: number;
};

const DETERMINATE_COUNT = 10;

export const ComponentLoadingIndicator: FC<ComponentProps> = ({
  children,
  loading,
  size = 24,
  variant,
  current = 0,
  all = 0,
}) => {
  const progress = (current / all) * 100;
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {children}
      {loading && (
        <>
          <CircularProgress
            size={size}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
            color="primary"
            variant={all >= DETERMINATE_COUNT ? variant : undefined}
            value={all >= DETERMINATE_COUNT ? progress : undefined}
          />
          {variant === 'determinate' && all >= DETERMINATE_COUNT && (
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="caption"
                component="div"
                sx={{ color: 'text.secondary', fontSize: '8px' }}
              >{`${Math.round(progress)}%`}</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
