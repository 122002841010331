import { PrsForPrsList } from 'src/components/shien/pages/PrsListPage/types';
import { PrsActionType } from 'src/types/common/PrsActionType';

export type PrsOrderKind = 'status' | 'name';

export type PrsOrder = {
  [key in PrsOrderKind]: {
    label: '安否順' | '名前順';
    sort: (list: PrsForPrsList[]) => PrsForPrsList[];
  };
};

const prsActionTypeOrder: Array<PrsActionType | undefined> = [undefined, 'visit', 'evacuate'];

export const prsOrders: PrsOrder = {
  status: {
    label: '安否順',
    sort: (persons) => {
      return [...persons].sort((a, b) => {
        const comparison = a.sortOrder - b.sortOrder;
        if (comparison !== 0) return comparison;
        return prsActionTypeOrder.indexOf(a.prsActionType) - prsActionTypeOrder.indexOf(b.prsActionType);
      });
    },
  },
  name: {
    label: '名前順',
    sort: (persons) => {
      return [...persons].sort((a, b) => a.kPrsName.localeCompare(b.kPrsName, 'ja'));
    },
  },
} as const;
