import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';
import { RequestPostSendLineMessage, ResponsePostSendLineMessage } from '../types/sendLineMessage';

const url = '/send-line-message';

export const sendLineMessage = async (data: RequestPostSendLineMessage) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponsePostSendLineMessage>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponsePostSendLineMessage>>(url, data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
