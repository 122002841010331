import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appConst } from 'src/constants/common';
import { useJichitaiData } from 'src/hooks/common/useJichitaiData';
import { useUserData } from 'src/hooks/common/useUserData';
import { postLineIntegration } from 'src/utilities/restApi/shien/lineIntegration';
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from 'src/utilities/restApi/storageUtil';
import { generateLineAuthUrl } from '../logics/generateLineAuthUrl';

// LINEプラットフォームから受け取ったアクセストークンの取得に使用される認可コード
const useRedirectParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? undefined;
  const state = searchParams.get('state') ?? undefined;
  return { code, state };
};

export const useLineIntegration = () => {
  // state
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { userData, updateIsLinkedLineId } = useUserData();
  const { code, state } = useRedirectParams();
  const { jichitaiData } = useJichitaiData();

  //   useEffect
  const isInitialMount = useRef(true);
  useEffect(() => {
    // LINEの認証コードは1度しか使えないため、開発環境の2回目のuseEffectの実行を抑止
    // queryにcodeがある(=line認証画面から遷移してきた)場合,lineuseridをdbに登録する
    if (isInitialMount.current && code && state) {
      isInitialMount.current = false;
      // 認証コード取得に使用したstateとnonceを取得
      const storedState = localStorageGetItem<string | undefined>(appConst.STORAGE_KEY_LINE_STATE);
      const storedNonce = localStorageGetItem<string | undefined>(appConst.STORAGE_KEY_LINE_NONCE);
      const storedCodeVerifier = localStorageGetItem<string | undefined>(appConst.STORAGE_KEY_LINE_CODE_VERIFIER);
      // 不要なので削除
      localStorageRemoveItem(appConst.STORAGE_KEY_LINE_STATE);
      localStorageRemoveItem(appConst.STORAGE_KEY_LINE_NONCE);
      localStorageRemoveItem(appConst.STORAGE_KEY_LINE_CODE_VERIFIER);

      if (storedState && storedNonce && storedCodeVerifier && state === storedState) {
        setLoading(true);
        setError(false);

        // db更新処理
        const redirectPath = window.location.pathname;
        postLineIntegration({ code, nonce: storedNonce, codeVerifier: storedCodeVerifier, redirectPath })
          .then(() => updateIsLinkedLineId(true)) // グローバルstateの更新(楽観的UI更新)
          .catch(() => setError(true))
          .finally(() => setLoading(false));
        // クエリパラメータを削除
        navigate(window.location.pathname);
      } else {
        // リロードや直打ち時は何もしない
        navigate(window.location.pathname);
        return;
      }
    }
  }, []);

  /**
   * 連携する押下時処理
   * @param redirectUri Line認証画面からのリダイレクト先uri
   */
  const link = async () => {
    if (jichitaiData?.channelId) {
      // Line認証画面からのリダイレクト先
      // NOTE:このイベント呼び出したページにリダイレクトする
      const redirectUri = window.location.origin + window.location.pathname;
      const { url, state, nonce, codeVerifier } = await generateLineAuthUrl(jichitaiData.channelId, redirectUri);

      // リダイレクト先の検証用に保持
      localStorageSetItem(appConst.STORAGE_KEY_LINE_STATE, state);
      localStorageSetItem(appConst.STORAGE_KEY_LINE_NONCE, nonce);
      localStorageSetItem(appConst.STORAGE_KEY_LINE_CODE_VERIFIER, codeVerifier);

      window.location.href = url;
    }
  };

  /**
   * 連携解除押下時処理
   */
  const unlink = () => {
    // NOTE:連携解除はスコープ外
  };

  return {
    isLinked: userData?.isLinkedLineUserId,
    isImportedJichitaiData: jichitaiData !== undefined,
    loading,
    error,
    link,
    unlink,
  };
};
