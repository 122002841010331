import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import {
  RequestGetPrsDetail,
  RequestPatchPrsDetail,
  ResponseGetPrsDetail,
} from 'src/utilities/restApi/common/types/prsDetail';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/prsdetail';
export const getPrsDetail = async (params: RequestGetPrsDetail) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseGetPrsDetail>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetPrsDetail>>(url, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};

export const patchPrsDetail = async (data: RequestPatchPrsDetail) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<string>>((resolve, reject) => {
    restApi()
      .patch<SuccessResponse<string>>(url, data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
