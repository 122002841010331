export const IconHand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M29.5 7H24.5C23.67 7 23 7.67 23 8.5V9H17.35L17.15 8.69C16.87 8.26 16.4 8 15.89 8H8.9C8.69 7.42 8.14 7 7.49 7H2.5C1.67 7 1 7.67 1 8.5V19.5C1 20.33 1.67 21 2.5 21H7.5C7.5 21 7.56 21 7.59 21C7.61 21.02 7.63 21.05 7.65 21.07L12 25.42C12.38 25.8 12.88 26.01 13.41 26.01H16.17C16.97 26.01 17.73 25.7 18.29 25.13L23.19 20.23C23.45 20.69 23.93 21.01 24.5 21.01H29.5C30.33 21.01 31 20.34 31 19.51V8.51C31 7.68 30.33 7.01 29.5 7.01V7ZM18.72 14.86L17.9 15.68L14.94 13.46C14.35 13.01 13.5 13.07 12.98 13.6L8.99 17.59V10H15.61L18.71 14.86H18.72ZM7 19H3V9H7V19ZM16.88 23.71C16.69 23.9 16.44 24 16.17 24H14.06L16.35 21.71C16.45 21.61 16.45 21.45 16.35 21.36L15.64 20.65C15.54 20.55 15.38 20.55 15.29 20.65L12.67 23.27L11.73 22.33L14.35 19.71C14.45 19.61 14.45 19.45 14.35 19.36L13.64 18.65C13.54 18.55 13.38 18.55 13.29 18.65L10.67 21.27L9.41 20.01L14.09 15.33L17.05 17.55C17.64 18 18.49 17.94 19.01 17.41L20.42 16C20.91 15.51 21 14.72 20.62 14.13L18.63 11.01H23V17.6L16.88 23.72V23.71ZM29 19H25V9H29V19Z"
        fill="#999999"
      />
    </svg>
  );
};
