import { useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { Illust04 } from 'src/components/common/atoms/images/Illust04';
import { ButtonPageTemplate } from 'src/components/shien/templates/ButtonPageTemplate';

export const SystemErrorPage = () => {
  const navigate = useNavigate();

  return (
    <PageContainer title="システムエラー">
      <ButtonPageTemplate
        headline="ただいまご利用できません"
        description="現在システムが停止しているため、このサービスは利用できません。少し時間をあけてから、もう一度お試しください。"
        primary={{
          text: '閉じる',
          onClick: () => {
            // TODO:適切なパス、ボタンの必要性
            navigate('/');
          },
        }}
      >
        <Illust04 />
      </ButtonPageTemplate>
    </PageContainer>
  );
};
