import { yupResolver } from '@hookform/resolvers/yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Container,
  Divider,
  FormHelperText,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { SearchConditionLabel } from 'src/components/kanri/atoms/SearchConditionLabel';
import { SearchConditionTextField } from 'src/components/kanri/atoms/SearchConditionTextField';
import { SelectableChip } from 'src/components/kanri/atoms/SelectableChip';
import { appConst, RoleId } from 'src/constants/common';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { PrsStatus } from 'src/types/common/PrsStatus';
import { Soshiki } from 'src/types/common/Soshiki';
import { SpCheck } from 'src/types/common/SpCheck';
import { ConditionType } from 'src/types/kanri/ConditionType';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { getPrsStatus } from 'src/utilities/restApi/common/prsStatus';
import { getSpCheck } from 'src/utilities/restApi/common/spCheck';
import { getSoshikiList } from 'src/utilities/restApi/kanri/soshiki';
import * as yup from 'yup';
import { SearchConditions, StrBoolValues } from './types';

const SEARCH_CONDITION_LABEL_WIDTH = 70;

type ComponentProps = {
  adisplayFlg: boolean;
  isLoading?: SearchCallerType;
  searchButtonOnClick: (searchConditions: SearchConditions) => void;
};

type FormValues = {
  prsFName?: string | null;
  prsSName?: string | null;
  prsSoshiki?: Soshiki | null;
  spFName?: string | null;
  spSName?: string | null;
  spSoshiki?: Soshiki | null;
};

export const SearchConditionField = ({ adisplayFlg, isLoading, searchButtonOnClick }: ComponentProps) => {
  const navigateSystemError = useNavigateSystemError();
  const { userData } = useUserData();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<ConditionType>('simple');
  const [prsStatusList, setPrsStatusList] = useState<PrsStatus[] | undefined>(undefined);
  const [spCheckList, setSpCheckList] = useState<SpCheck[] | undefined>(undefined);
  const [soshikiList, setSoshikiList] = useState<Soshiki[] | undefined>(undefined);
  // simple search
  const [selectedPrsStatusId, setSelectedPrsStatusId] = useState<string>(appConst.STR_EMPTY);
  const [selectedSpCheckId, setSelectedSpCheckId] = useState<string>(appConst.STR_EMPTY);
  const [selectedSoshikiList, setSelectedSoshikiList] = useState<Soshiki[]>([]);
  const [selectedRegisteredMemo, setSelectedRegisteredMemo] = useState<StrBoolValues>(appConst.STR_EMPTY);
  const [selectedAgreement, setSelectedAgreement] = useState<StrBoolValues>(appConst.STR_EMPTY);

  useEffect(() => {
    if (userData) {
      getPrsStatus({ jichitaiid: userData.jichitaiId })
        .then((response) => {
          if (response.data) {
            const newData = response.data.prsstatus_list.map((x) => {
              return {
                prsStatusId: x.prsstatusid,
                jichitaiId: x.jichitaiid,
                status: x.status,
                color: x.color,
                sortOrder: x.sortorder,
              } as PrsStatus;
            });
            setPrsStatusList(newData);
          }
        })
        .catch(() => navigateSystemError());

      getSpCheck()
        .then((response) => {
          if (response.data) {
            const newData = response.data.spcheck_list.map((x) => {
              return {
                spCheckId: x.spcheckid,
                spCheckStatus: x.spcheckstatus,
              } as SpCheck;
            });
            setSpCheckList(newData);
          }
        })
        .catch(() => navigateSystemError());

      getSoshikiList()
        .then((response) => {
          if (response.data) {
            const newData = response.data.soshiki_list.map((x) => {
              return {
                soshikiId: x.soshikiid,
                soshikiName: x.soshikiname,
              } as Soshiki;
            });
            setSoshikiList(newData);
          }
        })
        .catch(() => navigateSystemError());
    }
  }, [userData]);

  // validation
  const schema: yup.ObjectSchema<FormValues> = yup.object(
    searchType === 'advanced'
      ? {
          prsFName: yup
            .string()
            .test('prsFNameRequired1', '対象者名または支援者名のいずれかは必須です。', () => {
              let isValid = true;
              const { prsFName, prsSName, prsSoshiki, spFName, spSName, spSoshiki } = getValues();
              if (!prsFName && !prsSName && !prsSoshiki && !spFName && !spSName && !spSoshiki) {
                isValid = false;
              }
              return isValid;
            })
            .test('prsFNameRequired2', '姓または名のいずれかは必須です。', (value) => {
              let isValid = true;
              const { prsSName, prsSoshiki } = getValues();
              if (prsSoshiki) {
                if (!prsSName && !value) {
                  isValid = false;
                }
              }
              return isValid;
            })
            .max(200),
          prsSName: yup
            .string()
            .test('prsFName', '姓または名のいずれかは必須です。', (value) => {
              let isValid = true;
              const { prsFName, prsSoshiki } = getValues();
              if (prsSoshiki) {
                if (!prsFName && !value) {
                  isValid = false;
                }
              }
              return isValid;
            })
            .max(200),
          prsSoshiki:
            userData?.roleId === RoleId.JichitaiStaff
              ? yup
                  .mixed<Soshiki>()
                  .nullable()
                  .test('prsSoshikiRequired', '地域は必須です。', (value) => {
                    let isValid = true;
                    const { prsFName, prsSName } = getValues();
                    if (!value) {
                      if (prsFName || prsSName) {
                        isValid = false;
                      }
                    }
                    return isValid;
                  })
              : yup.mixed<Soshiki>().notRequired(),
          spFName: yup
            .string()
            .test('spFNameRequired1', '対象者名または支援者名のいずれかは必須です。', () => {
              let isValid = true;
              const { prsFName, prsSName, prsSoshiki, spFName, spSName, spSoshiki } = getValues();
              if (!prsFName && !prsSName && !prsSoshiki && !spFName && !spSName && !spSoshiki) {
                isValid = false;
              }
              return isValid;
            })
            .test('spFNameRequired2', '姓または名のいずれかは必須です。', (value) => {
              let isValid = true;
              const { spSName, spSoshiki } = getValues();
              if (spSoshiki) {
                if (!spSName && !value) {
                  isValid = false;
                }
              }
              return isValid;
            })
            .max(200),
          spSName: yup
            .string()
            .test('prsFName', '姓または名のいずれかは必須です。', (value) => {
              let isValid = true;
              const { spFName, spSoshiki } = getValues();
              if (spSoshiki) {
                if (!spFName && !value) {
                  isValid = false;
                }
              }
              return isValid;
            })
            .max(200),
          spSoshiki:
            userData?.roleId === RoleId.JichitaiStaff
              ? yup
                  .mixed<Soshiki>()
                  .nullable()
                  .test('spSoshikiRequired', '地域は必須です。', (value) => {
                    let isValid = true;
                    const { spFName, spSName } = getValues();
                    if (!value) {
                      if (spFName || spSName) {
                        isValid = false;
                      }
                    }
                    return isValid;
                  })
              : yup.mixed<Soshiki>().notRequired(),
        }
      : {
          prsFName: yup.string().notRequired(),
          prsSName: yup.string().notRequired(),
          prsSoshiki: yup.mixed<Soshiki>().notRequired(),
          spFName: yup.string().notRequired(),
          spSName: yup.string().notRequired(),
          spSoshiki: yup.mixed<Soshiki>().notRequired(),
        }
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      prsFName: '',
      prsSName: '',
      prsSoshiki: null,
      spFName: '',
      spSName: '',
      spSoshiki: null,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    setExpanded(false);
    const searchConditions: SearchConditions = {
      simple:
        searchType === 'simple'
          ? {
              prsStatusId: selectedPrsStatusId,
              spCheckId: selectedSpCheckId,
              soshikiList: selectedSoshikiList,
              registeredMemo: selectedRegisteredMemo,
              agreement: selectedAgreement,
            }
          : undefined,
      advanced:
        searchType === 'advanced'
          ? {
              prsFName: data.prsFName ?? undefined,
              prsSName: data.prsSName ?? undefined,
              prsSoshiki: data.prsSoshiki ?? undefined,
              spFName: data.spFName ?? undefined,
              spSName: data.spSName ?? undefined,
              spSoshiki: data.spSoshiki ?? undefined,
            }
          : undefined,
    };
    searchButtonOnClick(searchConditions);
  });

  const handleSearchType = (_event: React.MouseEvent<HTMLElement>, newSearchType: ConditionType | null) => {
    if (newSearchType) {
      setSearchType(newSearchType);
    }
  };

  const clearButtonOnClick = () => {
    // 検索ボタンクリック
    reset();
    setSearchType('simple');
    setSelectedPrsStatusId(appConst.STR_EMPTY);
    setSelectedSpCheckId(appConst.STR_EMPTY);
    setSelectedSoshikiList([]);
    setSelectedRegisteredMemo(appConst.STR_EMPTY);
    setSelectedAgreement(appConst.STR_EMPTY);
  };

  return (
    <form onSubmit={onSubmit}>
      <div>
        <Accordion
          variant="outlined"
          disableGutters
          expanded={expanded}
          onChange={(_event: React.SyntheticEvent, expanded: boolean) => setExpanded(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>検索条件</AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <ToggleButtonGroup size="small" color="primary" value={searchType} exclusive onChange={handleSearchType}>
                <ToggleButton value="simple">シンプルな検索</ToggleButton>
                <ToggleButton value="advanced">氏名検索</ToggleButton>
              </ToggleButtonGroup>
              <Stack spacing={1} width="100%">
                {searchType === 'simple' ? (
                  <>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={1}
                      alignItems={{ xs: 'start', sm: 'center' }}
                    >
                      <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>安否状況</SearchConditionLabel>
                      <Grid container spacing={1}>
                        <Grid>
                          <SelectableChip
                            label="すべて"
                            selected={selectedPrsStatusId === appConst.STR_EMPTY}
                            onClick={() => setSelectedPrsStatusId(appConst.STR_EMPTY)}
                          />
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
                        {prsStatusList ? (
                          prsStatusList.map((x, i) => (
                            <Grid key={`prsStatus-${i}`}>
                              <SelectableChip
                                label={x.status}
                                selected={selectedPrsStatusId === x.prsStatusId}
                                onClick={() => setSelectedPrsStatusId(x.prsStatusId)}
                              />
                            </Grid>
                          ))
                        ) : (
                          <AppSkeleton width={300} />
                        )}
                      </Grid>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={1}
                      alignItems={{ xs: 'start', sm: 'center' }}
                    >
                      <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>支援可否</SearchConditionLabel>
                      <Grid container spacing={1}>
                        <Grid>
                          <SelectableChip
                            label="すべて"
                            selected={selectedSpCheckId === appConst.STR_EMPTY}
                            onClick={() => setSelectedSpCheckId(appConst.STR_EMPTY)}
                          />
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
                        {spCheckList ? (
                          spCheckList.map((x, i) => (
                            <Grid key={`spCheck-${i}`}>
                              <SelectableChip
                                label={x.spCheckStatus}
                                selected={selectedSpCheckId === x.spCheckId}
                                onClick={() => setSelectedSpCheckId(x.spCheckId)}
                              />
                            </Grid>
                          ))
                        ) : (
                          <AppSkeleton width={300} />
                        )}
                      </Grid>
                    </Stack>
                    {userData?.roleId === RoleId.JichitaiStaff && (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={1}
                        alignItems={{ xs: 'start', sm: 'center' }}
                      >
                        <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>地域</SearchConditionLabel>
                        {soshikiList ? (
                          <Autocomplete
                            multiple
                            options={soshikiList}
                            value={selectedSoshikiList}
                            onChange={(_event, newValue) => {
                              setSelectedSoshikiList(newValue);
                            }}
                            getOptionLabel={(option) => option.soshikiName}
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) => option.soshikiId === value.soshikiId}
                            renderInput={(params) => <TextField {...params} size="small" />}
                            size="small"
                            ChipProps={{ color: 'primary' }}
                            sx={{ width: { xs: '100%', sm: 500 } }}
                          />
                        ) : (
                          <AppSkeleton width={300} />
                        )}
                      </Stack>
                    )}
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={1}
                      alignItems={{ xs: 'start', sm: 'center' }}
                    >
                      <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>メモ有無</SearchConditionLabel>
                      <Grid container spacing={1}>
                        <Grid>
                          <SelectableChip
                            label="すべて"
                            selected={selectedRegisteredMemo === appConst.STR_EMPTY}
                            onClick={() => setSelectedRegisteredMemo(appConst.STR_EMPTY)}
                          />
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
                        <Grid>
                          <SelectableChip
                            label="無"
                            selected={selectedRegisteredMemo === appConst.STR_FALSE}
                            onClick={() => setSelectedRegisteredMemo(appConst.STR_FALSE)}
                          />
                        </Grid>
                        <Grid>
                          <SelectableChip
                            label="有"
                            selected={selectedRegisteredMemo === appConst.STR_TRUE}
                            onClick={() => setSelectedRegisteredMemo(appConst.STR_TRUE)}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    {adisplayFlg && (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={1}
                        alignItems={{ xs: 'start', sm: 'center' }}
                      >
                        <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>同意</SearchConditionLabel>
                        <Grid container spacing={1}>
                          <Grid>
                            <SelectableChip
                              label="すべて"
                              selected={selectedAgreement === appConst.STR_EMPTY}
                              onClick={() => setSelectedAgreement(appConst.STR_EMPTY)}
                            />
                          </Grid>
                          <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
                          <Grid>
                            <SelectableChip
                              label={appConst.LABEL_UNAGREED}
                              selected={selectedAgreement === appConst.STR_FALSE}
                              onClick={() => setSelectedAgreement(appConst.STR_FALSE)}
                            />
                          </Grid>
                          <Grid>
                            <SelectableChip
                              label={appConst.LABEL_AGREED}
                              selected={selectedAgreement === appConst.STR_TRUE}
                              onClick={() => setSelectedAgreement(appConst.STR_TRUE)}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    )}
                  </>
                ) : (
                  <>
                    <FormHelperText>氏名検索は検索に時間がかかる場合があります</FormHelperText>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>対象者名</SearchConditionLabel>
                        {userData?.roleId === RoleId.JichitaiStaff && (
                          <Typography variant="caption">（※地域の選択は必須です。）</Typography>
                        )}
                      </Stack>
                      {soshikiList ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ width: '100%', '& > :not(style)': { m: 1 } }}
                        >
                          <Container maxWidth="md">
                            <Grid container spacing={1} sx={{ width: '100%' }}>
                              <Grid xs={12} md={4}>
                                <Controller
                                  name="prsFName"
                                  control={control}
                                  render={({ field }) => (
                                    <SearchConditionTextField
                                      {...field}
                                      label="姓"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        setValue('prsFName', e.target.value);
                                        trigger(['prsFName', 'prsSName', 'prsSoshiki', 'spFName']);
                                      }}
                                      error={'prsFName' in errors}
                                      helperText={errors.prsFName?.message ?? appConst.LABEL_HALF_SPACE}
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid xs={12} md={4}>
                                <Controller
                                  name="prsSName"
                                  control={control}
                                  render={({ field }) => (
                                    <SearchConditionTextField
                                      {...field}
                                      label="名"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        setValue('prsSName', e.target.value);
                                        trigger(['prsFName', 'prsSName', 'prsSoshiki', 'spFName']);
                                      }}
                                      error={'prsSName' in errors}
                                      helperText={errors.prsSName?.message ?? appConst.LABEL_HALF_SPACE}
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              {userData?.roleId === RoleId.JichitaiStaff && (
                                <Grid xs={12} md={4}>
                                  <Controller
                                    name="prsSoshiki"
                                    control={control}
                                    render={({ field }) => {
                                      return (
                                        <Autocomplete
                                          {...field}
                                          disablePortal
                                          options={soshikiList}
                                          getOptionLabel={(option) => option.soshikiName}
                                          size="small"
                                          onChange={(_event, newValue) => {
                                            setValue('prsSoshiki', newValue);
                                            trigger(['prsFName', 'prsSName', 'spFName', 'prsSoshiki']);
                                          }}
                                          isOptionEqualToValue={(option, value) => option.soshikiId === value.soshikiId}
                                          renderInput={(params) => (
                                            <SearchConditionTextField
                                              {...params}
                                              label="地域"
                                              autoComplete="off"
                                              error={'prsSoshiki' in errors}
                                              helperText={errors.prsSoshiki?.message ?? appConst.LABEL_HALF_SPACE}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Container>
                        </Stack>
                      ) : (
                        <AppSkeleton width={300} />
                      )}
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>支援者名</SearchConditionLabel>
                        {userData?.roleId === RoleId.JichitaiStaff && (
                          <Typography variant="caption">（※地域の選択は必須です。）</Typography>
                        )}
                      </Stack>
                      {soshikiList ? (
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ '& > :not(style)': { m: 1 } }}>
                          <Container maxWidth="md">
                            <Grid container spacing={1} sx={{ width: '100%' }}>
                              <Grid xs={12} md={4}>
                                <Controller
                                  name="spFName"
                                  control={control}
                                  render={({ field }) => (
                                    <SearchConditionTextField
                                      {...field}
                                      label="姓"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        setValue('spFName', e.target.value);
                                        trigger(['spFName', 'spSName', 'spSoshiki', 'prsFName']);
                                      }}
                                      error={'spFName' in errors}
                                      helperText={errors.spFName?.message ?? appConst.LABEL_HALF_SPACE}
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid xs={12} md={4}>
                                <Controller
                                  name="spSName"
                                  control={control}
                                  render={({ field }) => (
                                    <SearchConditionTextField
                                      {...field}
                                      label="名"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        setValue('spSName', e.target.value);
                                        trigger(['spFName', 'spSName', 'spSoshiki', 'prsFName']);
                                      }}
                                      error={'spSName' in errors}
                                      helperText={errors.spSName?.message ?? appConst.LABEL_HALF_SPACE}
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              {userData?.roleId === RoleId.JichitaiStaff && (
                                <Grid xs={12} md={4}>
                                  <Controller
                                    name="spSoshiki"
                                    control={control}
                                    render={({ field }) => {
                                      return (
                                        <Autocomplete
                                          {...field}
                                          disablePortal
                                          options={soshikiList}
                                          getOptionLabel={(option) => option.soshikiName}
                                          size="small"
                                          onChange={(_event, newValue) => {
                                            setValue('spSoshiki', newValue);
                                            trigger(['spFName', 'spSName', 'prsFName', 'spSoshiki']);
                                          }}
                                          isOptionEqualToValue={(option, value) => option.soshikiId === value.soshikiId}
                                          renderInput={(params) => (
                                            <SearchConditionTextField
                                              {...params}
                                              label="地域"
                                              autoComplete="off"
                                              error={'spSoshiki' in errors}
                                              helperText={errors.spSoshiki?.message ?? appConst.LABEL_HALF_SPACE}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Container>
                        </Stack>
                      ) : (
                        <AppSkeleton width={300} />
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ width: { xs: '100%', sm: '300px' } }}
                  type="submit"
                  disabled={isLoading === 'user'}
                >
                  検索
                </Button>
                <Button onClick={() => clearButtonOnClick()}>条件をクリア</Button>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </div>
    </form>
  );
};
