import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { Illust04 } from 'src/components/common/atoms/images/Illust04';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { PrsMemoEditDialog } from 'src/components/shien/pages/prs/components/PrsMemoEditDialog';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { ButtonWithHeaderPageTemplate } from 'src/components/shien/templates/ButtonWithHeaderPageTemplate';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';

export const PrsCompleteRegistrationPage = () => {
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const { prs, refreshData, initialized } = useContext(PrsContext);
  const [memoDialogOpen, setMemoDialogOpen] = useState(false);
  const { setShowBackdrop } = useBackdrop();

  return (
    <PageContainer title={`登録完了| 避難行動支援`}>
      <ButtonWithHeaderPageTemplate
        headlineText={'登録完了'}
        upperText={(initialized && `${prs?.fName} ${prs?.sName}`) || <AppSkeleton width="100px" />}
        description="ご協力ありがとうございました。対象者のケガの状況などの共有したい情報があれば、「メモを登録する」を押してください。"
        primary={{
          text: '登録を終わる',
          onClick: () => {
            // 支援ガイド画面に戻る
            navigate('/app/shien/prs/summary');
          },
        }}
        secondary={{
          text: 'メモを登録する',
          onClick: () => {
            setMemoDialogOpen(true);
          },
        }}
      >
        <Illust04 />
      </ButtonWithHeaderPageTemplate>
      <PrsMemoEditDialog
        prs={prs}
        dialogOpen={memoDialogOpen}
        onClose={({ reason, text }) => {
          // 変更がある場合登録APIを呼ぶ
          if (reason === 'registerButtonClick' && text !== prs?.prsMemo) {
            setShowBackdrop(true);
            (async () => {
              try {
                await patchPrsDetail({ prsid: prs!.prsId, prsmemo: text === '' ? null : text ?? null });
                await refreshData();
                setMemoDialogOpen(false);
              } catch {
                navigateSystemError();
              } finally {
                setShowBackdrop(false);
              }
            })();
          } else {
            setMemoDialogOpen(false);
          }
        }}
      ></PrsMemoEditDialog>
    </PageContainer>
  );
};
