import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { AppList } from 'src/components/kanri/atoms/AppList';
import { useSectionDataList } from 'src/components/kanri/pages/jichitai/SearchSupportersPage/components/SupporterDetailsDialog/hooks/useSectionDataList';
import { TransitionUp } from 'src/components/shien/atoms/TransitionUp';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';

/**
 * 取得コスト削減のため一覧画面から受け取る詳細情報
 */
export type PreFetchedData = {
  soshikiNames: string[];
};

export type ComponentProps = {
  open: boolean;
  onClose: () => void;
  spId: undefined | string;
  preFetched: undefined | Readonly<PreFetchedData>;
};

export const SupporterDetailsDialog = ({ open, onClose: callOnClose, spId, preFetched }: ComponentProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateSystemError = useNavigateSystemError();

  // States
  const { sectionDataList, error } = useSectionDataList({
    spId,
    preFetched,
  });

  // Effects
  useEffect(() => {
    if (error) {
      navigateSystemError();
    }
  }, [error]);

  // Event handlers
  const onClose = () => {
    callOnClose();
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth TransitionComponent={TransitionUp} fullScreen={fullScreen}>
      <DialogTitle variant="h4">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box>支援者詳細</Box>
          <StackSpacer />
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <AppList sectionDataList={sectionDataList} />
    </Dialog>
  );
};
