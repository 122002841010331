export const IconList = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M6.75 8H5.25C5.11193 8 5 8.11193 5 8.25V9.75C5 9.88807 5.11193 10 5.25 10H6.75C6.88807 10 7 9.88807 7 9.75V8.25C7 8.11193 6.88807 8 6.75 8Z"
        fill="#999999"
      />
      <path
        d="M6.75 15H5.25C5.11193 15 5 15.1119 5 15.25V16.75C5 16.8881 5.11193 17 5.25 17H6.75C6.88807 17 7 16.8881 7 16.75V15.25C7 15.1119 6.88807 15 6.75 15Z"
        fill="#999999"
      />
      <path
        d="M6.75 22H5.25C5.11193 22 5 22.1119 5 22.25V23.75C5 23.8881 5.11193 24 5.25 24H6.75C6.88807 24 7 23.8881 7 23.75V22.25C7 22.1119 6.88807 22 6.75 22Z"
        fill="#999999"
      />
      <path
        d="M26.75 8H10.25C10.1119 8 10 8.11193 10 8.25V9.75C10 9.88807 10.1119 10 10.25 10H26.75C26.8881 10 27 9.88807 27 9.75V8.25C27 8.11193 26.8881 8 26.75 8Z"
        fill="#999999"
      />
      <path
        d="M26.75 15H10.25C10.1119 15 10 15.1119 10 15.25V16.75C10 16.8881 10.1119 17 10.25 17H26.75C26.8881 17 27 16.8881 27 16.75V15.25C27 15.1119 26.8881 15 26.75 15Z"
        fill="#999999"
      />
      <path
        d="M26.75 22H10.25C10.1119 22 10 22.1119 10 22.25V23.75C10 23.8881 10.1119 24 10.25 24H26.75C26.8881 24 27 23.8881 27 23.75V22.25C27 22.1119 26.8881 22 26.75 22Z"
        fill="#999999"
      />
    </svg>
  );
};
