import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { SearchConditionLabel } from 'src/components/kanri/atoms/SearchConditionLabel';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { Soshiki } from 'src/types/common/Soshiki';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { getSoshikiList } from 'src/utilities/restApi/kanri/soshiki';

const SEARCH_CONDITION_LABEL_WIDTH = 70;

type ComponentProps = {
  isLoading?: SearchCallerType;
  onSearchButtonClick: (searchBtnClickFlg: boolean, soshikiChangeList: Soshiki[]) => void;
};

export const SearchConditionFieldForSoshikiNm = ({ isLoading, onSearchButtonClick }: ComponentProps) => {
  const { userData } = useUserData();
  const navigateSystemError = useNavigateSystemError();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [soshikiList, setSoshikiList] = useState<Soshiki[] | undefined>(undefined);
  const [selectedSoshikiList, setSelectedSoshikiList] = useState<Soshiki[]>([]);

  useEffect(() => {
    if (userData) {
      getSoshikiList()
        .then((response) => {
          if (response.data) {
            const newData = response.data.soshiki_list.map((x) => {
              return {
                soshikiId: x.soshikiid,
                soshikiName: x.soshikiname,
              } as Soshiki;
            });
            setSoshikiList(newData);
          }
        })
        .catch(() => navigateSystemError());
    }
  }, [userData]);

  const searchButtonOnClick = () => {
    setExpanded(false);
    onSearchButtonClick(true, selectedSoshikiList);
  };

  const clearButtonOnClick = () => {
    // 検索ボタンクリック
    setSelectedSoshikiList([]);
  };

  return (
    <Accordion
      variant="outlined"
      disableGutters
      expanded={expanded}
      onChange={(_event: React.SyntheticEvent, expanded: boolean) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>検索条件</AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Stack spacing={1} width="100%">
            {
              <>
                {/* 支援組織 */}
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} alignItems={{ xs: 'start', sm: 'center' }}>
                  <SearchConditionLabel width={SEARCH_CONDITION_LABEL_WIDTH}>支援組織</SearchConditionLabel>
                  {soshikiList ? (
                    <Autocomplete
                      multiple
                      options={soshikiList}
                      value={selectedSoshikiList}
                      onChange={(_event, newValue) => {
                        setSelectedSoshikiList(newValue);
                      }}
                      getOptionLabel={(option) => option.soshikiName}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.soshikiId === value.soshikiId}
                      renderInput={(params) => <TextField {...params} size="small" />}
                      size="small"
                      ChipProps={{ color: 'primary' }}
                      sx={{ width: { xs: '100%', sm: 500 } }}
                    />
                  ) : (
                    <AppSkeleton width={300} />
                  )}
                </Stack>
              </>
            }
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" spacing={1}>
            <Button
              variant="contained"
              sx={{ width: { xs: '100%', sm: '300px' } }}
              disabled={isLoading === 'user'}
              onClick={() => searchButtonOnClick()}
            >
              検索
            </Button>
            <Button onClick={() => clearButtonOnClick()}>条件をクリア</Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
