import { Box, Stack } from '@mui/material';
import { HeaderButton } from 'src/components/shien/atoms/Header/HeaderButton';
import { HeaderTitle, HeaderTitleProps } from 'src/components/shien/atoms/Header/HeaderTitle';

export type HeaderWithCancelButtonProps = HeaderTitleProps & {
  disabledRegister?: boolean;
  onCancelClick: () => void;
  onRegisterClick: () => void;
};

export const HeaderWithRegisterButton = ({
  disabledRegister = false,
  onCancelClick,
  onRegisterClick,
  ...headerTitleProps
}: HeaderWithCancelButtonProps) => {
  return (
    <Box
      component="header"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
        width: '100%',
        padding: '10px 0',
        gap: '10px',
      }}
    >
      <Stack alignItems="start" sx={{ paddingLeft: '12px' }}>
        <HeaderButton variant="secondary" text="中止" onClick={onCancelClick} />
      </Stack>
      <HeaderTitle {...headerTitleProps} />
      <Stack alignItems="end" sx={{ paddingRight: '12px' }}>
        <HeaderButton variant="primary" text="登録" disabled={disabledRegister} onClick={onRegisterClick} />
      </Stack>
    </Box>
  );
};
