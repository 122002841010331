import { Backdrop, CircularProgress, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useBackdrop } from 'src/hooks/common/backdrops';

type ComponentProps = {
  children?: React.ReactNode;
  title: string;
};

export const PageContainer = ({ children, title }: ComponentProps) => {
  const { showBackdrop } = useBackdrop();
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
      <Backdrop open={showBackdrop} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
