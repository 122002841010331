import { SnackbarMessage, VariantType } from 'notistack';
import { Dictionary } from 'src/types/common/Dictionary';

export enum SnackbarMessageId {
  SearchTimeOut,
}

type SnackbarMessages = Dictionary<
  SnackbarMessageId,
  {
    message: SnackbarMessage;
    variant: VariantType;
  }
>;

export const snackbarMessages: SnackbarMessages = {
  [SnackbarMessageId.SearchTimeOut]: {
    message: (
      <>
        検索がタイムアウトしました。
        <br />
        検索条件を見なおすか、時間をおいて再度検索してください。
      </>
    ),
    variant: 'warning',
  },
};
