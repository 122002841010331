import { useState } from 'react';
import { SpStatusId } from 'src/components/shien/pages/possibility/SupportPossibleSubmissionPage/types';
import { useUserData } from 'src/hooks/common/useUserData';
import { patchSupporter } from 'src/utilities/restApi/common/supporters';

export const useUpdateSpCheckStatus = () => {
  const { userData } = useUserData();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const updateSpStatus = async (spcheckid: SpStatusId) => {
    let message: 'success' | 'failed' = 'success';
    try {
      setLoading(true);
      // 支援者IDがセッションストレージに存在しない場合エラー
      if (!userData?.userId) {
        throw new Error('invalid session');
      }

      // messageは受け取らない
      await patchSupporter({ spid: userData?.userId, data: { spcheckid } });
    } catch (_) {
      setError(true);
      message = 'failed';
    } finally {
      setLoading(false);
    }
    return message;
  };
  return { updateSpStatus, loading, error };
};
