import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestGetPrsList, ResponseGetPrsList } from 'src/utilities/restApi/shien/types/prsList';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

/**
 * 支援者に紐づく要支援者一覧取得
 */
export const getResponsePrsList = async (params: RequestGetPrsList) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseGetPrsList>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetPrsList>>('prslist', config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
