const TENNANT_BASE_URL = new URL(import.meta.env.VITE_AZURE_AD_B2C_TENNANT_BASE_URL);

type PolicyEntry = {
  name: string;
  authority: string;
};
export const azureConst = {
  clientId: import.meta.env.VITE_AZURE_AD_B2C_CLIENT_ID,
  policy: {
    policies: {
      signin: {
        name: import.meta.env.VITE_AZURE_AD_B2C_POLICY_SIGNIN,
        authority: `${TENNANT_BASE_URL.href}/${import.meta.env.VITE_AZURE_AD_B2C_POLICY_SIGNIN}`,
      },
    } as const satisfies Record<string, PolicyEntry>,
    authorityDomain: TENNANT_BASE_URL.host,
  },
  appName: 'MutualAssistance',
} as const;
