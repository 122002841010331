import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { PrsStatusRow } from 'src/components/kanri/pages/jichitai/settings/StatusSettingPage/logics/statusSettingPage';
import { appConst } from 'src/constants/common';

type ImageButtonProps = {
  isEditButton?: boolean; // 更新/削除ボタン切替
  disabled?: boolean; // ボタンの非活性化を制御するフラグ
  onClick: () => void;
  activeData: PrsStatusRow;
};

export const ImageButton = ({ isEditButton = true, onClick, disabled, activeData }: ImageButtonProps) => {
  // 未確認の場合非表示にする
  if (activeData.prsstatusid === appConst.ID_PRS_STATUTS_UNCHECKED) return null;

  return (
    <div>
      <IconButton
        aria-label="save"
        onClick={onClick}
        disabled={disabled}
        sx={{
          '& .MuiSvgIcon-root': { color: disabled ? 'gray' : 'black' },
        }}
      >
        {isEditButton ? <EditIcon /> : <DeleteIcon />}
      </IconButton>
    </div>
  );
};
