import { PublicClientApplication as _PublicClientApplication } from '@azure/msal-browser';
import { azureConst } from 'src/constants/azure';
import { routerPaths } from 'src/routes/path';

export class PublicClientApplication {
  private static instance: _PublicClientApplication;
  private constructor() {}

  public static getInstance(): _PublicClientApplication {
    if (!PublicClientApplication.instance) {
      PublicClientApplication.instance = new _PublicClientApplication({
        auth: {
          clientId: azureConst.clientId,
          authority: azureConst.policy.policies.signin.authority,
          knownAuthorities: [azureConst.policy.authorityDomain],
          postLogoutRedirectUri: routerPaths.index,
          navigateToLoginRequestUrl: false,
        },
      });
    }
    return PublicClientApplication.instance;
  }

  public static hasInstance(): boolean {
    return !!PublicClientApplication.instance;
  }
}
