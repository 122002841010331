import { Paper, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type ComponentProps = {
  children?: ReactNode;
  title?: ReactNode;
  action?: ReactNode;
};
export const Section: FC<ComponentProps> = ({ children, title, action }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" component={'div'}>
        {title}
      </Typography>
      <Paper variant="outlined">{children}</Paper>
      {action}
    </Stack>
  );
};
