import { Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Template, TemplateProps, upperColors } from './Template';

type SmallStatusButtonProps = TemplateProps & {
  statusText: string;
  icon?: ReactNode;
};

export const SmallStatusButton = ({
  statusText,
  icon,
  upperColor = 'none',
  ...templateProps
}: SmallStatusButtonProps) => {
  const theme = useTheme();

  return (
    <Template upperColor={upperColor} {...templateProps}>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
        <Stack>{icon}</Stack>
        <Typography
          sx={{
            fontSize: '24px',
            lineHeight: '24px',
            fontWeight: 'bold',
            color: theme.palette.uiStandard[upperColors[upperColor].text],
          }}
        >
          {statusText}
        </Typography>
      </Stack>
    </Template>
  );
};
