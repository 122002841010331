import { differenceInYears, format, parse } from 'date-fns';

export const calculateCurrentAge = (birthday?: string) => {
  let age = undefined;
  if (birthday) {
    const birthdayDate = parse(birthday, 'yyyyMMdd', new Date());
    if (birthdayDate) {
      age = differenceInYears(new Date(), birthdayDate);
    }
  }
  return age;
};

export const shortTimeFormat = (dateStr?: string) => {
  let label = '';
  if (dateStr) {
    const date = new Date(dateStr);
    // date が今日の日付かどうか
    if (date && format(date, 'yyyyMMdd') === format(new Date(), 'yyyyMMdd')) {
      label = format(date, '今日 HH:mm');
    } else {
      label = format(date, 'MM/dd HH:mm');
    }
  }
  return label;
};
