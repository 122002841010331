import { Backdrop, Box, Button, Divider, Fade, Modal, Stack, Typography, useTheme } from '@mui/material';
import { PrsOrderItemButton } from 'src/components/shien/pages/PrsListPage/components/PrsOrderSelectionModal/PrsOrderItemButton';
import { PrsOrderKind, prsOrders } from 'src/components/shien/pages/PrsListPage/logics/prsOrders';

type OrderSelectionModalProps = {
  open: boolean;
  current: PrsOrderKind;
  onClose: () => void;
  onSelect: (key: any) => void;
};

export const PrsOrderSelectionModal = ({ open, current, onClose, onSelect }: OrderSelectionModalProps) => {
  const theme = useTheme();
  const handleSelect = (key: string) => {
    if (key !== current) {
      onSelect(key);
    }
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            width: '90%',
            padding: 0,
            borderRadius: '10px',
          }}
        >
          <Stack spacing={2} sx={{ p: 2 }}>
            <Typography variant="headline">並び変える</Typography>
            <Typography variant="subtitle">対象者の並び順を選んでください。</Typography>
          </Stack>
          <Divider />
          <Stack divider={<Divider />} width="100%">
            {Object.entries(prsOrders).map(([key, { label }]) => (
              <Box key={key}>
                <PrsOrderItemButton label={label} onClick={() => handleSelect(key)} checked={key === current} />
              </Box>
            ))}
          </Stack>
          <Divider />
          <Button onClick={handleClose} sx={{ py: 2.5, color: 'black' }}>
            <Typography
              textAlign="center"
              sx={{
                color: theme.palette.uiStandard.black,
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: '20px',
              }}
            >
              キャンセル
            </Typography>
          </Button>
        </Stack>
      </Fade>
    </Modal>
  );
};
