import { useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { LineIntegrationRow } from 'src/components/shien/pages/SupporterProfilePage/components/LineIntegrationRow';
import { appConst } from 'src/constants/common';
import { useUserData } from 'src/hooks/common/useUserData';
import { AppListSectionData } from 'src/types/common/AppListSectionData';
import { getSupporter } from 'src/utilities/restApi/common/supporters';

const suspendDataList = [
  {
    header: '基本情報',
    items: [
      { title: '氏名', value: <AppSkeleton /> },
      { title: '氏名カナ', value: <AppSkeleton /> },
      { title: '住所', value: <AppSkeleton /> },
      { title: '連絡先', value: <AppSkeleton /> },
      { title: 'メール', value: <AppSkeleton /> },
      { title: 'LINE連携', value: <AppSkeleton /> },
      { title: '自治体', value: <AppSkeleton /> },
    ],
  },
] as AppListSectionData[];

export const useSpSectionDataList = () => {
  const [sectionDataList, setSectionDataList] = useState<AppListSectionData[]>(suspendDataList);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [soshikiIdList, setSoshikiIdList] = useState<string[] | undefined>(undefined);
  const { userData } = useUserData();
  const [lineIntegrationError, setLineIntegrationError] = useState(false);

  const getSectionDataList = (spId: string) => {
    return new Promise<AppListSectionData[]>((resolve, reject) => {
      if (!userData) {
        setSectionDataList([]);
        reject();
      }

      getSupporter({ spid: spId })
        .then((response) => {
          if (response.data?.sp && userData) {
            const sectionDataList = [
              {
                header: '基本情報',
                items: [
                  { title: '氏名', value: [userData.fName, userData.sName].join(appConst.LABEL_HALF_SPACE) },
                  { title: '氏名カナ', value: [userData.kfName, userData.ksName].join(appConst.LABEL_HALF_SPACE) },
                  { title: '住所', value: response.data.sp.address },
                  { title: '連絡先', value: userData.telNo },
                  { title: 'メール', value: userData.mailAddress },
                  {
                    title: 'LINE連携',
                    value: (
                      <LineIntegrationRow
                        lineIntegrationErrorCallback={() => {
                          setLineIntegrationError(true);
                        }}
                      />
                    ),
                  },
                  { title: '自治体', value: userData.jichitaiName },
                ],
              },
            ];

            setSoshikiIdList(response.data.sp.soshikiid_list ?? []);
            setSectionDataList(sectionDataList);
            resolve(sectionDataList);
          } else {
            setSectionDataList([]);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setSearchCompleted(true);
        });
    });
  };

  return { sectionDataList, getSectionDataList, searchCompleted, soshikiIdList, lineIntegrationError };
};
