import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { DialogButton, DialogButtonProps } from 'src/components/shien/atoms/dialogs/DialogButton';

type ComponentProps = {
  title?: string;
  message?: ReactNode;
  positiveButtonProps?: DialogButtonProps;
  negativeButtonProps?: DialogButtonProps;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmDialog = ({
  title,
  message,
  open,
  positiveButtonProps,
  negativeButtonProps,
  onClose,
  onConfirm,
}: ComponentProps) => {
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '10px' } }}>
      <DialogTitle>
        <Typography variant="headline">{title ?? '確認'}</Typography>
      </DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText variant="body1">{message}</DialogContentText>
        </DialogContent>
      )}
      <Divider />
      <DialogActions sx={{ padding: 0 }}>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} sx={{ flexGrow: 1 }}>
          <DialogButton onClick={onClose} sx={{ width: '50%', my: '8px' }} {...negativeButtonProps}>
            {negativeButtonProps?.children ?? 'キャンセル'}
          </DialogButton>
          <DialogButton
            onClick={onConfirm}
            buttonType="positive"
            sx={{ width: '50%', my: '8px' }}
            {...positiveButtonProps}
          >
            {positiveButtonProps?.children ?? 'OK'}
          </DialogButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
