import { Stack, Typography } from '@mui/material';
import { HeaderTitle } from 'src/components/shien/atoms/Header/HeaderTitle';
import { appConst } from 'src/constants/common';

type UncheckedCountProps = {
  top: number;
  bottom: number;
};

// `未確認:{未確認の人数}/{要支援者の人数}`のテキスト 未確認の人数のみ太字だったためコンポーネント化
const UncheckedCount = ({ top, bottom }: UncheckedCountProps) => {
  return (
    <Stack direction="row" alignItems="end" justifyContent="center" spacing={1}>
      <Typography variant="subtitle" component="span" sx={{ lineHeight: '16px' }}>
        {appConst.LABEL_UNCHECKED} :
      </Typography>
      <Typography variant="headline" component="span" sx={{ lineHeight: '24px' }}>
        {top}
      </Typography>
      <Typography variant="subtitle" component="span" sx={{ lineHeight: '16px' }}>
        /
      </Typography>
      <Typography variant="subtitle" component="span" sx={{ lineHeight: '16px' }}>
        {bottom} 人
      </Typography>
    </Stack>
  );
};

export const HeaderWithUncheckedCount = ({ top, bottom }: UncheckedCountProps) => {
  return <HeaderTitle headlineText="支援が必要な対象者一覧" lowerText={<UncheckedCount top={top} bottom={bottom} />} />;
};
