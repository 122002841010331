import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Button,
  ButtonProps,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { ReactNode } from 'react';

type ComponentProps = {
  title?: string;
  message?: ReactNode;
  positiveButtonProps?: ButtonProps & { children?: ReactNode };
  negativeButtonProps?: ButtonProps & { children?: ReactNode };
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmDialog = ({
  title,
  message,
  open,
  positiveButtonProps,
  negativeButtonProps,
  onClose,
  onConfirm,
}: ComponentProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h4">
        <Stack direction="row" alignItems="center" spacing={1}>
          <HelpIcon sx={{ color: colors.grey['700'] }} />
          <Box>{title ?? '確認'}</Box>
        </Stack>
      </DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText variant="h4" sx={{ px: { xs: 'none', sm: 3 } }}>
            {message}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>{negativeButtonProps?.children ?? 'いいえ'}</Button>
        <Button variant="contained" onClick={onConfirm} {...positiveButtonProps}>
          {positiveButtonProps?.children ?? 'はい'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
