import { Box, Stack, Typography, useTheme } from '@mui/material';
import { IconTick } from 'src/components/shien/atoms/images/IconTick';

// TODO: 受け取った時刻をhh:mm形式に変換する処理を追加
function formatTimeToHHMM(time: string) {
  return time;
}

export type LastUpdateTimeProps = {
  time: string;
};

export const LastUpdateTime = ({ time }: LastUpdateTimeProps) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 10,
        right: 10,
      }}
    >
      <Box>
        <IconTick />
      </Box>
      <Typography
        sx={{
          color: theme.palette.uiStandard.gray50,
          textAlign: 'right',
          fontFamily: '"Noto Sans JP"',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '14px',
        }}
      >
        {formatTimeToHHMM(time)}
      </Typography>
    </Stack>
  );
};
