import { GridRowsProp, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  PaginationModel,
  PrsGridRow,
  GridRowSpidkankei,
  GridRowSoshikiidkankei,
} from 'src/components/kanri/pages/jichitai/SelectSupportRecipientsPage/types';
import { useUserData } from 'src/hooks/common/useUserData';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';
import { ErrorResponse } from 'src/utilities/restApi/common/types/responses';
import { getSupportRecipients } from 'src/utilities/restApi/kanri/supportRecipients';

type PageInfo = {
  totalRowCount: number;
  hasNextPage: boolean;
  pageSize: number;
};

type HookParams = {
  paginationModel: PaginationModel;
  spId: string;
  sortModel?: GridSortModel;
};

export const useQuery = ({ paginationModel, spId, sortModel }: HookParams) => {
  const [controller, setController] = useState<AbortController>();
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState<SearchCallerType | undefined>('system');
  const [error, setError] = useState<SearchError>({ isError: false, reason: undefined });
  const [rows, setRows] = useState<GridRowsProp<PrsGridRow>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    totalRowCount: 0,
    hasNextPage: false,
    pageSize: 0,
  });
  useEffect(() => {
    setPageInfo({
      totalRowCount: rows.length,
      hasNextPage: false,
      pageSize: paginationModel.pageSize,
    });
  }, [rows]);

  useEffect(() => {
    search('user');
  }, [userData, paginationModel, spId, sortModel]);

  // functions
  const search = async (callerType: SearchCallerType) => {
    if (userData) {
      controller?.abort();
      setIsLoading(callerType);
      const newController = new AbortController();
      setController(newController);
      getSupportRecipients(
        sortModel && sortModel.length > 0
          ? {
              spid: spId,
              sortField: sortModel[0].field,
              sortType: sortModel[0].sort!,
            }
          : {
              spid: spId,
            }
      )
        .then((response) => {
          if (response.data) {
            const gridRows: GridRowsProp<PrsGridRow> = response.data.user_list.map((row) => ({
              id: row.prsid,
              prsId: row.prsid,
              name: row.name,
              agreement: row.agreement ?? undefined,
              prsStatusId: row.prsstatusid,
              prsStatusName: row.prsstatusname,
              prsStatusColor: row.prsstatuscolor,
              prsStatusSortOrder: row.prsstatussortorder,
              prsMemo: row.prsmemo ?? undefined,
              lastPrsStatusUpdatedAt: row.lastprsstatusupdatedat ?? undefined,
              lastVisitedAt: row.lastvisitedat ?? undefined,
              lastEvacuatedAt: row.lastevacuatedat ?? undefined,
              soshikiNames: row.soshikinames ?? undefined,
              spidkankeis: row.spidkankeis.map(
                (x) =>
                  ({
                    spId: x.spid ?? undefined,
                  } as GridRowSpidkankei)
              ),
              soshikiidkankeis: row.soshikiidkankeis.map(
                (x) =>
                  ({
                    soshikiId: x.soshikiid ?? undefined,
                  } as GridRowSoshikiidkankei)
              ),
            }));
            setRows(gridRows);
          }
        })
        .catch((response: ErrorResponse) => {
          setError({ isError: true, reason: response.error.reason });
        })
        .finally(() => {
          if (!newController.signal.aborted) {
            setIsLoading(undefined);
          }
        });
    }
  };

  return { isLoading, rows, pageInfo, error, search };
};
