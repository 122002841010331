import { AccountInfo } from '@azure/msal-common';

export const getCustomClaims = (idTokenClaims: NonNullable<AccountInfo['idTokenClaims']>) => {
  const allowedApps = (() => {
    const commaSeparatedText = idTokenClaims.extension_allowedApps as string;
    return commaSeparatedText.split(',');
  })();

  return {
    allowedApps,
  };
};
