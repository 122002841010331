import { useAtom } from 'jotai';
import { globalDataStore, userDataAtom } from 'src/jotai/atom';
import { UserData } from 'src/types/common/UserData';

export const useUserData = () => {
  const [userData, setUserData] = useAtom(userDataAtom, { store: globalDataStore });

  /**
   * 連携する or 連携解除 時の更新処理
   * @param isLinked
   * @returns boolean userDataを更新したか
   */
  const updateIsLinkedLineId = (isLinked: boolean): boolean => {
    if (!userData) {
      return false;
    }
    setUserData((prev) => ({ ...(prev as UserData), isLinkedLineUserId: isLinked }));
    return true;
  };
  return { userData, updateIsLinkedLineId };
};
