import { EventError } from '@azure/msal-browser';

const ERROR_CODE_REGEXP = /AADB2C\d{5}/;

/**
 * 以下ページより\
 * https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
 * `Array.from(document.querySelectorAll("#main > div.content > div.has-inner-focus > table > tbody > tr > td:nth-child(1) > code")).map(code => code.textContent)`
 */
export const ERROR_CODES = {
  AADB2C90001: 'AADB2C90001',
  AADB2C90002: 'AADB2C90002',
  AADB2C90006: 'AADB2C90006',
  AADB2C90007: 'AADB2C90007',
  AADB2C90008: 'AADB2C90008',
  AADB2C90010: 'AADB2C90010',
  AADB2C90011: 'AADB2C90011',
  AADB2C90012: 'AADB2C90012',
  AADB2C90013: 'AADB2C90013',
  AADB2C90014: 'AADB2C90014',
  AADB2C90016: 'AADB2C90016',
  AADB2C90017: 'AADB2C90017',
  AADB2C90018: 'AADB2C90018',
  AADB2C90019: 'AADB2C90019',
  AADB2C90021: 'AADB2C90021',
  AADB2C90022: 'AADB2C90022',
  AADB2C90023: 'AADB2C90023',
  AADB2C90025: 'AADB2C90025',
  AADB2C90027: 'AADB2C90027',
  AADB2C90028: 'AADB2C90028',
  AADB2C90031: 'AADB2C90031',
  AADB2C90035: 'AADB2C90035',
  AADB2C90036: 'AADB2C90036',
  AADB2C90037: 'AADB2C90037',
  AADB2C90039: 'AADB2C90039',
  AADB2C90040: 'AADB2C90040',
  AADB2C90043: 'AADB2C90043',
  AADB2C90044: 'AADB2C90044',
  AADB2C90046: 'AADB2C90046',
  AADB2C90047: 'AADB2C90047',
  AADB2C90048: 'AADB2C90048',
  AADB2C90051: 'AADB2C90051',
  AADB2C90052: 'AADB2C90052',
  AADB2C90053: 'AADB2C90053',
  AADB2C90054: 'AADB2C90054',
  AADB2C90055: 'AADB2C90055',
  AADB2C90057: 'AADB2C90057',
  AADB2C90058: 'AADB2C90058',
  AADB2C99059: 'AADB2C99059',
  AADB2C90067: 'AADB2C90067',
  AADB2C90068: 'AADB2C90068',
  AADB2C90073: 'AADB2C90073',
  AADB2C90075: 'AADB2C90075',
  AADB2C90077: 'AADB2C90077',
  AADB2C90079: 'AADB2C90079',
  AADB2C90080: 'AADB2C90080',
  AADB2C90081: 'AADB2C90081',
  AADB2C90083: 'AADB2C90083',
  AADB2C90084: 'AADB2C90084',
  AADB2C90085: 'AADB2C90085',
  AADB2C90086: 'AADB2C90086',
  AADB2C90087: 'AADB2C90087',
  AADB2C90088: 'AADB2C90088',
  USER_CANCELLATION: 'AADB2C90091',
  AADB2C90092: 'AADB2C90092',
  AADB2C90107: 'AADB2C90107',
  AADB2C90108: 'AADB2C90108',
  AADB2C90110: 'AADB2C90110',
  AADB2C90111: 'AADB2C90111',
  AADB2C90114: 'AADB2C90114',
  AADB2C90115: 'AADB2C90115',
  AADB2C90117: 'AADB2C90117',
  AADB2C90118: 'AADB2C90118',
  AADB2C90120: 'AADB2C90120',
  AADB2C90122: 'AADB2C90122',
  AADB2C90128: 'AADB2C90128',
  AADB2C90129: 'AADB2C90129',
  AADB2C90145: 'AADB2C90145',
  AADB2C90146: 'AADB2C90146',
  AADB2C90149: 'AADB2C90149',
  AADB2C90151: 'AADB2C90151',
  AADB2C90152: 'AADB2C90152',
  AADB2C90154: 'AADB2C90154',
  AADB2C90155: 'AADB2C90155',
  AADB2C90156: 'AADB2C90156',
  AADB2C90157: 'AADB2C90157',
  AADB2C90158: 'AADB2C90158',
  AADB2C90159: 'AADB2C90159',
  AADB2C90161: 'AADB2C90161',
  AADB2C90165: 'AADB2C90165',
  AADB2C90168: 'AADB2C90168',
  AADB2C90178: 'AADB2C90178',
  AADB2C90182: 'AADB2C90182',
  AADB2C90183: 'AADB2C90183',
  AADB2C90184: 'AADB2C90184',
  AADB2C90188: 'AADB2C90188',
  AADB2C90194: 'AADB2C90194',
  AADB2C90205: 'AADB2C90205',
  AADB2C90206: 'AADB2C90206',
  AADB2C90208: 'AADB2C90208',
  AADB2C90209: 'AADB2C90209',
  AADB2C90210: 'AADB2C90210',
  AADB2C90211: 'AADB2C90211',
  AADB2C90212: 'AADB2C90212',
  AADB2C90220: 'AADB2C90220',
  AADB2C90223: 'AADB2C90223',
  AADB2C90224: 'AADB2C90224',
  AADB2C90225: 'AADB2C90225',
  AADB2C90226: 'AADB2C90226',
  AADB2C90232: 'AADB2C90232',
  AADB2C90233: 'AADB2C90233',
  AADB2C90235: 'AADB2C90235',
  AADB2C90237: 'AADB2C90237',
  AADB2C90238: 'AADB2C90238',
  AADB2C90239: 'AADB2C90239',
  AADB2C90240: 'AADB2C90240',
  AADB2C90242: 'AADB2C90242',
  AADB2C90243: 'AADB2C90243',
  AADB2C90244: 'AADB2C90244',
  AADB2C90248: 'AADB2C90248',
  AADB2C90250: 'AADB2C90250',
  AADB2C90255: 'AADB2C90255',
  AADB2C90261: 'AADB2C90261',
  AADB2C90272: 'AADB2C90272',
  AADB2C90273: 'AADB2C90273',
  AADB2C90274: 'AADB2C90274',
  AADB2C90276: 'AADB2C90276',
  AADB2C90277: 'AADB2C90277',
  AADB2C90279: 'AADB2C90279',
  AADB2C90284: 'AADB2C90284',
  AADB2C90285: 'AADB2C90285',
  AADB2C90288: 'AADB2C90288',
  AADB2C90287: 'AADB2C90287',
  AADB2C90289: 'AADB2C90289',
  AADB2C90296: 'AADB2C90296',
  AADB2C99005: 'AADB2C99005',
  AADB2C99006: 'AADB2C99006',
  AADB2C99011: 'AADB2C99011',
  AADB2C99013: 'AADB2C99013',
  AADB2C99015: 'AADB2C99015',
  AADB2C99002: 'AADB2C99002',
  AADB2C99027: 'AADB2C99027',
  AADB2C90229: 'AADB2C90229',
} as const;

/**
 * EventErrorのメッセージからエラーコード部を取得する
 * @param error EventErrorの
 * @returns `/AADB2C\d{5}/` | ない場合は`null`
 */
export const parseErrorCode = (error: NonNullable<EventError>) => {
  const errorCode = ERROR_CODE_REGEXP.exec(error.message)?.[0];
  return errorCode ?? null;
};
