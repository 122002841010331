import { useEffect, useState } from 'react';
import { SpStatusId } from 'src/components/shien/pages/possibility/SupportPossibleSubmissionPage/types';
import { appConst } from 'src/constants/common';
import { useUserData } from 'src/hooks/common/useUserData';
import { getSupporter } from 'src/utilities/restApi/common/supporters';
import { ResponseGetSupporter } from 'src/utilities/restApi/shien/types/supporters';

export const useSpCheckStatus = () => {
  const { userData } = useUserData();
  const [selectingItem, setSelectingItem] = useState<SpStatusId>(appConst.SPCHECKID.NOT_ANSWERD);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (userData) {
      const fetch = async () => {
        try {
          const res = (await getSupporter({ spid: userData.userId })) as { data: ResponseGetSupporter };
          const spstatus = res.data.sp?.spcheckid;
          switch (spstatus) {
            case appConst.SPCHECKID.NOT_ANSWERD:
            case appConst.SPCHECKID.AVAILABLE:
            case appConst.SPCHECKID.UNABLE:
              setSelectingItem(spstatus);
              break;
            default:
              setSelectingItem(appConst.SPCHECKID.NOT_ANSWERD);
          }
        } catch (_) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };

      fetch().catch(() => {});
    }
  }, [userData]);
  return { selectingItem, setSelectingItem, loading, error };
};
