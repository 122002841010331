import { useEffect, useState } from 'react';
import { PrsStatusRow } from 'src/components/kanri/pages/jichitai/settings/StatusSettingPage/logics/statusSettingPage';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { getJianinfo } from 'src/utilities/restApi/common/jianinfo';
import { getPrsStatus } from 'src/utilities/restApi/common/prsStatus';

export const useQuery = () => {
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [operationflg, setOperationflg] = useState(false);
  const [rows, setRows] = useState<PrsStatusRow[]>();
  const navigateSystemError = useNavigateSystemError();

  const fetchPrsStatusList = async () => {
    if (userData) {
      try {
        // 自治体IDに紐づく安否ステータスを取得
        const resPrsStatus = await getPrsStatus({ jichitaiid: userData.jichitaiId });
        const resPrsStatusList = resPrsStatus.data!.prsstatus_list;
        const rows: PrsStatusRow[] = resPrsStatusList.map((row) => ({
          prsstatusid: row.prsstatusid,
          jichitaiid: row.jichitaiid,
          sortorder: row.sortorder,
          status: row.status,
          color: row.color,
        }));
        setRows(rows);

        // システム状態の稼働フラグを取得
        const responseJianInfo = await getJianinfo();
        let operationflg = false;
        if (responseJianInfo.data?.jianinfo) {
          operationflg = responseJianInfo.data.jianinfo.jianend === null;
        }
        setOperationflg(operationflg);
      } catch (e) {
        navigateSystemError();
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPrsStatusList()
      .then(() => setIsLoading(false))
      .catch(() => {});
  }, [userData]);

  return { isLoading, setIsLoading, rows, operationflg, fetchPrsStatusList };
};
