import { useCallback, useEffect, useState } from 'react';
import { Prs2, ShienKankei } from 'src/types/common/Prs2';
import { getPrsDetail } from 'src/utilities/restApi/common/prsDetail';
import { RequestGetPrsDetail } from 'src/utilities/restApi/common/types/prsDetail';

type HookParams = {
  initSearch?: boolean;
  searchParams?: RequestGetPrsDetail;
};

export const usePrs = ({ initSearch = true, searchParams }: HookParams) => {
  const [initialized, setInitialized] = useState(false);
  const [data, setData] = useState<Prs2>();

  const refreshData = useCallback(async (params: RequestGetPrsDetail) => {
    return new Promise<Prs2>((resolve, reject) => {
      getPrsDetail(params)
        .then((response) => {
          if (response.data?.prsDetail) {
            const prsDetail = response.data.prsDetail;
            const newData = {
              prsId: prsDetail.prsid,
              evaPrsId: prsDetail.evaprsid,
              fName: prsDetail.fname,
              sName: prsDetail.sname,
              kfName: prsDetail.kfname,
              ksName: prsDetail.ksname,
              sex: prsDetail.sex ?? undefined,
              birthday: prsDetail.birthday ?? undefined,
              telNo: prsDetail.telno ?? undefined,
              address: prsDetail.address ?? undefined,
              subAddress: prsDetail.subaddress ?? undefined,
              agreement: prsDetail.agreement ?? undefined,
              prsKubun: prsDetail.prskubun ?? undefined,
              familyStructure: prsDetail.family_structure ?? undefined,
              solitudeCohabitation: prsDetail.solitude_cohabitation ?? undefined,
              primaryDoctor: prsDetail.primary_doctor ?? undefined,
              physicalDisabilityCertificate: prsDetail.physical_disability_certificate ?? undefined,
              rehabilitationNotebook: prsDetail.rehabilitation_notebook ?? undefined,
              mentalDisabilityHealthAndWelfareHandbook:
                prsDetail.mental_disability_health_and_welfare_handbook ?? undefined,
              nursingCareLevel: prsDetail.nursing_care_level ?? undefined,
              intractableDisease: prsDetail.intractable_disease ?? undefined,
              emergencyContactName: prsDetail.emergency_contact_name ?? undefined,
              emergencyContactRelationship: prsDetail.emergency_contact_relationship ?? undefined,
              emergencyContactAddress: prsDetail.emergency_contact_address ?? undefined,
              emergencyContactPhoneNumber: prsDetail.emergency_contact_phone_number ?? undefined,
              supporterRemarks: prsDetail.supporter_remarks ?? undefined,
              prsStatusId: prsDetail.prsstatusid,
              prsStatusName: prsDetail.prsstatusname,
              prsStatusColor: prsDetail.prsstatuscolor,
              prsStatusSortOrder: prsDetail.prsstatussortorder,
              shelterTypeCode: prsDetail.shelter_type_code ?? undefined,
              shelterId: prsDetail.shelterid ?? undefined,
              shelterName: prsDetail.sheltername ?? undefined,
              customShelterName: prsDetail.custom_shelter_name ?? undefined,
              prsMemo: prsDetail.prsmemo ?? undefined,
              jichitaiId: prsDetail.jichitaiid,
              jichitaiName: prsDetail.jichitainame,
              lastPrsStatusUpdatedAt: prsDetail.lastprsstatusupdatedat ?? undefined,
              lastCalledAt: prsDetail.lastcalledat ?? undefined,
              lastVisitedAt: prsDetail.lastvisitedat ?? undefined,
              lastEvacuatedAt: prsDetail.lastevacuatedat ?? undefined,
              delFlg: prsDetail.delflag,
              soshiki: prsDetail.soshiki.map((x) => ({ soshikiId: x.soshikiid, soshikiName: x.soshikiname })),
              shienKankei: prsDetail.shienkankei.map(
                (item) =>
                  ({
                    shienKankeiId: item.shienkankeiid,
                    spId: item.spid ?? undefined,
                    soshikiId: item.soshikiid ?? undefined,
                  } as ShienKankei)
              ),
              plannedShelters: prsDetail.planned_shelters.map((x) => {
                const customShelterAddress =
                  x.custom_shelter_address1 === null && x.custom_shelter_address1 === null
                    ? undefined
                    : [x.custom_shelter_address1, x.custom_shelter_address2].join(' ').trim();
                return {
                  disasterTypeNumber: x.disaster_type_number,
                  disasterTypeName: x.disaster_type_name,
                  shelterTypeCode: x.shelter_type_code ?? undefined,
                  shelterId: x.shelterid ?? undefined,
                  shelterName: x.shelter_name ?? undefined,
                  shelterAddress: x.shelter_address ?? undefined,
                  customShelterName: x.custom_shelter_name ?? undefined,
                  customShelterAddress,
                };
              }),
            } as Prs2;

            setData(newData);
            resolve(newData);
          }
        })
        .catch((error) => reject(error))
        .finally(() => setInitialized(true));
    });
  }, []);

  useEffect(() => {
    if (initSearch && searchParams) {
      refreshData(searchParams);
    }
  }, []);

  return { data, refreshData, initialized };
};
