type ComponentProps = {
  fill?: string;
};

export const IconYes = ({ fill = '#147942' }: ComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 68 68" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3961 53.6C45.0041 53.6 53.5961 45.008 53.5961 34.4C53.5961 23.792 45.0041 15.2 34.3961 15.2C23.7881 15.2 15.1961 23.792 15.1961 34.4C15.1961 45.008 23.7881 53.6 34.3961 53.6ZM34.3961 60.8C48.9761 60.8 60.7961 48.98 60.7961 34.4C60.7961 19.82 48.9761 8 34.3961 8C19.8161 8 7.99609 19.82 7.99609 34.4C7.99609 48.98 19.8161 60.8 34.3961 60.8Z"
        fill={fill}
      />
    </svg>
  );
};
