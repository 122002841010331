import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { useInitialUrlByUser } from 'src/hooks/common/useInitialUrlByUser';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { routerPaths } from 'src/routes/path';
import { isAppAllowed } from 'src/utilities/azure/checkAuth';
import { ERROR_CODES, parseErrorCode } from 'src/utilities/azure/handleEvents';
import { useUserDetails } from 'src/utilities/azure/hooks/useUserDetails';
import { updateJotai } from 'src/utilities/azure/jotai';
import { logout } from 'src/utilities/azure/signout';

/**
 * Azure AD B2Cでの認証結果をハンドルするページ
 */
export const AzureCallbackPage = () => {
  const { accounts, instance: pca } = useMsal();
  const { details: userDetails, error: userDetailsError } = useUserDetails(accounts[0]);
  const { url: initialUrl, error: initialUrlError } = useInitialUrlByUser(userDetails);
  /** トークン取得成功 or 失敗 を起こさないハンドル結果を補足するためのフラグ */
  const hasGotLoginResultRef = useRef(false);
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();

  // Effects
  // AADB2Cエラー処理
  useEffect(() => {
    // MSALがインタラクション中にログイン処理等を行うと警告が発生するためmsal:handleRedirectEnd後にハンドル処理を行う
    let azureErrorCode: null | string;
    const callbackId = pca.addEventCallback((message) => {
      switch (message.eventType) {
        // fallthrough
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          hasGotLoginResultRef.current = true;
          break;

        // fallthrough
        case EventType.LOGIN_FAILURE:
        case EventType.ACQUIRE_TOKEN_FAILURE: {
          hasGotLoginResultRef.current = true;
          const errorCode = parseErrorCode(message.error!);
          azureErrorCode = errorCode;
          break;
        }

        case EventType.HANDLE_REDIRECT_END:
          /* トークン取得手続き未開始の状態でアクセスされた場合 */
          if (!hasGotLoginResultRef.current) {
            navigateSystemError();
            break;
          }

          // トークン取得エラー結果がある場合
          if (azureErrorCode !== undefined) {
            switch (azureErrorCode) {
              // キャンセル操作
              case ERROR_CODES.USER_CANCELLATION:
                navigate(routerPaths.index);
                break;
              default:
                navigateSystemError();
            }
          }
          break;
      }
    });
    return () => {
      if (callbackId !== null) {
        pca.removeEventCallback(callbackId);
      }
    };
  }, [pca]);

  useEffect(() => {
    if (accounts[0] == null || userDetails == null) return;

    const account = accounts[0];

    // allowedAppsの検証
    if (!isAppAllowed(account)) {
      logout(pca).catch(() => {
        navigateSystemError();
      });
      navigateSystemError();
    }

    updateJotai(userDetails);
  }, [accounts, userDetails]);

  useEffect(() => {
    if (initialUrl) {
      navigate(initialUrl);
    }
  }, [initialUrl]);

  useEffect(() => {
    if (userDetailsError || initialUrlError) {
      navigateSystemError();
    }
  }, [userDetailsError, initialUrlError]);

  return <PageContainer title="リダイレクト" />;
};
