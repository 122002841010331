export const IconCaution = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#B33D35"
      />
      <path
        d="M12.5 6H11.5C11.2239 6 11 6.22386 11 6.5V13.5C11 13.7761 11.2239 14 11.5 14H12.5C12.7761 14 13 13.7761 13 13.5V6.5C13 6.22386 12.7761 6 12.5 6Z"
        fill="white"
      />
      <path
        d="M12.5 16H11.5C11.2239 16 11 16.2239 11 16.5V17.5C11 17.7761 11.2239 18 11.5 18H12.5C12.7761 18 13 17.7761 13 17.5V16.5C13 16.2239 12.7761 16 12.5 16Z"
        fill="white"
      />
    </svg>
  );
};
