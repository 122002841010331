import { Container, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { EditMemoButton } from 'src/components/shien/atoms/buttons/SplitButton/EditMemoButton';
import { EditShelterButton } from 'src/components/shien/atoms/buttons/SplitButton/EditShelter';
import { SmallStatusButton } from 'src/components/shien/atoms/buttons/SplitButton/SmallStatusButton';
import { IconCaution } from 'src/components/shien/atoms/images/IconCaution';
import { PrsMemoEditDialog } from 'src/components/shien/pages/prs/components/PrsMemoEditDialog';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { HeaderWithBackButtonTemplate } from 'src/components/shien/templates/HeaderWithBackButtonTemplate';
import { appConst } from 'src/constants/common';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { determineShelterName } from 'src/utilities/appUtil/commonUtil';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';

export const PrsSafetySummary = () => {
  // context
  const { prs, refreshData, initialized } = useContext(PrsContext);
  // state
  const { setShowBackdrop } = useBackdrop();
  const [memoDialogOpen, setMemoDialogOpen] = useState(false);

  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();

  return (
    <PageContainer title="安否サマリ">
      <HeaderWithBackButtonTemplate
        headlineText={(initialized && '安否を登録') || <AppSkeleton width="200px" />}
        upperText={(initialized && `${prs?.fName} ${prs?.sName}`) || <AppSkeleton width="100px" />}
        onBackClick={() => navigate('/app/shien/prs/summary')}
      >
        <Container sx={{ py: 1 }}>
          <Stack spacing={2}>
            {(initialized && (
              <>
                <SmallStatusButton
                  statusText={prs?.prsStatusName ?? ''}
                  icon={prs?.prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED && <IconCaution />}
                  upperColor={prs?.prsStatusColor}
                  buttonText="安否を変更する"
                  onClick={() =>
                    navigate('/app/shien/prs/register-safety', {
                      state: { prsSafetyRegistrationFlowType: 'change' },
                    })
                  }
                />
                <EditShelterButton
                  shelterName={determineShelterName(
                    prs?.prsStatusId || '',
                    prs?.shelterTypeCode,
                    prs?.shelterName || '',
                    prs?.customShelterName || ''
                  )}
                  disabled={prs?.prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED}
                  onClick={() => {
                    navigate('/app/shien/prs/register-shelter-type', {
                      state: { prsSafetyRegistrationFlowType: 'change' },
                    });
                  }}
                />
                <EditMemoButton memoText={prs?.prsMemo} onClick={() => setMemoDialogOpen(true)} />
              </>
            )) || (
              <>
                <AppSkeleton height="120px" />
                <AppSkeleton height="120px" />
                <AppSkeleton height="120px" />
              </>
            )}
          </Stack>
        </Container>
      </HeaderWithBackButtonTemplate>
      <PrsMemoEditDialog
        prs={prs}
        dialogOpen={memoDialogOpen}
        onClose={({ reason, text }) => {
          // 変更がある場合登録APIを呼ぶ
          if (reason === 'registerButtonClick' && text !== prs?.prsMemo) {
            setShowBackdrop(true);
            (async () => {
              try {
                await patchPrsDetail({ prsid: prs!.prsId, prsmemo: text === '' ? null : text ?? null });
                await refreshData();
                setMemoDialogOpen(false);
              } catch {
                navigateSystemError();
              } finally {
                setShowBackdrop(false);
              }
            })();
          } else {
            setMemoDialogOpen(false);
          }
        }}
      ></PrsMemoEditDialog>
    </PageContainer>
  );
};
