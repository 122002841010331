import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';

type ComponentProps = {
  children: ReactNode;
  headline: ReactNode;
  description: ReactNode;
  caption?: ReactNode;
  primary?: { text: string; onClick: () => void; disabled?: boolean };
  secondary?: { text: string; onClick: () => void; disabled?: boolean };
};

export const ButtonPageTemplate = ({
  children,
  headline,
  description,
  caption,
  primary,
  secondary,
}: ComponentProps) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ height: '100%', paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) }}>
      <Stack alignItems="center" sx={{ height: '100%' }}>
        <Box marginTop="36px">
          <Typography variant="headline">{headline}</Typography>
        </Box>
        <Box marginTop="30px">
          <Typography variant="body1">{description}</Typography>
        </Box>
        <StackSpacer />
        {children}
        <StackSpacer />
        {caption && (
          <Box>
            <Typography variant="caption">{caption}</Typography>
          </Box>
        )}
        {(primary || secondary) && (
          <Stack spacing={2} sx={{ width: '100%' }}>
            {primary && (
              <Button variant="primary" onClick={() => primary.onClick()} disabled={primary.disabled} fullWidth>
                {primary.text}
              </Button>
            )}
            {secondary && (
              <Button variant="secondary" onClick={() => secondary.onClick()} disabled={secondary.disabled} fullWidth>
                {secondary.text}
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Container>
  );
};
