export const IconRun = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M36.35 32H32V25.92C32 25.5 31.91 25.09 31.75 24.71L28.66 17.69H30.71L32.69 21.53C33.11 22.34 33.82 22.93 34.69 23.2C35.56 23.46 36.48 23.37 37.28 22.93C38.88 22.05 39.47 20.09 38.64 18.47L35.35 12.1C35.01 11.43 34.32 11.02 33.57 11.02H21.24C21.72 10.3 22 9.44002 22 8.52002C22 6.04002 19.98 4.02002 17.5 4.02002C15.02 4.02002 13 6.04002 13 8.52002C13 11 14.94 12.92 17.35 13L13.88 18.33H9.34C7.5 18.33 6 19.83 6 21.67C6 23.51 7.5 25.01 9.34 25.01H16.4C17.09 25.01 17.72 24.66 18.09 24.08L19.01 22.62L20.03 25.21L9.99 42.05L12.19 43.36C12.91 43.79 13.73 44.01 14.56 44.01C16.19 44.01 17.73 43.14 18.56 41.74L24.99 31.06V37C24.99 38.1 25.89 39 26.99 39H40.99V36.65C40.99 34.09 38.91 32 36.34 32H36.35ZM15 8.50002C15 7.12002 16.12 6.00002 17.5 6.00002C18.88 6.00002 20 7.12002 20 8.50002C20 9.88002 18.88 11 17.5 11C16.12 11 15 9.88002 15 8.50002ZM39 37H27V24.97H26.32L16.85 40.7C16.13 41.9 14.44 42.34 13.22 41.62L12.74 41.33L22.25 25.37L19.43 18.2L16.4 22.99H9.34C8.6 22.99 8 22.39 8 21.65C8 20.91 8.6 20.31 9.34 20.31H14.96L19.42 13.46C19.61 13.18 19.92 13 20.26 13H33.56L36.85 19.37C37.19 20.02 36.95 20.81 36.3 21.16C35.98 21.34 35.61 21.38 35.26 21.27C34.91 21.16 34.62 20.93 34.45 20.6L31.91 15.68H25.58L29.91 25.51C29.97 25.64 29.99 25.77 29.99 25.91V33.99H36.34C37.8 33.99 38.99 35.18 38.99 36.64V36.99L39 37Z"
        fill="black"
      />
    </svg>
  );
};
