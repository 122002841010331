import { BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconHand } from 'src/components/shien/atoms/images/FooterIcons/IconHand';
import { IconHandFull } from 'src/components/shien/atoms/images/FooterIcons/IconHandFull';
import { IconList } from 'src/components/shien/atoms/images/FooterIcons/IconList';
import { IconListFull } from 'src/components/shien/atoms/images/FooterIcons/IconListFull';
import { IconPerson } from 'src/components/shien/atoms/images/FooterIcons/IconPerson';
import { IconPersonFull } from 'src/components/shien/atoms/images/FooterIcons/IconPersonFull';

// TODO:対応するpathを追加
const navigationItems = {
  supporter: [
    {
      text: '協力の可否',
      selectedIcon: <IconHandFull />,
      unselectedIcon: <IconHand />,
      path: '/app/shien/possibility/question',
      state: { from: 'Footer' },
    },
    {
      text: '対象者一覧',
      selectedIcon: <IconListFull />,
      unselectedIcon: <IconList />,
      path: '/app/shien/prs-list',
    },
    {
      text: 'あなた',
      selectedIcon: <IconPersonFull />,
      unselectedIcon: <IconPerson />,
      path: '/app/shien/profile',
    },
  ],
};

type FooterProps = {
  mode: 'supporter';
  selected?: '協力の可否' | '対象者一覧' | 'あなた';
  disabled?: boolean;
};
export const Footer = ({ mode, selected, disabled }: FooterProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (path: string, state?: string) => {
    if (!disabled) navigate(path, { state });
  };

  return (
    <BottomNavigation
      value={selected}
      showLabels
      // TODO:opacityによる非活性は。現在背景が白いので問題ないが、避けたい
      sx={{
        padding: '9px',
        borderTop: `1px solid ${theme.palette.uiStandard.gray25}`,
        opacity: !disabled ? 1 : 0.3,
        pointerEvents: !disabled ? 'auto' : 'none',
      }}
    >
      {navigationItems[mode].map((item) => (
        <BottomNavigationAction
          key={item.text}
          label={item.text}
          value={item.text}
          icon={selected === item.text ? item.selectedIcon : item.unselectedIcon}
          onClick={() => handleNavigate(item.path, item.state?.from)}
        />
      ))}
    </BottomNavigation>
  );
};
