import { Box, Stack, useTheme } from '@mui/material';
import { IconCaution } from 'src/components/shien/atoms/images/IconCaution';

// NOTE:未確認時のみラベルを透過する必要があったため別途用意
export const UncheckedLabel = () => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Box>
        <IconCaution />
      </Box>
      <Box sx={{ fontSize: '16px', fontWeight: 'bold', color: theme.palette.uiStandard.red50 }}>未確認</Box>
    </Stack>
  );
};
