import { Box, Button, Container, Paper, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { ConfirmDialog } from 'src/components/kanri/atoms/ConfirmDialog';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import { StatusChip } from 'src/components/kanri/atoms/StatusChip';
import { useKanriPageBackdrop } from 'src/hooks/common/backdrops';
import { useJianInfo } from 'src/hooks/common/useJianInfo';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { StatusColor } from 'src/types/common/statusColor';
import {
  deletePrsStatus,
  patchPrsStatus,
  postPrsStatus,
  PRSSTATUSID_IS_USED,
} from 'src/utilities/restApi/common/prsStatus';
import { ErrorResponse } from 'src/utilities/restApi/common/types/responses';
import { ImageButton } from './components/imageButton';
import { EditDialog } from './components/prsStatusEditDialog';
import { PrsStatusRow, setNewRowData, setRequestData, setRequestQuery } from './logics/statusSettingPage';
import { useQuery } from './useQuery';

const ICON_BUTTON_COLUMN_WIDTH = '40px';

export const StatusSettingPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setShowBackdrop } = useKanriPageBackdrop();
  const navigateSystemError = useNavigateSystemError();
  const { jianAllDone } = useJianInfo();
  const { userData } = useUserData();
  const { isLoading, setIsLoading, rows, operationflg, fetchPrsStatusList } = useQuery();
  const [maxCountError, setMaxCountError] = useState<string>('');
  // 新規＆編集ボタン
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState<PrsStatusRow>(setNewRowData());

  // 削除ボタン用

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setShowBackdrop(isLoading);
    return () => setShowBackdrop(false);
  }, [isLoading]);

  const handleEditDialog = (data: PrsStatusRow | null) => {
    if (data === null) {
      // 新規作成の場合、データは空
      setEditData(setNewRowData(userData?.jichitaiId));
      if (rows && rows.length >= 10) {
        setMaxCountError('登録できる安否状況は10個までです。');
        return;
      }
    } else {
      // 編集するデータをセット
      setEditData(data);
    }
    setEditDialogOpen(true);
  };

  // DBの登録&更新処理
  const handleSave = async (newData: PrsStatusRow) => {
    if (userData) {
      try {
        setIsLoading(true);
        if (editData.prsstatusid === '') {
          await postPrsStatus(setRequestData(newData));
          await fetchPrsStatusList();
        } else {
          await patchPrsStatus(setRequestQuery(newData), setRequestData(newData));
          await fetchPrsStatusList();
        }
      } catch {
        navigateSystemError();
      } finally {
        setShowBackdrop(false);
        setIsLoading(false);
      }
    }
  };

  const handleCloseEdit = () => {
    setEditData(setNewRowData());
    setEditDialogOpen(false);
  };

  const handleDelete = (data: PrsStatusRow) => {
    setEditData(data);
    setDialogOpen(true);
  };

  return (
    <PageContainer title="安否状況設定">
      <KanriPageContainer title="安否状況設定">
        <Container sx={{ height: '100%' }}>
          <Stack spacing={2} sx={{ height: '100%' }}>
            {/* 追加するボタン行 */}
            <Stack direction="row" alignItems="center" spacing={2}>
              <StackSpacer />
              {maxCountError && <Box sx={{ color: 'red' }}>{maxCountError}</Box>}
              <Box>
                <Button
                  variant="contained"
                  fullWidth={true}
                  disabled={
                    operationflg || maxCountError !== '' || jianAllDone() === undefined || jianAllDone() === false
                  }
                  onClick={() => handleEditDialog(null)}
                >
                  追加する
                </Button>
              </Box>
            </Stack>
            {/* 一覧 */}
            <Paper variant="outlined" sx={{ px: 1, flexGrow: 1, overflow: 'auto' }}>
              <Table sx={{}} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width="60px" align="center">
                      並び順
                    </TableCell>
                    <TableCell align="center">名称</TableCell>
                    <TableCell align="center">表示イメージ</TableCell>
                    {jianAllDone() && (
                      <>
                        <TableCell align="center" sx={{ width: ICON_BUTTON_COLUMN_WIDTH }}>
                          {/* 編集アイコン用 */}
                        </TableCell>
                        <TableCell align="center" sx={{ width: ICON_BUTTON_COLUMN_WIDTH }}>
                          {/* 削除アイコン用 */}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody id="statusTableBody">
                  {rows?.map((row) => (
                    <TableRow key={row.prsstatusid}>
                      <TableCell align="center">{row.sortorder}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">
                        {row.status ? (
                          <StatusChip
                            prsStatusId={row.prsstatusid}
                            label={row.status}
                            color={row.color as StatusColor}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      {jianAllDone() && (
                        <>
                          <TableCell>
                            <ImageButton
                              isEditButton={true}
                              onClick={() => handleEditDialog(row)}
                              disabled={operationflg}
                              activeData={row}
                            />
                          </TableCell>
                          <TableCell>
                            <ImageButton
                              isEditButton={false}
                              onClick={() => handleDelete(row)}
                              disabled={operationflg}
                              activeData={row}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Stack>
        </Container>
      </KanriPageContainer>
      <EditDialog isOpen={editDialogOpen} onClose={handleCloseEdit} onSave={handleSave} initialData={editData} />
      <ConfirmDialog
        message="削除してもよろしいですか？"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          if (userData) {
            setIsLoading(true);
            setDialogOpen(false);

            deletePrsStatus(setRequestQuery(editData))
              .then(() => {
                // エラー処理は内部で行っている
                fetchPrsStatusList()
                  .then(() => setMaxCountError(''))
                  .finally(() => {
                    setIsLoading(false);
                    enqueueSnackbar('安否状況を削除しました。', { variant: 'success' });
                  });
              })
              .catch((response: ErrorResponse) => {
                if (response.error.reason === PRSSTATUSID_IS_USED) {
                  enqueueSnackbar(
                    <>
                      削除しようとした安否状況は要支援者に登録されています。
                      <br />
                      リセットしてから再度削除してください。
                    </>,
                    { variant: 'error' }
                  );
                  setIsLoading(false);
                } else {
                  navigateSystemError();
                }
              });
          }
        }}
      />
    </PageContainer>
  );
};
