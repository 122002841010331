const IconHandWrapperStyle = {
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const IconHandFull = () => {
  return (
    <div style={IconHandWrapperStyle}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19" fill="none">
        <path
          d="M25 1V13C25 13.55 25.45 14 26 14H28.5C29.33 14 30 13.33 30 12.5V1.5C30 0.67 29.33 0 28.5 0H26C25.45 0 25 0.45 25 1Z"
          fill="black"
        />
        <path
          d="M4 0H1.5C0.67 0 0 0.67 0 1.5V12.5C0 13.33 0.67 14 1.5 14H4C4.55 14 5 13.55 5 13V1C5 0.45 4.55 0 4 0Z"
          fill="black"
        />
        <path
          d="M16.14 9.87L18.14 7.87L14.19 1.69C13.91 1.26 13.44 1 12.93 1H7V12.01L11.22 7.79C11.75 7.26 12.58 7.2 13.18 7.65L16.14 9.87Z"
          fill="black"
        />
        <path
          d="M20.12 8.72001L16.94 11.9C16.59 12.25 16.03 12.29 15.63 11.99L12.32 9.51001L7.99999 13.83C7.89999 13.93 7.89999 14.08 7.99999 14.18L8.98999 15.23C9.08999 15.33 9.24999 15.33 9.34999 15.23L11.82 12.76C11.92 12.66 12.08 12.66 12.17 12.76L12.88 13.47C12.98 13.57 12.98 13.73 12.88 13.82L10.37 16.33C10.27 16.43 10.27 16.58 10.37 16.68L11.37 17.73C11.47 17.83 11.63 17.83 11.73 17.73L14.26 15.2C14.36 15.1 14.52 15.1 14.61 15.2L15.32 15.91C15.42 16.01 15.42 16.17 15.32 16.26L13 18.58C12.84 18.74 12.95 19.01 13.18 19.01H16.58C17.11 19.01 17.62 18.8 17.99 18.43L22.99 13.43V2.01001H16.75L20.24 7.48001C20.49 7.88001 20.44 8.39001 20.1 8.73001L20.12 8.72001Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
