import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestGetUser, ResponseGetUser } from 'src/utilities/restApi/common/types/users';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/users';
export const getUser = async (params: RequestGetUser) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetUser>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetUser>>(`${url}/${params.userid}`, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject('Unknown error');
        }
      });
  });
};
