import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Dialog, Divider, Input, Stack, Typography } from '@mui/material';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { AppFullSizeInput } from 'src/components/shien/atoms/AppFullSizeInput';
import { HeaderTitleProps } from 'src/components/shien/atoms/Header/HeaderTitle';
import { TransitionUp } from 'src/components/shien/atoms/TransitionUp';
import { HeaderWithRegisterButtonTemplate } from 'src/components/shien/templates/HeaderWithRegisterButtonTemplate';
import * as yup from 'yup';

export type Reason = 'cancelButtonClick' | 'registerButtonClick';

type ComponentProps = HeaderTitleProps & {
  pageTitle: string;
  description?: ReactNode;
  text?: string;
  max: number;
  open: boolean;
  multiline: boolean;
  isRequired: boolean;
  onClose: ({ reason, text }: { reason: Reason; text?: string }) => void;
};

type FormValues = {
  text?: string;
};

export const TextEditDialog: FC<ComponentProps> = ({
  pageTitle,
  headlineText,
  upperText,
  lowerText,
  description,
  text,
  max,
  open,
  multiline,
  isRequired,
  onClose,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  // validation
  const schema: yup.ObjectSchema<FormValues> = isRequired
    ? yup.object({
        text: yup.string().label('文字').max(max).required('入力は必須です'),
      })
    : yup.object({
        text: yup.string().label('文字').max(max),
      });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      text: undefined,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (open) {
      reset();
      setValue('text', text);
    }
  }, [open]);

  return (
    <Dialog disableRestoreFocus fullScreen open={open} TransitionComponent={TransitionUp}>
      <PageContainer title={`${pageTitle}`}>
        <form
          ref={formRef}
          onSubmit={handleSubmit(() => {
            onClose({ reason: 'registerButtonClick', text: getValues('text') });
          })}
          style={{ height: '100%' }}
        >
          <HeaderWithRegisterButtonTemplate
            headlineText={headlineText}
            upperText={upperText}
            lowerText={lowerText}
            onCancelClick={() => {
              onClose({ reason: 'cancelButtonClick' });
            }}
            onRegisterClick={() => {
              formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }}
          >
            <Stack sx={{ height: '100%' }}>
              {multiline ? (
                <>
                  {description && (
                    <>
                      <Container sx={{ py: 1 }}>
                        <Typography variant="body1">{description}</Typography>
                        {errors.text && <Typography color="error">{errors.text.message}</Typography>}
                      </Container>
                      <Divider />
                    </>
                  )}
                  <Stack flexGrow={1}>
                    <Controller
                      name="text"
                      control={control}
                      render={({ field }) => <AppFullSizeInput {...field} autoFocus error={'text' in errors} />}
                    />
                  </Stack>
                </>
              ) : (
                <>
                  <Stack flexGrow={1} sx={{ mt: 3, p: 4 }}>
                    <Controller
                      name="text"
                      control={control}
                      render={({ field }) => <Input {...field} autoFocus error={'text' in errors} />}
                    />
                    {description && (
                      <>
                        <Divider />
                        <Container sx={{ py: 1 }}>
                          <Typography variant="body1">{description}</Typography>
                          {errors.text && <Typography color="error">{errors.text.message}</Typography>}
                        </Container>
                      </>
                    )}
                  </Stack>
                </>
              )}
            </Stack>
          </HeaderWithRegisterButtonTemplate>
        </form>
      </PageContainer>
    </Dialog>
  );
};
