import { Typography } from '@mui/material';
import { Template } from './Template';

type EditMemoButton = {
  memoText?: string;
  onClick: () => void;
  disabled?: boolean;
};

export const EditMemoButton = ({ memoText = '', onClick, disabled = false }: EditMemoButton) => {
  return (
    <Template buttonText={memoText ? 'メモを変更する' : 'メモを登録する'} onClick={onClick} disabled={disabled}>
      {memoText && (
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '20px',
            wordBreak: 'break-all',
            whiteSpace: 'pre-wrap',
          }}
        >
          {memoText}
        </Typography>
      )}
    </Template>
  );
};
