import { useEffect, useState } from 'react';
import { RoleId } from 'src/constants/common';
import { useJianInfo } from 'src/hooks/common/useJianInfo';
import { useSupporterDetails } from 'src/hooks/common/useSupporterDetails';
import { getInitialUrlByUser } from 'src/routes/conditionalRedirect';
import { ResponseGetUser } from 'src/utilities/restApi/common/types/users';

export const useInitialUrlByUser = (userDetails: undefined | ResponseGetUser['user']) => {
  const [spId, setSpId] = useState<string>();
  const {
    details: supporterDetails,
    error: supporterDetailsError,
    loading: supporterDetailsLoading,
  } = useSupporterDetails({ spId });
  const { soshikis: ongoingSoshikis } = useJianInfo();
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(true);

  // ログイン状態に応じた処理
  useEffect(() => {
    // [認可済みユーザーあり]
    if (userDetails != null && ongoingSoshikis != null) {
      setLoading(true);
      // 事案開始中且つ支援者の場合は追加で支援者情報を取得する
      const ongoing = ongoingSoshikis
        .map((x) => x.soshikiid)
        .some((ongoingId) => userDetails.soshikiids.includes(ongoingId));
      if (ongoing && userDetails.roleid === RoleId.Supporter) {
        if (spId == null) {
          // 取得開始
          setSpId(userDetails.userid);
          return;
        } else if (supporterDetails == null) {
          // 取得中
          return;
        }
      }

      const redirectUrl = getInitialUrlByUser(userDetails.roleid, ongoing, supporterDetails?.spcheckid);
      // 未実装画面の場合null
      if (redirectUrl !== null) {
        setUrl(redirectUrl);
      }

      setLoading(false);
    }
  }, [userDetails, ongoingSoshikis, spId, supporterDetails]);

  useEffect(() => {
    if (supporterDetailsLoading) {
      setLoading(true);
    }
  }, [supporterDetailsLoading]);

  return {
    url,
    error: supporterDetailsError,
    loading,
  };
};
