import { Box, Link } from '@mui/material';
import { ReactNode, useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { AppListSectionData } from 'src/types/common/AppListSectionData';
import { getSupporter } from 'src/utilities/restApi/common/supporters';

const suspendDataList = [
  {
    header: '他の支援者',
    items: [
      { title: '氏名', value: <AppSkeleton /> },
      { title: '氏名カナ', value: <AppSkeleton /> },
      { title: '連絡先', value: <AppSkeleton /> },
    ],
  },
] as AppListSectionData[];

export const useOtherSupportersSectionDataList = (sectionLabel: string | undefined = '支援者') => {
  const [sectionDataList, setSectionDataList] = useState<AppListSectionData[]>(suspendDataList);

  const getSectionDataList = (
    spIds: string[],
    clickPhoneNumberCallback: (phoneNumber: string, message: ReactNode) => void
  ) => {
    return new Promise<AppListSectionData[]>((resolve, reject) => {
      if (spIds.length === 0) {
        setSectionDataList([]);
        resolve([]);
      } else {
        Promise.all(spIds.map((x) => getSupporter({ spid: x })))
          .then((responses) => {
            const _sectionDataList = [] as AppListSectionData[];
            responses.forEach((response, index, list) => {
              if (response.data?.sp) {
                _sectionDataList.push({
                  header: `${sectionLabel}${list.length > 1 ? index + 1 : ''}`,
                  items: [
                    { title: '氏名', value: `${response.data.sp.fname} ${response.data.sp.sname}` },
                    { title: '氏名カナ', value: `${response.data.sp.kfname} ${response.data.sp.ksname}` },
                    {
                      title: '連絡先',
                      value: response.data.sp.telno && (
                        <>
                          <Link
                            display={{ xs: 'block', sm: 'none' }}
                            onClick={() =>
                              clickPhoneNumberCallback(response.data!.sp!.telno!, <>支援者に電話をかけますか？</>)
                            }
                            sx={{ cursor: 'pointer' }}
                          >
                            {response.data.sp.telno}
                          </Link>
                          <Box display={{ xs: 'none', sm: 'block' }}>{response.data.sp.telno}</Box>
                        </>
                      ),
                    },
                  ],
                });
              }
            });

            setSectionDataList(_sectionDataList);
            resolve(_sectionDataList);
          })
          .catch((error) => reject(error));
      }
    });
  };

  const clearSectionDataList = () => {
    setSectionDataList(suspendDataList);
  };

  return { sectionDataList, getSectionDataList, clearSectionDataList };
};
