import { useMsal } from '@azure/msal-react';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  colors,
  Divider,
  Drawer,
  IconButton,
  Stack,
  styled,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { JichitaiMenuList } from 'src/routes/kanri/jichitai/JichitaiMenu/JichitaiMenuList';
import { logout } from 'src/utilities/azure/signout';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#dddddd',
  color: colors.grey[900],
  zIndex: theme.zIndex.drawer + 1,
}));

const drawerWidth = 240;

type ComponentProps = {
  window?: () => Window;
};

export const JichitaiTemplate = ({ window }: ComponentProps) => {
  const { instance: pca } = useMsal();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  // handler
  const handleLogout = () => {
    logout(pca)
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        navigateSystemError();
      });
  };

  const drawer = (
    <Stack height="100%">
      <Toolbar variant="dense" />
      <Stack direction="column" sx={{ overflow: 'auto', px: '10px', flexGrow: 1, pb: 1 }}>
        <JichitaiMenuList menuOnClick={() => setMobileOpen(false)} />
        <StackSpacer />
        <Stack sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Divider />
          <Button fullWidth sx={{ mt: 1 }} onClick={handleLogout}>
            ログアウト
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <StyledAppBar position="sticky" elevation={0}>
        <Toolbar variant="dense">
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <StackSpacer />
          <Stack direction="row" alignItems="center" spacing={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Stack direction="row" alignItems="center">
              <Tooltip title="ログアウト">
                <IconButton onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Toolbar>
      </StyledAppBar>
      <Stack direction="row" height="100%" sx={{ overflow: 'auto' }}>
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            overflow: 'auto',
            width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </>
  );
};
