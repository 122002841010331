import { Chip } from '@mui/material';
import { FC } from 'react';
import { appConst } from 'src/constants/common';
import { getStatusContrastText, getUiStandardColor, StatusColor } from 'src/types/common/statusColor';

type ComponentProps = {
  prsStatusId: string;
  label: string;
  color: StatusColor;
};

export const StatusChip: FC<ComponentProps> = ({ prsStatusId, label, color }) => {
  const uiStatndardColor = getUiStandardColor(color);

  return label ? (
    <Chip
      size="small"
      variant={prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED ? 'outlined' : 'filled'}
      label={label}
      sx={
        prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED
          ? {
              color: uiStatndardColor,
              backgroundColor: 'transparent',
              border: `1px solid ${uiStatndardColor}`,
            }
          : { color: getStatusContrastText(color), backgroundColor: uiStatndardColor }
      }
    />
  ) : (
    <></>
  );
};
