import { Box, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { Illust04 } from 'src/components/common/atoms/images/Illust04';
import { QuestionPageFrom } from 'src/components/shien/pages/possibility/common/types';
import { ButtonPageTemplate } from 'src/components/shien/templates/ButtonPageTemplate';

export const AnswerNotPossiblePage = () => {
  const location = useLocation();
  const fromState = location.state as QuestionPageFrom;
  const from = fromState.from;
  const navigate = useNavigate();
  return (
    <PageContainer title="回答完了">
      <ButtonPageTemplate
        headline={
          <Stack alignItems="center">
            <Box>ご回答</Box>
            <Box>ありがとうございます</Box>
          </Stack>
        }
        description={'まずはご自身やご家族の安全を確保してください。' + '救助が必要な場合には、119に電話をしましょう。'}
        primary={{
          text: '回答を変更する',
          onClick: () => {
            navigate('/app/shien/possibility/question', { state: { from } });
          },
        }}
      >
        <Illust04 />
      </ButtonPageTemplate>
    </PageContainer>
  );
};
