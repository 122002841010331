import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, colors, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  SpGridRow,
  SpGridRowSoshiki,
} from 'src/components/common/types/SupportersGridTypes';
import { SearchConditions } from 'src/components/common/types/SupportersSearchConditions';
import { useQuery } from 'src/components/common/useQuery/SupportersTableQuery';
import { AssignPageInfo } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/types';
import { PreFetchedData } from 'src/components/kanri/pages/jichitai/SearchSupportersPage/components/SupporterDetailsDialog';
import { appConst } from 'src/constants/common';
import { useUserData } from 'src/hooks/common/useUserData';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';
import { ShienkankeiList } from 'src/utilities/restApi/kanri/types/searchSupporters';

type ComponentProps = {
  onDetailsClick: (spId: string, preFetchedDataForDetailsDialog: PreFetchedData) => void;
  searchConditions: SearchConditions;
  onSearchError: (error: SearchError) => void;
  onChangeLoadingStatus: (isLoading: SearchCallerType | undefined) => void;
  onChangeSelectedRows: (selectedRows: GridRowSelectionModel) => void;
  oldAssignInfo?: AssignPageInfo;
  setShienkankeiList: (shienkankeiList:ShienkankeiList)=>void
};

const iconColorGrey = colors.grey[700];

export const SupportersList = ({
  onDetailsClick,
  searchConditions,
  onSearchError,
  onChangeLoadingStatus,
  onChangeSelectedRows,
  oldAssignInfo,
  setShienkankeiList
}: ComponentProps) => {
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>([{ field: 'name', sort: 'asc' }]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const prsid_list = oldAssignInfo?.prsIds;

  const {userData} = useUserData()

  const {
    isLoading,
    rows,
    pageInfo,
    error: searchError,
    shienkankeiList
  } = useQuery({ paginationModel, searchConditions, sortModel, prsid_list});

  useEffect(()=>setShienkankeiList(shienkankeiList),[shienkankeiList])

  const apiRef: React.MutableRefObject<GridApiCommunity> = useGridApiRef();

  const rowCountRef = useRef(pageInfo.totalRowCount || 0);
  const rowCount = useMemo(() => {
    rowCountRef.current = pageInfo.totalRowCount;
    return rowCountRef.current;
  }, [pageInfo.totalRowCount]);

  const onSortModelChange = (model: GridSortModel) => {
    if (isLoading !== 'user') {
      setSortModel(model);
    }
  };
  
  // 遷移元から貰った支援者情報を選択する
  useEffect(() => {
    if(!oldAssignInfo?.spIds || !userData || isLoading || apiRef.current.getSelectedRows().size > 0) return
    const selectedIds:string[] = []
    if(!userData?.soshikiid){
      oldAssignInfo.spIds.forEach(id=>selectedIds.push(id))
    }else{
      oldAssignInfo.spIds.forEach(spid=>{
        shienkankeiList.forEach(sk=>{
          sk.supporters.forEach(sp=>{
            if(sp.spid === spid && sp.soshikiid_list.includes(userData!.soshikiid!) ){
              selectedIds.push(spid)
            }
          })
        })
      })
    }
    apiRef.current.setRowSelectionModel(selectedIds);
    
  }, [oldAssignInfo?.spIds,userData,isLoading,shienkankeiList]);

  // エラー通知
  useEffect(() => {
    onSearchError(searchError);
  }, [searchError]);

  useEffect(() => {
    onChangeLoadingStatus(isLoading);
  }, [isLoading]);

  const columns: GridColDef<SpGridRow>[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'name', headerName: '氏名', width: 150, disableColumnMenu: true, sortable: true },
      {
        field: 'spcheck',
        headerName: '支援可否',
        width: 120,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<SpGridRow, string>) => {
          // 支援可否IDに対応する文言とアイコンを表示
          const availability =
            params.row.spCheckId === appConst.SPCHECKID.NOT_ANSWERD
              ? {
                  // 未回答
                  spCheckName: params.row.spCheckStatus,
                  icon: <QuestionMarkIcon fontSize="small" htmlColor={iconColorGrey} />,
                }
              : params.row.spCheckId === appConst.SPCHECKID.AVAILABLE
              ? {
                  // 支援可
                  spCheckName: params.row.spCheckStatus,
                  icon: <CircleOutlinedIcon fontSize="small" color="success" />,
                }
              : params.row.spCheckId === appConst.SPCHECKID.UNABLE
              ? {
                  // 支援不可
                  spCheckName: params.row.spCheckStatus,
                  icon: <ChangeHistoryIcon fontSize="small" color="error" />,
                }
              : {
                  // 未知の支援可否IDが来た場合、ハイフンを表示（本来は到達しないコード）
                  spCheckName: undefined,
                  icon: <RemoveIcon htmlColor={iconColorGrey} />,
                };

          return (
            <Stack height="100%" direction="row" alignItems="center">
              {availability.icon}
              {availability.spCheckName}
            </Stack>
          );
        },
      },
      {
        field: 'supportStatus',
        renderHeader: () => (
          <Stack direction="column" alignItems="center">
            <Box>支援状況</Box>
            <Box>(未確認/予定)</Box>
          </Stack>
        ),
        width: 200,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<SpGridRow, string>) => {
          const prsList = params.row.supportRecipients.map((x) => ({
            prsId: x.prsId,
            prsStatusId: x.prsStatusId,
          }));

          const totalCount = prsList.length;
          const uncheckedCount = prsList.filter((x) => x.prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED).length;

          return (
            <Stack height="100%" direction="row" alignItems="center" justifyContent="space-between">
              <Box sx={{ m: 2 }}>
                {uncheckedCount}/{totalCount}人
              </Box>
            </Stack>
          );
        },
      },
      {
        field: 'soshikiList',
        headerName: '所属',
        width: 200,
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<SpGridRow, SpGridRowSoshiki[]>) =>
          params.value?.map((x) => x.soshikiName).join(', '),
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        width: 90,
        minWidth: 90,
        maxWidth: 90,
        renderCell: (params) => (
          <Button
            onClick={() => {
              onDetailsClick(params.row.id, {
                soshikiNames: params.row.soshikiList.map((x) => x.soshikiName),
              });
            }}
          >
            詳細
          </Button>
        ),
      },
    ],
    []
  );
  // チェックされた情報保持
  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    onChangeSelectedRows(newSelection)
  };

  return (
    <Stack sx={{ height: '600px', minHeight: '300px', maxHeight: 'calc(100vh - 420px)', overflow: 'auto' }}>
      <DataGrid
        checkboxSelection
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        loading={!!isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        apiRef={apiRef}
        onRowSelectionModelChange={(newSelectionModel) => handleSelectionChange(newSelectionModel)}
        keepNonExistentRowsSelected
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(sortModel) => {
          onSortModelChange(sortModel);
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'circular-progress',
            noRowsVariant: 'skeleton',
          },
          ...(isLoading === 'user'
            ? {
                pagination: {
                  backIconButtonProps: {
                    disabled: true,
                  },
                  nextIconButtonProps: {
                    disabled: true,
                  },
                },
              }
            : {}),
        }}
      />
    </Stack>
  );
};
