import { Box, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import {
  HeaderWithBackButton,
  HeaderWithBackButtonProps,
} from 'src/components/shien/atoms/Header/HeaderWithBackButton';

type ComponentProps = HeaderWithBackButtonProps & {
  children: ReactNode;
};

export const HeaderWithBackButtonTemplate: FC<ComponentProps> = ({ children, ...rest }) => {
  return (
    <Stack sx={{ overflow: 'hidden', height: '100%' }}>
      <HeaderWithBackButton {...rest} />
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>{children}</Box>
    </Stack>
  );
};
