import { Backdrop, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useKanriPageBackdrop } from 'src/hooks/common/backdrops';

type ComponentProps = {
  children?: ReactNode;
  title: string;
};

export const KanriPageContainer: FC<ComponentProps> = ({ children, title }) => {
  const { showBackdrop } = useKanriPageBackdrop();
  const theme = useTheme();

  return (
    <Stack height="100%" sx={{ position: 'relative' }} id="KanriPageContainerParentStack">
      <Typography variant="h2" component="h1" p={3}>
        {title}
      </Typography>
      <Stack sx={{ flexGrow: 1, px: 3, pb: 3, overflow: 'auto' }}>{children}</Stack>
      <Backdrop
        open={showBackdrop}
        sx={{
          position: 'absolute',
          zIndex: theme.zIndex.appBar + 1,
        }}
        id="KanriPageContainerBackdrop"
      >
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
    </Stack>
  );
};
