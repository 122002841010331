import { Tooltip } from '@mui/material';
import { FC, ReactElement } from 'react';

type ComponentProps = {
  children: ReactElement;
  show?: boolean;
  message: string;
};
export const AppTooltip: FC<ComponentProps> = ({ children, show, message }) => {
  return (
    <Tooltip title={show ? message : undefined} followCursor placement="top">
      {children}
    </Tooltip>
  );
};
