import { PrsActionType } from 'src/types/common/PrsActionType';

export const determinePrsActionType = (
  lastPrsStatusUpdatedAt?: string,
  lastVisitedAt?: string,
  lastEvacuatedAt?: string
): PrsActionType | undefined => {
  if (lastPrsStatusUpdatedAt && lastVisitedAt && lastPrsStatusUpdatedAt === lastVisitedAt) {
    return 'visit';
  } else if (lastPrsStatusUpdatedAt && lastEvacuatedAt && lastPrsStatusUpdatedAt === lastEvacuatedAt) {
    return 'evacuate';
  } else {
    return undefined;
  }
};
