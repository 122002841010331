import { Box, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Footer } from 'src/components/shien/atoms/Footer';
import { HeaderTitle, HeaderTitleProps } from 'src/components/shien/atoms/Header/HeaderTitle';

type ComponentProps = HeaderTitleProps & {
  children: ReactNode;
};

export const ShienProfileTemplate: FC<ComponentProps> = ({ children, ...rest }) => {
  return (
    <Stack sx={{ overflow: 'hidden', height: '100%' }}>
      <Box
        component="header"
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
          alignItems: 'center',
          width: '100%',
          padding: '10px 0',
        }}
      />
      <HeaderTitle {...rest} />
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>{children}</Box>
      <Footer mode="supporter" selected="あなた" />
    </Stack>
  );
};
