export const IconPhone = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M34.23 33.3901L29.92 29.8701C29.25 29.3201 28.3 29.2501 27.55 29.6801L23.58 31.9301C22.86 31.5101 21.36 30.5001 19.42 28.5701C17.49 26.6401 16.49 25.1401 16.07 24.3301L18.31 20.4401C18.74 19.6901 18.66 18.7401 18.12 18.0701L14.6 13.7601C13.97 12.9901 12.87 12.7701 11.99 13.2401L7.66001 15.5901C6.49001 16.2201 5.85001 17.4801 6.02001 18.7901C6.41001 21.7301 7.98001 27.6001 14.18 33.8001C20.38 40.0001 26.24 41.5701 29.19 41.9601C29.33 41.9801 29.47 41.9901 29.61 41.9901C30.76 41.9901 31.83 41.3701 32.39 40.3201L34.74 35.9901C35.22 35.1101 35 34.0101 34.22 33.3801L34.23 33.3901ZM30.64 39.3801C30.41 39.8101 29.94 40.0501 29.46 39.9901C26.77 39.6401 21.39 38.1801 15.6 32.4001C9.82001 26.6201 8.36001 21.2401 8.01001 18.5401C7.95001 18.0601 8.18001 17.5901 8.62001 17.3601L12.95 15.0101C12.95 15.0101 13.03 15.0001 13.06 15.0301L16.58 19.3401C16.58 19.3401 16.61 19.4101 16.58 19.4401L14.34 23.3301C13.96 23.9801 13.97 24.7801 14.34 25.4301C14.81 26.2401 15.92 27.9001 18.01 29.9901C20.1 32.0801 21.76 33.1901 22.57 33.6601C23.21 34.0401 24.02 34.0401 24.67 33.6601L28.56 31.4201H28.66L32.97 34.9501C33 34.9801 33.01 35.0201 32.99 35.0601L30.64 39.3901V39.3801Z"
        fill="black"
      />
      <path
        d="M25.75 16.5H25.25C24.97 16.5 24.75 16.72 24.75 17V18C24.75 18.28 24.97 18.5 25.25 18.5H25.75C27.82 18.5 29.5 20.18 29.5 22.25V22.75C29.5 23.03 29.72 23.25 30 23.25H31C31.28 23.25 31.5 23.03 31.5 22.75V22.25C31.5 19.08 28.92 16.5 25.75 16.5Z"
        fill="black"
      />
      <path
        d="M25.75 11H25.25C24.97 11 24.75 11.22 24.75 11.5V12.5C24.75 12.78 24.97 13 25.25 13H25.75C30.85 13 35 17.15 35 22.25V22.75C35 23.03 35.22 23.25 35.5 23.25H36.5C36.78 23.25 37 23.03 37 22.75V22.25C37 16.05 31.95 11 25.75 11Z"
        fill="black"
      />
      <path
        d="M25.75 6H25.25C24.97 6 24.75 6.22 24.75 6.5V7.5C24.75 7.78 24.97 8 25.25 8H25.75C33.61 8 40 14.39 40 22.25V22.75C40 23.03 40.22 23.25 40.5 23.25H41.5C41.78 23.25 42 23.03 42 22.75V22.25C42 13.29 34.71 6 25.75 6Z"
        fill="black"
      />
    </svg>
  );
};
