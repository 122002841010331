import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export type HeaderTitleProps = {
  upperText?: string | React.ReactNode;
  headlineText: ReactNode;
  lowerText?: string | React.ReactNode;
};

export const HeaderTitle = ({ upperText, headlineText, lowerText }: HeaderTitleProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
    >
      <Typography variant="subtitle" lineHeight="16px">
        {upperText}
      </Typography>
      <Typography variant="headline" lineHeight="24px">
        {headlineText}
      </Typography>
      <Typography variant="subtitle" lineHeight="16px">
        {lowerText}
      </Typography>
    </Stack>
  );
};
