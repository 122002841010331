import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestGetShelter, ResponseGetShelter, ResponseGetShelters } from 'src/utilities/restApi/shien/types/shelters';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/shelters';
export const getShelter = async (params: RequestGetShelter) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetShelter>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetShelter>>(`${url}/${params.shelterid}`, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};

export const getShelters = async () => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetShelters>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetShelters>>(`${url}`, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};
