import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import {
  RequestCreateAccount,
  RequestDeleteAccount,
  RequestResetAccount,
  ResponseCreateAccount,
  ResponseDeleteAccount,
  ResponseResetAccount,
} from 'src/utilities/restApi/kanri/types/account';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

/**
 * アカウント発行
 */
export const createAccount = async (data: RequestCreateAccount) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseCreateAccount>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponseCreateAccount>>('/azure-account/create', data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};

// パスワードリセット
export const resetAccount = async (data: RequestResetAccount) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseResetAccount>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponseResetAccount>>('/azure-account/reset', data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};

// アカウント削除
export const deleteAccount = async (data: RequestDeleteAccount) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseDeleteAccount>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponseDeleteAccount>>('/azure-account/delete', data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
