import { useAtom } from 'jotai';
import { globalDataStore, showBackdropAtom, showKanriPageBackdropAtom } from 'src/jotai/atom';

export const useBackdrop = () => {
  const [showBackdrop, setShowBackdrop] = useAtom(showBackdropAtom, { store: globalDataStore });
  return { showBackdrop, setShowBackdrop };
};

export const useKanriPageBackdrop = () => {
  const [showBackdrop, setShowBackdrop] = useAtom(showKanriPageBackdropAtom);
  return { showBackdrop, setShowBackdrop };
};
