import { useEffect, useState } from 'react';
import { JianMode } from 'src/constants/common';
import { LineMessageTemplate } from 'src/types/kanri/LineMessageTemplate';
import { getLineMessageTemplates } from 'src/utilities/restApi/kanri/lineMessageTemplate';

type HookParams = {
  jianMode?: JianMode;
  initSearch?: boolean;
};

export const useLineMessageTemplates = ({ jianMode: mode, initSearch = true }: HookParams) => {
  const [templates, setTemplates] = useState<LineMessageTemplate[]>([]);
  const [initialized, setInitialized] = useState(false); // 初回の処理が完了したか
  const [isLoading, setIsLoading] = useState(false); // (初回に限らず)フェッチが完了したか
  const [isError, setIsError] = useState(false); // フェッチでエラーが発生したか

  const fetch = async () => {
    if (mode == null) return;

    let isSuccess = true;
    setIsLoading(true);
    try {
      const response = await getLineMessageTemplates({ mode });
      setTemplates(response?.data?.line_template_list ?? []);
    } catch (e) {
      setIsError(true);
      isSuccess = false;
    }
    setIsLoading(false);
    return isSuccess;
  };

  useEffect(() => {
    if (mode != null && initSearch) {
      setInitialized(false);
      fetch().then((isSuccess) => setInitialized(isSuccess ?? false));
    }
  }, [mode]);

  return { templates, isLoading, isError, initialized, refreshData: fetch };
};
