export const IconHome = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M43.15 19.8299L25.15 7.17995C24.46 6.69995 23.54 6.69995 22.85 7.17995L4.84999 19.8299C3.94999 20.4599 3.72999 21.7099 4.35999 22.6199L6.08999 25.0699C6.39999 25.5099 6.85999 25.7999 7.37999 25.8899C7.49999 25.9099 7.60999 25.9199 7.72999 25.9199C8.13999 25.9199 8.53999 25.7999 8.87999 25.5599L9.00999 25.4699V39.9999C9.00999 41.0999 9.90999 41.9999 11.01 41.9999H37.01C38.11 41.9999 39.01 41.0999 39.01 39.9999V25.4699L39.13 25.5599C40.03 26.1899 41.28 25.9799 41.92 25.0699L43.65 22.6199C44.28 21.7199 44.07 20.4699 43.16 19.8299H43.15ZM16 39.9999V28.9999H21V39.9999H16ZM37 39.9999H23V28.9999C23 27.8999 22.1 26.9999 21 26.9999H16C14.9 26.9999 14 27.8999 14 28.9999V39.9999H11V24.0599L24 14.9199L37 24.0599V39.9999ZM40.28 23.9199L25.15 13.2899C24.46 12.8099 23.54 12.8099 22.85 13.2899L7.71999 23.9199L5.98999 21.4599L23.99 8.80995L41.99 21.4599L40.27 23.9199H40.28Z"
        fill="black"
      />
      <path
        d="M28 35H32C33.1 35 34 34.1 34 33V29C34 27.9 33.1 27 32 27H28C26.9 27 26 27.9 26 29V33C26 34.1 26.9 35 28 35ZM28 29H32V33H28V29Z"
        fill="black"
      />
    </svg>
  );
};
