import { Input as BaseInput, InputProps } from '@mui/base/Input';
import { styled } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { Override } from 'src/types/common/Override';

type ComponentProps = Override<InputProps, { multiline?: never; type?: never }>;

export const AppFullSizeInput = forwardRef<HTMLDivElement, ComponentProps>(function _AppFullSizeInput(
  { ...rest },
  ref
) {
  return <Input multiline {...rest} ref={ref} />;
});

const Input = forwardRef(function CustomInput(props: InputProps, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <BaseInput
      slots={{
        root: RootDiv,
        input: 'input',
        textarea: TextareaElement,
      }}
      {...props}
      ref={ref}
      style={{ height: '100%' }}
    />
  );
});

const RootDiv = styled('div')`
  display: flex;
  max-width: 100%;
`;

const TextareaElement = styled('textarea', {
  shouldForwardProp: (prop) => !['ownerState', 'minRows', 'maxRows'].includes(prop.toString()),
})(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: ${theme.palette.uiStandard.black};
  font-family: ${theme.typography.fontFamily};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 8px 12px;
  border: 0px;
  &:focus-visible {
    outline: 0;
  }
`
);
