import { Box } from '@mui/material';

type ComponentProps = {
  color: string;
};

export const SelectedLabel = ({ color }: ComponentProps) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: '2px',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '5px 8px',
      }}
    >
      選択中
    </Box>
  );
};
