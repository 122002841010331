import { List } from '@mui/material';
import { FC, ReactNode } from 'react';

type ComponentProps = {
  children?: ReactNode;
};

export const DataList: FC<ComponentProps> = ({ children }) => {
  return <List disablePadding>{children}</List>;
};
