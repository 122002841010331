import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { PrsActionChip } from 'src/components/common/atoms/PrsActionChip';
import { LastUpdateTime } from 'src/components/shien/atoms/LastUpdateTime';
import { PrsActionType } from 'src/types/common/PrsActionType';
import { Template, TemplateProps, upperColors } from './Template';

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.uiStandard.gray50,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '24px', // 半角英字に対応する
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

type LargeStatusButtonProps = TemplateProps & {
  statusText: string;
  prsActionType?: PrsActionType;
  icon?: ReactNode;
  shelterName: string;
  memoText: string;
  lastUpdateTime?: string;
};

export const LargeStatusButton = ({
  statusText,
  prsActionType,
  icon,
  upperColor = 'none',
  shelterName,
  memoText,
  lastUpdateTime,
  ...templateProps
}: LargeStatusButtonProps) => {
  const theme = useTheme();

  return (
    <Template upperColor={upperColor} {...templateProps}>
      {lastUpdateTime && <LastUpdateTime time={lastUpdateTime} />}
      <Stack spacing={1.5} sx={{ textAlign: 'left' }}>
        <Stack direction="row" justifyContent="start" alignItems="left" spacing={0.5}>
          <Box>{icon}</Box>
          <Typography
            sx={{
              color: theme.palette.uiStandard[upperColors[upperColor].text],
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: '24px',
            }}
          >
            {statusText}
          </Typography>
          <PrsActionChip prsActionType={prsActionType} />
        </Stack>
        <Stack spacing={1}>
          <Box>
            <DescriptionTypography>- {shelterName}</DescriptionTypography>
          </Box>
          <Box>
            <DescriptionTypography>- {memoText}</DescriptionTypography>
          </Box>
        </Stack>
      </Stack>
    </Template>
  );
};
