import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { AppTooltip } from 'src/components/common/atoms/AppTooltip';
import { ComponentLoadingIndicator } from 'src/components/common/atoms/ComponentLoadingIndicator';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { ConfirmDialog } from 'src/components/kanri/atoms/ConfirmDialog';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import { SendLineButton } from 'src/components/kanri/atoms/SendLineButton';
import { DataList } from 'src/components/kanri/pages/jichitai/SafetyConfirmation/dataList/DataList';
import { DataListRow } from 'src/components/kanri/pages/jichitai/SafetyConfirmation/dataList/DataListRow';
import { DisasterTypesSelect } from 'src/components/kanri/pages/jichitai/SafetyConfirmation/DisasterTypesSelect';
import { useLineMessageTemplates } from 'src/components/kanri/pages/jichitai/SafetyConfirmation/hooks/useLineMessageTemplates';
import { useSendLine } from 'src/components/kanri/pages/jichitai/SafetyConfirmation/hooks/useSendLine';
import { SafetyConfirmationStatusChip } from 'src/components/kanri/pages/jichitai/SafetyConfirmation/SafetyConfirmationStatusChip';
import { JianMode } from 'src/constants/common';
import { useJianInfo } from 'src/hooks/common/useJianInfo';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { Soshiki } from 'src/types/common/Soshiki';
import { startJian } from 'src/utilities/restApi/kanri/jian/srartJian';
import { stopJian } from 'src/utilities/restApi/kanri/jian/stopJian';
import { deleteJianSoshiki, getJianSoshiki, postJianSoshiki } from 'src/utilities/restApi/kanri/jianSoshiki';
import { resetStatus } from 'src/utilities/restApi/kanri/resetStatus';
import { getSoshikiList } from 'src/utilities/restApi/kanri/soshiki';
import { Section } from './Section';

const DATA_LIST_TITLE_WIDTH = 210;
const MESSAGE_JIAN_ONGOING = '安否確認中は操作できません';
const MESSAGE_JIAN_NOT_START = '安否確認中のみ操作できます';
const MESSAGE_EMPTY_FIELD = '未選択の入力欄があります';

export const SafetyConfirmationPage = () => {
  const theme = useTheme();
  const { userData } = useUserData();
  const navigateSystemError = useNavigateSystemError();
  const { enqueueSnackbar } = useSnackbar();
  const { jianInfo, jianOngoing, refreshJianInfo, isError: jianInfoError } = useJianInfo();

  // states
  const [jianMode, setJianMode] = useState<JianMode | undefined>();
  const [soshikiList, setSoshikiList] = useState<Soshiki[]>();
  const [initialJianSoshikiListLoading, setInitialJianSoshikiListLoading] = useState<true>();

  // selected state
  const [selectedJianSoshikiList, setSelectedJianSoshikiList] = useState<Soshiki[]>([]);
  const [selectedDisasterTypeNumber, setSelectedDisasterTypeNumber] = useState<number>();
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<string>();

  const [jianSoshikiUpdateCondfirmDialogOpen, setJianSoshikiUpdateCondfirmDialogOpen] = useState(false);
  const [selectedJianSoshikiListDialogValues, setSelectedJianSoshikiListDialogValues] = useState<Soshiki[]>([]);

  // send line
  const {
    templates: messageTemplates,
    isLoading: isLoadingTemplates,
    isError: isErrorTemplates,
    initialized: initializedTemplates,
  } = useLineMessageTemplates({
    jianMode,
  });
  const { sendLine, isLoading: isLoadingSendLine, isError: isErrorSendLine } = useSendLine();
  const [isOpenConfirmSendLineDialog, setIsOpenConfirmSendLineDialog] = useState(false);

  // loading state
  const [loadingJianStartStop, setLoadingJianStartStop] = useState(false);
  const [loadingJianSoshiki, setLoadingJianSoshiki] = useState(false);
  const [loadingResetStatus, setLoadingResetStatus] = useState(false);
  const [jianSoshikiAllCount, setJianSoshikiAllCount] = useState<number>(0);
  const [jianSoshikiDoneCount, setJianSoshikiDoneCount] = useState<number>(0);

  // handlers
  const handleMode = (_event: React.MouseEvent<HTMLElement>, newValue: JianMode | null) => {
    if (newValue) {
      setJianMode(newValue);
    }
  };

  // メッセージの内容変更時
  const handleChangeLineMessageTemplate = (event: SelectChangeEvent) => {
    setSelectedMessageTemplate(event.target.value);
  };

  // メッセージを送信するボタン押下時
  const handleOpenConfirmSendLineDialog = () => {
    setIsOpenConfirmSendLineDialog(true);
  };

  // LINE送信確認ダイアログを閉じる
  const handleCloseConfirmSendLineDialog = () => {
    setIsOpenConfirmSendLineDialog(false);
  };

  // LINE送信確認ダイアログ　"はい"押下
  const handleSendMessage = async () => {
    if (selectedMessageTemplate == null) return;
    setIsOpenConfirmSendLineDialog(false);
    sendLine(selectedMessageTemplate).then((isSuccess) => {
      if (isSuccess) {
        enqueueSnackbar('メッセージの送信リクエストに成功しました。', { variant: 'success' });
      }
    });
  };

  // useEffect
  useEffect(() => {
    if (userData) {
      getSoshikiList()
        .then((response) => {
          if (response.data) {
            const newData = response.data.soshiki_list.map((x) => {
              return {
                soshikiId: x.soshikiid,
                soshikiName: x.soshikiname,
              } as Soshiki;
            });
            setSoshikiList(newData);
          }
        })
        .catch(() => navigateSystemError());
    }
  }, [userData]);

  useEffect(() => {
    if (jianInfo !== undefined) {
      if (jianInfo === null) {
        setJianMode(JianMode.Training);
        setInitialJianSoshikiListLoading(true);
      } else {
        setJianMode(jianInfo.jianMode);

        getJianSoshiki({ jianid: jianInfo.jianId })
          .then((response) => {
            if (response.data) {
              const newData = response.data.soshiki_list.map((x) => {
                return {
                  soshikiId: x.soshikiid,
                  soshikiName: x.soshikiname,
                } as Soshiki;
              });
              setInitialJianSoshikiListLoading(true);
              setSelectedJianSoshikiList(newData);
            }
          })
          .catch(() => navigateSystemError());
      }
    }
  }, [jianInfo]);

  useEffect(() => {
    if (jianInfoError) {
      navigateSystemError();
    }
  }, [jianInfoError]);

  // テンプレート取得後に初期値を設定
  useEffect(() => {
    if (messageTemplates.length === 0) {
      setSelectedMessageTemplate(undefined);
    } else {
      setSelectedMessageTemplate(messageTemplates[0].templateid);
    }
  }, [messageTemplates]);

  // エラー処理
  useEffect(() => {
    if (isErrorTemplates || isErrorSendLine) {
      navigateSystemError();
    }
  }, [isErrorTemplates, isErrorSendLine]);

  // functions
  // muiのwarning解消用 (存在しないidをvalueに設定すると警告が出るため)
  const generateSelectedTemplate = () => {
    const ids = messageTemplates.map((t) => t.templateid);
    if (selectedMessageTemplate != null && ids.includes(selectedMessageTemplate)) {
      return selectedMessageTemplate;
    } else {
      return '';
    }
  };

  const onChangeJianSoshikiList = (value: Soshiki[]) => {
    // 事案開始~終了(リセット前)
    if (jianInfo && jianInfo.jianReset === undefined) {
      if (value.length === 0) {
        enqueueSnackbar('安否確認中は地域を1つ以上選択してください', { variant: 'warning' });
        return;
      }

      // 安否確認中の場合の地域変更はダイアログを表示
      setSelectedJianSoshikiListDialogValues(value);
      setJianSoshikiUpdateCondfirmDialogOpen(true);
    } else {
      setSelectedJianSoshikiList(value);
    }
  };

  const updateJianSoshikiList = (jianId: string, soshikiIdList: { add: string[]; delete: string[] }) => {
    // promise.allで並列処理　addとdeleteを同時に実行
    setJianSoshikiDoneCount(0);
    setJianSoshikiAllCount(soshikiIdList.add.length + soshikiIdList.delete.length);
    return Promise.all([
      ...soshikiIdList.add.map((soshikiid) =>
        postJianSoshiki({ jianid: jianId, soshikiid }).finally(() => setJianSoshikiDoneCount((prev) => prev + 1))
      ),
      ...soshikiIdList.delete.map((soshikiid) =>
        deleteJianSoshiki({ jianid: jianId, soshikiid }).finally(() => setJianSoshikiDoneCount((prev) => prev + 1))
      ),
    ]);
  };

  const jianStartButtonOnClick = () => {
    setLoadingJianStartStop(true);
    setLoadingJianSoshiki(true);
    (async () => {
      try {
        // 事案開始API
        const responseStartJian = await startJian({
          jianmode: jianMode!,
          disaster_type_number: Number(selectedDisasterTypeNumber),
        });

        if (responseStartJian.data) {
          if (responseStartJian.data.result === 'error') {
            enqueueSnackbar(responseStartJian.data.reason, { variant: 'error' });
          } else {
            // 安否確認組織追加・削除API
            await updateJianSoshikiList(responseStartJian.data.jianid, {
              add: selectedJianSoshikiList.map((x) => x.soshikiId),
              delete: [],
            });
            setLoadingJianSoshiki(false);
            // 事案情報再取得
            await refreshJianInfo();
            enqueueSnackbar('安否確認機能を開始しました。', { variant: 'success' });
          }
        } else {
          throw new Error('事案開始APIエラー');
        }
      } catch (error) {
        navigateSystemError();
      } finally {
        setLoadingJianStartStop(false);
        setLoadingJianSoshiki(false);
      }
    })();
  };

  const jianStopButtonOnClick = () => {
    setLoadingJianStartStop(true);
    (async () => {
      try {
        // 事案停止API
        const responseStopJian = await stopJian({ jianid: jianInfo!.jianId });

        if (responseStopJian.data) {
          if (responseStopJian.data.result === 'error') {
            enqueueSnackbar(responseStopJian.data.reason, { variant: 'error' });
          } else {
            await refreshJianInfo();
            enqueueSnackbar('安否確認機能を停止しました。', { variant: 'success' });
          }
        } else {
          throw new Error('事案停止APIエラー');
        }
      } catch (error) {
        navigateSystemError();
      }

      setLoadingJianStartStop(false);
    })();
  };

  const resetButtonOnClick = () => {
    setLoadingResetStatus(true);

    (async () => {
      try {
        const responsetResetStatus = await resetStatus();
        if (responsetResetStatus.data) {
          if (responsetResetStatus.data.result === 'error') {
            enqueueSnackbar(responsetResetStatus.data.reason, { variant: 'error' });
          } else {
            await refreshJianInfo();
            enqueueSnackbar('安否登録情報をリセットしました。', { variant: 'success' });
            // NOTE: 安否確認組織取得APIで取得されるがpromiseで遅いので先に削除
            setSelectedJianSoshikiList([]);
          }
        } else {
          throw new Error('事案リセットAPIエラー');
        }
      } catch (error) {
        navigateSystemError();
      } finally {
        setLoadingResetStatus(false);
      }
    })();
  };

  return (
    <PageContainer title="安否確認操作">
      <KanriPageContainer title="安否確認操作">
        <Container maxWidth="md" sx={{ px: { xs: '0px', sm: theme.spacing(2), md: theme.spacing(3) } }}>
          <Stack spacing={4}>
            <AppTooltip show={jianOngoing()} message={MESSAGE_JIAN_ONGOING}>
              <div>
                <AppSkeleton show={jianInfo === undefined}>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={jianMode}
                    exclusive
                    onChange={handleMode}
                    disabled={jianOngoing() || (!!jianInfo && !jianInfo.jianReset)}
                  >
                    <ToggleButton value="1">本番モード</ToggleButton>
                    <ToggleButton value="2">訓練モード</ToggleButton>
                  </ToggleButtonGroup>
                </AppSkeleton>
              </div>
            </AppTooltip>
            <Section
              title={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box>安否確認機能を開始/停止する</Box>
                  <AppSkeleton show={jianInfo === undefined} variant="chip">
                    <SafetyConfirmationStatusChip isRunning={jianOngoing()} />
                  </AppSkeleton>
                </Stack>
              }
              action={
                <Stack alignItems="center">
                  <Box width={{ xs: '100%', sm: 'auto' }}>
                    <AppSkeleton show={jianInfo === undefined}>
                      <ComponentLoadingIndicator loading={loadingJianStartStop}>
                        {jianOngoing() ? (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PauseCircleIcon />}
                            fullWidth
                            disabled={loadingJianStartStop}
                            onClick={() => jianStopButtonOnClick()}
                          >
                            停止する
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PlayCircleOutlineIcon />}
                            fullWidth
                            disabled={
                              loadingJianStartStop ||
                              (!!jianInfo && !jianInfo.jianReset) ||
                              selectedJianSoshikiList.length === 0
                            }
                            onClick={() => jianStartButtonOnClick()}
                          >
                            開始する
                          </Button>
                        )}
                      </ComponentLoadingIndicator>
                    </AppSkeleton>
                  </Box>
                </Stack>
              }
            >
              <DataList>
                <DataListRow
                  title="安否確認をする地域"
                  titleWidth={DATA_LIST_TITLE_WIDTH}
                  value={
                    <Stack alignItems="end">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Button
                          onClick={() => {
                            onChangeJianSoshikiList(soshikiList ?? []);
                          }}
                          disabled={!!jianInfo && jianInfo.jianEnd !== undefined && jianInfo.jianReset === undefined}
                        >
                          全ての地域を選択
                        </Button>
                        <div>
                          <Divider orientation="vertical" flexItem sx={{ height: '1rem' }} />
                        </div>
                        <Button
                          onClick={() => {
                            onChangeJianSoshikiList([]);
                          }}
                          disabled={!!jianInfo && jianInfo.jianEnd !== undefined && jianInfo.jianReset === undefined}
                        >
                          削除
                        </Button>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="end">
                        <Typography variant="caption">現在 {soshikiList?.length.toLocaleString()} 件中</Typography>
                        <Typography variant="h5" fontWeight="bold">
                          {selectedJianSoshikiList.length.toLocaleString()}
                        </Typography>
                        <Typography variant="caption">件の地域が選択されています。</Typography>
                      </Stack>
                      <AppSkeleton show={!soshikiList || !initialJianSoshikiListLoading} width="100%">
                        <ComponentLoadingIndicator
                          loading={loadingJianSoshiki}
                          variant="determinate"
                          current={jianSoshikiDoneCount}
                          all={jianSoshikiAllCount}
                        >
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={soshikiList ?? []}
                            value={selectedJianSoshikiList}
                            onChange={(_event, value) => onChangeJianSoshikiList(value)}
                            getOptionLabel={(option) => option.soshikiName}
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) => option.soshikiId === value.soshikiId}
                            renderInput={(params) => <TextField {...params} size="small" />}
                            size="small"
                            // Warning対応
                            // Warning: A props object containing a "key" prop is being spread into JSX:
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return (
                                  <Chip
                                    key={key}
                                    color="primary"
                                    size="small"
                                    label={option.soshikiName}
                                    {...tagProps}
                                  />
                                );
                              })
                            }
                            limitTags={10}
                            fullWidth
                            disabled={
                              loadingJianSoshiki ||
                              (!!jianInfo && jianInfo.jianEnd !== undefined && jianInfo.jianReset === undefined)
                            }
                            disableClearable={true}
                          />
                        </ComponentLoadingIndicator>
                      </AppSkeleton>
                    </Stack>
                  }
                />
                <Divider />
                <DataListRow
                  title="災害種別"
                  titleWidth={DATA_LIST_TITLE_WIDTH}
                  value={
                    <AppTooltip show={jianOngoing()} message={MESSAGE_JIAN_ONGOING}>
                      <div>
                        <DisasterTypesSelect
                          jianInfo={jianInfo}
                          onChange={(disasterTypeNumber) => setSelectedDisasterTypeNumber(disasterTypeNumber)}
                          disabled={
                            loadingJianStartStop || jianOngoing() || (!!jianInfo && jianInfo.jianReset === undefined)
                          }
                        />
                      </div>
                    </AppTooltip>
                  }
                />
                <Divider />
                <DataListRow
                  title="安否登録情報をリセット"
                  titleWidth={DATA_LIST_TITLE_WIDTH}
                  value={
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={1}
                      alignItems={{ xs: 'start', sm: 'center' }}
                    >
                      <AppSkeleton show={jianInfo === undefined}>
                        <AppTooltip show={jianOngoing()} message={MESSAGE_JIAN_ONGOING}>
                          <div>
                            <ComponentLoadingIndicator loading={loadingResetStatus}>
                              <Button
                                variant="outlined"
                                color="error"
                                startIcon={<ErrorOutlineIcon />}
                                disabled={
                                  jianInfo === null ||
                                  (jianInfo !== undefined && jianOngoing()) ||
                                  jianInfo?.jianReset !== undefined ||
                                  loadingResetStatus
                                }
                                onClick={() => resetButtonOnClick()}
                              >
                                リセットする
                              </Button>
                            </ComponentLoadingIndicator>
                          </div>
                        </AppTooltip>
                      </AppSkeleton>
                      <Box flex={1}>
                        <FormHelperText>
                          安否確認をする地域、災害種別の設定、及び、登録した安否確認結果をすべてリセットします。
                          <br />
                          この操作を実行すると元に戻すことはできません。
                        </FormHelperText>
                      </Box>
                    </Stack>
                  }
                />
              </DataList>
            </Section>
            <Divider />
            <Section
              title="LINEメッセージを送信する"
              action={
                <Stack alignItems="center">
                  <Box width={{ xs: '100%', sm: 'auto' }}>
                    <AppTooltip
                      show={!jianOngoing() || selectedMessageTemplate == null}
                      message={!jianOngoing() ? MESSAGE_JIAN_NOT_START : MESSAGE_EMPTY_FIELD}
                    >
                      <div>
                        <ComponentLoadingIndicator loading={isLoadingSendLine}>
                          <SendLineButton
                            onClick={handleOpenConfirmSendLineDialog}
                            disabled={!jianOngoing() || isLoadingSendLine || selectedMessageTemplate == null}
                          />
                        </ComponentLoadingIndicator>
                      </div>
                    </AppTooltip>
                  </Box>
                </Stack>
              }
            >
              <DataList>
                <DataListRow
                  title="メッセージの内容"
                  titleWidth={DATA_LIST_TITLE_WIDTH}
                  value={
                    <AppTooltip show={!jianOngoing()} message={MESSAGE_JIAN_NOT_START}>
                      <div>
                        <AppSkeleton
                          show={jianInfo === undefined || !initializedTemplates || isLoadingTemplates}
                          sx={{ minWidth: { xs: '100%', lg: 300 } }}
                        >
                          <Select
                            value={generateSelectedTemplate()}
                            defaultValue={''}
                            onChange={handleChangeLineMessageTemplate}
                            size="small"
                            disabled={!jianOngoing() || isLoadingTemplates || messageTemplates.length === 0}
                            sx={{ minWidth: { xs: '100%', lg: 300 } }}
                          >
                            {messageTemplates.map(({ templateid, template_title }) => (
                              <MenuItem key={templateid} value={templateid}>
                                {template_title}
                              </MenuItem>
                            ))}
                          </Select>
                        </AppSkeleton>
                      </div>
                    </AppTooltip>
                  }
                />
                <Divider />
              </DataList>
            </Section>
          </Stack>
        </Container>
        {/* 送信確認ダイアログ */}
        <ConfirmDialog
          open={isOpenConfirmSendLineDialog}
          message={
            <>
              安否確認中の地域に、選択したLINEメッセージ
              <br />
              を送信します。
              <br />
              よろしいですか？
            </>
          }
          onConfirm={handleSendMessage}
          onClose={handleCloseConfirmSendLineDialog}
        />
        <ConfirmDialog
          open={jianSoshikiUpdateCondfirmDialogOpen}
          message={
            <>
              追加（削除）した地域は、すぐに安否確認が可能（不可能）な状態となります。
              <br />
              よろしいですか？
            </>
          }
          onConfirm={() => {
            setJianSoshikiUpdateCondfirmDialogOpen(false);
            setLoadingJianSoshiki(true);

            // selectedJianSoshikiListDialogValuesとselectedJianSoshikiListの差分を取得
            const soshikiIdList = {
              add: selectedJianSoshikiListDialogValues
                .filter((x) => !selectedJianSoshikiList.some((y) => x.soshikiId === y.soshikiId))
                .map((x) => x.soshikiId),
              delete: selectedJianSoshikiList
                .filter((x) => !selectedJianSoshikiListDialogValues.some((y) => x.soshikiId === y.soshikiId))
                .map((x) => x.soshikiId),
            };

            updateJianSoshikiList(jianInfo!.jianId, soshikiIdList)
              .then(() => {
                setSelectedJianSoshikiList(selectedJianSoshikiListDialogValues);
                enqueueSnackbar('安否確認組織を更新しました。', { variant: 'success' });
              })
              .catch(() => navigateSystemError())
              .finally(() => setLoadingJianSoshiki(false));
          }}
          onClose={() => setJianSoshikiUpdateCondfirmDialogOpen(false)}
        />
      </KanriPageContainer>
    </PageContainer>
  );
};
