export const IconGroup = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.48 14C15.59 14.09 15.71 14.2 15.84 14.33C16.94 15.46 17.62 16.87 17.83 18.41C17.92 19.05 17.97 19.72 17.99 20.41C17.99 20.46 17.99 20.73 17.99 21H21.99C21.99 20.77 21.99 20.55 21.99 20.51C21.97 19.93 21.93 19.33 21.84 18.68C21.69 17.54 21.16 16.52 20.4 15.73C20.27 15.6 20.12 15.49 19.98 15.37C19.87 15.28 19.77 15.17 19.66 15.09C18.98 14.6 18.18 14.27 17.32 14.16C16.67 14.07 16.07 14.03 15.49 14.01C15.49 14.01 15.48 14.01 15.47 14.01L15.48 14Z"
        fill="#999999"
      />
      <path
        d="M9 5C9.21 5 9.4 5.02 9.58 5.05C10 5.13 11 5.31 11 7.5C11 9.69 10.01 9.88 9.58 9.95C9.4 9.98 9.21 10 9 10C8.79 10 8.6 9.98 8.42 9.95C7.82 9.84 7.48 9.56 7.25 8.97C7.17 8.77 7.12 8.56 7.08 8.35C7.03 8.04 7 7.77 7 7.5C7 7.23 7.02 6.96 7.08 6.65C7.12 6.43 7.17 6.23 7.25 6.03C7.48 5.44 7.82 5.16 8.42 5.05C8.6 5.02 8.79 5 9 5ZM9 3C8.67 3 8.35 3.03 8.06 3.08C6.73 3.32 5.84 4.12 5.38 5.31C5.26 5.62 5.16 5.96 5.1 6.33C5.04 6.7 5 7.09 5 7.5C5 7.91 5.04 8.31 5.1 8.67C5.16 9.04 5.26 9.38 5.38 9.69C5.84 10.88 6.73 11.67 8.06 11.92C8.35 11.97 8.66 12 9 12C9.34 12 9.65 11.97 9.94 11.92C11.98 11.55 13 9.92 13 7.5C13 5.08 11.98 3.45 9.94 3.08C9.65 3.03 9.34 3 9 3Z"
        fill="#999999"
      />
      <path
        d="M9.00001 16H9.27001H9.43001C10 16.02 10.53 16.06 11.06 16.13C11.56 16.2 12.05 16.39 12.47 16.68L12.51 16.72C12.57 16.77 12.63 16.83 12.69 16.88L12.85 17.01C12.85 17.01 12.94 17.08 12.98 17.12C13.48 17.63 13.79 18.26 13.88 18.94C13.88 18.96 13.88 18.98 13.88 19H4.13001C4.13001 19 4.13001 18.96 4.13001 18.94C4.17001 18.63 4.27001 18.31 4.42001 17.99C4.43001 17.97 4.44001 17.94 4.45001 17.92C4.48001 17.87 4.51001 17.82 4.54001 17.77C4.64001 17.61 4.75001 17.44 4.88001 17.29L5.00001 17.14C5.00001 17.14 5.05001 17.08 5.06001 17.06C5.57001 16.55 6.24001 16.22 6.93001 16.12H7.05001L7.22001 16.09C7.70001 16.04 8.15001 16 8.59001 15.99C8.66001 15.99 8.83001 15.99 9.00001 15.99M9.00001 14C8.79001 14 8.59001 14 8.51001 14C8.03001 14.01 7.53001 14.05 7.00001 14.11C6.89001 14.12 6.79001 14.13 6.68001 14.14C5.51001 14.3 4.46001 14.84 3.66001 15.64C3.55001 15.75 3.46001 15.87 3.37001 15.99C3.17001 16.23 2.99001 16.49 2.83001 16.76C2.78001 16.85 2.72001 16.93 2.67001 17.02C2.65001 17.06 2.64001 17.09 2.63001 17.13C2.40001 17.61 2.24001 18.13 2.16001 18.67C2.07001 19.32 2.03001 19.92 2.01001 20.5C2.01001 20.55 2.01001 20.65 2.01001 20.76C2.01001 20.83 2.01001 20.91 2.01001 20.99H16.01C16.01 20.76 16.01 20.54 16.01 20.5C15.99 19.92 15.95 19.32 15.86 18.67C15.71 17.53 15.18 16.51 14.42 15.72C14.29 15.59 14.14 15.48 14 15.36C13.89 15.27 13.79 15.16 13.68 15.08C13 14.59 12.2 14.26 11.34 14.15C10.69 14.06 10.09 14.02 9.51001 14C9.47001 14 9.40001 14 9.31001 14C9.22001 14 9.12001 14 9.02001 14H9.00001Z"
        fill="#999999"
      />
      <path
        d="M15 3C14.67 3 14.35 3.03 14.06 3.08C13.94 3.1 13.84 3.15 13.73 3.18C14.55 4.28 15 5.75 15 7.5C15 9.25 14.55 10.72 13.72 11.82C13.84 11.85 13.94 11.9 14.06 11.92C14.35 11.97 14.66 12 15 12C15.34 12 15.65 11.97 15.94 11.92C17.98 11.55 19 9.92 19 7.5C19 5.08 17.98 3.45 15.94 3.08C15.65 3.03 15.34 3 15 3Z"
        fill="#999999"
      />
    </svg>
  );
};
