export const Illust04 = () => {
  return (
    <svg width="343" height="260" viewBox="0 0 343 260" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_732_17992)">
        <path d="M150.205 79.052H191.59L170.475 148.825L150.205 79.052Z" fill="white" />
        <path
          d="M133.76 195.4H205.409L205.169 284.932L197.76 374.955C197.76 374.955 191.673 376.466 184.534 376.643C177.396 376.82 171.299 374.955 171.299 374.955C171.299 374.955 166.682 376.695 156.719 376.653C146.756 376.612 144.828 374.6 144.828 374.6L133.77 195.4H133.76Z"
          fill="white"
        />
        <path
          d="M135.584 196.713C135.584 196.713 137.991 274.073 138.533 284.922C139.075 295.771 143.733 374.944 143.733 374.944C143.733 374.944 149.424 376.633 159.689 376.633C169.954 376.633 171.299 374.944 171.299 374.944V217.817"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.014 196.713C207.014 196.713 205.711 274.073 205.169 284.922C204.627 295.771 198.864 374.944 198.864 374.944C198.864 374.944 193.174 376.633 182.909 376.633C172.654 376.633 171.299 374.944 171.299 374.944V217.817"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.58 77.5721C148.58 77.5721 129.852 84.4921 125.6 86.2638C121.348 88.0355 121.045 93.163 120.045 96.5396C119.044 99.9162 114.594 131.588 114.105 151.629C113.729 167.251 112.666 167.188 114.824 177.87C116.126 184.301 117.054 210.782 117.054 210.782L134.02 209.552L133.447 200.183C133.447 200.183 148.538 207.999 170.486 207.999C192.434 207.999 207.212 201.632 207.212 201.632L206.722 209.042L224.918 209.98L229.254 163.718C229.254 163.718 227.096 113.631 222.844 101.271C221.073 96.1227 220.77 87.3372 212.85 85.5551C199.458 82.5433 192.392 77.8118 192.392 77.8118L189.151 82.1056L177.708 123.553L173.029 102.032L177.719 90.7868C177.719 90.7868 172.268 91.9853 169.965 91.8186C167.662 91.6518 162.763 90.7868 162.763 90.7868L168.381 102.313L163.264 124.251L152.154 81.3969L148.59 77.5617L148.58 77.5721Z"
          fill="#CFCFCF"
        />
        <path
          d="M150.205 160.07L196.009 146.949"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M184.91 116.904H207.202V122.604L196.05 128.138L184.91 122.604V116.904Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.202 116.904H184.91V137.403H207.202V116.904Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.073 116.904H156.375V122.604L145.224 128.138L134.073 122.604V116.904Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M156.364 116.904H134.073V137.403H156.364V116.904Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.357 89.9218L168.089 99.5827C169.277 101.146 171.476 101.156 172.695 99.6035L178.323 89.9218"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M172.695 99.6035L177.698 123.563"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M163.285 123.563L168.089 99.5723"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M189.735 81.4073L170.476 148.825L151.31 81.5324"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.312 201.736C134.312 201.736 145.484 208.02 170.455 208.02C195.425 208.02 207.504 201.736 207.504 201.736"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.312 193.472C134.312 193.472 145.484 199.756 170.455 199.756C195.425 199.756 207.504 193.472 207.504 193.472"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M175.113 132.609L168.641 155.255V207.999"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M194.737 78.5101L200.594 105.283L192.173 98.4467L180.96 112.13"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.089 79.2813L140.399 105.283L148.809 98.4467L160.023 112.13"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M225.481 201.611L208.921 200.194"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M116.345 201.653L133.437 200.194"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M210.974 209.709L221.792 211.209L223.272 230.812L211.933 252.625L209.442 251.968L210.953 242.11C210.953 242.11 209.619 243.089 208.504 242.235C207.389 241.38 207.264 237.024 207.264 237.024C207.264 237.024 209.838 229.77 209.807 227.759C209.776 225.758 207.712 222.35 208.098 220.172C208.483 217.994 210.985 209.709 210.985 209.709H210.974Z"
          fill="white"
        />
        <path
          d="M221.771 210.834C221.771 210.834 222.949 228.52 222.542 232.532C222.136 236.544 213.6 248.821 212.777 251.062C211.954 253.313 210.078 252.75 209.171 251.687C208.264 250.624 212.933 239.317 214.299 237.128C215.664 234.939 215.028 228.947 215.028 228.947C215.028 228.947 213.423 243.662 209.098 242.693C204.773 241.724 208.369 233.543 209.202 229.145C210.036 224.757 206.295 223.476 209.432 214.669C210.12 212.741 210.63 211.199 211.005 209.98"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M131.947 209.709L121.129 211.209L119.649 230.812L130.988 252.625L133.478 251.968L131.967 242.11C131.967 242.11 133.301 243.089 134.416 242.235C135.532 241.38 135.657 237.024 135.657 237.024C135.657 237.024 133.082 229.77 133.114 227.759C133.145 225.748 135.208 222.35 134.823 220.172C134.437 217.994 131.936 209.709 131.936 209.709H131.947Z"
          fill="white"
        />
        <path
          d="M121.15 210.834C121.15 210.834 119.972 228.52 120.379 232.532C120.785 236.544 129.32 248.821 130.144 251.062C130.967 253.313 132.843 252.75 133.75 251.687C134.656 250.624 129.987 239.317 128.622 237.128C127.257 234.939 127.893 228.947 127.893 228.947C127.893 228.947 129.498 243.662 133.823 242.693C138.148 241.724 134.552 233.543 133.718 229.145C132.885 224.747 136.626 223.476 133.489 214.669C132.801 212.741 132.291 211.199 131.915 209.98"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M164.337 15.2817V13.9999H176.52V15.1463C176.52 15.1463 192.934 17.5016 198.114 36.938C198.114 36.938 199.771 40.8565 199.646 46.0778C199.521 51.2991 201.939 54.6757 201.939 54.6757C201.939 54.6757 196.884 65.7018 186.535 64.1698C175.301 62.5128 166.797 64.0656 153.134 64.2636C141.868 64.4304 139.523 54.7799 139.523 54.7799C139.523 54.7799 141.274 52.9665 141.545 49.2773C141.816 45.588 139.982 22.7541 164.337 15.2713V15.2817Z"
          fill="#FEDA04"
        />
        <path
          d="M138.877 59.1987C138.877 59.1987 136.876 61.3039 137.293 64.8681C137.71 68.4323 138.637 72.1737 144.932 72.6323C144.932 72.6323 146.256 71.3608 148.09 75.4669C149.924 79.5731 156.396 91.8186 170.465 91.8186C184.534 91.8186 194.247 75.6858 195.665 72.2258C195.665 72.2258 203.825 71.9965 203.7 65.3996C203.575 58.8027 201.001 58.3962 201.001 58.3962C201.001 58.3962 189.766 65.7018 170.476 65.7018C151.185 65.7018 139.805 59.4801 139.805 59.4801L138.888 59.1987H138.877Z"
          fill="white"
        />
        <path
          d="M146.089 61.9188C146.089 61.9188 147.267 67.9216 149.726 67.7445C152.186 67.5673 149.841 63.555 152.3 63.68C154.76 63.8051 146.089 61.9188 146.089 61.9188Z"
          fill="#6D6D6D"
        />
        <path
          d="M194.914 60.7932C194.914 60.7932 193.737 67.9112 191.277 67.7445C188.818 67.5777 191.163 63.555 188.703 63.68C186.254 63.8051 194.914 60.7932 194.914 60.7932Z"
          fill="#6D6D6D"
        />
        <path
          d="M196.009 69.0472C194.685 73.4868 192.663 77.8431 189.631 81.5324C185.003 87.1705 176.583 91.829 170.465 91.829C164.348 91.829 155.927 87.1705 151.3 81.5324C148.277 77.8431 146.256 73.4868 144.922 69.0472"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M201.324 58.7818C203.616 60.1366 203.512 63.4195 203.241 65.1703C202.439 70.4437 198.885 72.6427 195.675 72.6427"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M144.942 72.6323C141.733 72.6323 138.189 70.4437 137.376 65.1599C137.147 63.6696 137.345 61.0538 138.992 59.4801"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.507 79.052C171.428 79.052 172.174 78.4268 172.174 77.6555C172.174 76.8842 171.428 76.259 170.507 76.259C169.586 76.259 168.839 76.8842 168.839 77.6555C168.839 78.4268 169.586 79.052 170.507 79.052Z"
          fill="black"
        />
        <path
          d="M174.738 83.6063C174.457 83.7001 172.8 84.0231 170.507 84.0231C168.214 84.0231 166.734 83.7834 166.307 83.6479"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M177.698 70.6834L178.531 70.9648C180.011 71.4754 181.637 71.3608 183.023 70.6417L184.211 70.0268"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M163.253 70.6938L162.42 70.9752C160.94 71.4859 159.314 71.3608 157.928 70.6521L156.74 70.0373"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M175.78 15.7507C188.859 18.2207 198.76 29.7054 198.76 43.5141V51.0073"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.264 51.0073C142.097 44.9002 142.264 43.5141 142.264 43.5141C142.264 29.8617 151.946 18.4708 164.817 15.8341"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.476 63.1798C176.155 63.1798 186.525 64.8473 190.266 63.8676C194.008 62.888 201.939 58.3129 201.939 55.4886C201.939 52.6643 199.917 51.5075 199.917 51.5075"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.475 63.1798C164.796 63.1798 154.426 64.8473 150.685 63.8676C146.943 62.888 139.012 58.3129 139.012 55.4886C139.012 52.6643 141.034 51.5075 141.034 51.5075"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.715 56.7287C148.715 56.7287 155.771 55.2593 158.511 54.832C160.033 54.5923 164.41 54.436 170.476 54.4568"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M192.236 56.7287C192.236 56.7287 185.181 55.2593 182.44 54.832C180.918 54.5923 176.541 54.436 170.476 54.4568"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M155.27 20.6072C155.27 20.6072 157.115 44.5354 147.902 46.3279"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M185.681 20.6072C185.681 20.6072 183.836 44.5354 193.049 46.3279"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M163.733 36.5941L165.171 30.3411V14.1249H175.78V30.3411L177.218 36.5941"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.58 78.0828C142.525 79.9587 134.031 83.2207 130.237 84.7631C122.723 87.8271 120.9 93.1109 119.534 98.8115C114.251 120.947 113 164.437 113 164.437L117.054 210.792L134.021 209.563L133.291 169.064L133.041 117.498L131.102 108.222"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M192.382 77.8223C198.468 79.7086 207.129 82.9601 210.953 84.513C218.467 87.5769 220.916 93.1108 222.282 98.8115C227.565 120.947 228.42 164.437 228.42 164.437L224.762 210.792L207.796 209.563L208.525 169.064L208.431 117.498L210.318 108.222"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_732_17992">
          <rect width="343" height="260" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
