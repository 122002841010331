export const IconBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(180deg)' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10 19C9.74 19 9.49 18.9 9.29 18.71C8.9 18.32 8.9 17.69 9.29 17.3L14.58 12.01L9.29 6.72002C8.9 6.33002 8.9 5.70002 9.29 5.31002C9.68 4.92002 10.31 4.92002 10.7 5.31002L16.7 11.31C17.09 11.7 17.09 12.33 16.7 12.72L10.7 18.72C10.5 18.92 10.25 19.01 9.99 19.01L10 19Z"
        fill="#000000"
      />
    </svg>
  );
};
