import { GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { SearchConditions } from 'src/components/kanri/pages/jichitai/CreateAccountPage/components/SearchConditionField/types';
import {
  PaginationModel,
  UserGridRow,
} from 'src/components/kanri/pages/jichitai/CreateAccountPage/components/UsersTable/types';
import { useUserData } from 'src/hooks/common/useUserData';
import { searchUsers } from 'src/utilities/restApi/kanri/search/searchUsers';

type PageInfo = {
  totalRowCount: number;
  hasNextPage: boolean;
  pageSize: number;
};

type HookParams = {
  paginationModel: PaginationModel;
  searchConditions: SearchConditions;
};

export const useQuery = ({ paginationModel, searchConditions }: HookParams) => {
  const [controller, setController] = useState<AbortController>();
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rows, setRows] = useState<GridRowsProp<UserGridRow>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    totalRowCount: 0,
    hasNextPage: false,
    pageSize: 0,
  });

  useEffect(() => {
    search();
  }, [userData, paginationModel, searchConditions]);

  // functions
  const search = () => {
    if (userData) {
      controller?.abort();
      setIsLoading(true);
      const newController = new AbortController();
      setController(newController);

      searchUsers(
        {
          page: paginationModel.page,
          page_size: paginationModel.pageSize,
          search_conditions: {
            roleid: searchConditions.roleid,
            delflg: searchConditions.delflg,
          },
        },
        newController
      )
        .then((response) => {
          if (response.data) {
            const gridRows: GridRowsProp<UserGridRow> = response.data.search_results.rows.map((row) => ({
              id: row.userid,
              name: `${row.fname} ${row.sname}`,
              roleid: row.roleid,
              delflg: row.delflg,
              mailAddress: row.mailaddress,
              linkedAadb2cTypeCode: row.linked_aadb2c_type_code,
              soshikiList: row.soshiki_list?.map((x) => ({ soshikiId: x.id, soshikiName: x.name })) ?? null,
            }));
            setRows(gridRows);
            setPageInfo({
              totalRowCount: response.data.search_results.page_info.total_row_count,
              hasNextPage: response.data.search_results.page_info.has_next_page,
              pageSize: paginationModel.pageSize,
            });
          }
        })
        .catch((error) => {
          if (error !== 'canceled') {
            setIsError(true);
          }
        })
        .finally(() => {
          // abort時は次のリクエストのローディングが走るので、ローディングを解除しない
          if (!newController.signal.aborted) {
            setIsLoading(false);
          }
        });
    }
  };

  return { isLoading, rows, pageInfo, isError, search };
};
