import { Button, Stack, Typography, useTheme } from '@mui/material';
import { IconBar } from 'src/components/shien/atoms/images/IconBar';
import { IconCheck } from 'src/components/shien/atoms/images/IconCheck';
import { IconNext } from 'src/components/shien/atoms/images/IconNext';
import { StatusColor } from 'src/types/common/statusColor';

type ListButtonProps = {
  statusText: string;
  barColor?: StatusColor | 'none';
  displayChecked?: boolean;
  displayArrow?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export const ListButton = ({
  statusText,
  barColor = 'none',
  displayChecked = false,
  displayArrow = false,
  disabled = false,
  onClick,
}: ListButtonProps) => {
  const theme = useTheme();

  const barColorVariation: {
    [key: string]: string;
  } = {
    red: theme.palette.uiStandard.red50,
    green: theme.palette.uiStandard.green40,
    yellow: theme.palette.uiStandard.yellow50,
    none: 'rgba(0,0,0,0)', // 指定しない時は透過
  };

  return (
    <Button
      variant="secondary"
      onClick={onClick}
      disabled={disabled}
      fullWidth
      sx={{
        padding: '14px 8px 14px 16px',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
        <IconBar fill={barColorVariation[barColor]} />
        <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%', minHeight: '32px' }}>
          <Typography
            sx={{
              color: theme.palette.uiStandard.black,
              textAlign: 'left',
              fontSize: '20px',
              fontWeight: 'bold',
              lineHeight: '28px',
              flexGrow: '1',
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            {statusText}
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ minWidth: '56px' }}>
            {displayChecked && <IconCheck />}
            {displayArrow && <IconNext />}
          </Stack>
        </Stack>
      </Stack>
    </Button>
  );
};
