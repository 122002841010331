import { Box, styled } from '@mui/material';
import { RequestPrsStatusBody, RequestPrsStatusQuery } from 'src/utilities/restApi/common/types/prsStatus';

export const Overlay = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

export type PrsStatusRow = {
  prsstatusid: string;
  jichitaiid: string | undefined;
  status: string | null;
  color: string | null;
  sortorder: number | '';
};

export const setRequestQuery = (row: PrsStatusRow): RequestPrsStatusQuery => {
  return {
    prsstatusid: row.prsstatusid,
  };
};

export const setRequestData = (row: PrsStatusRow): RequestPrsStatusBody => {
  return {
    sortorder: row.sortorder,
    status: row.status,
    color: row.color,
  };
};

export const setNewRowData = (param?: string): PrsStatusRow => {
  return {
    prsstatusid: '',
    jichitaiid: param,
    status: '',
    color: '',
    sortorder: '',
  };
};
