import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { Illust04 } from 'src/components/common/atoms/images/Illust04';
import { useLineIntegration } from 'src/components/shien/pages/SupporterProfilePage/hooks/useLineIntegration';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';

enum ButtonContent {
  NotLinked = 'Line連携する',
  Linked = 'Line連携済',
}

export const SystemStopInformationPage = () => {
  const theme = useTheme();
  const navigateSystemError = useNavigateSystemError();

  const { isLinked, isImportedJichitaiData, loading, error: lineIntegrationError, link, unlink } = useLineIntegration();

  useEffect(() => {
    if (lineIntegrationError) {
      navigateSystemError();
    }
  }, [lineIntegrationError]);

  const handleLineClick = () => {
    if (isLinked === true) {
      unlink();
    } else if (isLinked === false) {
      link();
    } else {
      // undefinedの場合何もしない(クリック不可のためこの分岐には入らない想定)
    }
  };

  return (
    <PageContainer title="システム停止案内">
      <Container maxWidth="lg" sx={{ height: '100%', paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) }}>
        <Stack alignItems="center" sx={{ height: '100%' }}>
          <Box marginTop="36px">
            <Typography variant="headline">ただいまご利用できません</Typography>
          </Box>
          <Box marginTop="30px">
            <Typography variant="body1">
              現在、安否確認の実施期間外のため、一部を除き、このサービスは利用できません。安否確認時には、ご協力をお願いします。
            </Typography>
          </Box>
          <StackSpacer />
          <Illust04 />
          <StackSpacer />
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Button
              variant={loading || isLinked == null || isLinked || !isImportedJichitaiData ? 'secondary' : 'primary'}
              sx={{ flex: 1, minHeight: '64px' }}
              onClick={handleLineClick}
              fullWidth
              disabled={loading || isLinked == null || isLinked || !isImportedJichitaiData}
            >
              <AppSkeleton show={loading || isLinked == null || !isImportedJichitaiData}>
                <span>{isLinked ? ButtonContent.Linked : ButtonContent.NotLinked}</span>
              </AppSkeleton>
            </Button>
            <Button variant="primary" sx={{ flex: 1 }} onClick={() => {}} fullWidth>
              閉じる
            </Button>
          </Stack>
        </Stack>
      </Container>
    </PageContainer>
  );
};
