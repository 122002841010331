import { appConst } from 'src/constants/common';

/**
 * boolean型を文字列に変換する
 * - false -> '0'
 * - true -> '1'
 * - undefined -> undefined
 * @param value 変換対象の値
 * @returns 変換後の値
 */
export const convertBool2String = (value?: boolean): string | undefined => {
  if (typeof value === 'boolean') {
    return value ? appConst.STR_TRUE : appConst.STR_FALSE;
  }
  return value;
};

/**
 * 文字列をboolean型に変換する
 * - '0' -> false
 * - '1' -> true
 * - それ以外 -> undefined
 * @param value 変換対象の値
 * @returns 変換後の値
 */
export const convertString2Bool = (value?: string): boolean | undefined => {
  if (value === appConst.STR_FALSE) {
    return false;
  }
  if (value === appConst.STR_TRUE) {
    return true;
  }
  return undefined;
};
