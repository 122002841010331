import { Button, Typography, useTheme } from '@mui/material';

type HeaderButtonProps = {
  variant: 'primary' | 'secondary';
  text: string;
  disabled?: boolean;
  onClick: () => void;
};

export const HeaderButton = ({ variant, text, disabled = false, onClick }: HeaderButtonProps) => {
  const theme = useTheme();
  return (
    <Button onClick={onClick} variant={variant} sx={{ padding: '16px' }} disabled={disabled}>
      <Typography
        noWrap
        sx={{
          color: variant === 'primary' ? theme.palette.uiStandard.white : theme.palette.uiStandard.black,
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '16px',
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};
