type IconBarProps = {
  fill: string;
};

export const IconBar = ({ fill }: IconBarProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="24" viewBox="0 0 4 24" fill="none">
      <rect width="4" height="24" rx="1" fill={fill} />
    </svg>
  );
};
