import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';
import { LastUpdateTime, LastUpdateTimeProps } from 'src/components/shien/atoms/LastUpdateTime';
import { IconNext } from 'src/components/shien/atoms/images/IconNext';

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  lineHeight: '20px',
  color: theme.palette.uiStandard.black,
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '20px',
  color: theme.palette.uiStandard.gray50,
}));

type ComponentProps = LastUpdateTimeProps & {
  icon: ReactNode;
  title: string;
  description: string;
  onClick: () => void;
  disabled?: boolean;
};

export const ActionMenuButton = ({ icon, title, description, onClick, disabled = false, time }: ComponentProps) => {
  return (
    <Button variant="secondary" sx={{ padding: '0', position: 'relative' }} onClick={onClick} disabled={disabled}>
      {time && <LastUpdateTime time={time} />}
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Box sx={{ mx: '20px' }}>{icon}</Box>
        <Stack flexGrow={1} spacing={1} sx={{ textAlign: 'left' }}>
          <TitleText>{title}</TitleText>
          <DescriptionText>{description}</DescriptionText>
        </Stack>
        <Box sx={{ m: '36px 8px' }}>
          <IconNext />
        </Box>
      </Stack>
    </Button>
  );
};
