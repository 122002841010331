import { createTheme, ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { getDesignTokens } from 'src/themes/NoAuthAreaTheme';

export const NoAuthAreaTemplate = () => {
  const designTokens = getDesignTokens('light');
  const theme = createTheme(designTokens);
  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
};
