import { Chip, useTheme } from '@mui/material';

type SelectableChipProps = { label: string; selected: boolean; onClick?: () => void };
export const SelectableChip = ({ label, selected, onClick }: SelectableChipProps) => {
  const theme = useTheme();
  return (
    <Chip
      label={label}
      size="small"
      variant="filled"
      color="primary"
      sx={{
        border: '1px solid transparent',
        ...(selected
          ? {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }
          : {
              backgroundColor: 'transparent',
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: 'transparent',
                border: `1px solid ${theme.palette.primary.main}`,
              },
            }),
      }}
      clickable
      onClick={onClick}
    />
  );
};
