export const IconPerson = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M16 16C12.87 16 11 13.94 11 10.5C11 7.06 12.87 5 16 5C19.13 5 21 7.06 21 10.5C21 13.94 19.13 16 16 16ZM16 7C14.01 7 13 8.18 13 10.5C13 12.82 14.01 14 16 14C17.99 14 19 12.82 19 10.5C19 8.18 17.99 7 16 7Z"
        fill="#999999"
      />
      <path
        d="M7.53997 27C7.10997 27 6.68997 26.81 6.40997 26.49C6.11997 26.16 5.99997 25.73 6.04997 25.3C6.27997 23.59 7.68997 18.01 16 18.01C24.31 18.01 25.7 23.59 25.93 25.3C25.99 25.73 25.86 26.16 25.57 26.49C25.29 26.82 24.87 27 24.44 27H7.53997ZM16.01 20C10.16 20 8.56997 23.15 8.13997 25H23.85C23.42 23.15 21.84 20 16 20H16.01Z"
        fill="#999999"
      />
    </svg>
  );
};
