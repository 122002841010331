import { useMsal } from '@azure/msal-react';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { useInitialUrlByUser } from 'src/hooks/common/useInitialUrlByUser';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserDetails } from 'src/utilities/azure/hooks/useUserDetails';
import { updateJotai } from 'src/utilities/azure/jotai';
import { loginWithRedirect } from 'src/utilities/azure/signin';

export const AzureSigninPage = () => {
  const { instance: pca, accounts, inProgress } = useMsal();
  const { details: userDetails, error: userDetailsError } = useUserDetails(accounts[0]);
  const { url: initialUrl, error: initialUrlError } = useInitialUrlByUser(userDetails);
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  /** クリーンアップできないログイン処理がStrictMode時に2回実行されるのを防ぐためのフラグ */
  const isInitialRenderRef = useRef(true);

  // ログイン状態に応じた処理
  useEffect(() => {
    if (accounts.length > 0) {
      // [認可済みユーザーあり]
      // useUserDetailsによりユーザー情報取得
    } else if (inProgress != 'none') {
      // [処理中]
    } else {
      // [認可なし]
      if (isInitialRenderRef.current) {
        isInitialRenderRef.current = false;

        loginWithRedirect(pca).catch(() => {
          navigateSystemError();
        });
      }
    }
  }, [accounts, inProgress]);

  useEffect(() => {
    if (userDetails == null) return;
    updateJotai(userDetails);
  }, [userDetails]);

  useEffect(() => {
    if (initialUrl != null) {
      navigate(initialUrl);
    }
  }, [initialUrl]);

  useEffect(() => {
    if (userDetailsError || initialUrlError) {
      navigateSystemError();
    }
  }, [userDetailsError, initialUrlError]);

  return <PageContainer title="サインイン" />;
};
