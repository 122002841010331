import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import {
  PreFetchedData,
  SupporterDetailsDialog,
} from 'src/components/kanri/pages/jichitai/SearchSupportersPage/components/SupporterDetailsDialog';
import { SupportersTable } from 'src/components/kanri/pages/jichitai/SearchSupportersPage/components/SupportersTable';

import { Stack } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchConditionField } from 'src/components/common/SearchConditionField';
import { SearchType } from 'src/components/common/types/SupportersGridTypes';
import { SearchConditions } from 'src/components/common/types/SupportersSearchConditions';
import { SnackbarMessageId, snackbarMessages } from 'src/constants/snackbarMessages';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';

export const SupportersPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();

  // States
  const [detailsShownSpId, setDetailsShownSpId] = useState<string>();
  const [detailsShownPreFetchedData, setDetailsShownPreFetchedData] = useState<PreFetchedData>();
  const [supporterModalOpen, setSupporterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<SearchCallerType | undefined>('system');
  // search state
  const [searchType, setSearchType] = useState<SearchType>('search');
  const [searchConditions, setSearchConditions] = useState<SearchConditions>({
    simple: {
      spCheckId: '',
      soshikiList: [],
    },
    advanced: undefined,
  });

  // Event handelers
  const supporterDetailsClick = (spId: string, preFetchedData: Readonly<PreFetchedData>) => {
    setDetailsShownSpId(spId);
    setDetailsShownPreFetchedData(preFetchedData);
    setSupporterOpen(true);
  };

  const supportDialogClose = () => {
    setDetailsShownSpId(undefined);
    setSupporterOpen(false);
  };

  const changeAssignmentOfSupporterRecipients = (spId: string, soshikiids: string[]) => {
    navigate('/app/kanri/select-support-recipients', { state: { spId: spId, soshikiids: soshikiids } });
  };

  const onSearchError = (searchError: SearchError) => {
    if (searchError.isError) {
      if (![axios.AxiosError.ERR_CANCELED, axios.AxiosError.ECONNABORTED].includes(searchError.reason ?? '')) {
        navigateSystemError();
      } else if (searchError.reason === axios.AxiosError.ECONNABORTED) {
        const snackbarMessage = snackbarMessages[SnackbarMessageId.SearchTimeOut];

        if (snackbarMessage) {
          enqueueSnackbar(snackbarMessage.message, {
            variant: snackbarMessage.variant,
          });
        }
      }
    }
  };

  return (
    <PageContainer title="支援者一覧">
      <KanriPageContainer title="支援者一覧">
        <Stack spacing={2} sx={{ overflow: 'auto', height: '100%' }}>
          <SearchConditionField
            searchButtonOnClick={(searchConditions) => {
              setSearchConditions(searchConditions);
              setSearchType('search');
            }}
            isLoading={isLoading}
          />
          <SupportersTable
            onDetailsClick={(spId, preFetchedData) => {
              supporterDetailsClick(spId, preFetchedData);
            }}
            onChangeAssignClick={(spId, soshikiIds) => {
              changeAssignmentOfSupporterRecipients(spId, soshikiIds);
            }}
            searchType={searchType}
            setSearchType={setSearchType}
            searchConditions={searchConditions}
            onSearchError={onSearchError}
            onChangeLoadingStatus={(isLoading) => {
              setIsLoading(isLoading);
            }}
          />
        </Stack>
      </KanriPageContainer>
      <SupporterDetailsDialog
        open={supporterModalOpen}
        onClose={() => supportDialogClose()}
        spId={detailsShownSpId}
        preFetched={detailsShownPreFetchedData}
      />
    </PageContainer>
  );
};
