import { Box, Stack, styled } from '@mui/material';
import { StatusColor } from 'src/types/common/statusColor';

export type StatusLabelProps = {
  status: string;
  statusColor: StatusColor | 'none';
};

const labelColors = {
  red: { bg: 'red20', text: 'red50' },
  yellow: { bg: 'yellow20', text: 'yellow60' },
  green: { bg: 'green20', text: 'green50' },
  none: { bg: 'red20', text: 'red50' }, // noneは基本入らない予定
} as const;

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'statusColor' })<{
  statusColor: StatusColor | 'none';
}>(({ theme, statusColor }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  padding: '5px 7px 5px 7px',
  borderRadius: '3px',
  backgroundColor: theme.palette.uiStandard[labelColors[statusColor].bg],
  color: theme.palette.uiStandard[labelColors[statusColor].text],
  whiteSpace: 'nowrap',
}));

export const StatusLabel = ({ status, statusColor }: StatusLabelProps) => {
  return (
    <Stack direction="row" alignItems="center">
      <StyledBox statusColor={statusColor}>{status}</StyledBox>
    </Stack>
  );
};
