import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routerPaths } from 'src/routes/path';

export const IndexPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: クエリパラメーター等を見てLINEログインの場合はLINEログイン用のサインインページをredirectUrlに設定する
    const redirectUrl = routerPaths['azure-signin'];
    navigate(redirectUrl);
  }, []);

  return null;
};
