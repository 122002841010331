import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type ComponentProps = { children: ReactNode; width?: number };

export const SearchConditionLabel: FC<ComponentProps> = ({ children, width }) => {
  return (
    <Box sx={{ ...(width ? { width, minWidth: width } : {}) }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {children}:
      </Typography>
    </Box>
  );
};
