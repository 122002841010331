import { AccountInfo } from '@azure/msal-common';
import { azureConst } from 'src/constants/azure';
import { RoleId } from 'src/constants/common';
import { getCustomClaims } from 'src/utilities/azure/idToken';

/**
 * @returns `allowedApps`に共助アプリのアプリ名が含まれているかどうか
 */
export const isAppAllowed = (account: AccountInfo) => {
  const customClaims = getCustomClaims(account.idTokenClaims!);
  return customClaims.allowedApps.includes(azureConst.appName);
};

export const isUserRoleAllowed = (userRoleId: null | string, allowedRoles: Readonly<(null | RoleId)[]>) =>
  (allowedRoles as (null | string)[]).includes(userRoleId);
