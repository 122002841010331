import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestPatchSupportAssignment } from 'src/utilities/restApi/kanri/types/supportAssignment';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/shienkankei';

export const patchShienkankei = async (data: RequestPatchSupportAssignment) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<string>>((resolve, reject) => {
    restApi()
      .patch<SuccessResponse<string>>(url, data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
