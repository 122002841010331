import { Button, ButtonProps, Typography, styled } from '@mui/material';
import { Override } from 'src/types/common/Override';

export type DialogButtonProps = Override<
  ButtonProps,
  {
    buttonType?: 'positive' | 'negative';
  }
>;

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'buttonType',
})<DialogButtonProps>(({ buttonType, theme }) => ({
  fontSize: '20px',
  color: buttonType === 'positive' ? theme.palette.uiStandard.green50 : theme.palette.uiStandard.black,
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '20px',
}));

export const DialogButton = ({ children, onClick, buttonType = 'negative', ...rest }: DialogButtonProps) => {
  return (
    <Button variant="text" {...rest} onClick={onClick}>
      <StyledTypography buttonType={buttonType}>{children}</StyledTypography>
    </Button>
  );
};
