import { ListItem, ListItemText, Stack } from '@mui/material';
import { ReactNode } from 'react';

type ComponentProps = {
  title?: ReactNode;
  value?: ReactNode;
  titleWidth?: number;
};

export const DataListRow = ({ title, value, titleWidth }: ComponentProps) => {
  return (
    <ListItem>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'start', sm: 'center' }} sx={{ width: '100%' }}>
        <ListItemText
          primary={title}
          primaryTypographyProps={{ fontWeight: 'bold' }}
          sx={{ flex: 'none', width: titleWidth }}
        />
        <ListItemText primary={value} sx={{ width: '100%' }} />
      </Stack>
    </ListItem>
  );
};
