import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { IconPullDown } from 'src/components/shien/atoms/images/IconPullDown';

type ComponentProps = {
  buttonText: string;
  onClick: () => void;
  disabled?: boolean;
};

export const SortButton = ({ buttonText, onClick, disabled = false }: ComponentProps) => {
  const theme = useTheme();
  return (
    <Box>
      <Button variant="secondary" onClick={onClick} disabled={disabled} sx={{ px: '16px', py: '14px' }}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 'bold', lineHeight: '16px', color: theme.palette.uiStandard.black }}
          >
            {buttonText}
          </Typography>
          <Box>
            <IconPullDown />
          </Box>
        </Stack>
      </Button>
    </Box>
  );
};
