import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';
import { PublicClientApplication } from 'src/utilities/azure/hooks/client';
import { acquireTokenRedirect, acquireTokenSilent } from 'src/utilities/azure/publicClientApplicationUtil';

const getIdToken = async () => {
  // サインイン時に生成されている場合のみトークンの再取得を行う
  if (PublicClientApplication.hasInstance()) {
    // AADB2Cでログイン済みの場合
    let azureAccount = PublicClientApplication.getInstance().getAllAccounts()[0] as undefined | AccountInfo;

    if (azureAccount == null) {
      // 未ログインの場合
      console.error('No account available');
      throw new Error('No account available');
    }

    try {
      const acquireTokenSilentResult = (await acquireTokenSilent())!; // getInstance()は呼び出し済み
      azureAccount = acquireTokenSilentResult.account;
    } catch (error) {
      console.error('Failed to acquireTokenSilent', error);
      if (error instanceof InteractionRequiredAuthError) {
        await acquireTokenRedirect();
      } else {
        throw error;
      }
    }

    return azureAccount.idToken!;
  }

  // TODO: LINEログインの場合のトークン更新処理をここに実装

  throw new Error('Not logged in');
};

export const getCommonHeaders = async () => {
  const idToken = await getIdToken();

  return {
    Authorization: bearerOf(idToken),
  };
};

/**
 * @param token token68エンコード済みの文字列
 * @returns `Bearer ${token}`
 */
export const bearerOf = (token: string) => `Bearer ${token}`;
