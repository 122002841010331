import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import {
  RequestGetLineMessageTemplates,
  ResponseGetLineMessageTemplates,
} from 'src/utilities/restApi/kanri/types/lineMessageTemplate';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/line-message-template';

export const getLineMessageTemplates = async (params: RequestGetLineMessageTemplates) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseGetLineMessageTemplates>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetLineMessageTemplates>>(url, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
