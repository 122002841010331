import { Typography } from '@mui/material';
import { Template } from './Template';

type EditShelterButton = {
  shelterName?: string;
  disabled?: boolean;
  onClick: () => void;
};

export const EditShelterButton = ({ shelterName = '', disabled = false, onClick }: EditShelterButton) => {
  return (
    <Template buttonText={shelterName ? '避難先を変更する' : '避難先を登録する'} onClick={onClick} disabled={disabled}>
      {shelterName && (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '20px',
          }}
        >
          {shelterName}
        </Typography>
      )}
    </Template>
  );
};
