import { List, ListItem, ListItemText, ListSubheader, Typography, styled, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { standardColors } from 'src/themes/standardColors';
import { AppListSectionData } from 'src/types/common/AppListSectionData';

const StyledList = styled(List)(() => ({
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  position: 'relative',
  overflow: 'auto',
  '& ul': { padding: 0 },
  backgroundColor: standardColors.gray10,
  paddingBottom: 0,
}));

const StyledListSubheader = styled(ListSubheader)(() => ({
  backgroundColor: standardColors.gray10,
  borderBottom: `1px solid ${standardColors.gray25}`,
}));

const StyledListItem = styled(ListItem)(() => ({
  backgroundColor: standardColors.white,
  borderBottom: `1px solid ${standardColors.gray25}`,
}));

export type AppListProps = {
  children?: ReactNode;
  titleWidth?: number;
  sectionDataList: AppListSectionData[];
  bottomSpacing?: number;
};

export const AppList: FC<AppListProps> = ({ children, titleWidth = 100, sectionDataList, bottomSpacing = 0 }) => {
  const theme = useTheme();

  return (
    <StyledList subheader={<li />}>
      {sectionDataList.map((sectionData, sectionId) => (
        <li key={`section-${sectionId}`}>
          <ul>
            <StyledListSubheader>
              <Typography component={'div'} variant="title1" sx={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                {sectionData.header}
              </Typography>
            </StyledListSubheader>
            {sectionData.items.map((item, itemId) => (
              <StyledListItem key={`item-${sectionId}-${itemId}`} sx={{ alignItems: 'start' }}>
                {item.title && (
                  <ListItemText
                    primary={item.title}
                    sx={{
                      width: titleWidth,
                      minWidth: titleWidth,
                      maxWidth: titleWidth,
                    }}
                    primaryTypographyProps={{ component: 'div', variant: 'subtitle' }}
                  />
                )}
                <ListItemText
                  primary={item.value}
                  primaryTypographyProps={{
                    component: 'div',
                    variant: 'body2',
                    sx: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' },
                  }}
                  sx={{ pl: 1 }}
                />
              </StyledListItem>
            ))}
          </ul>
        </li>
      ))}
      {children}
      <li style={{ marginTop: theme.spacing(bottomSpacing) }} />
    </StyledList>
  );
};
