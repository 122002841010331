import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PreFetchedData } from 'src/components/kanri/pages/jichitai/SearchSupportersPage/components/SupporterDetailsDialog';
import { appConst } from 'src/constants/common';
import { useSupporterDetails } from 'src/hooks/common/useSupporterDetails';
import { AppListSectionData } from 'src/types/common/AppListSectionData';
import { getSupportAvailabilityView } from 'src/utilities/appUtil/commonUtil';

type SectionDataList = [
  {
    header: '基本情報';
    items: [
      { title: '氏名'; value: React.ReactNode },
      { title: '氏名カナ'; value: React.ReactNode },
      { title: '住所'; value: React.ReactNode },
      { title: '連絡先'; value: React.ReactNode },
      { title: '所属'; value: React.ReactNode }
    ];
  },
  {
    header: '支援情報';
    items: [{ title: '支援可否'; value: React.ReactNode }];
  }
];

/**
 * `spId`がnon-nullのときは`preFetched`もnon-null
 */
export type Props = {
  spId: undefined | string;
  preFetched: undefined | Readonly<PreFetchedData>;
};

export const useSectionDataList = ({ spId, preFetched }: Props) => {
  // Constants
  const SUSPEND_SECTION_DATA = useMemo(
    () =>
      [
        {
          header: '基本情報',
          items: [
            { title: '氏名', value: <AppSkeleton /> },
            { title: '氏名カナ', value: <AppSkeleton /> },
            { title: '住所', value: <AppSkeleton /> },
            { title: '連絡先', value: <AppSkeleton /> },
            { title: '所属', value: <AppSkeleton /> },
          ],
        },
        {
          header: '支援情報',
          items: [{ title: '支援可否', value: <AppSkeleton /> }],
        },
      ] satisfies SectionDataList,
    []
  );

  // States
  const { details, error, loading } = useSupporterDetails({ spId });
  const sectionDataList = useMemo<AppListSectionData[]>(() => {
    if (loading) return SUSPEND_SECTION_DATA;

    if (
      error ||
      // spIdがundefinedのとき (Close時なのでエラーにはしない)
      details == null
    )
      return [];

    return [
      {
        header: '基本情報',
        items: [
          { title: '氏名', value: [details.fname, details.sname].join(appConst.LABEL_HALF_SPACE) },
          { title: '氏名カナ', value: [details.kfname, details.ksname].join(appConst.LABEL_HALF_SPACE) },
          { title: '住所', value: details.address },
          { title: '連絡先', value: details.telno },
          {
            title: '所属',
            value: preFetched!.soshikiNames.join(appConst.LABEL_HALF_COMMA + appConst.LABEL_HALF_SPACE), // spIdがnon-nullのときはpreFetchedはnon-null
          },
        ],
      },
      {
        header: '支援情報',
        items: [
          {
            title: '支援可否',
            value: (() => {
              const { Icon, label } = getSupportAvailabilityView(details.spcheckid);
              return (
                <Stack height="100%" direction="row" alignItems="center">
                  <Icon fontSize="small" />
                  {label}
                </Stack>
              );
            })(),
          },
        ],
      },
    ] satisfies SectionDataList;
  }, [loading, error, details, preFetched]);

  return { sectionDataList, error, loading };
};
