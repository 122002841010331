export const Illust03 = () => {
  return (
    <svg width="343" height="260" viewBox="0 0 343 260" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_732_3723)">
        <path
          d="M240.88 137.76L253.9 141.02L266.49 137.76C266.49 137.76 294.83 145.67 300.13 148.42C305.43 151.17 310.56 155.66 312.16 166.27C313.76 176.88 317.94 227.88 317.94 227.88L316.46 294.99L298.25 294.59L297.35 287.02L209.11 287.12L209.85 233.01C209.85 233.01 207.52 241.85 195.99 241.68C184.46 241.51 186.24 218.39 186.55 211.37C186.86 204.35 190.64 168.52 194.18 160.09C197.71 151.67 207.61 147.93 207.61 147.93L240.88 137.75V137.76Z"
          fill="#86A4C4"
        />
        <path
          d="M240.88 130.43V137.76L253.9 143.02L266.37 137.45L266.25 130.43C266.25 130.43 263.97 135.1 254.38 135.1C244.79 135.1 240.87 130.43 240.87 130.43H240.88Z"
          fill="white"
        />
        <path
          d="M266.5 137.76L253.91 143.02L240.88 137.76"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M295.97 171.52L298.26 233.79V287.11H209.12L210.62 231.97"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M187.69 226.36C187.69 226.36 182.99 217.4 192.51 168.39C193.92 161.12 196.25 153 205.75 149.13C215.25 145.26 240.88 137.76 240.88 137.76V130.81"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M266.5 130.81V137.76C266.5 137.76 288.21 143.77 297.7 147.64C307.2 151.51 311.47 161.39 312.49 167.93C316.9 196.22 317.95 227.88 317.95 227.88L316.47 295.16H298.26V287.12"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M266.5 134.05L253.91 143.02L264.01 155.64L273.33 139.71L266.5 134.05Z"
          fill="#86A4C4"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M241.32 134.05L253.91 143.02L243.81 155.64L234.49 139.71L241.32 134.05Z"
          fill="#86A4C4"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M200.11 118.84L209.36 113.12L219.97 112.9C219.97 112.9 220.82 113.27 220.34 115.2C219.86 117.13 217.75 118.34 217.75 118.34C217.75 118.34 219.9 119.02 219.9 120.95C219.9 122.88 218.35 124.5 216.85 124.36C216.85 124.36 218.71 124.95 218.71 127.12C218.71 129.29 216.85 131.41 215.07 131.67C213.29 131.93 207.87 131.52 207.87 131.52L207.75 134.36L219.47 148.86C219.47 148.86 220.95 150.01 221.31 148.11C221.67 146.21 223.43 125.81 223.43 125.81C223.43 125.81 224.96 122.19 227.82 122.8C230.68 123.41 230.13 126.98 230.13 126.98L228.35 136.48C228.35 136.48 228.66 142.13 227.85 147.05C227.03 151.97 220.98 158.35 220.98 158.35L218.9 168.81L206.78 167.24L206.53 156.58L197.65 137.82C197.26 137 197.14 136.08 197.29 135.19L200.1 118.83L200.11 118.84Z"
          fill="white"
        />
        <path
          d="M224.95 110L221.33 148.11C221.16 149.75 219.46 150.39 218.3 149.22L206.78 134.83L207.88 131.53L217.79 130.92L218.98 125.94L216.61 125.11L219.42 122.76L219.46 119.59L217.25 119.01L219.27 117.4L219.34 113.31L209.04 113.14L210.69 93.76C211.14 92.3 213.23 92.93 214.22 94.02L224.95 110.03V110Z"
          fill="#C3D8F0"
        />
        <path
          d="M204.8 166.33L206.65 156.75L198.02 138.01C197.55 136.99 197.39 135.86 197.56 134.75L199.77 120.34C199.91 119.44 200.42 118.64 201.19 118.15L208.16 113.31C208.92 112.82 209.81 112.55 210.72 112.55L216.29 112.51C218.73 112.51 219.71 114.53 219.7 115.78C219.7 117.24 218.92 118.81 217.28 119.17C217.28 119.17 218.9 119.42 218.86 121.83C218.82 124.38 216.91 125.21 216.91 125.21C216.91 125.21 218 125.93 217.91 127.7C217.71 131.86 213.02 131.68 213.02 131.68L203.78 132.09"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M221.24 149.05L224.95 110L214.22 93.99"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M210.69 93.74L209.04 112.23"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.46 125.61C223.46 125.61 224.86 122.95 227.28 122.81C231.22 122.58 229.72 128.02 229.72 128.02L228.3 136.88C228.3 136.88 229.04 146.54 226.84 150.82C224.64 155.1 220.99 158.35 220.99 158.35L218.91 168.81"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M214.22 93.99C214.22 93.99 213.39 92.84 212.43 92.89C211.24 92.96 211.02 93.73 211.02 93.73"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.05 132.05L206.79 134.83"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.79 134.83L218.31 149.22C219.9 150.16 221.25 149.05 221.25 149.05"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M219.81 149.6L207.87 134.83L208.18 132.49"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.8 113.29L209.56 93.57C209.56 93.57 212.01 91.58 214.22 93.99"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M221.2 93.24C221.2 93.24 213.9 93.48 215.16 101.49C216.42 109.5 225.29 109.62 225.29 109.62C225.29 109.62 233.56 134.26 253.86 135.09C274.16 135.92 282.21 110.26 282.21 110.26C282.21 110.26 290.98 108.97 291.87 102.86C292.76 96.75 290.02 93.59 286.77 93.24C286.77 93.24 287.7 80.39 285.08 70.15C282.47 59.92 276.4 56.5 272.3 56.22C272.3 56.22 262.04 45.17 248.99 47.58C235.94 49.98 213.26 60.5 221.21 93.24H221.2Z"
          fill="white"
        />
        <path
          d="M219.94 93.87C219.94 93.87 225.25 94.78 227.3 93.87C229.35 92.97 228.43 83.39 234.71 75.79C240.99 68.2 246.41 67.17 253.66 67.17C260.91 67.17 273.02 65.86 280.19 94.26C280.19 94.26 280.46 96.66 285.63 93.55C285.63 93.55 289.55 76.99 282.78 64.52C276.01 52.05 271.43 56.17 271.43 56.17C271.43 56.17 261.05 43.11 245.07 48.17C229.08 53.23 215.08 65.92 219.95 93.85L219.94 93.87Z"
          fill="#6D6D6D"
        />
        <path
          d="M220.4 92.69C219.52 86.96 219.26 85.7 219.26 81.57C219.26 63.31 232.7 48.19 251.48 47.38C262.84 46.89 271.67 56.2 271.67 56.2C271.67 56.2 286.44 55.51 286.44 82.81C286.44 86.94 286.19 91.78 285.46 96.82"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M283.71 105.42C282.16 111.2 279.79 116.87 276.26 121.67C270.85 129.02 261 135.08 253.85 135.08C246.7 135.08 236.85 129.02 231.44 121.67C227.9 116.87 225.54 111.2 223.99 105.42"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M285.84 93.93C292.66 93.23 292.58 98.9 292.16 101.48C291.22 107.23 287.07 109.61 283.32 109.61"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.99 109.61C220.24 109.61 216.09 107.23 215.15 101.48C214.73 98.9 215.57 93.23 222.39 93.93"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M266.27 106.39C268.07 106.39 269.53 104.81 269.53 102.86C269.53 100.91 268.07 99.33 266.27 99.33C264.47 99.33 263.01 100.91 263.01 102.86C263.01 104.81 264.47 106.39 266.27 106.39Z"
          fill="black"
        />
        <path
          d="M241.44 106.39C243.241 106.39 244.7 104.81 244.7 102.86C244.7 100.91 243.241 99.33 241.44 99.33C239.64 99.33 238.18 100.91 238.18 102.86C238.18 104.81 239.64 106.39 241.44 106.39Z"
          fill="black"
        />
        <path
          d="M253.89 115.74C254.967 115.74 255.84 115.006 255.84 114.1C255.84 113.194 254.967 112.46 253.89 112.46C252.813 112.46 251.94 113.194 251.94 114.1C251.94 115.006 252.813 115.74 253.89 115.74Z"
          fill="black"
        />
        <path
          d="M258.84 122.67C258.47 122.71 256.51 122.68 253.83 122.68C251.15 122.68 249.48 122.83 248.98 122.77"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M263.93 94.26C263.93 94.26 264.87 93.89 266.87 93.88C268.63 93.88 269.97 94.36 269.97 94.36"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M243.77 94.26C243.77 94.26 242.83 93.89 240.83 93.88C239.07 93.88 237.73 94.36 237.73 94.36"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M230.39 87.82C230.39 87.82 232.34 75.49 239.96 70.62"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M278.91 89.12C278.91 89.12 277.45 81.17 271.61 74.03"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M253.85 68.31C253.85 68.31 255.2 62.61 259.95 61.38"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.95 70.15C259.95 70.15 263.09 66.56 266.26 66.47"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.81 160.91L226.8 164.53C226.8 164.53 220.29 246.33 196 241.67C173.8 237.41 202.81 160.9 202.81 160.9V160.91Z"
          fill="#86A4C4"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask0_732_3723"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="24"
          y="22"
          width="150"
          height="151"
        >
          <path
            d="M99.06 172.4C140.393 172.4 173.9 138.893 173.9 97.56C173.9 56.227 140.393 22.72 99.06 22.72C57.727 22.72 24.22 56.227 24.22 97.56C24.22 138.893 57.727 172.4 99.06 172.4Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_732_3723)">
          <path
            d="M87.71 131.01V137.2L75.48 141.87L76.34 149.46C76.34 149.46 85.29 152.92 98.4 152.92C111.51 152.92 121.21 149.61 121.21 149.61L122.65 141.88L110.42 137.21V130.8C110.42 130.8 107.76 134.17 98.4 134.17C89.04 134.17 87.71 131.02 87.71 131.02V131.01Z"
            fill="white"
          />
          <path
            d="M64.2399 72.7C64.2399 72.7 59.0399 48.63 93.5899 43C108.71 40.54 117.9 56.68 117.9 56.68C135.23 59.55 137.29 73.27 138.79 79.4C140.66 87.05 135.78 93.33 135.78 93.33C149.94 117.09 121.36 121.29 121.36 121.29C117.5 125.79 111.38 133.61 98.5499 133.61C92.4099 133.61 82.7699 131.74 75.8199 121.22C75.8199 121.22 67.1499 123.66 60.9099 116.4C57.7999 112.78 58.3199 106.99 59.2399 99.86C59.2399 99.86 44.3399 80.45 64.2499 72.7H64.2399Z"
            fill="white"
          />
          <path
            d="M75.4799 141.87L90.8299 277.9H63.9799L65.1799 301.25L41.9899 303.01C41.9899 303.01 39.7699 259.06 39.8499 248.07C39.9299 237.08 39.9099 214.99 43.0699 184.95C46.2299 154.91 50.9599 149.5 75.4899 141.88L75.4799 141.87Z"
            fill="#928070"
          />
          <path
            d="M122.65 141.87L107.3 277.9H134.15L132.95 301.25L156.14 303.01C156.14 303.01 158.36 259.06 158.28 248.07C158.19 237.08 158.22 214.99 155.06 184.95C151.9 154.91 147.17 149.5 122.64 141.88L122.65 141.87Z"
            fill="#928070"
          />
          <path
            d="M121.11 149.48L109.16 260.95H88.9099L76.4099 149.48C76.4099 149.48 82.1999 152.91 98.3999 152.91C114.6 152.91 121.11 149.48 121.11 149.48Z"
            fill="white"
          />
          <path
            d="M121.8 149.33C116.87 151.77 108.5 152.91 99.01 152.91C89.52 152.91 81.29 151.79 76.34 149.38"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M87.7099 131.32V137.2C87.7099 137.2 74.2099 142.26 65.2899 145.9C56.3699 149.54 51.3899 152.69 48.4899 160.02C38.5399 185.12 39.9099 257.48 39.9099 257.48L41.9799 303.01L65.1799 301.55L62.6999 253.03L59.7899 192.44L61.6999 176.73L57.2299 164.31"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M110.42 131.55V137.2C110.42 137.2 123.92 142.26 132.84 145.9C141.76 149.54 146.74 152.69 149.64 160.02C159.59 185.12 158.22 257.48 158.22 257.48L156.15 303.01L132.95 301.55L135.43 253.03L138.34 192.44L136.43 176.73L140.9 164.31"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.65 141.87L107.3 277.9H133.58"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75.4799 141.87L90.8299 277.9H64.5499"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M131.62 92.25C138.49 91.55 138.41 97.26 137.99 99.86C137.04 105.65 132.98 110.55 127.81 108.45"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M64.91 92.11C58.04 91.41 58.12 97.12 58.54 99.72C59.49 105.51 63.55 110.41 68.72 108.31"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M111.06 104.8C112.877 104.8 114.35 103.206 114.35 101.24C114.35 99.2739 112.877 97.68 111.06 97.68C109.243 97.68 107.77 99.2739 107.77 101.24C107.77 103.206 109.243 104.8 111.06 104.8Z"
              fill="black"
            />
            <path
              d="M86.05 104.8C87.867 104.8 89.34 103.206 89.34 101.24C89.34 99.2739 87.867 97.68 86.05 97.68C84.233 97.68 82.76 99.2739 82.76 101.24C82.76 103.206 84.233 104.8 86.05 104.8Z"
              fill="black"
            />
            <path
              d="M98.56 114.22C99.6425 114.22 100.52 113.481 100.52 112.57C100.52 111.659 99.6425 110.92 98.56 110.92C97.4775 110.92 96.6 111.659 96.6 112.57C96.6 113.481 97.4775 114.22 98.56 114.22Z"
              fill="black"
            />
            <path
              d="M103.52 120.74C103.19 120.85 101.18 121.22 98.48 121.22C95.78 121.22 94.09 121.01 93.59 120.85"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M108.71 90.81C108.71 90.81 109.66 90.43 111.67 90.43C113.45 90.43 114.79 91.38 114.79 91.38"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M88.3999 90.81C88.3999 90.81 87.4499 90.43 85.4399 90.43C83.6599 90.43 82.3199 91.38 82.3199 91.38"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M127.81 100.58C127.81 118.82 114.74 133.61 98.6099 133.61C82.4799 133.61 69.4099 118.82 69.4099 100.58"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M107.5 115.35C107.5 115.35 109.27 116.39 110.37 118.55"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M58.15 98.8C56.19 96.61 52.4399 91.07 54.4399 82.3C55.6999 76.79 58.26 73.39 60.75 72.38C61.94 71.9 62.68 70.69 62.57 69.41C62.17 64.48 64.56 53.74 81.76 46.26C104.01 36.58 114.63 51.07 117.24 55.48C117.68 56.22 118.39 56.73 119.23 56.9C122.58 57.57 131.39 60.11 135.76 68.85C139.88 77.07 140.29 87.36 135.95 92.81"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M75.8099 121.22C56.0499 123.14 57.8499 106.57 58.8799 101.38"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M117.18 76.28C117.18 76.28 118.9 94.52 131.62 92.25"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M106.39 76.28C106.39 76.28 106.2 61.8 94.4199 64.98"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M106.39 76.28C106.39 76.28 103.25 69.82 95.22 70.34C86.83 70.89 87.22 81.22 77.11 88.17C72.39 91.41 65.6 92.24 65.6 92.24"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M121.44 121.22C144.82 123.49 138.02 99.86 138.02 99.86"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <path
          d="M147.02 155.04C130.69 168.65 108.7 175.25 86.12 171.28C45.41 164.13 18.21 125.32 25.36 84.61C32.51 43.9 71.32 16.7 112.03 23.85C152.74 31 179.94 69.81 172.79 110.52C171.09 120.2 167.6 129.12 162.72 136.99"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.71 137L176.68 163.66L147.02 155.04"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_732_3723">
          <rect width="343" height="260" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
