import { GridRowsProp, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  PaginationModel,
  SpGridRow,
  SpGridRowSoshiki,
  SpGridRowSupportRecipients,
} from 'src/components/common/types/SupportersGridTypes';
import { SearchConditions } from 'src/components/common/types/SupportersSearchConditions';
import { appConst, RoleId } from 'src/constants/common';
import { useUserData } from 'src/hooks/common/useUserData';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';
import { ErrorResponse } from 'src/utilities/restApi/common/types/responses';
import { searchSupporters } from 'src/utilities/restApi/kanri/search/searchSupporters';
import { ShienkankeiList } from 'src/utilities/restApi/kanri/types/searchSupporters';

type PageInfo = {
  totalRowCount: number;
  hasNextPage: boolean;
  pageSize: number;
};

type HookParams = {
  paginationModel: PaginationModel;
  searchConditions: SearchConditions;
  sortModel?: GridSortModel;
  prsid_list?: string[];
};

export const useQuery = ({ paginationModel, searchConditions, sortModel, prsid_list }: HookParams) => {
  const [controller, setController] = useState<AbortController>();
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState<SearchCallerType | undefined>('system');
  const [error, setError] = useState<SearchError>({ isError: false, reason: undefined });
  const [rows, setRows] = useState<GridRowsProp<SpGridRow>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    totalRowCount: 0,
    hasNextPage: false,
    pageSize: 0,
  });
  const [shienkankeiList, setShienkankeiList] = useState<ShienkankeiList>([])

  useEffect(() => {
    search('user');
  }, [userData, paginationModel, searchConditions, sortModel]);

  // functions
  const search = (callerType: SearchCallerType) => {
    if (userData) {
      controller?.abort();
      setIsLoading(callerType);
      const newController = new AbortController();
      setController(newController);
      searchSupporters(
        {
          page: paginationModel.page,
          page_size: paginationModel.pageSize,
          search_conditions: {
            simple: searchConditions.simple
              ? {
                  spcheckid: searchConditions.simple.spCheckId || null,
                  soshikiid_list:
                    searchConditions.simple.soshikiList.length > 0
                      ? searchConditions.simple.soshikiList.map((x) => x.soshikiId)
                      : null,
                  prsid_list:
                    userData?.roleId === RoleId.SoshikiLeader && prsid_list && prsid_list?.length > 0
                      ? prsid_list?.map((x) => x)
                      : undefined,
                }
              : undefined,
            advanced: searchConditions.advanced
              ? {
                  sp_fname: searchConditions.advanced.spFName || null,
                  sp_sname: searchConditions.advanced.spSName || null,
                  sp_soshikiid:
                    !searchConditions.advanced.spSoshiki ||
                    searchConditions.advanced.spSoshiki?.soshikiId === appConst.STR_EMPTY
                      ? null
                      : searchConditions.advanced.spSoshiki.soshikiId,
                }
              : undefined,
          },
          sort_model:
            sortModel && sortModel.length > 0
              ? {
                  field: sortModel[0].field,
                  sort: sortModel[0].sort ?? null,
                }
              : undefined,
        },
        newController
      )
        .then((response) => {
          if (response.data) {
            const gridRows: GridRowsProp<SpGridRow> = response.data.search_results.rows.map((row) => ({
              id: row.spid,
              name: row.name,
              spCheckId: row.spcheckid,
              spCheckStatus: row.spcheckstatus,
              supportRecipients: row.prslist.map(
                (x) =>
                  ({
                    prsId: x.prsid,
                    prsStatusId: x.prsstatusid,
                  } as SpGridRowSupportRecipients)
              ),
              soshikiList: row.soshiki_list.map(
                (x) => ({ soshikiId: x.soshikiid, soshikiName: x.soshikiname } as SpGridRowSoshiki)
              ),
            }));
            setRows(gridRows);
            setPageInfo({
              totalRowCount: response.data.search_results.page_info.total_row_count,
              hasNextPage: response.data.search_results.page_info.has_next_page,
              pageSize: paginationModel.pageSize,
            });

            // 自治体職員の絞り込みように支援関係を取得
            setShienkankeiList(response.data.shienkankei_list)
          }
        })
        .catch((response: ErrorResponse) => {
          setError({ isError: true, reason: response.error.reason });
        })
        .finally(() => {
          // abort時は次のリクエストのローディングが走るので、ローディングを解除しない
          if (!newController.signal.aborted) {
            setIsLoading(undefined);
          }
        });
    }
  };

  return { isLoading, rows, pageInfo, error, search, shienkankeiList};
};
