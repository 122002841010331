import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestGetJichitai, ResponseGetJichitai } from 'src/utilities/restApi/shien/types/jichitais';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/jichitais';

export const getJichitai = async (params: RequestGetJichitai) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetJichitai>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetJichitai>>(`${url}/${params.jichitaiid}`, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError) => reject(error.response?.status));
  });
};
