import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { PrsActionChip } from 'src/components/common/atoms/PrsActionChip';
import { HeaderButton } from 'src/components/shien/atoms/Header/HeaderButton';
import { HeaderTitle, HeaderTitleProps } from 'src/components/shien/atoms/Header/HeaderTitle';
import { BackButton } from 'src/components/shien/atoms/buttons/BackButton';
import { PrsActionType } from 'src/types/common/PrsActionType';

export type HeaderWithBackButtonProps = HeaderTitleProps & {
  onDetailClick?: () => void;
  onBackClick: () => void;
  prsActionType?: PrsActionType;
};

export const HeaderWithBackButton: FC<HeaderWithBackButtonProps> = ({
  onDetailClick,
  onBackClick,
  prsActionType,
  ...headerTitleProps
}) => {
  return (
    <Box
      component="header"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
        width: '100%',
        padding: '10px 0',
        gap: '10px',
      }}
    >
      <BackButton onClick={onBackClick} />
      <HeaderTitle {...headerTitleProps} />
      {onDetailClick ? (
        <Stack alignItems="end" sx={{ paddingRight: '8px' }}>
          <HeaderButton variant="secondary" text="詳細" onClick={onDetailClick} />
        </Stack>
      ) : (
        <Stack alignItems="center">
          <PrsActionChip prsActionType={prsActionType} />
        </Stack>
      )}
    </Box>
  );
};
