import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestGetUserid, ResponseGetUserid } from 'src/utilities/restApi/common/types/users';
import { bearerOf } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/users/me/id';

export const getUserid = async (params: RequestGetUserid) => {
  const headers = {
    headers: {
      Authorization: bearerOf(params.idToken),
    },
  };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetUserid>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetUserid>>(url, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError) => reject(error.response?.status));
  });
};
