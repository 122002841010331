import { Button, Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { Illust04 } from 'src/components/common/atoms/images/Illust04';
import {
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'src/components/kanri/pages/SystemStopInformationPage/buttonStyle';
import { useLineIntegration } from 'src/components/shien/pages/SupporterProfilePage/hooks/useLineIntegration';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';

enum ButtonContent {
  NotLinked = 'Line連携する',
  Linked = 'Line連携済',
}

export const SystemStopInformationPage = () => {
  const navigateSystemError = useNavigateSystemError();

  const { isLinked, isImportedJichitaiData, loading, error: lineIntegrationError, link, unlink } = useLineIntegration();

  useEffect(() => {
    if (lineIntegrationError) {
      navigateSystemError();
    }
  }, [lineIntegrationError]);

  const handleLineClick = () => {
    if (isLinked === true) {
      unlink();
    } else if (isLinked === false) {
      link();
    } else {
      // undefinedの場合何もしない(クリック不可のためこの分岐には入らない想定)
    }
  };

  return (
    <PageContainer title="システム停止案内">
      <Container maxWidth="lg" sx={{ height: '100%', py: 5 }}>
        <Stack
          alignItems="center"
          spacing={4}
          sx={{
            height: '100%',
            textShadow: '3px 3px 6px #ffffff,-3px 3px 6px #ffffff,3px -3px 6px #ffffff,-3px -3px 6px #ffffff',
          }}
        >
          <Typography variant="h3">ただいまご利用できません</Typography>
          <Typography variant="h5">
            現在、安否確認の実施期間外のため、一部を除き、このサービスは利用できません。安否確認時には、ご協力をお願いします。
          </Typography>
          <StackSpacer />
          <Illust04 />
          <StackSpacer />
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Button
              sx={{
                flex: 1,
                minHeight: '64px',
                ...(loading || isLinked == null || isLinked || !isImportedJichitaiData
                  ? secondaryButtonStyle
                  : primaryButtonStyle),
              }}
              onClick={handleLineClick}
              fullWidth
              disabled={loading || isLinked == null || isLinked || !isImportedJichitaiData}
            >
              <AppSkeleton show={loading || isLinked == null || !isImportedJichitaiData}>
                <span>{isLinked ? ButtonContent.Linked : ButtonContent.NotLinked}</span>
              </AppSkeleton>
            </Button>
            <Button sx={primaryButtonStyle} onClick={() => {}} fullWidth>
              閉じる
            </Button>
          </Stack>
        </Stack>
      </Container>
    </PageContainer>
  );
};
