import { AxiosError, AxiosRequestConfig } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestStopJian, ResponseStopJian } from 'src/utilities/restApi/kanri/types/stopJian';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

export const stopJian = async (data: RequestStopJian) => {
  const headers = { headers: await getCommonHeaders() };
  const config: AxiosRequestConfig = { ...headers };

  return new Promise<SuccessResponse<ResponseStopJian>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponseStopJian>>('/stop-jian', data, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        return reject(error.response?.data);
      });
  });
};
