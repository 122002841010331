import { RoleId } from 'src/constants/common';
import { globalDataStore, userDataAtom, userIdAtom } from 'src/jotai/atom';
import { ResponseGetUser } from 'src/utilities/restApi/common/types/users';

/**
 * {@link globalDataStore}を更新する
 */
export const updateJotai = (userDetails: Readonly<ResponseGetUser['user']>) => {
  globalDataStore.set(userIdAtom, userDetails.userid);
  globalDataStore.set(userDataAtom, {
    userId: userDetails.userid,
    fName: userDetails.fname,
    sName: userDetails.sname,
    kfName: userDetails.kfname,
    ksName: userDetails.ksname,
    telNo: userDetails.telno ?? undefined,
    mailAddress: userDetails.mailaddress ?? undefined,
    isLinkedAadb2cuserid: userDetails.is_linked_aadb2cuserid,
    linkedAadb2cTypeCode: userDetails.linked_aadb2c_type_code,
    isLinkedLineUserId: userDetails.is_linked_lineuserid,
    roleId: (userDetails.roleid as RoleId) ?? undefined,
    roleName: userDetails.rolename ?? undefined,
    jichitaiId: userDetails.jichitaiid,
    jichitaiName: userDetails.jichitainame,
    soshikiids: userDetails.soshikiids,
  });
};
