import { AxiosError } from 'axios';
import { ErrorResponse } from 'react-router-dom';
import { restApi } from 'src/utilities/restApi';
import { SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import {
  RequestGetSupportRecipients,
  ResponseGetSupportRecipients,
} from 'src/utilities/restApi/kanri/types/supportRecipients';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

export const getSupportRecipients = async (params: RequestGetSupportRecipients) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseGetSupportRecipients>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetSupportRecipients>>('support-recipients', config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};
