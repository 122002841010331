import { Button, CircularProgress, Container, Paper, Stack, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import { useLineIntegration } from 'src/components/shien/pages/SupporterProfilePage/hooks/useLineIntegration';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { standardColors } from 'src/themes/standardColors';

enum ButtonContent {
  NotLinked = '連携する',
  Linked = '連携済',
}

enum LinkedStatus {
  NotLinked = '未連携',
  Linked = '連携中',
  Loading = '更新中',
}

const StyledButton = styled(Button)(() => ({
  width: '88px',
  height: '24px',
  backgroundColor: standardColors.lineGreen,
  '&:hover': {
    backgroundColor: standardColors.lineGreen,
  },
}));

const StyledCircularProgress = styled(CircularProgress)(() => ({
  '.MuiCircularProgress-circle': {
    color: standardColors.white, // テキストカラーに合わせる
  },
}));

export const LineIntegrationPage = () => {
  const theme = useTheme();
  const navigateSystemError = useNavigateSystemError();

  const { isLinked, isImportedJichitaiData, loading, error: lineIntegrationError, link } = useLineIntegration();
  const [buttonContent, setButtonContent] = useState<ButtonContent | JSX.Element>();
  const [linkedStatus, setLinkedStatus] = useState<LinkedStatus>();

  useEffect(() => {
    if (lineIntegrationError) {
      navigateSystemError();
    }
  }, [lineIntegrationError]);

  // ボタンの表示設定
  useEffect(() => {
    if (!loading && isImportedJichitaiData && isLinked === true) {
      setButtonContent(ButtonContent.Linked);
      setLinkedStatus(LinkedStatus.Linked);
    } else if (!loading && isImportedJichitaiData && isLinked === false) {
      setButtonContent(ButtonContent.NotLinked);
      setLinkedStatus(LinkedStatus.NotLinked);
    } else {
      // loading=true || isLinked===undefined を想定
      setButtonContent(<StyledCircularProgress size={14.5} />); // テキストのlineHeightに合わせる
      setLinkedStatus(LinkedStatus.Loading);
    }
  }, [loading, isLinked, isImportedJichitaiData]);

  // 連携する or 連携解除ボタン押下時処理
  const handleClick = () => {
    if (isLinked === true) {
      // NOTE: 連携解除はスコープ外
    } else if (isLinked === false) {
      // 連携処理 Line認証画面へ遷移
      link();
    } else {
      // undefinedの場合何もしない(クリック不可のためこの分岐には入らない想定)
    }
  };

  return (
    <PageContainer title="LINE連携">
      <KanriPageContainer title="LINE連携">
        <Container maxWidth="sm" sx={{ px: { xs: '0px', sm: theme.spacing(10) } }}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Stack direction="row" justifyContent="space-around" alignItems="center">
              {linkedStatus}
              {/* CircularProgress表示中はクリック不可 */}
              <StyledButton
                variant="contained"
                onClick={handleClick}
                disabled={loading || isLinked === undefined || isLinked || !isImportedJichitaiData}
              >
                {buttonContent}
              </StyledButton>
            </Stack>
          </Paper>
        </Container>
      </KanriPageContainer>
    </PageContainer>
  );
};
