import { useEffect, useState } from 'react';
import { getSupporter } from 'src/utilities/restApi/common/supporters';
import { ResponseGetSupporter } from 'src/utilities/restApi/shien/types/supporters';

type UseSupporterDetailsProps = {
  spId: undefined | string;
};

export const useSupporterDetails = ({ spId }: UseSupporterDetailsProps) => {
  const [details, setDetails] = useState<NonNullable<ResponseGetSupporter['sp']>>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Reset states
    setDetails(undefined);
    setError(false);

    if (spId == null) {
      setLoading(true); // 要支援者詳細情報ダイアログと同様にcloseアニメーション中はスケルトンを表示させる
      return;
    }

    setLoading(true);
    getSupporter({ spid: spId })
      .then((response) => {
        const sp = response.data!.sp;
        if (sp === null) {
          throw new Error(`No such supporter (${spId})`);
        }

        setDetails(sp);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [spId]);

  return { details, error, loading };
};
