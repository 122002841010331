import { FC } from 'react';
import { Reason, TextEditDialog } from 'src/components/shien/atoms/dialogs/TextEditDialog';
import { Prs2 } from 'src/types/common/Prs2';

const MEMO_MAX_LENGTH = 100;

type ComponentProps = {
  prs: Prs2 | undefined;
  dialogOpen: boolean;
  onClose: ({ reason, text }: { reason: Reason; text?: string }) => void;
};

// 要支援者情報メモ登録ダイアログ
export const PrsMemoEditDialog: FC<ComponentProps> = ({ prs, dialogOpen, onClose }) => {
  return (
    <TextEditDialog
      pageTitle="メモ登録"
      headlineText="メモを登録"
      upperText={`${prs?.fName} ${prs?.sName}`}
      description={
        <>
          対象者のケガや家族の状況など、共有したい情報があれば教えてください。
          <br />
          ※文字は最大{MEMO_MAX_LENGTH}文字まで入力できます。
        </>
      }
      text={prs?.prsMemo}
      multiline={true}
      max={MEMO_MAX_LENGTH}
      isRequired={false}
      open={dialogOpen}
      onClose={onClose}
    />
  );
};
