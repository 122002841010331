import { Box, Link } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import {
  appConst,
  gradeCodes,
  mentalDisabilityHealthAndWelfareHandbookCodes,
  nursingCareLevelCodes,
  presenceAbsenceCodes,
  rehabilitationNotebookCodes,
  sexCodes,
  solitudeCohabitationCodes,
} from 'src/constants/common';
import { AppListSectionData } from 'src/types/common/AppListSectionData';
import { Prs2 } from 'src/types/common/Prs2';
import { calculateCurrentAge } from 'src/utilities/dateUtil';

const suspendDataList = [
  {
    header: '基本情報',
    items: [
      { title: '氏名', value: <AppSkeleton /> },
      { title: '氏名カナ', value: <AppSkeleton /> },
      { title: '性別', value: <AppSkeleton /> },
      { title: '年齢', value: <AppSkeleton /> },
      { title: '住所', value: <AppSkeleton /> },
      { title: '連絡先', value: <AppSkeleton /> },
      { title: '本人確認', value: <AppSkeleton /> },
    ],
  },
  {
    header: '配慮情報',
    items: [
      { title: '区分', value: <AppSkeleton /> },
      { title: '世帯状況/家族構成', value: <AppSkeleton /> },
      { title: '独居/同居', value: <AppSkeleton /> },
      { title: '所属地域', value: <AppSkeleton /> },
      { title: '医療機関', value: <AppSkeleton /> },
      { title: '身体障害手帳', value: <AppSkeleton /> },
      { title: '療育手帳', value: <AppSkeleton /> },
      { title: '精神障害者保健福祉手帳', value: <AppSkeleton /> },
      { title: '要介護度', value: <AppSkeleton /> },
      { title: '難病指定の有無', value: <AppSkeleton /> },
    ],
  },
  {
    header: '緊急連絡先',
    items: [
      { title: '氏名', value: <AppSkeleton /> },
      { title: '続柄', value: <AppSkeleton /> },
      { title: '住所', value: <AppSkeleton /> },
      { title: '電話番号', value: <AppSkeleton /> },
    ],
  },
  {
    header: '備考',
    items: [{ value: <AppSkeleton /> }],
  },
] as AppListSectionData[];

export const usePrsSectionDataList = (
  clickPhoneNumberCallback: (phoneNumber: string, message: ReactNode) => void,
  prs?: Prs2
) => {
  const [sectionDataList, setSectionDataList] = useState<AppListSectionData[]>(suspendDataList);

  useEffect(() => {
    if (prs) {
      const age = calculateCurrentAge(prs.birthday);

      setSectionDataList([
        {
          header: '基本情報',
          items: [
            { title: '氏名', value: `${prs.fName} ${prs.sName}` },
            { title: '氏名カナ', value: `${prs.kfName} ${prs.ksName}` },
            { title: '性別', value: sexCodes.find((x) => x.code === prs.sex)?.Label },
            { title: '年齢', value: age ? `${age}歳` : '' },
            {
              title: '住所',
              value: [prs.address, prs.subAddress].join(appConst.LABEL_HALF_SPACE),
            },
            {
              title: '連絡先',
              value: prs.telNo && (
                <>
                  <Link
                    display={{ xs: 'block', sm: 'none' }}
                    onClick={() => {
                      clickPhoneNumberCallback(prs.telNo!, '対象者に電話をかけ、安否や避難先などを確認しましょう。');
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {prs.telNo}
                  </Link>
                  <Box display={{ xs: 'none', sm: 'block' }}>{prs.telNo}</Box>
                </>
              ),
            },
            { title: '本人確認', value: prs.agreement ? '同意' : '未同意' },
          ],
        },
        {
          header: '配慮情報',
          items: [
            { title: '区分', value: prs.prsKubun },
            {
              title: (
                <>
                  世帯状況
                  <br />
                  /家族構成
                </>
              ),
              value: prs.familyStructure,
            },
            {
              title: '独居/同居',
              value: solitudeCohabitationCodes.find((x) => x.code === prs.solitudeCohabitation)?.Label,
            },
            {
              title: '所属地域',
              value:
                prs.soshiki.length > 0
                  ? prs.soshiki.map((x) => x.soshikiName).join(appConst.LABEL_HALF_COMMA + appConst.LABEL_HALF_SPACE)
                  : '未設定',
            },
            { title: '医療機関', value: prs.primaryDoctor },
            {
              title: '身体障害手帳',
              value: gradeCodes.find((x) => x.code === prs.physicalDisabilityCertificate)?.Label,
            },
            {
              title: '療育手帳',
              value: rehabilitationNotebookCodes.find((x) => x.code === prs.rehabilitationNotebook)?.Label,
            },
            {
              title: '精神障害者保健福祉手帳',
              value: mentalDisabilityHealthAndWelfareHandbookCodes.find(
                (x) => x.code === prs.mentalDisabilityHealthAndWelfareHandbook
              )?.Label,
            },
            { title: '要介護度', value: nursingCareLevelCodes.find((x) => x.code === prs.nursingCareLevel)?.Label },
            {
              title: '難病指定の有無',
              value: presenceAbsenceCodes.find((x) => x.code === prs.intractableDisease)?.Label,
            },
          ],
        },
        {
          header: '緊急連絡先',
          items: [
            { title: '氏名', value: prs.emergencyContactName },
            { title: '続柄', value: prs.emergencyContactRelationship },
            { title: '住所', value: prs.emergencyContactAddress },
            {
              title: '電話番号',
              value: prs.emergencyContactPhoneNumber && (
                <>
                  <Link
                    display={{ xs: 'block', sm: 'none' }}
                    onClick={() => {
                      clickPhoneNumberCallback(prs.emergencyContactPhoneNumber!, <>緊急連絡先に電話をかけますか？</>);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {prs.emergencyContactPhoneNumber}
                  </Link>
                  <Box display={{ xs: 'none', sm: 'block' }}>{prs.emergencyContactPhoneNumber}</Box>
                </>
              ),
            },
          ],
        },
        {
          header: '備考',
          items: [{ value: prs.supporterRemarks }],
        },
      ]);
    } else {
      setSectionDataList(suspendDataList);
    }
  }, [prs]);

  const clearSectionDataList = () => {
    setSectionDataList(suspendDataList);
  };

  return { sectionDataList, clearSectionDataList };
};
