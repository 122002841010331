import { Box, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { Illust03 } from 'src/components/shien/atoms/images/Illust03';
import { QuestionPageFrom } from 'src/components/shien/pages/possibility/common/types';
import { ButtonPageTemplate } from 'src/components/shien/templates/ButtonPageTemplate';

export const AnswerPossiblePage = () => {
  const location = useLocation();
  const fromState = location.state as QuestionPageFrom;
  const from = fromState.from;
  const navigate = useNavigate();
  return (
    <PageContainer title="回答完了">
      <ButtonPageTemplate
        headline={
          <Stack alignItems="center">
            <Box>ご協力</Box>
            <Box>ありがとうございます</Box>
          </Stack>
        }
        description="安全には十分注意して、安否確認をお願いします。なお危険を感じた場合には、すぐに中断をしてください。"
        primary={{
          text: '安否確認をはじめる',
          onClick: () => {
            if (from === 'EnsuringSafetyPage') {
              navigate('/app/shien/prs-list');
            } else {
              navigate('/app/shien/possibility/question', { state: { from } });
            }
          },
        }}
      >
        <Illust03 />
      </ButtonPageTemplate>
    </PageContainer>
  );
};
