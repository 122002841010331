import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import {
  RequestGetSystemStatus,
  RequestPatchSystemStatus,
  ResponseGetSystemStatus,
} from 'src/utilities/restApi/kanri/types/systemStatus';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/system-status';

export const getSystemStatus = async (params: RequestGetSystemStatus) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseGetSystemStatus>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetSystemStatus>>(url, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};

export const patchSystemStatus = async (data: RequestPatchSystemStatus) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<string>>((resolve, reject) => {
    restApi()
      .patch<SuccessResponse<string>>(url, data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
