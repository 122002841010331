import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { ResponseGetSoshikiList } from 'src/utilities/restApi/kanri/types/soshiki';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/soshiki';

export const getSoshikiList = async () => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponseGetSoshikiList>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetSoshikiList>>(url, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};
