import { standardColors } from 'src/themes/standardColors';

const statusColors = ['red', 'yellow', 'green'] as const;
export type StatusColor = (typeof statusColors)[number];

export const isStatusColor = (statuscolor: string | null): statuscolor is StatusColor => {
  return statusColors.some((v) => v === statuscolor);
};

export const getUiStandardColor = (statusColor: StatusColor) => {
  switch (statusColor) {
    case 'red':
      return standardColors.red50;
    case 'yellow':
      return standardColors.yellow50;
    case 'green':
      return standardColors.green40;
    default:
      return 'default';
  }
};

export const getStatusContrastText = (statusColor: StatusColor) => {
  switch (statusColor) {
    case 'red':
      return standardColors.white;
    case 'yellow':
      return standardColors.black;
    case 'green':
      return standardColors.white;
    default:
      return 'default';
  }
};
