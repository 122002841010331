import { Soshiki } from 'src/types/common/Soshiki';
import { StatusColor } from 'src/types/common/statusColor';

export enum ShelterTypeCode {
  Home = '1',
  Shelter = '2',
  Other = '3',
  Unknown = '4',
}

export enum PlannedShelterTypeCode {
  Home = '1',
  Shelter = '2',
  Other = '3',
}

export type PlannedShelter = {
  disasterTypeNumber: number;
  disasterTypeName: string;
  shelterTypeCode?: PlannedShelterTypeCode;
  shelterId?: string;
  shelterName?: string;
  shelterAddress?: string;
  customShelterName?: string;
  customShelterAddress?: string;
};

export type ShienKankei = { shienKankeiId: string; spId?: string; soshikiId?: string };

export type Prs2 = {
  prsId: string;
  evaPrsId: number;
  fName: string;
  sName: string;
  kfName: string;
  ksName: string;
  sex?: string;
  birthday?: string;
  telNo?: string;
  address?: string;
  subAddress?: string;
  agreement?: boolean;
  prsKubun?: string;
  familyStructure?: string;
  solitudeCohabitation?: string;
  primaryDoctor?: string;
  physicalDisabilityCertificate?: string;
  rehabilitationNotebook?: string;
  mentalDisabilityHealthAndWelfareHandbook?: string;
  nursingCareLevel?: string;
  intractableDisease?: string;
  emergencyContactName?: string;
  emergencyContactRelationship?: string;
  emergencyContactAddress?: string;
  emergencyContactPhoneNumber?: string;
  supporterRemarks?: string;
  prsStatusId: string;
  prsStatusName: string;
  prsStatusColor: StatusColor;
  prsStatusSortOrder: number;
  customShelterName?: string;
  shelterTypeCode?: ShelterTypeCode;
  shelterId?: string;
  shelterName?: string;
  prsMemo?: string;
  jichitaiId: string;
  jichitaiName: string;
  lastPrsStatusUpdatedAt?: string;
  lastCalledAt?: string;
  lastVisitedAt?: string;
  lastEvacuatedAt?: string;
  delFlg: boolean;
  soshiki: Soshiki[];
  shienKankei: ShienKankei[];
  plannedShelters: PlannedShelter[];
};
