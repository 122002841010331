import { createTheme, ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { getDesignTokens as getKanriDesignTokens } from 'src/themes/KanriTheme';

export const KanriTemplate = () => {
  const designTokens = getKanriDesignTokens('light');
  const theme = createTheme(designTokens);
  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
};
