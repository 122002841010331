import { Button, darken, SxProps } from '@mui/material';
import { ComponentProps } from 'react';
import { SiLine } from 'react-icons/si';

type Props = {
  text?: string;
  sx?: SxProps;
  onClick: () => void;
} & ComponentProps<typeof Button>;

const DEFAULT_BUTTON_TEXT = 'メッセージを送信する';

export const SendLineButton = ({ text = DEFAULT_BUTTON_TEXT, sx, ...props }: Props) => {
  return (
    <Button
      variant="contained"
      startIcon={<SiLine />}
      sx={{ backgroundColor: '#06c755', ' &:hover': { backgroundColor: darken('#06c755', 0.1) }, ...sx }}
      {...props}
    >
      {text}
    </Button>
  );
};
