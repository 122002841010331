import { SxProps, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

type ComponentProps = TextFieldProps & {
  sx?: SxProps;
};

export const SearchConditionTextField = forwardRef<HTMLDivElement, ComponentProps>(function _SearchConditionTextField(
  { label, variant = 'outlined', size = 'small', InputLabelProps, ...rest },
  ref
) {
  return (
    <TextField
      {...rest}
      label={label}
      variant={variant}
      size={size}
      InputLabelProps={{
        shrink: true,
        ...(InputLabelProps ?? {}),
      }}
      ref={ref}
    />
  );
});
