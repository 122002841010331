import { useState } from 'react';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { appConst } from 'src/constants/common';
import { AppListSectionData } from 'src/types/common/AppListSectionData';
import { PlannedShelter, PlannedShelterTypeCode } from 'src/types/common/Prs2';

const suspendDataList = [
  {
    header: '予定避難先',
    items: [
      { title: '名称', value: <AppSkeleton /> },
      { title: '住所', value: <AppSkeleton /> },
    ],
  },
] as AppListSectionData[];

export const usePlannedShelterSectionDataList = () => {
  const [sectionDataList, setSectionDataList] = useState<AppListSectionData[]>(suspendDataList);

  const getSectionDataList = (plannedShelters: PlannedShelter[]) => {
    const dataList = plannedShelters.map((plannedShelter) => {
      const header = `予定避難先(${plannedShelter.disasterTypeName})`;
      switch (plannedShelter.shelterTypeCode) {
        case PlannedShelterTypeCode.Home:
          return {
            header,
            items: [{ value: appConst.SHELTER_TYPE.SUMMARY_NAME.HOME }],
          } as AppListSectionData;
        case PlannedShelterTypeCode.Shelter:
          return {
            header,
            items: [
              { title: '名称', value: plannedShelter.shelterName ?? '未設定' },
              { title: '住所', value: plannedShelter.shelterAddress ?? '未設定' },
            ],
          } as AppListSectionData;
        case PlannedShelterTypeCode.Other:
          return {
            header,
            items: [
              { title: '名称', value: plannedShelter.customShelterName ?? '未設定' },
              { title: '住所', value: plannedShelter.customShelterAddress ?? '未設定' },
            ],
          } as AppListSectionData;
        default:
          return {
            header,
            items: [
              { title: '名称', value: '未設定' },
              { title: '住所', value: '未設定' },
            ],
          } as AppListSectionData;
      }
    });

    setSectionDataList(dataList);
  };

  const clearSectionDataList = () => {
    setSectionDataList(suspendDataList);
  };

  return { sectionDataList, getSectionDataList, clearSectionDataList };
};
