import { Chip, ChipProps, CircularProgress } from '@mui/material';
import { useMemo } from 'react';

type ComponentProps = {
  isRunning?: boolean;
};

export const SafetyConfirmationStatusChip = ({ isRunning }: ComponentProps) => {
  const chipProps: { label: ChipProps['label']; color: ChipProps['color'] } = useMemo(() => {
    return isRunning === undefined
      ? {
          label: <CircularProgress size="0.7rem" />,
          variant: 'outlined',
          sx: { minWidth: 80 },
          color: 'default',
        }
      : isRunning
      ? {
          label: '安否確認中',
          color: 'success',
        }
      : {
          label: '停止中',
          color: 'default',
          variant: 'outlined',
        };
  }, [isRunning]);
  return <Chip {...chipProps} size="small" />;
};
