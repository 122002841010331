import { Stack } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams, GridRowSelectionModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/SoshikisList/useQuery';
import { SoshikiGridRow } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/types';
import { Soshiki } from 'src/types/common/Soshiki';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';

type ComponentProps = {
  searchBtnClickFlg: boolean;
  selectedSoshikiList?: Soshiki[];
  onSearchError: (error: SearchError) => void;
  onChangeLoadingStatus: (isLoading: SearchCallerType | undefined) => void;
  onChangeSelectedRows: (selectedRows: GridRowSelectionModel) => void;
  oldAssignIds?: string[];
};

export const SoshikisList = ({
  searchBtnClickFlg,
  selectedSoshikiList,
  onSearchError,
  onChangeLoadingStatus,
  onChangeSelectedRows,
  oldAssignIds
}: ComponentProps) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const {
    isLoading,
    rows,
    error: searchError,
  } = useQuery({searchBtnClickFlg, selectedSoshikiList });

  const [sortedRows, SetSorterdRows] = useState(rows)
  const initialSortModel:GridSortModel = [{field:"name",sort:"asc"}]
  const [sortModel,setSortModel] = useState(initialSortModel)
  useEffect(()=>{
      if(!!rows){
        if(sortModel.find(x=>x.field === 'name')?.sort === 'desc'){
          SetSorterdRows([...rows].sort((a, b) => b.name.localeCompare(a.name,"ja")))
        }else{
          SetSorterdRows([...rows].sort((a, b) => a.name.localeCompare(b.name,"ja")))
        }
      }
  },[rows,sortModel])

  const apiRef: React.MutableRefObject<GridApiCommunity> = useGridApiRef();

  useEffect(() => {
    if(oldAssignIds){
      apiRef.current.setRowSelectionModel(oldAssignIds);
    }
  }, [oldAssignIds]);

  // エラー通知
  useEffect(() => {
    if (searchError) {
      onSearchError(searchError);
    }
  }, [searchError]);

  useEffect(() => {
    onChangeLoadingStatus(isLoading);
  }, [isLoading]);

  const columns: GridColDef<SoshikiGridRow>[] = useMemo(
    () => [
      { field: 'id', 
        headerName: 'ID', 
        width: 90,
        renderCell: (params: GridRenderCellParams<SoshikiGridRow, string>) =>
          params.value,
      },
      { field: 'name', 
        headerName: '支援組織', 
        width: 760, 
        disableColumnMenu: true, 
      },
    ],
    []
  );
  
  // チェックされた情報保持
  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    onChangeSelectedRows(newSelection)
  };

  const handlePaginationChange = (newPagination: GridPaginationModel) => {
    setPaginationModel(newPagination);
  };

  const onSortModelChange =  (model: GridSortModel)=>{
    setSortModel(model)
  }

  return (
    <Stack sx={{ height: '600px',minHeight: '300px', maxHeight: 'calc(100vh - 420px)', overflow: 'auto' }}>
      <DataGrid
        rows={sortedRows?.slice(paginationModel.page * paginationModel.pageSize,paginationModel.page * paginationModel.pageSize +paginationModel.pageSize )}
        rowCount={rows?.length??0}
        columns={columns}
        paginationMode="server"
        pagination
        pageSizeOptions={[paginationModel.pageSize]}
        paginationModel={paginationModel}
        onPaginationModelChange={(newPaginationModel) => handlePaginationChange(newPaginationModel)}
        checkboxSelection
        apiRef={apiRef}
        onRowSelectionModelChange={(newSelectionModel) => handleSelectionChange(newSelectionModel)}
        keepNonExistentRowsSelected
        loading={!!isLoading}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(sortModel) => {
          onSortModelChange(sortModel);
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'circular-progress',
            noRowsVariant: 'skeleton',
          },
          ...(isLoading === 'user'
            ? {
                pagination: {
                  backIconButtonProps: {
                    disabled: true,
                  },
                  nextIconButtonProps: {
                    disabled: true,
                  },
                },
              }
            : {}),
        }}
      />
    </Stack>
  );
};
